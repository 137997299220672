import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { DateTime } from "@kinherit/ts-common";

import { customReportTypes } from "@/module/report/form/read-download-report.form";

interface ComputeDownloadReportMessage {
  customReportType: keyof typeof customReportTypes;
  network: string | null;
  company: string | null;
  created?: [number, number] | null;
  assignedTo?: string | null;
  salesTeam?: string | null;
  includeOptions?: Array<{
    value: string;
    text: string;
    description: string;
  }>;
  tags?: string[] | null;
}

interface ComputeDownloadReportResponse {
  [key: string]: never;
}

export class ComputeDownloadReportHandler extends ActionBase {
  constructor(private message: ComputeDownloadReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeDownloadReportResponse> {
    if (!this.message.created) {
      this.message.created = [
        new Date("2018-01-01").getTime(),
        new Date().getTime(),
      ];
    }
    let startDate: string = new DateTime(this.message.created[0]).formatYMD;
    let endDate: string = new DateTime(this.message.created[1]).formatYMD;

    // Set to the start of the day
    startDate += "T00:00";
    // Set to the end of the day
    endDate += "T23:59";

    const includeOptions = this.message.includeOptions
      ? this.message.includeOptions.map((option) => option.value)
      : [];

    const target =
      this.message.customReportType === "leadReport"
        ? "/v2/portal/report/download/lead-report"
        : this.message.customReportType === "feeReport"
          ? "/v2/portal/report/download/fee-report"
          : this.message.customReportType === "contactReport"
            ? "/v2/portal/report/download/contacts-report"
            : "/v2/portal/report/download/lead-report";

    await Api.resource("portal", target, {})
      .method("get")
      .params({
        network: this.message.network ?? null,
        company: this.message.company ?? null,
        startDate: startDate ?? null,
        endDate: endDate ?? null,
        includeOptions: includeOptions ?? null,
        assignedTo: this.message.assignedTo ?? null,
        salesTeam: this.message.salesTeam ?? null,
        tags: this.message.tags ?? null,
      })
      .download({
        encoding: "text/csv",
        fileName: `${this.message.customReportType}.csv`,
      });

    return {};
  }
}
