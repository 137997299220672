import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Adviser, Kintin } from "@kinherit/sdk";

interface CreateAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface CreateAdviserResponse {
  adviser: Adviser;
}

export class CreateAdviserHandler extends ActionBase {
  constructor(private message: CreateAdviserMessage) {
    super();
  }

  public async execute(): Promise<CreateAdviserResponse> {
    let { defaultTrustIfa } = this.message.adviser.$data;

    if (null === defaultTrustIfa || undefined === defaultTrustIfa) {
      defaultTrustIfa = false;
    }

    const { adviser: adviserData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-adviser/{kintin}/person/adviser",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        defaultTrustIfa: defaultTrustIfa,
        notes: this.message.adviser.$data.notes,
        person: this.message.adviser.$data.person,
        forPerson: this.message.adviser.$data.forPerson,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const adviser = Adviser.$inflate(adviserData).first();

    if (!adviser) {
      throw new Error("Failed to create adviser");
    }

    this.message.adviser.$delete();

    return { adviser };
  }
}
