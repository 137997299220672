import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, FilterModelInternals, In, Like } from "@kinherit/orm";
import {
  DeepPartialArrayApiQuery,
  IIntroducerOutsource,
  IntroducerCompany,
  IntroducerOutsource,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadIntroducerOutsourceMessage {
  name?: string;
  type?: Array<string>;
  status?: Array<string>;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerOutsource;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerOutsourceHandler extends ActionBase {
  constructor(private message: ReadIntroducerOutsourceMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerOutsourceResponse> {
    const request = Api.resource("portal", "/v2/portal/introducer-outsource")
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    const sharedConditions: QueryMask<typeof IntroducerOutsource> = {
      type: {
        id: In(this.message.type),
      },
      status: {
        id: In(this.message.status),
      },
      createdAt: Between(this.message.created),
    };
    const uniqueConditions: DeepPartialArrayApiQuery<
      FilterModelInternals<IntroducerOutsource>
    > = [];

    if (this.message.name) {
      uniqueConditions.push({
        clientName: Like(this.message.name),
        ...sharedConditions,
      });

      uniqueConditions.push({
        company: {
          profile: {
            organisationName: Like(this.message.name),
          },
        },
        ...sharedConditions,
      });
    }

    const query = request.buildQuery(IntroducerOutsource);

    if (uniqueConditions.length > 0) {
      query.where(uniqueConditions);
    } else {
      query.where(sharedConditions);
    }

    const response = await request.result();

    return {
      introducerOutsources: IntroducerOutsource.$inflate(
        response.introducerOutsource,
        this.message.sort,
        response.$rootIds,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      profiles: Profile.$inflate(response.profile),
      pagination: this.getPagination(response),
    };
  }
}
