<template>
  <TrusteesContainer v-if="kintin" :kintin="kintin" @reload="$emit('reload')" />
</template>

<script lang="ts">
import { CreateTrustee } from "@/config/model.config";
import TrusteesContainer from "@/module/kinvault.kintin/component/address-book/TrusteesContainer.vue";
import { UpdateTrusteeForm } from "@/module/kinvault.kintin/form/update-trustee.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import RouteStorageMixin from "@kinherit/framework/component.mixin/route-storage-mixin";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Person, Trustee } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookTrusteesRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookTrusteesRoute,
  components: {
    TrusteesContainer,
  },
  mixins: [KinvaultKintinDetailsMixin, RouteStorageMixin(true, "query")],
  emits: ["reload"],
  data: () => ({
    filtered: {
      trustees: {
        main: Array<Trustee>(),
        reserve: Array<Trustee>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson?.profile.fullName) {
        names.push(this.kintin?.primaryPerson?.profile.fullName);
      }
      if (this.kintin?.secondaryPerson?.profile.fullName) {
        names.push(this.kintin?.secondaryPerson?.profile.fullName);
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });
      const trustees = selectedPerson?.appointedTrustee ?? [
        ...(this.kintin?.primaryPerson?.appointedTrustee ?? []),
        ...(this.kintin?.secondaryPerson?.appointedTrustee ?? []),
      ];
      this.filtered.trustees = trustees.groupBy({
        main: (trustee) => !trustee.isReserve,
        reserve: (trustee) => trustee.isReserve,
      });
      this.filtered.people = trustees
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");
    },
    async createTrustee() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const trustee = CreateTrustee({
        forPerson,
      });
      try {
        await UpdateTrusteeForm({
          data: trustee,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create trustee",
          },
        });
      } catch {
        trustee.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/trustee/create", {
        trustee,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
