import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferral } from "@kinherit/sdk";

interface UpdateAccountReferralMessage {
  referral: AccountReferral;
}

interface UpdateAccountReferralResponse {
  referral: AccountReferral;
}

export class UpdateAccountReferralHandler extends ActionBase {
  constructor(private message: UpdateAccountReferralMessage) {
    super();
  }

  public async execute(): Promise<UpdateAccountReferralResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/account-referral/{accountReferral}",
      {
        accountReferral: this.message.referral,
      },
    )
      .method("patch")

      .body({
        contact: this.message.referral.$data.contact ?? null,
        nextActionAt: this.message.referral.$data.nextActionAt ?? null,
        referralCode: this.message.referral.$data.referralCode ?? null,
      })
      .result();

    const referral = AccountReferral.$inflate(
      response.accountReferral,
      undefined,
      response.$rootIds,
    ).first();

    if (!referral) {
      throw new Error("Failed to update referral code");
    }

    return {
      referral,
    };
  }
}
