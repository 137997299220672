import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem | string;
}

export interface DeleteIntroducerFeePaymentItemResponse {}

export class DeleteIntroducerFeePaymentItemHandler extends ActionBase {
  constructor(private message: DeleteIntroducerFeePaymentItemMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerFeePaymentItemResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-item/{introducerFeePaymentItem}",
      this.message,
    )
      .method("delete")
      .result();

    IntroducerFeePaymentItem.$delete(this.message.introducerFeePaymentItem);

    return {};
  }
}
