<template>
  <PageHeader htag="h1" text="Subscriptions" />
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :is-clickable="false"
    :sort-by-options="{
      billingDate: 'Billing Date',
      'kintin.friendlyName': 'Client',
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #status="{ row }: { row: Subscription }">
      <Badge
        :text="row.status.text"
        :color="row.status.class ? ((`is-` + row.status.class) as any) : ``"
        size="is-small"
        isCompact
      />
    </template>
    <template #files="{ row }: { row: Subscription }">
      <Badge
        :color="row.fileCount > 0 ? `is-purple` : `is-light-grey`"
        size="is-small"
        isCompact
        >{{ row.fileCount }}
      </Badge>
    </template>
    <template #product="{ row }: { row: Subscription }">
      <small
        ><code>{{ row.productSku?.publicText }}</code></small
      >
      {{ row.subscriptionValue?.format }}
    </template>
    <template #progress="{ row }: { row: Subscription }">
      <Badge
        text="Direct Debit"
        :color="row.procDd ? `is-success` : `is-light-grey`"
        size="is-small"
        isCompact
      />
      <Badge
        text="Xero Setup"
        :color="row.procXero ? `is-success` : `is-light-grey`"
        size="is-small"
        isCompact
      />
    </template>
    <template #client="{ row }: { row: Subscription }">
      <a
        class="client-name"
        @click="(event: MouseEvent) => handleClientClick(row, event)"
      >
        {{ row.kintin.friendlyName ?? row.kintin.ref }}
      </a>
      <br />
      <small class="is-family-monospace">{{ row.kintin.ref }}</small>
    </template>
    <template #actions="{ row }: { row: Subscription }">
      <Button
        v-if="
          $auth.hasPermission('subscription:write') &&
          !row.procXero &&
          row.procDd
        "
        @click="handleEditXeroClick(row)"
        class="xero-button"
        :icon-left="$style.icon.subscription.icon"
        ariaLabel="Create Xero Repeating Invoice"
        size="is-small"
        color="is-plain"
        tooltip="Create Xero Repeating Invoice"
      />
      <Button
        v-if="$auth.hasPermission('subscription:write')"
        @click="handleEditClick(row)"
        class="edit-button"
        :icon-left="$style.icon.edit.icon"
        ariaLabel="Edit Subscription"
        size="is-small"
        color="is-plain"
        tooltip="Edit Subscription"
      />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import {
  KintinDetailsSubscriptionParams,
  KintinDetailsSubscriptionRoute,
} from "@/module/kinvault.kintin/page/details/documents/subscriptions";
import { ReadSubscriptionForm } from "@/module/kinvault.subscription/form/read-subscription.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Badge } from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { ISubscription, Subscription } from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultSubscriptionMasterListRoute } from ".";
import { CreateXeroRepeatingInvoice } from "../form/create-xero-repeating-invoice.form";
import { UpdateSubscriptionForm } from "../form/update-subscription.form";

export default defineComponent({
  name: KinvaultSubscriptionMasterListRoute,
  mixins: [AuthService.mixin(), StyleService.mixin],
  components: { MasterListPage, Button, Badge, PageHeader },
  data: () => ({
    filters: ReadSubscriptionForm(),
    columns: [
      {
        title: "Client",
        sort: true,
        slot: "client",
        blockClicks: true,
      },
      {
        title: "Product",
        sort: true,
        slot: "product",
      },
      {
        title: "Status",
        sort: false,
        slot: "status",
      },
      {
        title: "Created",
        sort: true,
        map: (v: Subscription) => v.createdAt.formatDate,
      },
      {
        title: "Billing",
        sort: true,
        map: (v: Subscription) => v.billingDate?.formatDate,
      },
      {
        title: "Ended",
        sort: true,
        map: (v: Subscription) => v.billingEndDate?.formatDate,
      },
      {
        title: "Files",
        sort: true,
        slot: "files",
      },
      {
        title: "Progress",
        sort: false,
        slot: "progress",
      },
      {
        slot: "actions",
        class: "is-narrow",
        blockClicks: true,
      },
    ] as Array<TableColumn>,
    rows: Array<Subscription>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ISubscription,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    async handleEditXeroClick(subscription: Subscription): Promise<void> {
      if (!this.$auth.hasPermission("subscription:write")) {
        return;
      }
      try {
        const data = await CreateXeroRepeatingInvoice({
          formTitle:
            subscription.kintin.friendlyName + " - " + subscription.kintin.ref,
          subscriptionId: subscription.id,
          price:
            subscription.subscriptionValue ??
            new Currency({
              amount: 50,
            }),
          xeroContactId: undefined,
          quantity: 1,
          description: subscription.productSku?.publicText,
          accountCode: "200",
        }).dialog({
          dialog: {
            title: "Xero Repeating Invoice",
          },
          button: {
            ok: {
              text: "Create Repeating Invoice",
            },
          },
        });

        if (undefined === data.xeroContactId) {
          return;
        }

        await window.Kernel.ActionBus.execute(
          "kinvault/subscription/create-xero-repeating-invoice",
          {
            accountCode: data.accountCode,
            description: data.description,
            price: data.price,
            quantity: data.quantity,
            subscriptionId: data.subscriptionId,
            xeroContactId: data.xeroContactId,
          },
        );
      } catch (e) {
        // subscription.$restore();
        console.log(e);
        throw e;
      }

      // subscription.$persist();
    },
    async handleEditClick(subscription: Subscription): Promise<void> {
      if (!this.$auth.hasPermission("subscription:write")) {
        return;
      }

      try {
        await UpdateSubscriptionForm(subscription).dialog({
          dialog: {
            title: "Subscription",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        subscription.$restore();
        throw e;
      }

      subscription.$persist();

      await window.Kernel.ActionBus.execute("kinvault/subscription/update", {
        subscription,
      });

      await this.refresh(this.filters.localData);
    },
    handleClientClick(subscription: Subscription, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KintinDetailsSubscriptionRoute,
          params: {
            kintin: subscription.kintin.id,
            subscription: subscription.id,
          } satisfies KintinDetailsSubscriptionParams,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadSubscriptionForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "kinvault/subscription/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.subscriptions;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
