<template>
  <SidebarPage
    :key="key"
    v-if="cpdSession"
    class="cpd-session-details"
    :name="cpdSession.title"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <CpdDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <CpdDetailsSummary />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateEmailCampaign } from "@/config/model.config";
import {
  EmailCampaignDetailsParams,
  EmailCampaignDetailsRoute,
} from "@/module/admin.email-campaign/page/details";
import { CpdDetailsMixin } from "@/module/cpd/mixin/cpd-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { CpdDetailsParams, CpdDetailsRoute } from ".";
import { CpdSessionsMasterListRoute } from "..";
import { CreateEmailCampaignForm } from "../../form/create-email-campaign.form";
import { UpdateCpdSessionForm } from "../../form/update-cpd-session.form";
import CpdDetailsSidebar from "./CpdDetails.Sidebar.vue";
import { CpdDetailsIntroducersRoute } from "./introducers";
import { CpdDetailsRegistrationsRoute } from "./registrations";
import { CpdDetailsSummaryRoute } from "./summary";
import CpdDetailsSummary from "./summary/CpdDetails.Summary.vue";

export default defineComponent({
  name: CpdDetailsRoute,
  mixins: [CpdDetailsMixin, AuthService.mixin()],
  components: {
    PageNotFound,
    SidebarPage,
    CpdDetailsSummary,
    CpdDetailsSidebar,
    RouterChildView,
  },
  computed: {
    showContextMenu(): boolean {
      return this.$auth.hasPermission("cpd-session:write");
    },
  },
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.execute(
      "cpd/session/record",
      to.params as CpdDetailsParams,
    );
  },
  data: (ctx) => ({
    key: 0,
    tabs: [
      {
        label: "Summary",
        route: {
          name: CpdDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Participants",
        route: {
          name: CpdDetailsIntroducersRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.contact.icon,
      },
      {
        label: "Registrations",
        route: {
          name: CpdDetailsRegistrationsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.signFile.icon,
      },
    ],
  }),
  methods: {
    async contextMenu(): Promise<void> {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
          items: [
            {
              title: "Edit",
              icon: StyleService.icon.edit.icon,
              line1: "Edit this CPD Session",
              action: this.edit,
              premissions: ["cpd-session:write"],
            },
            {
              title: "Email Campaign",
              icon: StyleService.icon.email.icon,
              line1: "Create an email campaign",
              action: this.emailCampaign,
              premissions: ["cpd-session:write"],
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this CPD Session",
              action: this.delete,
              premissions: ["cpd-session:write"],
            },
          ],
        },
        filter: "all",
        path: "premissions",
        callback: (item) => item.action(),
      });

      await contextMenu();
    },
    async edit(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      try {
        await UpdateCpdSessionForm(this.cpdSession).dialog({
          dialog: {
            title: "Edit CPD Session",
          },
        });
      } catch (e) {
        this.cpdSession.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.execute("cpd/session/update", {
        cpdSession: this.cpdSession,
      });

      this.key++;
    },
    async delete(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete CPD Session",
          message: `Are you sure you want to delete this CPD Session?`,
        },
      });

      await window.Kernel.ActionBus.execute("cpd/session/delete", {
        cpdSession: this.cpdSession,
      });

      window.Kernel.Router.push({ name: CpdSessionsMasterListRoute });
    },
    async emailCampaign(): Promise<void> {
      const cpdSession = this.cpdSession;

      if (!cpdSession) {
        return;
      }

      const data = {
        emailCampaign: CreateEmailCampaign(),
        include: null as "registrations" | "participants" | "both" | null,
      };

      data.emailCampaign.name = `${cpdSession.title} - ${cpdSession.date.formatDateTime}`;

      try {
        const { include } = await CreateEmailCampaignForm(
          data.emailCampaign,
        ).dialog({
          dialog: {
            title: "Create Email Campaign",
          },
        });
        data.include = include;
      } catch {
        data.emailCampaign.$delete();
        return;
      }

      const { emailCampaign: newEmailCampaign } =
        await window.Kernel.ActionBus.execute(
          "cpd/session/email-campaign/create",
          {
            emailCampaign: data.emailCampaign,
            cpdSession,
            include: data.include as "registrations" | "participants" | "both",
          },
        );

      await OpenAlertDialog({
        dialog: {
          title: `Email Campaign Created`,
          message: `Would you like to view the email campaign?`,
        },
        button: {
          ok: {
            text: "View",
          },
          cancel: {
            text: "Return",
          },
        },
      });

      window.Kernel.visitRoute({
        name: EmailCampaignDetailsRoute,
        params: {
          emailCampaign: newEmailCampaign.id,
        } satisfies EmailCampaignDetailsParams,
      });
    },
  },
});
</script>
