import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferral,
  AccountReferralCode,
  Address,
  Adviser,
  Attorney,
  Beneficiary,
  CallScript,
  EmailAddress,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  KintinAccess,
  KintinCheck,
  KintinCheckFollowUp,
  Note,
  Order,
  OrderItem,
  Person,
  PhoneNumber,
  Profile,
  Property,
  Trustee,
  User,
  Website,
} from "@kinherit/sdk";

interface RecordKintinCallScriptMessage {
  kintin: string;
}

interface RecordKintinCallScriptResponse {
  addresses: Array<Address>;
  advisers: Array<Adviser>;
  attorneies: Array<Attorney>;
  beneficiaries: Array<Beneficiary>;
  callScripts: Array<CallScript>;
  emailAddresses: Array<EmailAddress>;
  executors: Array<Executor>;
  exclusions: Array<Exclusion>;
  gifts: Array<Gift>;
  guardians: Array<Guardian>;
  introducerCompany: Array<IntroducerCompany>;
  introducerContacts: Array<IntroducerContact>;
  kintinChecks: Array<KintinCheck>;
  kintins: Array<Kintin>;
  kintinAccess: Array<KintinAccess>;
  orderItems: Array<OrderItem>;
  orders: Array<Order>;
  people: Array<Person>;
  phoneNumbers: Array<PhoneNumber>;
  profiles: Array<Profile>;
  properties: Array<Property>;
  referralCodes: Array<AccountReferralCode>;
  referrals: Array<AccountReferral>;
  trustees: Array<Trustee>;
  users: Array<User>;
  websites: Array<Website>;
  notes: Array<Note>;
  kintinCheckFollowUps: Array<KintinCheckFollowUp>;
}

export class RecordKintinCallScriptHandler extends ActionBase {
  constructor(private message: RecordKintinCallScriptMessage) {
    super();
  }

  public async execute(): Promise<RecordKintinCallScriptResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-call-script/{kintin}/call-script",
      {
        kintin: this.message.kintin,
      },
    )
      .method("get")

      .result();

    return {
      kintins: Kintin.$inflate(response.kintin),
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      profiles: Profile.$inflate(response.profile),
      referrals: AccountReferral.$inflate(response.accountReferral),
      kintinAccess: KintinAccess.$inflate(response.kintinAccess),
      users: User.$inflate(response.user),
      people: Person.$inflate(response.person),
      kintinChecks: KintinCheck.$inflate(response.kintinCheck),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
      callScripts: CallScript.$inflate(response.callScript),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      introducerCompany: IntroducerCompany.$inflate(response.introducerCompany),
      properties: Property.$inflate(response.property),
      beneficiaries: Beneficiary.$inflate(response.beneficiary),
      gifts: Gift.$inflate(response.gift),
      executors: Executor.$inflate(response.executor),
      advisers: Adviser.$inflate(response.adviser),
      orders: Order.$inflate(response.order),
      orderItems: OrderItem.$inflate(response.orderItem),
      trustees: Trustee.$inflate(response.trustee),
      exclusions: Exclusion.$inflate(response.exclusion),
      guardians: Guardian.$inflate(response.guardian),
      attorneies: Attorney.$inflate(response.attorney),
      notes: Note.$inflate(response.note),
      kintinCheckFollowUps: KintinCheckFollowUp.$inflate(
        response.kintinCheckFollowUp,
      ),
    };
  }
}
