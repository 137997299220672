import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface DeleteIntroducerContactPartnerPortalMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface DeleteIntroducerContactPartnerPortalResponse {
  [key: string]: never;
}

export class DeleteIntroducerContactPartnerPortalHandler extends ActionBase {
  constructor(private message: DeleteIntroducerContactPartnerPortalMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerContactPartnerPortalResponse> {
    console.log(this.message);
    await Api.resource(
      "portal",
      "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
      {
        introducerContact: this.message.contactPartnerPortalPermissions.contact,
        contactPartnerPortalPermissions:
          this.message.contactPartnerPortalPermissions.$id,
      },
    )
      .method("delete")
      .result();

    this.message.contactPartnerPortalPermissions.$delete();

    return {};
  }
}
