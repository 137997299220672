<template>
  <div class="beneficiary-list">
    <BeneficiarySummary
      v-for="role in beneficiaries.sortBy('sortOrder', 'asc')"
      :key="`beneficiary-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!beneficiaries.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import BeneficiarySummary from "@/module/kinvault.kintin/component/summary-cards/BeneficiarySummary.vue";
import { UpdateBeneficiaryForm } from "@/module/kinvault.kintin/form/update-beneficiary.form";
import Card from "@kinherit/framework/component.layout/card";
import { Beneficiary, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BeneficiaryList",
  components: { BeneficiarySummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    beneficiaries: {
      type: Array as () => Beneficiary[],
      required: true,
    },
  },
  methods: {
    async edit(beneficiary: Beneficiary): Promise<void> {
      try {
        await UpdateBeneficiaryForm({
          data: beneficiary,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Beneficiary",
          },
        });
      } catch {
        beneficiary.$restore();
        return;
      }

      const kintin = this.kintin;

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/beneficiary/update",
        {
          beneficiary,
          kintin,
        },
      );

      this.$emit("reload");
    },
    async remove(beneficiary: Beneficiary): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Beneficiary",
          message: `Are you sure you want to remove this beneficiary?`,
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/beneficiary/delete",
        {
          beneficiary,
          kintin: this.kintin,
        },
      );

      this.$emit("reload");
    },
  },
});
</script>
