import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeSignupsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  brandedKinvault: string | null;
  compareTo: string | null;
}

interface ComputeSignupsReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    createdCount: number;
    completedCount: number;
    declinedCount: number;
    sentCount: number;
  }>;
}
export type TSignupsReportResult = {
  year: number;
  week: number;
  quarter: number;
  month: number;
  createdCount: number;
  completedCount: number;
  declinedCount: number;
  sentCount: number;
};
export class ComputeSignupsReportHandler extends ActionBase {
  constructor(private message: ComputeSignupsReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeSignupsReportResponse> {
    const { year, created, granularity, brandedKinvault, compareTo } =
      this.message || {};

    const calculatedDates = calculateStartEndDates(year, created, compareTo);

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/signups",
      {},
    )
      .method("get")
      .params({
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        granularity: granularity,
        brandedKinvaultId: brandedKinvault ?? null,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];

    // For this dataset we want to split the ones used for graphs to be different to the one for tables.
    response.datasets.forEach((dataset: any, index: number) => {
      datasets.push({
        label: "Created",
        stack: "Stack " + index + " created",
        backgroundColor: "#2ca5c9",
        data: dataset.datasets.map((i: any) => {
          return i.createdCount;
        }),
      });
      datasets.push({
        label: "Sent",
        stack: "Stack " + index + " sent  ",
        backgroundColor: "#0f708c",
        data: dataset.datasets.map((i: any) => {
          return i.sentCount;
        }),
      });
      datasets.push({
        label: "Declined",
        stack: "Stack " + index + " declined  ",
        backgroundColor: "#99123d",
        data: dataset.datasets.map((i: any) => {
          return i.declinedCount;
        }),
      });
      datasets.push({
        label: "Completed",
        stack: "Stack " + index + " complete  ",
        backgroundColor: "#129948",
        data: dataset.datasets.map((i: any) => {
          return i.completedCount;
        }),
      });
    });

    return {
      datasets: datasets,
      labels: response.labels,
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
