import { ReadDashboardStatisticEPHandler } from "@/module/dashboard/action/dashboard-statistic/read-dashboard-statistic-ep.action";
import { ReadDashboardStatisticLAHandler } from "@/module/dashboard/action/dashboard-statistic/read-dashboard-statistic-la.action";
import { ReadSearchProfileHandler } from "@/module/dashboard/action/global-search/read-search-profile.action";
import { ReadNotificationsHandler } from "./notification/read-notifications.action";
import { ModifyPdfHandler } from "./pdf-utils/modify-pdf.action";

export const Actions = {
  "dashboard/notifications/read": ReadNotificationsHandler,
  "dashboard/global-search/profile/read": ReadSearchProfileHandler,
  "dashboard/dashboard-statistic-ep/read": ReadDashboardStatisticEPHandler,
  "dashboard/dashboard-statistic-la/read": ReadDashboardStatisticLAHandler,
  "dashboard/document-utils/modify": ModifyPdfHandler,
};
