<template>
  <div class="order-list">
    <Message v-for="order in $data.orders" :key="order.id">
      <Button
        @click="updateOrder(order)"
        size="is-small"
        icon-left="Edit"
        class="is-pulled-right"
        aria-label="Edit Order"
      />
      <b>Created</b> {{ order.createdAt.formatDate }}<br />
      <span v-if="order.status.value === 'paid'">
        <b class="has-text-success">Paid</b> {{ order.paidAt?.formatDate }}
      </span>
      <span v-else-if="order.status.value === 'pending'">
        <b class="has-text-warning">Pending Payment</b>
      </span>
      <span v-else-if="order.status.value === 'new'">
        <b class="has-text-info">New</b>
      </span>
      <span v-else-if="order.status.value === 'void'">
        <b class="has-text-danger">Void</b>
      </span>
      <span v-else-if="order.status.value === 'refunded'">
        <b class="has-text-danger">Refunded</b>
      </span>
      <br />
      <b>No of Items</b> {{ order.orderItemCount }}<br />
      <span v-if="order.invoiceNumber">
        <b>Invoice No</b> {{ order.invoiceNumber }}
      </span>
      <b>Total Price</b> {{ order.totalPrice?.format ?? "0.00" }} (Exc OPG
      Fees)<br />
      <span
        v-if="
          (order.discountPercentage && order.discountPercentage > 0) ||
          (order.discountValue && order.discountValue.amount > 0)
        "
      >
        <span v-if="!order.discountReason">
          <b class="has-text-danger">Reason for discount not set</b>
        </span>
        <span v-else>
          <b class="has-text-success">Discount Reason</b>
          {{ order.discountReason.value }}
        </span>
      </span>
      <span v-if="order.notes"> <b>Notes</b> {{ order.notes }} </span>
    </Message>
  </div>
</template>

<script lang="ts">
import { UpdateOrderForm } from "@/module/order/form/update-order.form";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import { Kintin, Order } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderList",
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  components: { Message, Button },
  data: () => ({
    orders: [] as Order[],
  }),
  mounted() {
    this.orders = Order.$findBy({
      kintin: {
        id: this.kintin.id,
      },
    });
  },
  methods: {
    async updateOrder(order: Order) {
      try {
        await UpdateOrderForm({
          data: order,
          referralCode: order.kintin?.referral?.referralCode ?? undefined,
        }).dialog({
          dialog: {
            title: "Update Order",
          },
        });
      } catch {
        order.orderItems.forEach((item) => item.$delete());
        await window.Kernel.ActionBus.execute("order/order/record", {
          order,
        });
        return;
      }

      order.$persist();

      await window.Kernel.ActionBus.execute("order/order/update", {
        order,
      });

      this.orders = Order.$findBy({
        kintin: {
          id: this.kintin.id,
        },
      });
    },
  },
});
</script>
