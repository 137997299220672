import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultInvitation, Credential } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";

interface CreateBrandedKinvaultInvitationMessage {
  firstName: string;
  lastName: string;
  emailAddress: string;
  reference: null | string;
  referralCode: null | string;
  adviser: null | string;
  sendInvitationEmail: null | boolean;
  createdBy: Credential;
  brandedKinvaultOverride: null | string; // @todo
  brandedKinvault: string;
}

interface CreateBrandedKinvaultInvitationResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export class CreateBrandedKinvaultInvitationHandler extends ActionBase {
  constructor(private message: CreateBrandedKinvaultInvitationMessage) {
    super();
  }

  public async execute(): Promise<CreateBrandedKinvaultInvitationResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation",
    )
      .method("post")

      .body({
        invite: {
          profile: {
            firstName: this.message.firstName,
            lastName: this.message.lastName,
            emails: [{ email: this.message.emailAddress, primary: true }],
          },
          signUpToken: Uuid.generate(),
          status: "notsent",
          brandedKinvault: this.message.brandedKinvault,
          createdBy: this.message.createdBy.id,
          introducerContact: this.message.adviser,
          externalRef: this.message.reference,
        },
        referralCode: this.message.referralCode,
      })
      .result();

    const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
    ).first();

    if (!brandedKinvaultInvitation) {
      throw new Error("BrandedKinvaultInvitation not found");
    }

    if (this.message.sendInvitationEmail) {
      await Api.resource(
        "portal",
        `/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}`,
        {
          brandedKinvaultInvitation: brandedKinvaultInvitation,
          invitationType: "initial",
        },
      )
        .method("put")

        .result();
    }

    return {
      brandedKinvaultInvitation,
    };
  }
}
