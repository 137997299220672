import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultMembership, Kintin, User } from "@kinherit/sdk";

export interface KintinUserJoinDefaultBrandedKinvaultMessage {
  kintin: Kintin;
  user: User;
}

export interface KintinUserJoinDefaultBrandedKinvaultResponse {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

export class KintinUserJoinDefaultBrandedKinvaultHandler extends ActionBase {
  constructor(private message: KintinUserJoinDefaultBrandedKinvaultMessage) {
    super();
  }

  public async execute(): Promise<KintinUserJoinDefaultBrandedKinvaultResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}/user/{user}/join-default-branded-kinvault",
      {
        kintin: this.message.kintin.id,
        user: this.message.user.id,
      },
    )
      .method("put")

      .result();

    const brandedKinvaultMembership = BrandedKinvaultMembership.$inflate(
      response.brandedKinvaultMembership,
    ).first();

    if (!brandedKinvaultMembership) {
      throw new Error("BrandedKinvault membership not found");
    }

    return {
      brandedKinvaultMembership,
    };
  }
}
