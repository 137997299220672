import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface UpdateKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface UpdateKnowledgeBaseCategoryResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export class UpdateKnowledgeBaseCategoryHandler extends ActionBase {
  constructor(private message: UpdateKnowledgeBaseCategoryMessage) {
    super();
  }

  public async execute(): Promise<UpdateKnowledgeBaseCategoryResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}",
      {
        knowledgeBaseCategory: this.message.knowledgeBaseCategory.id,
      },
    )
      .method("patch")

      .body({
        slug: this.message.knowledgeBaseCategory.slug,
        title: this.message.knowledgeBaseCategory.title,
      })
      .result();

    const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    ).first();

    if (!knowledgeBaseCategory) {
      throw new Error("Failed to update knowledge base category");
    }

    return {
      knowledgeBaseCategory,
    };
  }
}
