import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  EmailAddress,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  Note,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

interface RecordIntroducerContactMessage {
  introducerContact: IntroducerContact | string;
}

interface RecordIntroducerContactResponse {
  introducerContacts: Array<IntroducerContact>;
  introducerCompanies: Array<IntroducerCompany>;
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  websites: Array<Website>;
  introducerNotes: Array<IntroducerNote>;
  notes: Array<Note>;
}

export class RecordIntroducerContactHandler extends ActionBase {
  constructor(private message: RecordIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerContactResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact/{introducerContact}",
      {
        introducerContact: this.message.introducerContact,
      },
    )
      .method("get")

      .result();

    return {
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      profiles: Profile.$inflate(response.profile),
      addresses: Address.$inflate(response.address),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      websites: Website.$inflate(response.website),
      introducerNotes: IntroducerNote.$inflate(response.introducerNote),
      notes: Note.$inflate(response.note),
    };
  }
}
