import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaignLog } from "@kinherit/sdk";

type Message = {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
};

type Response = {};

export class UploadEmailCampaignLogAttachmentHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    for (const file of this.message.files) {
      await Api.resource(
        "portal",
        "/v2/portal/email-campaign-log/{emailCampaignLog}/attachment",
        {
          emailCampaignLog: this.message.emailCampaignLog,
        },
      )
        .method("put")

        .files({
          uploadedFile: file,
        })
        .result();
    }

    return {} as R;
  }
}
