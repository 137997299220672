import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentRun, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentRunMessage {
  query?: QueryMask<typeof IntroducerFeePaymentRun>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerFeePaymentRunResponse {
  introducerFeePaymentRuns: Array<IntroducerFeePaymentRun>;
}

export class ReadIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: ReadIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerFeePaymentRunResponse> {
    const request = Api.resource(
      "core",
      "/v2/core/select/introducer-fee-payment-run",
    )
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(IntroducerFeePaymentRun).where(this.message.query ?? {});

    const response = await request.result();

    return {
      introducerFeePaymentRuns: IntroducerFeePaymentRun.$inflate(
        response.introducerFeePaymentRun,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
