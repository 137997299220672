import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  BrandedKinvault,
  BrandedKinvaultMembership,
  Credential,
  EmailAddress,
  Kintin,
  KintinAccess,
  PhoneNumber,
  Profile,
  User,
  Website,
} from "@kinherit/sdk";

interface RecordUserMessage {
  user: string | User;
}

interface RecordUserResponse {
  user: User | null;
  credentials: Array<Credential>;
  profiles: Array<Profile>;
  phoneNumbers: Array<PhoneNumber>;
  addresses: Array<Address>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  brandedKinvaults: Array<BrandedKinvault>;
  brandedKinvaultMemberships: Array<BrandedKinvaultMembership>;
  kintinAccess: Array<KintinAccess>;
  kintins: Array<Kintin>;
}

export class RecordUserHandler extends ActionBase {
  constructor(private message: RecordUserMessage) {
    super();
  }

  public async execute(): Promise<RecordUserResponse> {
    const result = await Api.resource("portal", "/v2/portal/user/{user}", {
      user: this.message.user,
    })
      .method("get")

      .result();

    return {
      user: User.$inflate(result.user).first() as User,
      credentials: Credential.$inflate(result.credential),
      profiles: Profile.$inflate(result.profile),
      phoneNumbers: PhoneNumber.$inflate(result.phoneNumber),
      addresses: Address.$inflate(result.address),
      emailAddresses: EmailAddress.$inflate(result.emailAddress),
      websites: Website.$inflate(result.website),
      brandedKinvaults: BrandedKinvault.$inflate(result.brandedKinvault),
      brandedKinvaultMemberships: BrandedKinvaultMembership.$inflate(
        result.brandedKinvaultMembership,
      ),
      kintinAccess: KintinAccess.$inflate(result.kintinAccess),
      kintins: Kintin.$inflate(result.kintin),
    };
  }
}
