import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailAddress, Profile, QueryMask, User } from "@kinherit/sdk";

export interface ReadUserMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  query?: QueryMask<typeof User>;
}

interface ReadUserResponse {
  users: Array<User>;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
}

export class ReadUserHandler extends ActionBase {
  constructor(private message: ReadUserMessage) {
    super();
  }

  public async execute(): Promise<ReadUserResponse> {
    const request = Api.resource("core", "/v2/core/select/user")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(User).where(this.message.query ?? {});

    const response = await request.result();

    return {
      users: User.$inflate(response.user, undefined, response.$rootIds),
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
    };
  }
}
