import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface CreateIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export interface CreateIntroducerFeePaymentRunResponse {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export class CreateIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: CreateIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerFeePaymentRunResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run",
    )
      .method("post")
      .body({
        completedAt: this.message.introducerFeePaymentRun.$data.completedAt,
        name: this.message.introducerFeePaymentRun.$data.name,
        status: this.message.introducerFeePaymentRun.$data.status,
      })
      .result();

    const introducerFeePaymentRun = IntroducerFeePaymentRun.$inflate(
      repsonse.introducerFeePaymentRun,
    ).first();

    if (!introducerFeePaymentRun) {
      throw new Error("Failed to create introducerfeepaymentrun");
    }

    this.message.introducerFeePaymentRun.$delete();

    return {
      introducerFeePaymentRun: introducerFeePaymentRun,
    };
  }
}
