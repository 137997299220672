import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface UpdateKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: FileLog;
}

interface UpdateKnowledgeBaseDocumentFileLogResponse {}

export class UpdateKnowledgeBaseDocumentFileLogHandler extends ActionBase {
  constructor(private message: UpdateKnowledgeBaseDocumentFileLogMessage) {
    super();
  }

  public async execute(): Promise<UpdateKnowledgeBaseDocumentFileLogResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}",
      {
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
        fileLog: this.message.file.id,
      },
    )
      .method("patch")
      .body({
        fileName: this.message.file.fileName,
      })
      .result();

    return {};
  }
}
