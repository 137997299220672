import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IXeroContact, QueryMask, XeroContact } from "@kinherit/sdk";

export interface ReadXeroContactMessage {
  query?: QueryMask<typeof XeroContact>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IXeroContact;
    direction: "asc" | "desc";
  };
}

interface ReadXeroContactResponse {
  xeroContacts: Array<XeroContact>;
}

export class ReadXeroContactHandler extends ActionBase {
  constructor(private message: ReadXeroContactMessage) {
    super();
  }

  public async execute(): Promise<ReadXeroContactResponse> {
    const request = Api.resource("core", "/v2/core/select/xero-contact")
      .method("get")
      .sort({
        sortBy: this.message.sort?.by ?? "contactName",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(XeroContact).where(this.message.query ?? {});

    const response = await request.result();

    return {
      xeroContacts: XeroContact.$inflate(
        response.xeroContact,
        this.message.sort,
        response.$rootIds,
      ),
    };
  }
}
