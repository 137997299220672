import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

type Response = {
  emailLog: EmailLog;
};

export class UpdateEmailLogHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}",
      [Kintin.$name]:
        "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}",
      [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}",
      [Person.$name]:
        "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}",
    } as const;

    const route = routes[this.message.owner.$name];

    const localAttachments =
      this.message.emailLog.attachments.pluck("id") ?? [];
    const locatNamedAttachments =
      this.message.emailLog.namedAttachments.pluck("id") ?? [];

    const response = await Api.resource("portal", route, {
      owner: this.message.owner.$id,
      emailLog: this.message.emailLog,
    })
      .method("patch")

      .body({
        template: this.message.emailLog.$data.template,
        status: this.message.emailLog.$data.status,
        data: this.message.emailLog.$data.data,
        preloadedFiles: this.message.emailLog.$data.preloadedFiles,
        cc: this.message.emailLog.$data.cc,
        bcc: this.message.emailLog.$data.bcc,
        to: this.message.emailLog.$data.to,
        from: this.message.emailLog.$data.from,
        title: this.message.emailLog.$data.title,
        subject: this.message.emailLog.$data.subject,
        sentAt: this.message.emailLog.$data.sentAt,
        html: this.message.emailLog.$data.html,
        markdown: this.message.emailLog.$data.markdown,
      })
      .result();

    const emailLog = EmailLog.$inflate(response.emailLog).first();

    if (!emailLog) {
      throw new Error("Failed to update email log");
    }

    // Unnamed attachments
    const remoteAttachments = emailLog.attachments.pluck("id");
    const deletedAttachments = remoteAttachments.remove(...localAttachments);

    await window.Kernel.ActionBus.execute("core/email-log/attachment/upload", {
      owner: this.message.owner,
      emailLog,
      files: this.message.files,
    });

    for (const fileLog of deletedAttachments) {
      await window.Kernel.ActionBus.execute(
        "core/email-log/attachment/delete",
        {
          owner: this.message.owner,
          emailLog,
          fileLog,
        },
      );
    }

    // Named attachments
    const remoteNamedAttachments = emailLog.namedAttachments.pluck("id");
    const deletedNamedAttachments = remoteNamedAttachments.remove(
      ...locatNamedAttachments,
    );

    await window.Kernel.ActionBus.execute(
      "core/email-log/named-attachment/create",
      {
        owner: this.message.owner,
        emailLog,
        files: this.message.requiredFiles,
      },
    );

    for (const emailNamedAttachment of deletedNamedAttachments) {
      await window.Kernel.ActionBus.execute(
        "core/email-log/named-attachment/delete",
        {
          owner: this.message.owner,
          emailLog,
          emailNamedAttachment,
        },
      );
    }

    return {
      emailLog,
    } as R;
  }
}
