import { DownloadIntroducerRemittanceHandler } from "./download-introducer-remittance.action";
import { ReadIntroducerFeeKintinHandler } from "./read-introducer-fee-kintin.action";
import { CreateIntroducerInvoiceHandler } from "./create-introducer-invoice.action";
import { DownloadIntroducerFeeKintinCsvHandler } from "./download-introducer-fee-csv.action";

export const Actions = {
  "introducer-fee/kintin/read": ReadIntroducerFeeKintinHandler,
  "introducer-fee/kintin/download": DownloadIntroducerRemittanceHandler,
  "introducer-fee/invoice/create": CreateIntroducerInvoiceHandler,
  "introducer-fee/kintin/csv": DownloadIntroducerFeeKintinCsvHandler,
};
