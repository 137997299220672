import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferral,
  AccountReferralCode,
  Address,
  BrandedKinvault,
  BrandedKinvaultMembership,
  CallScript,
  EmailAddress,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNetwork,
  Kintin,
  KintinAccess,
  KintinCheck,
  Lead,
  Person,
  PhoneNumber,
  Profile,
  Property,
  User,
  Website,
} from "@kinherit/sdk";

interface RecordKintinMessage {
  kintin: string;
}

interface RecordKintinResponse {
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  referralCodes: Array<AccountReferralCode>;
  kintinAccess: Array<KintinAccess>;
  users: Array<User>;
  people: Array<Person>;
  kintinChecks: Array<KintinCheck>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  callScripts: Array<CallScript>;
  introducerContacts: Array<IntroducerContact>;
  introducerCompany: Array<IntroducerCompany>;
  properties: Array<Property>;
  brandedKinvaults: Array<BrandedKinvault>;
  leads: Array<Lead>;
  brandedKinvaultMemberships: Array<BrandedKinvaultMembership>;
  introducerNetworks: Array<IntroducerNetwork>;
}

export class RecordKintinHandler extends ActionBase {
  constructor(private message: RecordKintinMessage) {
    super();
  }

  public async execute(): Promise<RecordKintinResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}",
      {
        kintin: this.message.kintin,
      },
    )
      .method("get")

      .result();

    return {
      kintins: Kintin.$inflate(response.kintin),
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      profiles: Profile.$inflate(response.profile),
      referrals: AccountReferral.$inflate(response.accountReferral),
      kintinAccess: KintinAccess.$inflate(response.kintinAccess),
      users: User.$inflate(response.user),
      people: Person.$inflate(response.person),
      kintinChecks: KintinCheck.$inflate(response.kintinCheck),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
      callScripts: CallScript.$inflate(response.callScript),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      introducerCompany: IntroducerCompany.$inflate(response.introducerCompany),
      properties: Property.$inflate(response.property),
      brandedKinvaults: BrandedKinvault.$inflate(response.brandedKinvault),
      leads: Lead.$inflate(response.lead),
      brandedKinvaultMemberships: BrandedKinvaultMembership.$inflate(
        response.brandedKinvaultMembership,
      ),
      introducerNetworks: IntroducerNetwork.$inflate(
        response.introducerNetwork,
      ),
    };
  }
}
