import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, BrandedKinvaultPricingSchema } from "@kinherit/sdk";

export interface UpdateBrandedKinvaultPricingSchemaMessage {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

interface UpdateBrandedKinvaultPricingSchemaResponse {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

export class UpdateBrandedKinvaultPricingSchemaHandler extends ActionBase {
  constructor(private message: UpdateBrandedKinvaultPricingSchemaMessage) {
    super();
  }

  public async execute(): Promise<UpdateBrandedKinvaultPricingSchemaResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-pricing-schema/{brandedKinvaultPricingSchema}",
      this.message,
    )
      .method("patch")
      .body({
        name: this.message.brandedKinvaultPricingSchema.name,
        schema: this.message.brandedKinvaultPricingSchema.schema,
        brandedKinvaults:
          this.message.brandedKinvaultPricingSchema.$data.brandedKinvaults,
        startDate:
          this.message.brandedKinvaultPricingSchema.startDate?.formatMachine,
        endDate:
          this.message.brandedKinvaultPricingSchema.endDate?.formatMachine,
        isDefault: this.message.brandedKinvaultPricingSchema.isDefault,
      });

    const response = await request.result();

    const brandedKinvaultPricingSchema = BrandedKinvaultPricingSchema.$inflate(
      response.brandedKinvaultPricingSchema,
      undefined,
      response.$rootIds,
    ).first();

    if (!brandedKinvaultPricingSchema) {
      throw new Error(
        "Failed to update branded kinvault kinvault pricing schema",
      );
    }

    return {
      brandedKinvaultPricingSchema,
    };
  }
}
