import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CpdSession, EmailCampaign } from "@kinherit/sdk";

export interface CreateEmailCampaignMessage {
  cpdSession: CpdSession;
  emailCampaign: EmailCampaign;
  include: "registrations" | "participants" | "both";
}

export interface CreateEmailCampaignResponse {
  emailCampaign: EmailCampaign;
}

export class CreateEmailCampaignHandler extends ActionBase {
  constructor(private message: CreateEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<CreateEmailCampaignResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/cpd-session/{cpdSession}/email-campaign/{members}",
      {
        cpdSession: this.message.cpdSession,
        members: this.message.include,
      },
    )
      .method("post")

      .body({
        name: this.message.emailCampaign.name,
        description: this.message.emailCampaign.description,
      });

    const response = await request.result();

    this.message.emailCampaign.$delete({
      address: true,
    });

    this.message.emailCampaign.$delete();

    const emailCampaign = EmailCampaign.$inflate(
      response.emailCampaign,
    ).first();

    if (!emailCampaign) {
      throw new Error(`Failed to create email campaign`);
    }

    return {
      emailCampaign,
    };
  }
}
