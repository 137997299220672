import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";

export interface SearchAddressMessage {
  postcode: string;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface SearchAddressResponse {
  addresses: Array<Address>;
}

export class SearchAddressHandler extends ActionBase {
  constructor(private message: SearchAddressMessage) {
    super();
  }

  public async execute(): Promise<SearchAddressResponse> {
    const request = Api.resource(
      "external",
      "/v2/external/get-address/{postcode}",
      {
        postcode: this.message.postcode,
      },
    )
      .method("get")

      .paginate(this.message.pagination ?? false);

    const response = await request.result();

    return {
      addresses: Address.$inflate(
        response.address.map((address) => ({
          id: Uuid.generate(),
          createdAt: new DateTime().formatMachine,
          ...address,
          primaryMailing: false,
          primaryResidential: false,
        })),
        {
          by: "summary",
          direction: "asc",
        },
      ),
    };
  }
}
