import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferralCode } from "@kinherit/sdk";

interface DeleteAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode;
}

interface DeleteAccountReferralCodeResponse {
  [key: string]: never;
}

export class DeleteAccountReferralCodeHandler extends ActionBase {
  constructor(private message: DeleteAccountReferralCodeMessage) {
    super();
  }

  public async execute(): Promise<DeleteAccountReferralCodeResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/referral/account-referral-code/{accountReferralCode}",
      {
        accountReferralCode: this.message.accountReferralCode,
      },
    )
      .method("delete")

      .result();

    this.message.accountReferralCode.$delete();

    return {};
  }
}
