import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { PhysicalStorageFileLog } from "@kinherit/sdk";

export interface UpdateStorageRequestMessage {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

interface UpdateStorageRequestResponse {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

export class UpdateStorageRequestHandler extends ActionBase {
  constructor(private message: UpdateStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<UpdateStorageRequestResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/storage-request/{physicalStorageFileLog}",
      {
        physicalStorageFileLog: this.message.physicalStorageFileLog,
      },
    )
      .method("patch")
      .body({
        completedAt: this.message.physicalStorageFileLog.$data.completedAt,
        confirmedBy: this.message.physicalStorageFileLog.$data.confirmedBy,
        createdBy: this.message.physicalStorageFileLog.$data.createdBy,
        file: this.message.physicalStorageFileLog.$data.file,
        logAction: this.message.physicalStorageFileLog.$data.logAction,
        notes: this.message.physicalStorageFileLog.$data.notes,
        requestedAt: this.message.physicalStorageFileLog.$data.requestedAt,
      })

      .result();

    const physicalStorageFileLog = PhysicalStorageFileLog.$inflate(
      response.physicalStorageFileLog,
    ).first();

    if (!physicalStorageFileLog) {
      throw new Error("Failed to update storage request");
    }

    return {
      physicalStorageFileLog,
    };
  }
}
