import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContact } from "@kinherit/sdk";

interface UpdateIntroducerContactMessage {
  introducerContact: IntroducerContact;
}

interface UpdateIntroducerContactResponse {
  introducerContact: IntroducerContact;
}

export class UpdateIntroducerContactHandler extends ActionBase {
  constructor(private message: UpdateIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerContactResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact/{introducerContact}",
      {
        introducerContact: this.message.introducerContact,
      },
    )
      .method("patch")
      .body({
        newsLetter: this.message.introducerContact.$data.newsLetter,
        status: this.message.introducerContact.$data.status,
        callStatus: this.message.introducerContact.$data.callStatus,
      })

      .result();

    const introducerContact = IntroducerContact.$inflate(
      response.introducerContact,
    ).first();

    if (!introducerContact) {
      throw new Error("Failed to update contact");
    }

    return {
      introducerContact,
    };
  }
}
