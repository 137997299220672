import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, Kintin, Person, User } from "@kinherit/sdk";

type DownloadOfficerLetterMessage = {
  kintin: Kintin;
  officer: Person;
  address: Address;
  specialist: User;
};

interface DownloadOfficerLetterResponse {
  [key: string]: never;
}

export class DownloadOfficerLetterHandler extends ActionBase {
  constructor(private message: DownloadOfficerLetterMessage) {
    super();
  }

  public async execute(): Promise<DownloadOfficerLetterResponse> {
    const { kintin, officer, address, specialist } = this.message;

    await Api.resource("portal", "/v2/portal/officer-letter/download")
      .method("get")
      .params({
        kintin: kintin.id,
        officer: officer.id,
        address: address.id,
        specialist: specialist.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${kintin.ref} Officer Letter - ${
          officer.profile.knownAs ?? officer.profile.fullName
        }`,
      });

    return {};
  }
}
