import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Trustee } from "@kinherit/sdk";

interface DeleteTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface DeleteTrusteeResponse {
  [key: string]: never;
}

export class DeleteTrusteeHandler extends ActionBase {
  constructor(private message: DeleteTrusteeMessage) {
    super();
  }

  public async execute(): Promise<DeleteTrusteeResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-trustee/{kintin}/person/trustee/{trustee}",
      {
        kintin: this.message.kintin,
        trustee: this.message.trustee.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.trustee.$delete();

    return {};
  }
}
