import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin } from "@kinherit/sdk";

export interface DownloadPackingSlipMessage {
  kintin: Kintin;
  options: Array<
    | "0_packingslip"
    | "1_signingcoverletter"
    | "2_will"
    | "3_signingconfirmation"
    | "4_lpa"
    | "5_dos"
  >;
  primaryProducts: Array<string>;
  secondaryProducts: Array<string>;
}

export class DownloadPackingSlipHandler extends ActionBase {
  constructor(private message: DownloadPackingSlipMessage) {
    super();
  }

  public async execute() {
    const primaryPerson = this.message.kintin.primaryPerson;
    const secondaryPerson = this.message.kintin.secondaryPerson;

    const surname = [
      primaryPerson.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    await Api.resource("portal", "/v2/portal/kintin-process/packing-slip")
      .method("get")
      .params({
        ...this.message,
        kintin: this.message.kintin.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${this.message.kintin.ref} Packing Slip - ${surname}`,
      });
  }
}
