import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePayment, IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface DownloadIntroducerFeePaymentRemittanceMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
  introducerFeePayment: IntroducerFeePayment;
  downloadType:
    | "comp-adv-cli-pro"
    | "comp-adv-cli"
    | "comp-adv"
    | "comp"
    | "client-by-adviser"
    | "client-only"
    | "amount-only"
    | "flying-colours"
    | "sort-refer";
}

export interface DownloadIntroducerFeePaymentRemittanceResponse {}

export class DownloadIntroducerFeePaymentRemittanceHandler extends ActionBase {
  constructor(private message: DownloadIntroducerFeePaymentRemittanceMessage) {
    super();
  }

  public async execute(): Promise<void> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment/remittance/{introducerFeePayment}/{downloadType}",
      this.message,
    ).method("get");

    await request.download({
      encoding: "application/pdf",
      fileName: `remittance-advice-${this.message.downloadType}-${this.message.introducerFeePaymentRun.name}.pdf`,
    });
  }
}
