import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Like, Between, Equal } from "@kinherit/orm/index";
import {
  Api,
  IKnowledgeBaseDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";

export interface ReadKnowledgeBaseDocumentMessage {
  name?: string;
  created?: null | [number, number];
  category?: KnowledgeBaseCategory | string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKnowledgeBaseDocument;
    direction: "asc" | "desc";
  };
}

interface ReadKnowledgeBaseDocumentResponse {
  knowledgeBaseDocuments: Array<KnowledgeBaseDocument>;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKnowledgeBaseDocumentHandler extends ActionBase {
  constructor(private message: ReadKnowledgeBaseDocumentMessage) {
    super();
  }

  public async execute(): Promise<ReadKnowledgeBaseDocumentResponse> {
    const request = Api.resource("portal", "/v2/portal/knowledge-base-document")
      .method("get")

      .sort({
        by: this.message.sort.by,
        direction: this.message.sort.direction,
      })
      .paginate({
        currentPage: this.message.pagination?.currentPage,
        perPage: this.message.pagination?.perPage,
      });

    request.buildQuery(KnowledgeBaseDocument).where({
      title: Like(this.message.name),
      createdAt: Between(this.message.created),
      categories: {
        id: Equal(
          "string" === typeof this.message.category
            ? this.message.category
            : this.message.category?.id,
        ),
      },
    });

    const response = await request.result();

    return {
      knowledgeBaseDocuments: KnowledgeBaseDocument.$inflate(
        response.knowledgeBaseDocument,
        this.message.sort,
        response.$rootIds,
      ),
      knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
        response.knowledgeBaseCategory,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
