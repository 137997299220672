import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvault } from "@kinherit/sdk";

export interface CreateBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault;
}

interface CreateBrandedKinvaultResponse {
  brandedKinvault: BrandedKinvault;
}

export class CreateBrandedKinvaultHandler extends ActionBase {
  constructor(private message: CreateBrandedKinvaultMessage) {
    super();
  }

  public async execute(): Promise<CreateBrandedKinvaultResponse> {
    const response = await Api.resource("portal", "/v2/portal/branded-kinvault")
      .method("post")
      .body({
        profile: {
          organisationName:
            this.message.brandedKinvault.profile.organisationName,
        },
        kinvaultTheme: {
          footer: {},
          header: {},
          login: {},
          brand: this.message.brandedKinvault.kinvaultTheme.brand,
        },
        frontendUrl: this.message.brandedKinvault.$data.frontendUrl,
        clientPoolType: this.message.brandedKinvault.$data.clientPoolType,
        type: this.message.brandedKinvault.$data.type,
        systemEmail: this.message.brandedKinvault.$data.systemEmail,
        xeroContact: this.message.brandedKinvault.$data.xeroContact,
      })
      .result();

    const brandedKinvault = BrandedKinvault.$inflate(
      response.brandedKinvault,
    ).first();

    if (!brandedKinvault) {
      throw new Error("Failed to create branded kinvault");
    }

    this.message.brandedKinvault.$delete({
      kinvaultTheme: true,
      profile: {
        addresses: true,
        emails: true,
        phoneNumbers: true,
        websites: true,
      },
    });

    return {
      brandedKinvault,
    };
  }
}
