import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Exclusion } from "@kinherit/sdk";

interface DeleteKintinExclusionMessage {
  exclusion: Exclusion;
}

interface DeleteKintinExclusionResponse {
  [key: string]: never;
}

export class DeleteKintinExclusionHandler extends ActionBase {
  constructor(private message: DeleteKintinExclusionMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinExclusionResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-exclusion/{kintin}/exclusion/{exclusion}",
      {
        kintin: this.message.exclusion.$data.kintin,
        exclusion: this.message.exclusion.$data.id,
      },
    )
      .method("delete")

      .result();

    this.message.exclusion.$delete();

    return {};
  }
}
