import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between } from "@kinherit/orm/index";
import {
  IIntroducerFeePaymentItem,
  IntroducerFeePaymentItem,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentItemMessage {
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePaymentItem;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentItemResponse {
  introducerFeePaymentItems: Array<IntroducerFeePaymentItem>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerFeePaymentItemHandler extends ActionBase {
  constructor(private message: ReadIntroducerFeePaymentItemMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerFeePaymentItemResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-item",
    )
      .method("get")
      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(IntroducerFeePaymentItem).where({
      createdAt: Between(this.message.createdAt),
    });

    const response = await request.result();

    return {
      introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
        response.introducerFeePaymentItem,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
