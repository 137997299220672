import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface UpdateBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

interface UpdateBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export class UpdateBrandedKinvaultSubscriptionReferralHandler extends ActionBase {
  constructor(
    private message: UpdateBrandedKinvaultSubscriptionReferralMessage,
  ) {
    super();
  }

  public async execute(): Promise<UpdateBrandedKinvaultSubscriptionReferralResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
      {
        brandedKinvaultSubscriptionReferral:
          this.message.brandedKinvaultSubscriptionReferral,
      },
    )
      .method("patch")
      .body({
        subscription:
          this.message.brandedKinvaultSubscriptionReferral.$data.subscription,
        subscriptionIntent:
          this.message.brandedKinvaultSubscriptionReferral.$data
            .subscriptionIntent,
        referralCode:
          this.message.brandedKinvaultSubscriptionReferral.referralCode?.id,
      });
    const result = await request.result();

    const brandedKinvaultSubscriptionReferral =
      BrandedKinvaultSubscriptionReferral.$inflate(
        result.brandedKinvaultSubscriptionReferral,
      ).first();

    if (!brandedKinvaultSubscriptionReferral) {
      throw new Error("Failed to update subscription");
    }

    return {
      brandedKinvaultSubscriptionReferral,
    };
  }
}
