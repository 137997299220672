import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ChangeMyPasswordMessage {
  newPassword: string;
}

interface ChangeMyPasswordResponse {
  [key: string]: never;
}

export class ChangeMyPasswordHandler extends ActionBase {
  constructor(private message: ChangeMyPasswordMessage) {
    super();
  }

  public async execute(): Promise<ChangeMyPasswordResponse> {
    await Api.resource("auth", "/v2/auth/change-password")
      .method("put")
      .body({
        newPassword: this.message.newPassword,
      })

      .result();

    return {};
  }
}
