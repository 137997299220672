<template>
  <PageHeader htag="h1" :text="title">
    <template #buttons>
      <Button
        v-if="hasWritePermission"
        class="create"
        size="is-normal"
        @click="createIntroducerContact"
      >
        Add Contact
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :row-classes="rowClasses"
    :sort-by-options="{
      'companies.profile.organisationName': 'Company',
      createdAt: 'Created',
      'profile.fullName': 'Name',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #name="{ row }">
      <a class="has-text-weight-bold" @click="handleClick(row, $event)">{{
        row.profile.fullName
      }}</a>
      <Badge
        v-if="row.status.value === 'dormant'"
        text="Dormant"
        color="is-warning"
        size="is-small"
        isCompact
      />
      <Badge
        v-if="row.status.value === 'left'"
        text="Left Company"
        color="is-danger"
        size="is-small"
        isCompact
      />
      <Badge
        v-if="row.status.value === 'dead'"
        text="Deceased"
        color="is-purple"
        size="is-small"
        isCompact
      />
    </template>
    <template #companies="{ row }: { row: IntroducerContact }">
      <span v-for="company in row.companies" :key="company.id">
        {{ company.profile.organisationName ?? company.profile.fullName }}
        <br />
      </span>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadIntroducerContactForm } from "@/module/introducer.contact/form/read-introducer-contact.form";
import { AuthService } from "@/service/auth.service";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerContact, IntroducerContact, Tag } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import { IntroducerContactMasterListRoute } from ".";
import { CreateIntroducerContactForm } from "../form/create-introducer-contact.form";
import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from "./details";

export default defineComponent({
  name: IntroducerContactMasterListRoute,
  mixins: [AuthService.mixin()],
  components: { MasterListPage, Button, PageHeader, Badge },
  props: {
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filters: ReadIntroducerContactForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        slot: "name",
      },
      {
        title: "Job Title",
        sort: true,
        map: (v: IntroducerContact) => v.profile.jobTitle,
      },
      {
        title: "Email",
        sort: true,
        map: (v: IntroducerContact) =>
          v.profile.emails.pluck("email").join(", "),
      },
      {
        title: "Companies",
        sort: true,
        slot: "companies",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerContact>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerContact,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Introducer Contacts";
    },
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-contact:write");
    },
  },
  async mounted(): Promise<void> {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light": ["left", "dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-purple-light": ["dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-warning-light": scope.row.status?.value === "dormant",
      };
    },
    handleClick(introducerContact: IntroducerContact, event: MouseEvent): void {
      const params: IntroducerContactDetailsParams = {
        introducerContact: introducerContact.id,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerContactDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerContactForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "introducer/contact/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.introducerContacts;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createIntroducerContact(): Promise<void> {
      const introducerContact = await CreateIntroducerContactForm().dialog({
        dialog: {
          title: "Create Introducer Contact",
        },
      });

      await window.Kernel.ActionBus.execute("introducer/contact/create", {
        introducerContact,
      });

      this.refresh(this.filters.localData);
    },
  },
});
</script>
