import { Api } from "@/service/api.service";
import { AuthService } from "@/service/auth.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerOutsource } from "@kinherit/sdk";

interface CreateIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource;
}

interface CreateIntroducerOutsourceResponse {
  introducerOutsource: IntroducerOutsource;
}

export class CreateIntroducerOutsourceHandler extends ActionBase {
  constructor(private message: CreateIntroducerOutsourceMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerOutsourceResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-outsource",
    )
      .method("post")

      .body({
        clientName: this.message.introducerOutsource.$data.clientName,
        status: this.message.introducerOutsource.$data.status,
        type: this.message.introducerOutsource.$data.type,
        company: this.message.introducerOutsource.$data.company,
        expectedValue: this.message.introducerOutsource.$data.expectedValue,
        paidValue: this.message.introducerOutsource.$data.paidValue,
        paidAt: this.message.introducerOutsource.$data.paidAt,
        nextActionAt: this.message.introducerOutsource.$data.nextActionAt,
        createdBy: AuthService.loggedInUser?.$data.id,
        assignedTo: this.message.introducerOutsource.$data.assignedTo,
        datedAt: this.message.introducerOutsource.$data.datedAt,
      })
      .result();

    const introducerOutsource = IntroducerOutsource.$inflate(
      response.introducerOutsource,
    ).first();

    if (!introducerOutsource) {
      throw new Error("Failed to create Introducer Outsource");
    }

    this.message.introducerOutsource.$delete();

    return {
      introducerOutsource,
    };
  }
}
