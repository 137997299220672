import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";

export interface DeleteEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface DeleteEmailCampaignResponse {
  [key: string]: never;
}

export class DeleteEmailCampaignHandler extends ActionBase {
  constructor(private message: DeleteEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<DeleteEmailCampaignResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/{emailCampaign}",
      this.message,
    )
      .method("delete")

      .result();

    this.message.emailCampaign.$delete();

    return {};
  }
}
