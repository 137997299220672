import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface PreviewKintinSignDocDisclaimerMessage {
  signDoc: SignDoc;
  kintin: Kintin | string;
}

export interface PreviewKintinSignDocDisclaimerResponse {
  [key: string]: never;
}

export class PreviewKintinSignDocDisclaimerHandler extends ActionBase {
  constructor(private message: PreviewKintinSignDocDisclaimerMessage) {
    super();
  }

  public async execute(): Promise<PreviewKintinSignDocDisclaimerResponse> {
    const response = (await Api.resource(
      "portal",
      "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/preview",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
        signDoc: this.message.signDoc.id,
      },
    )
      .method("get")
      .result()) as never as string;

    const win = window.open("", "_blank");
    if (win) {
      win.document.write(response);
    }

    return {};
  }
}
