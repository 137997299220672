import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  Api,
  IKintinAccess,
  IntroducerContact,
  Kintin,
  KintinAccess,
} from "@kinherit/sdk";

export interface ReadKintinAccessMessage {
  introducerContact: IntroducerContact;
  search?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKintinAccess;
    direction: "asc" | "desc";
  };
}

interface ReadKintinAccessResponse {
  kintinAccess: Array<KintinAccess>;
  kintin: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKintinAccessHandler extends ActionBase {
  constructor(private message: ReadKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinAccessResponse> {
    const request = Api.resource("portal", "/v2/portal/kintin-user-access")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(KintinAccess).where({
      introducerContact: {
        id: Equal(this.message.introducerContact.$id),
      },
      kintin: {
        friendlyName: Like(this.message.search),
      },
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      kintinAccess: KintinAccess.$inflate(
        response.kintinAccess,
        this.message.sort,
        response.$rootIds,
      ),
      kintin: Kintin.$inflate(response.kintin),
      pagination: this.getPagination(response),
    };
  }
}
