import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeAgreementsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  compareTo: string | null;
}

interface ComputeAgreementsReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    agreementCount: number;
    signedCount: number;
    culmativeCount: number;
    culmativeSignedCount: number;
  }>;
}

export type TAgreementReportResult = {
  year: number;
  week: number;
  quarter: number;
  agreementCount: number;
  signedCount: number;
  culmativeCount: number;
  culmativeSignedCount: number;
};

export class ComputeAgreementsReportHandler extends ActionBase {
  constructor(private message: ComputeAgreementsReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeAgreementsReportResponse> {
    const { year, created, granularity, compareTo } = this.message || {};

    const calculatedDates = calculateStartEndDates(year, created, compareTo);

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/agreements",
      {},
    )
      .method("get")
      .params({
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        granularity: granularity,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];

    const colours = ["#e63427", "#ba1c11"];
    // For each dataset returned, create a new dataset object with the correct format
    // for the chart.js graph
    response.datasets.forEach((dataset: any, index: number) => {
      datasets.push({
        label: "Agreements",
        stack: "Stack " + index,
        backgroundColor: colours[index],
        data: dataset.datasets.map((i: any) => {
          return i.culmativeCount;
        }),
      });
    });

    return {
      datasets: datasets,
      labels: response.labels,
      // @ts-ignore
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
