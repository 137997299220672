import { UpdateLeadSettingsHandler } from "@/module/lead/action/lead/update-lead-settings.action";
import { ConvertLeadToKintinHandler } from "./convert/convert-lead-to-kintin.action";
import { CreateLeadHandler } from "./lead/create-lead.action";
import { DeleteLeadHandler } from "./lead/delete-lead.action";
import { ReadLeadHandler } from "./lead/read-lead.action";
import { RecordLeadHandler } from "./lead/record-lead.action";
import { UpdateLeadProfileHandler } from "./profile/update-lead-profile.action";
import { ReadLeadHistoryHandler } from "@/module/lead/action/history/read-lead-changes.action";

export const Actions = {
  "lead/read": ReadLeadHandler,
  "lead/record": RecordLeadHandler,
  "lead/delete": DeleteLeadHandler,
  "lead/create": CreateLeadHandler,
  "lead/profile/update": UpdateLeadProfileHandler,
  "lead/convert/kintin": ConvertLeadToKintinHandler,
  "lead/settings/update": UpdateLeadSettingsHandler,
  "lead/history/read": ReadLeadHistoryHandler,
};
