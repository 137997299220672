<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
    }"
  >
    <PageHeader htag="h2" text="Who Does What">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <Tabs
      v-model:tab="tab"
      is-lazy
      position="both"
      :config="
        ['Intro', 'Executors', 'Trustees', 'LPAs', 'IFAs', 'Outro'].map(
          (x) => ({ label: x }),
        )
      "
    >
      <!-- Tab  Intro -->
      <template #intro>
        <IntroTab />
      </template>
      <!-- Tab  Executors -->
      <template #executors>
        <ExecutorsTab />
      </template>
      <!-- Tab  Trustees -->
      <template #trustees>
        <TrusteesTab />
      </template>
      <!-- Tab  LPAs -->
      <template #lpas>
        <LPAsTab />
      </template>
      <!-- Tab  IFAs -->
      <template #ifas>
        <IFAsTab />
      </template>
      <!-- Tab Outro -->
      <template #outro>
        <OutroTab />
      </template>
    </Tabs>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import {
  KintinDetailsCallScriptParams,
  KintinDetailsCallScriptRoute,
} from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptClientSummaryRoute } from "../client-summary";
import ExecutorsTab from "./ExecutorsTab.vue";
import IFAsTab from "./IFAsTab.vue";
import IntroTab from "./IntroTab.vue";
import LPAsTab from "./LPAsTab.vue";
import OutroTab from "./OutroTab.vue";
import TrusteesTab from "./TrusteesTab.vue";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    PageHeader,
    Button,
    Tabs,
    IntroTab,
    ExecutorsTab,
    TrusteesTab,
    LPAsTab,
    IFAsTab,
    OutroTab,
  },
  data: () => ({
    isFocused: false,
    tab: 0,
  }),
  methods: {
    async saveAndContinueHandler() {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      this.$snackBar.success.saved();
      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptClientSummaryRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/call-script/update",
        {
          kintin: this.kintin,
        },
      );

      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
