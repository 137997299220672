<template>
  <div v-if="kintin" :key="renderKey">
    <Card title="Progress">
      <KintinStatus :kintin="kintin" />
      <template #buttons>
        <small>
          Status Last Updated:
          {{
            kintin.statusUpdatedAt
              ? kintin.statusUpdatedAt.formatDate
              : "Not Set"
          }}</small
        >
      </template>
    </Card>
    <Card
      v-if="isAutoChase"
      title="Autochase"
      :class="'has-background-warning-light'"
    >
      <p>
        <strong class="is-block">This account is in Autochase.</strong>
        <span v-if="!kintin.statusUpdatedAt">
          Warning: The status updated at field is not set! No emails will be
          sent.
        </span>
        <span v-else>
          The last status update was at
          <strong>{{ kintin.statusUpdatedAt.formatDate }}</strong>
          <ul>
            <li v-for="(s, i) in autoChaseSchedule" :key="i">
              <strong>{{ s.date }}</strong> - {{ s.action }}
            </li>
          </ul>
        </span>
      </p>
    </Card>
    <CollapsableWarningWidget
      v-if="accountWarnings.length"
      :items="accountWarnings"
      class="mb-5"
    />
    <Card
      v-if="kintin.$data.isEscalated"
      :class="'has-background-danger-light'"
    >
      <strong class="has-text-danger-dark"
        >This account has been escalated and is considered high priority</strong
      >
    </Card>
    <Card
      v-if="kintin.process?.value === 'lite'"
      :class="'has-background-info-light'"
    >
      <strong class="has-text-info-dark"
        >Standalone Kinvault: if this person now needs planning, this account
        should be changed to Advised</strong
      >
    </Card>
    <Card v-if="kintin.referral?.referralCode?.code" title="Referral">
      <strong
        v-if="kintin.referral?.referralCode?.company?.profile.fullName"
        class="mb-2"
      >
        {{ kintin.referral?.referralCode?.company?.profile.fullName }}
        <span v-if="kintin.referral?.referralCode?.company?.network">
          ({{ kintin.referral?.referralCode?.company.network?.name }})
        </span>
      </strong>
      <div v-if="kintin.referral?.referralCode.code === 'sortrefer'">
        <Button
          color="is-info"
          is-outlined
          size="is-small"
          @click="managereferral"
        >
          Show SortRefer Integration Data
        </Button>
      </div>

      <div v-if="kintin.referral.referralCode.isFeeSacrifice" class="mb-3">
        <strong class="has-text-warning-dark mb-3"
          >Introducer Sacrificing Fee</strong
        ><br />
        Ensure client gets appropriate discount &amp; correct discount code is
        used
      </div>
      <div v-if="kintin.referral.referralCode.isSalarySacrifice" class="mb-3">
        <strong class="has-text-warning-dark mb-3"
          >Salary Sacrifice Scheme</strong
        ><br />
        Salary Sacrifice is available for this client. Ensure employer is
        invoiced if taken.
      </div>
      <div v-if="kintin.referral.referralCode.isFriendsAndFamily" class="mb-3">
        <strong class="has-text-warning-dark mb-3">Friends And Family</strong
        ><br />
        Allow for 30% discount: ensure correct discount code is used. No
        introducer fees payable
      </div>
      <div v-if="kintin.referral.referralCode.notes" class="mb-3">
        <strong class="has-text-grey mb-3">Referral Notes</strong><br />
        {{ kintin.referral.referralCode.notes }}
      </div>
      <div v-if="kintin.referral.contact" class="mb-3">
        <strong class="has-text-grey">Introducer Contact</strong><br />
        {{ kintin.referral.contact.profile.fullName }}
      </div>
      <div>
        <strong class="has-text-grey">Recommended Kinvault</strong>
      </div>
      <div v-if="kintin.process.value !== 'lpasonly'">
        <div v-if="defaultBrandedKinvaultReferralCodes">
          https://{{
            defaultBrandedKinvaultReferralCodes.brandedKinvault?.frontendUrl
          }}
        </div>
        <div v-else>Kinherit Default</div>
      </div>
      <div v-else>No Kinvault access required</div>
      <div
        v-if="
          defaultBrandedKinvaultReferralCodes &&
          kintin.process?.value !== 'lpasonly'
        "
        class="mt-3"
      >
        <p v-if="$kintinCheck.checkIsYes(kintin, '9.0.0')">
          User has agreed to allow for IFA access: ensure IFA branded Kinvault
          is used if appropriate
        </p>
        <p v-else>
          User has either not agreed, or it's unknown as to whether they've
          allowed for IFA Kinvault Access
        </p>
      </div>
    </Card>
    <Card title="Users">
      <template #buttons>
        <Button icon-left="Plus" @click="$emit('add-user')"> Add User </Button>
      </template>
      <Table :rows="accessTableRows" :columns="userTable.columns">
        <template #name="{ row }">
          <div @click="() => visitUser(row)" class="is-flex is-clickable">
            <Avatar
              style="align-self: center"
              hide-tooltip
              size="is-normal"
              :name="row.user.profile.fullName ?? undefined"
            />
            <div class="ml-3">
              <div class="is-underlined">
                {{ row.user.profile.fullName }}
              </div>
              <span
                v-if="row.user.isDisabled"
                class="badge is-small is-compact is-rounded is-danger"
              >
                Disabled
              </span>
              <small v-if="row.user.credential?.username" class="is-block">{{
                row.user.credential?.username
              }}</small>
            </div>
          </div>
        </template>
        <template #kvOpen="{ row }">
          <Icon :icon="row.allowAccess ? `Success` : `Cross`" />
        </template>
        <template #options="{ row }">
          <div class="buttons mt-0 is-right">
            <Button
              v-if="row.allowAccess"
              icon-left="Cross"
              is-outlined
              @click="toggleAccess(row)"
            >
              Revoke
            </Button>
            <Button
              v-else
              icon-left="Success"
              is-outlined
              @click="toggleAccess(row)"
            >
              Grant
            </Button>
            <div class="buttons are-compact my-0">
              <Button is-outlined @click="manageOrganisations(row)">
                Organisations
              </Button>
              <Button
                v-if="
                  !!defaultBrandedKinvaultReferralCodes &&
                  row.user.brandedKinvaultMemberships.filter(
                    (m: any) =>
                      m.brandedKinvault.id ===
                      defaultBrandedKinvaultReferralCodes?.$data
                        .brandedKinvault,
                  ).length === 0
                "
                is-outlined
                @click="joinDefaultBrandedKinvault(row)"
              >
                Join Default
              </Button>
            </div>
          </div>
        </template>
      </Table>
    </Card>
    <Card v-if="notes.length > 0" title="Pinned Notes" class="pinned-notes">
      <NoteSummary
        v-for="note in $data.notes"
        :key="`note-${note.id}`"
        :note="note"
        is-plain
      />
    </Card>
  </div>
</template>

<script lang="ts">
import {
  AdminUserDetailsJoinBrandedKinvaultRoute,
  AdminUserDetailsParams,
  AdminUserDetailsRoute,
} from "@/module/admin.user/page/details";
import UserDetailsOrganisations from "@/module/admin.user/page/details/UserDetails.BrandedKinvaults.vue";
import NoteSummary from "@/module/core/component/note/NoteSummary.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { KintinCheckService } from "@/module/kinvault.kintin/service/kintin-check.service";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import KintinStatus from "@/shared/component/kintin/KintinStatus.vue";
import CollapsableWarningWidget from "@/shared/component/widget/CollapsableWarningWidget.vue";
import { OpenComponentDialog } from "@kinherit/framework/component.config/component-dialog";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import Icon from "@kinherit/framework/component.display/icon";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Equal } from "@kinherit/orm";
import {
  AccountReferralCode,
  DefaultBrandedKinvaultReferralCode,
  KintinAccess,
  Note,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsSummaryRoute } from ".";

const userTable = {
  columns: Array<TableColumn>().concat(
    {
      title: "Name",
      slot: "name",
    },
    {
      title: "KV Open",
      slot: "kvOpen",
    },
    {
      title: "Access",
      map: (a: KintinAccess) => a.accessType.ucFirst(),
    },
    {
      title: "",
      slot: "options",
      blockClicks: true,
    },
  ),
};

export default defineComponent({
  name: KinvaultKintinDetailsSummaryRoute,
  components: {
    Card,
    KintinStatus,
    Button,
    Table,
    Icon,
    Avatar,
    NoteSummary,
    CollapsableWarningWidget,
  },
  mixins: [KinvaultKintinDetailsMixin, KintinCheckService.mixin],
  emits: ["add-user"],
  data: () => ({
    renderKey: 0,
    userTable,
    notes: Array<Note>(),
    accessTableRows: Array<KintinAccess>(),
    defaultBrandedKinvaultReferralCodes:
      null as null | DefaultBrandedKinvaultReferralCode,
  }),
  computed: {
    isAutoChase() {
      if (!this.kintin) return false;
      if (
        ["c", "d", "e", "f"].includes(this.kintin?.stage?.value) &&
        this.kintin?.status?.value === "nurture"
      ) {
        return true;
      }
      return false;
    },
    isAutoChaseable() {
      return !!this.kintin?.statusUpdatedAt;
    },
    autoChaseSchedule() {
      if (
        !this.isAutoChase ||
        !this.isAutoChaseable ||
        !this.kintin ||
        !this.kintin.statusUpdatedAt
      ) {
        return null;
      }

      const schedule: { date: string; action: string }[] = [];

      if (!this.kintin?.statusUpdatedAt) {
        return schedule;
      }

      const seedDate = this.kintin.statusUpdatedAt;
      const actions = [
        { stage: "c", days: 2, action: "C4" },
        { stage: "c", days: 7, action: "C4" },
        { stage: "c", days: 10, action: "C4" },
        { stage: "c", days: 14, action: "C4" },
        { stage: "c", days: 28, action: "C4" },
        { stage: "c", days: 29, action: "C6A" },
        { stage: "c", days: 60, action: "C7" },
        { stage: "c", days: 90, action: "C7" },
        { stage: "c", days: 180, action: "C7" },
        { stage: "c", days: 270, action: "C7" },
        { stage: "c", days: 365, action: "C9" },

        { stage: "d", days: 5, action: "D2B" },
        { stage: "d", days: 14, action: "D2C1" },
        { stage: "d", days: 21, action: "D2C2" },
        { stage: "d", days: 25, action: "D2D" },
        { stage: "d", days: 26, action: "D2E" },
        { stage: "d", days: 28, action: "D2F" },
        { stage: "d", days: 29, action: "D2G" },
        { stage: "d", days: 60, action: "BCD1" },
        { stage: "d", days: 90, action: "BCD1" },
        { stage: "d", days: 180, action: "BCD1" },
        { stage: "d", days: 270, action: "BCD1" },
        { stage: "d", days: 365, action: "BCD4" },

        { stage: "e", days: 7, action: "E4" },
        { stage: "e", days: 10, action: "E4" },
        { stage: "e", days: 14, action: "E4" },
        { stage: "e", days: 28, action: "E4" },
        { stage: "e", days: 60, action: "E5" },
        { stage: "e", days: 90, action: "E5" },
        { stage: "e", days: 180, action: "E5" },
        { stage: "e", days: 270, action: "E5" },
        { stage: "e", days: 365, action: "E6" },

        { stage: "f", days: 14, action: "F5A" },
        { stage: "f", days: 28, action: "F5A" },
        { stage: "f", days: 60, action: "F5B" },
        { stage: "f", days: 90, action: "F5B" },
        { stage: "f", days: 120, action: "F5C" },
        { stage: "f", days: 180, action: "F5C" },
        { stage: "f", days: 270, action: "F5C" },
        { stage: "f", days: 365, action: "F10" },
      ].filter((a) => a.stage === this.kintin?.stage?.value);

      for (const { days, action } of actions) {
        schedule.push({
          date: seedDate.clone().add(days, "day").formatDate,
          action,
        });
      }

      return schedule;
    },
    accountWarnings() {
      const warnings = [];
      if (this.kintin?.estatePlanners.isEmpty()) {
        warnings.push("No Estate Planner has been assigned to this account");
      }
      if (this.kintin?.isActive === false) {
        warnings.push("This account has been flagged as inactive");
      }
      if (!this.kintin?.referral?.referralCode?.code) {
        warnings.push("No Referral Code has been assigned to this account");
      }
      if (this.kintin?.process?.value === "placeholder") {
        warnings.push(
          "This account is a placeholder account, and may be removed in future versions",
        );
      }
      if (["z1", "z2"].includes(this.kintin?.stage?.value as string)) {
        warnings.push(
          "This account is being dealt with by the Bereavement Team",
        );
      }
      return warnings;
    },
    referralCode(): AccountReferralCode | null {
      return this.kintin?.referral?.referralCode ?? null;
    },
  },
  async mounted(): Promise<void> {
    this.accessTableRows = this.kintin?.assignedUsers ?? [];
    const { notes } = await window.Kernel.ActionBus.execute("core/note/read", {
      kintin: this.$params.kintin,
      pinned: true,
      pagination: {
        perPage: 3,
      },
      sort: {
        by: "createdAt",
        direction: "desc",
      },
    });

    this.notes = notes;

    const code = this.kintin?.referral?.referralCode?.code;

    if (code) {
      const { defaultBrandedKinvaultReferralCodes } =
        await window.Kernel.ActionBus.execute(
          "core/select/default-branded-kinvault-referral-codes/read",
          {
            query: {
              code: Equal(code),
            },
          },
        );

      this.defaultBrandedKinvaultReferralCodes =
        defaultBrandedKinvaultReferralCodes.first() ?? null;
    }
  },
  methods: {
    managereferral(): void {
      // @todo
    },
    async joinDefaultBrandedKinvault(access: KintinAccess): Promise<void> {
      const { user, kintin } = access;

      if (!user) {
        throw new Error(`User not found`);
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/user/join-default-branded-kinvault",
        {
          user,
          kintin,
        },
      );

      SnackBarService.toast({
        text: "User joined default branded Kinvault",
        type: "success",
      });

      this.renderKey++;
    },
    async manageOrganisations(access: KintinAccess): Promise<void> {
      const user = access.user;

      if (!user) {
        throw new Error(`User not found`);
      }

      await OpenComponentDialog({
        component: FormComponentWrapper(UserDetailsOrganisations)({
          props: {
            userOverride: user,
          },
        }),
        button: {
          cancel: {
            show: false,
          },
          left: {
            text: "Add Branded Kinvault",
            click: () =>
              window.Kernel.Router.push({
                name: AdminUserDetailsJoinBrandedKinvaultRoute,
                params: {
                  user: user.id,
                } satisfies AdminUserDetailsParams,
              }),
            show: AuthService.hasPermission("branded-kinvault:write"),
          },
        },
        dialog: {
          title: `Manage Branded Kinvaults`,
        },
        value: "",
      });
    },
    async toggleAccess(access: KintinAccess): Promise<void> {
      if (!access.user) {
        throw new Error(`User not found`);
      }

      await OpenAlertDialog({
        dialog: {
          title: `${access.allowAccess ? "Revoke" : "Grant"} Access`,
          message: `Are you sure you want to ${
            access.allowAccess ? "revoke" : "grant"
          } ${access.user.profile.fullName} access to this Kinvault?`,
        },
        button: {
          ok: {
            text: `${access.allowAccess ? "Revoke" : "Grant"} Access`,
          },
        },
      });

      access.allowAccess = !access.allowAccess;

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/kintin-user-access/update",
        {
          kintin: this.$params.kintin,
          kintinAccess: access,
        },
      );

      this.accessTableRows = this.kintin?.assignedUsers ?? [];
      this.renderKey++;
    },
    visitUser(access: KintinAccess): void {
      if (!access.user) {
        throw new Error(`User not found`);
      }

      window.Kernel.Router.push({
        name: AdminUserDetailsRoute,
        params: { user: access.user.id } as AdminUserDetailsParams,
      });
    },
  },
});
</script>
