import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";

interface Message {
  emailCampaign: EmailCampaign;
  amendments: {
    profile: string;
    emailAddress: string;
  }[];
  file: File;
}

interface Response {
  [key: string]: never;
}

export class UploadEmailCampaignMembersCsvHandler extends ActionBase {
  constructor(private message: Message) {
    super();
  }

  public async execute(): Promise<Response> {
    const { file } = this.message;

    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/{emailCampaign}/members/csv/upload",
      {
        emailCampaign: this.message.emailCampaign,
      },
    )
      .method("put")

      .files({
        amendments: JSON.stringify(this.message.amendments),
        uploadedFile: file,
      })
      .result();

    return {};
  }
}
