import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  ContactPartnerPortalPermissions,
  Credential,
  EmailAddress,
  IIntroducerContact,
  IntroducerCompany,
  IntroducerContact,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyContactsMessage {
  introducerCompany: IntroducerCompany | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerContact;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyContactsResponse {
  introducerContacts: Array<IntroducerContact>;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
  credentials: Array<Credential>;
  contactPartnerPortalPermissions: Array<ContactPartnerPortalPermissions>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerCompanyContactsHandler extends ActionBase {
  constructor(private message: ReadIntroducerCompanyContactsMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerCompanyContactsResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}/contacts",
      {
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    const response = await request.result();

    return {
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
        this.message.sort,
        response.$rootIds,
      ),
      credentials: Credential.$inflate(response.credential),
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      contactPartnerPortalPermissions: ContactPartnerPortalPermissions.$inflate(
        response.contactPartnerPortalPermissions,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
