<template>
  <div class="pdf-utils">
    <PageHeader htag="h1" text="PDF Utilities" />
    <!--
      //@update:data="isValid = $data.form?.isValid() ?? false"-->
    <Card title="Modify PDF">
      <Form v-if="$data.form" :config="$data.form" :is-borderless="true" />
      <Button class="mt-3" @click="uploadPdf">Modify PDF</Button>
    </Card>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { Button } from "@kinherit/framework/component.input/button";
import { PdfUtilsRoute } from ".";
import { ModifyPdfForm } from "../form/modify-pdf.form";

import Form from "@kinherit/framework/component.form/form";
import Card from "@kinherit/framework/component.layout/card";

export default defineComponent({
  name: PdfUtilsRoute,
  components: {
    PageHeader,
    Button,
    Card,
    Form,
  },
  mixins: [AuthService.mixin({ sessionRequired: true })],
  data: () => ({
    form: ModifyPdfForm({
      pdfDocument: [] as File[],
      selectedWatermarkOption: 0 as number,
      selectedPasswordProtectionOption: 0 as number,
      selectedRestrictEditingOption: 0 as number,
      selectedRestrictPrintingOption: 0 as number,
      documentPassword: null as string | null,
    }),
  }),
  methods: {
    async uploadPdf() {
      this.form.options.showValidation = true;

      this.form.controls.incrementFormRenderKey();
      console.log(this.form.localData);
      if (!this.form.localData.pdfDocument) {
        throw new Error("No file selected?");
      }
      const file = this.form.localData.pdfDocument.first();
      const result = await window.Kernel.ActionBus.execute(
        "dashboard/document-utils/modify",
        {
          pdfDocument: file as File,
          selectedWatermarkOption: this.form.localData.selectedWatermarkOption,
          selectedPasswordProtectionOption:
            this.form.localData.selectedPasswordProtectionOption,
          selectedRestrictEditingOption:
            this.form.localData.selectedRestrictEditingOption,
          selectedRestrictPrintingOption:
            this.form.localData.selectedRestrictPrintingOption,
          documentPassword: this.form.localData.documentPassword,
        },
      );

      console.log(result);
    },
  },
});
</script>
