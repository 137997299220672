import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerFeePaymentRun, Option } from "@kinherit/sdk/index";
import { DateTime, Uuid } from "@kinherit/ts-common/index";

export const UpdateIntroducerFeePaymentRunForm = (
  introducerFeePaymentRun: IntroducerFeePaymentRun = new IntroducerFeePaymentRun(
    {
      id: Uuid.generate(),
      name: new DateTime().formatYMD,
      createdAt: new DateTime().formatMachine,
      status: Option.$findOneByOrThrow({
        group: "introducerFeePaymentRunStatus",
        value: "draft",
      }).id,
      introducerFeePayments: [],
      introducerFeePaymentsCount: 0,
    },
  ),
) =>
  defineForm({
    name: "update-introducer-fee-payment-run",
    data: () => introducerFeePaymentRun,
    formAreas: (data) => [
      defineFormArea({
        name: "details",
        data,
        template: GridLayout([["name"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
        }),
      }),
    ],
  });
