import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { WillRevision } from "@kinherit/sdk";

interface UpdateWillRevisionMessage {
  willRevision: WillRevision;
}

interface UpdateWillRevisionResponse {
  willRevision: WillRevision;
}

export class UpdateWillRevisionHandler extends ActionBase {
  constructor(private message: UpdateWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<UpdateWillRevisionResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/will-revision/{willRevision}",
      {
        willRevision: this.message.willRevision.id,
      },
    )
      .method("patch")

      .body({
        approvedAt: this.message.willRevision.$data.approvedAt,
        approvedBy: this.message.willRevision.$data.approvedBy,
        content: this.message.willRevision.$data.content,
        createdBy: this.message.willRevision.$data.createdBy,
        hasBusinessClause: this.message.willRevision.$data.hasBusinessClause,
        hasBusinessTrust: this.message.willRevision.$data.hasBusinessTrust,
        hasEstateProtectionTrust:
          this.message.willRevision.$data.hasEstateProtectionTrust,
        hasIipTrust: this.message.willRevision.$data.hasIipTrust,
        kintin: this.message.willRevision.$data.kintin,
      })
      .result();

    const willRevision = WillRevision.$inflate(response.willRevision).first();

    if (!willRevision) {
      throw new Error("Will revision not found");
    }

    return {
      willRevision,
    };
  }
}
