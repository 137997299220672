import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm/index";
import {
  AccountReferral,
  AccountReferralCode,
  Api,
  IOrder,
  Kintin,
  Order,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadOrderMessage {
  search?: string;
  type?: "kintin" | "trustReg";
  paymentType: Array<string>;
  referral: Array<string>;
  status: Array<string>;
  assignedUser: Array<string>;
  created: null | [number, number];
  paidWithin: null | [number, number];
  kintin?: string | Kintin;
  pagination:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
}

interface ReadOrderResponse {
  orders: Array<Order>;
  kintins: Array<Kintin>;
  referrals: Array<AccountReferral>;
  profiles: Array<Profile>;
  referralCodes: Array<AccountReferralCode>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadOrderHandler extends ActionBase {
  constructor(private message: ReadOrderMessage) {
    super();
  }

  private getKintinParams(): Array<QueryMask<typeof Order>> {
    const params1: QueryMask<typeof Order> = {
      kintin: {
        ref: Like(this.message.search),
        id: IsNotNull(),
        referral: {
          referralCode: {
            id: In(this.message.referral),
          },
        },
      },
      status: {
        id: In(this.message.status),
      },
      createdAt: Between(this.message.created),
      paidAt: Between(this.message.paidWithin),
      paymentType: { id: In(this.message.paymentType) },
      createdBy: { id: In(this.message.assignedUser) },
    };

    const params2: QueryMask<typeof Order> = {
      kintin: {
        friendlyName: Like(this.message.search),
        id: IsNotNull(),
        referral: { id: In(this.message.referral) },
      },
      status: {
        id: In(this.message.status),
      },
      createdAt: Between(this.message.created),
      paidAt: Between(this.message.paidWithin),
      paymentType: { id: In(this.message.paymentType) },
      createdBy: { id: In(this.message.assignedUser) },
    };

    if (this.message.search) {
      return [params1, params2];
    }

    return [params1];
  }

  private getTrustRegParams(): Array<QueryMask<typeof Order>> {
    const params1: QueryMask<typeof Order> = {
      isTrustReg: Equal(true),
      status: {
        id: In(this.message.status),
      },
      createdAt: Between(this.message.created),
      paidAt: Between(this.message.paidWithin),
      paymentType: { id: In(this.message.paymentType) },
    };

    return [params1];
  }

  public async execute(): Promise<ReadOrderResponse> {
    if (undefined === this.message.type) {
      this.message.type = "kintin";
    }

    const request = Api.resource("portal", "/v2/portal/order")
      .method("get")

      .sort({
        sortBy: this.message.sort.by,
        sortDirection: this.message.sort.direction,
      })
      .paginate(this.message.pagination);

    const queryBuilder = request.buildQuery(Order);

    if (this.message.kintin) {
      queryBuilder.where({
        kintin: {
          id: Equal(
            "string" === typeof this.message.kintin
              ? this.message.kintin
              : this.message.kintin.id,
          ),
        },
      });
    } else {
      if (this.message.type === "kintin") {
        this.getKintinParams().forEach((query) => queryBuilder.where(query));
      } else {
        this.getTrustRegParams().forEach((query) => queryBuilder.where(query));
      }
    }

    const response = await request.result();

    return {
      orders: Order.$inflate(
        response.order,
        this.message.sort,
        response.$rootIds,
      ),
      kintins: Kintin.$inflate(response.kintin),
      referrals: AccountReferral.$inflate(response.accountReferral),
      profiles: Profile.$inflate(response.profile),
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      users: User.$inflate(response.user),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
