<template>
  <RouterChildView> </RouterChildView>
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams, KintinDetailsCallScriptRoute } from ".";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: { RouterChildView },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.execute(
      "kinvault/kintin/call-script/record",
      to.params as KintinDetailsCallScriptParams,
    );
  },
});
</script>
