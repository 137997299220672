import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  CpdSession,
  IIntroducerCpd,
  IntroducerContact,
  IntroducerCpd,
} from "@kinherit/sdk";

export interface ReadIntroducerContactIntroducerCpdMessage {
  introducerContact: IntroducerContact | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerCpd;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerContactIntroducerCpdResponse {
  introducerCpds: Array<IntroducerCpd>;
  cpdSessions: Array<CpdSession>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerContactIntroducerCpdHandler extends ActionBase {
  constructor(private message: ReadIntroducerContactIntroducerCpdMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContactIntroducerCpdResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-contact/{introducerContact}/cpd",
      {
        introducerContact:
          "string" === typeof this.message.introducerContact
            ? this.message.introducerContact
            : this.message.introducerContact.id,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    const response = await request.result();

    return {
      introducerCpds: IntroducerCpd.$inflate(
        response.introducerCpd,
        this.message.sort,
        response.$rootIds,
      ),
      cpdSessions: CpdSession.$inflate(response.cpdSession),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
