import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  CommunicationNote,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type CreateNoteMessage = {
  note: Note;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string | IntroducerOutsource;
    }
);

type Controllers =
  | "/v2/portal/kintin-note/{owner}/notes"
  | "/v2/portal/lead-note/{owner}/notes"
  | "/v2/portal/introducer-company-note/{owner}/notes"
  | "/v2/portal/introducer-contact-note/{owner}/notes"
  | "/v2/portal/officer-note/{owner}/notes"
  | "/v2/portal/introducer-outsource-note/{owner}/notes";

interface CreateNoteResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
}

export class CreateNoteHandler extends ActionBase {
  constructor(private message: CreateNoteMessage) {
    super();
  }

  public async execute(): Promise<CreateNoteResponse> {
    let path = "" as any;
    const params = {} as any;
    const message = this.message;

    if ("kintin" in message && message.kintin) {
      path = "/v2/portal/kintin-note/{owner}/notes";
      params.owner = message.kintin;
    } else if ("lead" in message && message.lead) {
      path = "/v2/portal/lead-note/{owner}/notes";
      params.owner = message.lead;
    } else if ("introducerCompany" in message && message.introducerCompany) {
      path = "/v2/portal/introducer-company-note/{owner}/notes";
      params.owner = message.introducerCompany;
    } else if ("introducerContact" in message && message.introducerContact) {
      path = "/v2/portal/introducer-contact-note/{owner}/notes";
      params.owner = message.introducerContact;
    } else if ("person" in message && message.person) {
      path = "/v2/portal/officer-note/{owner}/notes";
      params.owner = message.person;
    } else if (
      "introducerOutsource" in message &&
      message.introducerOutsource
    ) {
      path = "/v2/portal/introducer-outsource-note/{owner}/notes";
      params.owner = message.introducerOutsource;
    } else {
      throw new Error("Invalid message");
    }

    const company =
      "introducerCompany" in message ? message.introducerCompany : null;
    const contact =
      "introducerContact" in message ? message.introducerContact : null;
    const lead = "lead" in message ? message.lead : null;
    const kintin = "kintin" in message ? message.kintin : null;
    const person = "person" in message ? message.person : null;
    const introducerOutsource =
      "introducerOutsource" in message ? message.introducerOutsource : null;

    const response = await Api.resource("portal", path as Controllers, params)
      .method("post")
      .body({
        company: "string" === typeof company ? company : company?.id,
        contact: "string" === typeof contact ? contact : contact?.id,
        lead: "string" === typeof lead ? lead : lead?.id,
        kintin: "string" === typeof kintin ? kintin : kintin?.id,
        person: "string" === typeof person ? person : person?.id,
        introducerOutsource:
          "string" === typeof introducerOutsource
            ? introducerOutsource
            : introducerOutsource?.id,
        notes: this.message.note.$data.notes,
        dueAt: this.message.note.$data.dueAt ?? undefined,
        name: this.message.note.$data.name,
        pinned: this.message.note.$data.pinned,
        isPublic: this.message.note.$data.isPublic,
        completedAt: this.message.note.$data.completedAt ?? undefined,
        type: this.message.note.$data.type,
        notifications: this.message.note.notifications.map((n) => ({
          user: n.$data.user,
        })),
      })

      .result();

    const note = Note.$inflate(response.note).first();

    if (!note) {
      throw new Error("Failed to create note");
    }

    if (this.message.note.communicationNote) {
      await Api.resource("portal", `${path as Controllers}/{note}`, {
        note,
        owner: params.owner,
      })
        .method("patch")
        .body({
          communicationNote: {
            communicationType:
              this.message.note.communicationNote.$data.communicationType,
            reference: this.message.note.communicationNote.$data.reference,
            note: note.id,
            outcome: this.message.note.communicationNote.$data.outcome,
          },
        })

        .result();
    }

    if (this.message.note.introducerNote) {
      await Api.resource("portal", `${path as Controllers}/{note}`, {
        note,
        owner: params.owner,
      })
        .method("patch")
        .body({
          company: this.message.note.$data.company,
          contact: this.message.note.$data.contact,
          introducerNote: {
            dueAtNotifySales:
              this.message.note.introducerNote.$data.dueAtNotifySales,
            dueAtNotifySpecialist:
              this.message.note.introducerNote.$data.dueAtNotifySpecialist,
            note: note.id,
          },
        })

        .result();
    }

    if (this.message.note.statusLog) {
      await Api.resource("portal", `${path as Controllers}/{note}`, {
        note,
        owner: params.owner,
      })
        .method("patch")
        .body({
          statusLog: {
            nextActionAt: this.message.note.statusLog.$data.nextActionAt,
            status: this.message.note.statusLog.$data.status ?? undefined,
            stage: this.message.note.statusLog.$data.stage ?? undefined,
            kintin: this.message.note.statusLog.$data.kintin,
            note: note.id,
          },
        })

        .result();
    }

    this.message.note.$delete({
      introducerNote: true,
      communicationNote: true,
      notifications: true,
    });

    return await window.Kernel.ActionBus.execute("core/note/record", {
      ...this.message,
      note,
    });
  }
}
