<template>
  <PageHeader text="Call Script" htag="h2" />

  <Message
    title="Script"
    size="is-medium"
    color="is-primary"
    shade="is-light"
    drop-shadow
  >
    <div class="columns is-mobile">
      <div class="column is-narrow">
        <Icon :icon="$style.icon.speechBubble.icon" size="is-large" />
      </div>
      <div class="column">
        <p class="has-text-weight-bold mt-0">
          Hi, is this {{ leadFirstName }}? It's {{ firstName }} calling from
          Kinherit, the Will Specialists. <br /><br />I notice you requested
          some information on our website... Was it a will you were looking for?
          And is this a single will for yourself, or one for you and your
          partner? And are you a homeowner? Do you have any children? How many?
          And are any of them under 18? <br /><br />And what was it that
          prompted you to look for a will at this time? Are there any current
          medical or well-being issues that we should be aware of &hellip; or do
          you just ‘need a Will…’?
        </p>
        <p class="has-text-weight-bold">
          Ok so... I assume you want a Will to maximise what goes to your loved
          ones..?
        </p>
        <p class="has-text-weight-bold">
          Well the two biggest costs of death are excessive probate fees and
          missed assets. Kinherit is a really unusual Will-writer, because we
          don’t try to lock-in probate fees via the small-print, and we do
          include a digital planning vault to ensure no lost assets.
        </p>
        <p class="has-text-weight-bold">
          So we think we save people tens of thousands of pounds on death.
        </p>
        <p class="has-text-weight-bold">
          Now our [single wills sell for £345][mirror wills normally come up at
          £495], but the points to bear in mind are this includes VAT, and it
          includes a complimentary Kinvault digital vault and handover.
        </p>
        <p class="has-text-weight-bold">How does that sound?</p>
        <p>
          <em> [If needed, give discount] </em>
        </p>
        <p class="has-text-weight-bold">
          The Kinvault takes a bit of an investment of your time, but I promise
          it is well worth it to protect your loved ones. Rather than trying to
          explain everything it does, I am going to send you link – do check out
          the video, its fantastic.
        </p>
        <p class="has-text-weight-bold">
          There is one last bit of good news. Your conversation with our
          specialist Will-writer, and all the advice on things like inheritance
          tax planning, is complimentary and without obligation, and we only
          invoice you when you ask us to produce your legal documents.
        </p>
        <p class="has-text-weight-bold">So I’ve got {{ email }} is that you?</p>
        <p class="has-text-weight-bold">
          Ok... when would be a good time for us to give you a call [this week?
          / next week?] Bearing in mind we can do 7pm calls as well if that’s
          helpful?
        </p>
        <p>
          <em> [BOOK THEM IN] </em>
        </p>
      </div>
    </div>
  </Message>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Icon from "@kinherit/framework/component.display/icon";
import Message from "@kinherit/framework/component.display/message";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { LeadDetailsCallScriptRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsCallScriptRoute,
  mixins: [LeadDetailsMixin, StyleService.mixin, AuthService.mixin()],
  components: { Message, Icon, PageHeader },
  computed: {
    firstName(): string {
      return this.$auth.loggedInUser?.profile.firstName || "[FIRST NAME]";
    },
    leadFirstName(): string {
      return this.lead?.profile.firstName || "[FIRST NAME]";
    },
    email(): string {
      return this.lead?.profile.emails.first()?.email || "[EMAIL]";
    },
  },
});
</script>
