<template>
  <Card title="Status" title-size="is-5" class="is-fullwidth mr-0">
    <OfficerCallStatusWidget
      v-if="$data.officer"
      class="is-fullwidth"
      :officer="$data.officer"
      @reload="reloadOfficer"
    />
  </Card>
  <Card :title="`Related Client`" title-size="is-5" class="is-fullwidth mr-0">
    {{ officer?.kintin?.friendlyName }}
    <p>
      <code :style="{ float: hasRelations ? 'right' : 'none' }">{{
        officer?.kintin?.ref
      }}</code>
    </p>

    <p v-if="officer?.relationToPrimaryPerson">
      <b> {{ officer.kintin?.primaryPerson?.profile.fullName }}</b
      >:
      {{ officer.relationToPrimaryPerson.text.ucFirst() }}
    </p>

    <p v-if="officer?.relationToSecondaryPerson">
      <b> {{ officer.kintin?.secondaryPerson?.profile.fullName }}</b
      >:
      {{ officer.relationToSecondaryPerson.text.ucFirst() }}
    </p>

    <Button @click="visitKintinDetails" is-fullwidth>Go to Kinvault</Button>
  </Card>
  <Card
    v-if="roles.length > 0"
    title="Roles"
    title-size="is-5"
    class="is-fullwidth mr-0"
  >
    <p v-for="role in roles" :key="`role-${role.role}-${role.forPerson?.id}`">
      <span class="my-1 badge is-small"> {{ role.role }} </span><br />
      for {{ role.forPerson?.profile.fullName }}
    </p>
  </Card>
  <Card title="Letter" title-size="is-5" class="is-fullwidth mr-0">
    <div class="buttons">
      <Button @click="letter('download')"> Download </Button>
      <Button
        v-if="officer?.officerCallStatus?.value === 'todoletter'"
        @click="letter('send')"
      >
        Send
      </Button>
    </div>
  </Card>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { Address, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import OfficerCallStatusWidget from "../../component/OfficerCallStatusWidget.vue";
import { CreateOfficerLetterForm } from "../../form/create-officer-letter.form";
import { KinvaultOfficerDetailsMixin } from "../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: "KinvaultOfficerDetailsSidebar",
  mixins: [KinvaultOfficerDetailsMixin],
  components: {
    Card,
    OfficerCallStatusWidget,
    Button,
  },
  computed: {
    hasRelations(): boolean {
      return (
        !!this.$data.officer?.relationToPrimaryPerson ||
        !!this.$data.officer?.relationToSecondaryPerson
      );
    },
    roles(): { role: string; forPerson: Person | null }[] {
      return [
        ...(this.$data.officer?.trusteeFor ?? []),
        ...(this.$data.officer?.executorFor ?? []),
        ...(this.$data.officer?.attorneyFor ?? []),
        ...(this.$data.officer?.guardianFor ?? []),
      ].map((officer) => ({
        role: `${officer.$name}${
          `isReserve` in officer && officer.isReserve === true
            ? ` (Reserve)`
            : ``
        }`,
        forPerson: officer.forPerson,
      }));
    },
  },
  methods: {
    async letter(action: "download" | "send"): Promise<void> {
      if (!this.$data.officer) {
        return;
      }

      const kintin = this.$data.officer.kintin;

      if (!kintin) {
        return;
      }

      const message = {
        kintin: kintin,
        officer: this.$data.officer,
        address: null as null | Address,
        specialist:
          kintin?.legalAssistants.first() ??
          (kintin?.estatePlanners.first() || null),
      };

      const { address, specialist } = await CreateOfficerLetterForm(
        this.$data.officer,
        message,
      ).dialog({
        dialog: {
          title: `${action.ucFirst()} Letter`,
        },
      });

      if (!address || !specialist) {
        return;
      }

      if (action === "download") {
        await window.Kernel.ActionBus.execute(
          "kinvault/officer/letter/download",
          {
            ...message,
            address,
            specialist,
          },
        );
      } else if (action === "send") {
        await window.Kernel.ActionBus.execute(
          "kinvault/officer/letter/send",
          message,
        );
      }
    },
  },
});
</script>
