import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface CreateKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface CreateKnowledgeBaseCategoryResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export class CreateKnowledgeBaseCategoryHandler extends ActionBase {
  constructor(private message: CreateKnowledgeBaseCategoryMessage) {
    super();
  }

  public async execute(): Promise<CreateKnowledgeBaseCategoryResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-category",
    )
      .method("post")

      .body({
        slug: this.message.knowledgeBaseCategory.slug,
        title: this.message.knowledgeBaseCategory.title,
      })
      .result();

    const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    ).first();

    if (!knowledgeBaseCategory) {
      throw new Error("Failed to create knowledge base category");
    }

    this.message.knowledgeBaseCategory.$delete();

    return {
      knowledgeBaseCategory,
    };
  }
}
