import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  IntroducerCompany,
  IntroducerOutsource,
  Profile,
  User,
} from "@kinherit/sdk";

export interface RecordIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource | string;
}

interface RecordIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
  profile: Array<Profile>;
  user: Array<User>;
  introducerCompany: Array<IntroducerCompany>;
}

export class RecordIntroducerOutsourceHandler extends ActionBase {
  constructor(private message: RecordIntroducerOutsourceMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerOutsourceResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-outsource/{introducerOutsource}",
      {
        introducerOutsource: this.message.introducerOutsource,
      },
    )
      .method("get")

      .result();

    return {
      introducerOutsources: IntroducerOutsource.$inflate(
        response.introducerOutsource,
        undefined,
        response.$rootIds,
      ),
      user: User.$inflate(response.user),
      profile: Profile.$inflate(response.profile),
      introducerCompany: IntroducerCompany.$inflate(response.introducerCompany),
    };
  }
}
