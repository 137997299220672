import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";

export interface UpdateEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface UpdateEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export class UpdateEmailCampaignHandler extends ActionBase {
  constructor(private message: UpdateEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<UpdateEmailCampaignResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign/{emailCampaign}",
      this.message,
    )
      .method("patch")

      .body({
        description: this.message.emailCampaign.$data.description,
        name: this.message.emailCampaign.$data.name,
      });

    const response = await request.result();

    this.message.emailCampaign.$persist();

    return {
      emailCampaign: EmailCampaign.$inflate(
        response.emailCampaign,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
