import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface CreateDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface CreateDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export class CreateDefaultBrandedKinvaultReferralCodeHandler extends ActionBase {
  constructor(
    private message: CreateDefaultBrandedKinvaultReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<CreateDefaultBrandedKinvaultReferralCodeResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/default-branded-kinvault-referral-code",
    )
      .method("post")

      .body({
        code: this.message.defaultBrandedKinvaultReferralCode.$data.code,
        name: this.message.defaultBrandedKinvaultReferralCode.$data.name,
        brandedKinvault:
          this.message.defaultBrandedKinvaultReferralCode.$data.brandedKinvault,
      })
      .result();

    const defaultBrandedKinvaultReferralCode =
      DefaultBrandedKinvaultReferralCode.$inflate(
        response.defaultBrandedKinvaultReferralCode,
        undefined,
        response.$rootIds,
      ).first();

    if (!defaultBrandedKinvaultReferralCode) {
      throw new Error("Failed to create default referral code");
    }

    this.message.defaultBrandedKinvaultReferralCode.$delete();

    return {
      defaultBrandedKinvaultReferralCode,
    };
  }
}
