import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CpdSession } from "@kinherit/sdk";

interface DeleteCpdSessionMessage {
  cpdSession: string | CpdSession;
}

interface DeleteCpdSessionResponse {
  [key: string]: never;
}

export class DeleteCpdSessionHandler extends ActionBase {
  constructor(private message: DeleteCpdSessionMessage) {
    super();
  }

  public async execute(): Promise<DeleteCpdSessionResponse> {
    await Api.resource("portal", "/v2/portal/cpd-session/{cpdSession}", {
      cpdSession:
        "string" === typeof this.message.cpdSession
          ? this.message.cpdSession
          : this.message.cpdSession.id,
    })
      .method("delete")
      .result();

    return {};
  }
}
