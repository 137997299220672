import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailTemplate, QueryMask } from "@kinherit/sdk";

export interface ReadEmailTemplateMessage {
  query?: QueryMask<typeof EmailTemplate>;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
}

interface ReadEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export class ReadEmailTemplateHandler extends ActionBase {
  constructor(private message: ReadEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<ReadEmailTemplateResponse> {
    const request = Api.resource("core", "/v2/core/select/email-template")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(EmailTemplate).where(this.message.query ?? {});

    const response = await request.result();

    return {
      emailTemplates: EmailTemplate.$inflate(
        response.emailTemplate,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
