import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Subscription } from "@kinherit/sdk";

interface DeleteSubscriptionMessage {
  subscription: Subscription;
}

interface DeleteSubscriptionResponse {
  [key: string]: never;
}

export class DeleteSubscriptionHandler extends ActionBase {
  constructor(private message: DeleteSubscriptionMessage) {
    super();
  }

  public async execute(): Promise<DeleteSubscriptionResponse> {
    await Api.resource("portal", "/v2/portal/subscription/{subscription}", {
      subscription: this.message.subscription.id,
    })
      .method("delete")
      .result();

    this.message.subscription.$delete();

    return {};
  }
}
