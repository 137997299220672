import {
  UpdateBrandedKinvaultProfileMessage,
  UpdateBrandedKinvaultProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateBrandedKinvaultProfileHandler = UpdateProfileHandler as new (
  message: UpdateBrandedKinvaultProfileMessage,
) => UpdateProfileHandler<
  UpdateBrandedKinvaultProfileMessage,
  UpdateBrandedKinvaultProfileResponse
>;
