<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
    }"
  >
    <PageHeader htag="h2" text="Intro">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>

    <Tabs
      v-model:tab="tab"
      position="both"
      is-lazy
      :config="
        ['Intro', 'Security', 'Process', 'Coercion'].map((x) => ({ label: x }))
      "
    >
      <!-- Tab Intro-->
      <template #intro>
        <IntroTab @save-callscript="saveCallscript" />
      </template>
      <!-- Tab Security-->
      <template #security>
        <SecurityTab />
      </template>
      <!-- Tab Process-->
      <template #process>
        <ProcessTab />
      </template>
      <!-- Tab Coercion-->
      <template #coercion>
        <CoercionTab
          @save="saveHandler"
          @save-and-continue="saveAndContinueHandler"
        />
      </template>
    </Tabs>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import {
  KintinDetailsCallScriptParams,
  KintinDetailsCallScriptRoute,
} from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptFamilyGuardiansRoute } from "../family-guardians";

import { Tabs } from "@kinherit/framework/component.display/tabs";
import CoercionTab from "./CoercionTab.vue";
import IntroTab from "./IntroTab.vue";
import ProcessTab from "./ProcessTab.vue";
import SecurityTab from "./SecurityTab.vue";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    PageHeader,
    Button,
    Tabs,
    IntroTab,
    SecurityTab,
    ProcessTab,
    CoercionTab,
  },
  data: () => ({
    isFocused: false,
    renderKey: 0,
    person2Present: null as boolean | null,
    tab: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async saveAndContinueHandler(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptFamilyGuardiansRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/person/profile/update", {
        person: this.kintin.primaryPerson,
        kintin: this.kintin!,
      });

      if (this.kintin.secondaryPerson) {
        await window.Kernel.ActionBus.execute(
          "kinvault/person/profile/update",
          {
            person: this.kintin.secondaryPerson,
            kintin: this.kintin!,
          },
        );
      }

      const referral = this.kintin.referral;

      if (referral) {
        await window.Kernel.ActionBus.execute("core/account-referral/update", {
          referral,
        });
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/update", {
        kintin: this.kintin,
      });

      this.$snackBar.success.saved();
    },
    async saveCallscript(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/call-script/update",
        {
          kintin: this.kintin,
          callScript: this.callScript,
        },
      );

      this.renderKey++;

      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
