import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Guardian, Kintin } from "@kinherit/sdk";

interface UpdateGuardianMessage {
  kintin: string | Kintin;
  guardian: Guardian;
}

interface UpdateGuardianResponse {
  guardian: Guardian;
}

export class UpdateGuardianHandler extends ActionBase {
  constructor(private message: UpdateGuardianMessage) {
    super();
  }

  public async execute(): Promise<UpdateGuardianResponse> {
    const { guardian: guardianData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-guardian/{kintin}/person/guardian/{guardian}",
      {
        kintin: this.message.kintin,
        guardian: this.message.guardian.$data.id,
      },
    )
      .method("patch")

      .body({
        forPerson: this.message.guardian.$data.forPerson,
        person: this.message.guardian.$data.person,
        sortOrder: this.message.guardian.$data.sortOrder,
        isReserve: this.message.guardian.$data.isReserve ?? false,
        includePartner: this.message.guardian.$data.includePartner ?? false,
        notes: this.message.guardian.$data.notes,
      })
      .result();

    const guardian = Guardian.$inflate(guardianData).first();

    if (!guardian) {
      throw new Error("Failed to update guardian");
    }

    return { guardian };
  }
}
