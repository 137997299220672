import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

export interface CreateIntroducerFeePaymentsMessage {
  feeType: "introducerFee" | "agentFee" | "parentAgentFee";
  /** Introducer fee payment run id */
  introducerFeePaymentRun: string;
  /** Orders ids */
  orders: string[];
  /** Xero contact id */
  xeroContact: string;
}

export interface CreateIntroducerFeePaymentsResponse {}

export class CreateIntroducerFeePaymentsHandler extends ActionBase {
  constructor(private message: CreateIntroducerFeePaymentsMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerFeePaymentsResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run/create-introducer-fee-payments",
    )
      .method("post")
      .body({
        feeType: this.message.feeType,
        introducerFeePaymentRun: this.message.introducerFeePaymentRun,
        orders: this.message.orders,
        xeroContact: this.message.xeroContact,
      })
      .result();

    return {};
  }
}
