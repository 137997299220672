import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, QueryMask } from "@kinherit/sdk";

export interface ReadAddressMessage {
  query?: QueryMask<typeof Address>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadAddressResponse {
  addresses: Array<Address>;
}

export class ReadAddressHandler extends ActionBase {
  constructor(private message: ReadAddressMessage) {
    super();
  }

  public async execute(): Promise<ReadAddressResponse> {
    const request = Api.resource("core", "/v2/core/select/address")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(Address).where(this.message.query ?? {});

    const response = await request.result();

    return {
      addresses: Address.$inflate(
        response.address,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
