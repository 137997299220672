import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface DeleteBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

interface DeleteBrandedKinvaultSubscriptionReferralCodeResponse {
  [key: string]: never;
}

export class DeleteBrandedKinvaultSubscriptionReferralCodeHandler extends ActionBase {
  constructor(
    private message: DeleteBrandedKinvaultSubscriptionReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultSubscriptionReferralCodeResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
      {
        brandedKinvaultSubscriptionReferralCode:
          this.message.brandedKinvaultSubscriptionReferralCode,
      },
    )
      .method("delete")

      .result();

    this.message.brandedKinvaultSubscriptionReferralCode.$delete();

    return {};
  }
}
