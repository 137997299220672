import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Attorney, Kintin } from "@kinherit/sdk";

interface DeleteAttorneyMessage {
  kintin: string | Kintin;
  attorney: Attorney;
}

interface DeleteAttorneyResponse {
  [key: string]: never;
}

export class DeleteAttorneyHandler extends ActionBase {
  constructor(private message: DeleteAttorneyMessage) {
    super();
  }

  public async execute(): Promise<DeleteAttorneyResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-attorney/{kintin}/person/attorney/{attorney}",
      {
        kintin: this.message.kintin,
        attorney: this.message.attorney.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.attorney.$delete();

    return {};
  }
}
