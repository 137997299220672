import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinAccess, User } from "@kinherit/sdk";

interface CreateUserKintinAccessMessage {
  accessType: "owner" | "edit" | "readonly" | "adviser" | "none";
  user: User;
  kintin: Kintin;
}

interface CreateUserKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class CreateUserKintinAccessHandler extends ActionBase {
  constructor(private message: CreateUserKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<CreateUserKintinAccessResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/kintin-user-access",
    ).method("post");

    request.body({
      accessType: this.message.accessType,
      kintin: this.message.kintin.id,
      user: this.message.user.id,
      allowAccess: true,
    });

    const result = await request.result();

    const kintinAccess = KintinAccess.$inflate(result.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to create kintin user access");
    }

    return {
      kintinAccess,
    };
  }
}
