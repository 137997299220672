import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Attorney, Kintin } from "@kinherit/sdk";

interface CreateAttorneyMessage {
  kintin: string | Kintin;
  attorney: Attorney;
}

interface CreateAttorneyResponse {
  attorney: Attorney;
}

export class CreateAttorneyHandler extends ActionBase {
  constructor(private message: CreateAttorneyMessage) {
    super();
  }

  public async execute(): Promise<CreateAttorneyResponse> {
    const { attorney: attorneyData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-attorney/{kintin}/person/attorney",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        isReserve: this.message.attorney.$data.isReserve,
        notes: this.message.attorney.$data.notes,
        isCertProv: this.message.attorney.$data.isCertProv,
        isHealthWealth: this.message.attorney.$data.isHealthWealth,
        isPropFinance: this.message.attorney.$data.isPropFinance,
        person: this.message.attorney.$data.person,
        forPerson: this.message.attorney.$data.forPerson,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const attorney = Attorney.$inflate(attorneyData).first();

    if (!attorney) {
      throw new Error("Failed to create attorney");
    }

    this.message.attorney.$delete();

    return { attorney };
  }
}
