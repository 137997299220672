import { RecordWillRevisionHandler } from "@/module/kinvault.will-revision/action/record-will-revision.action";
import { CreateWillRevisionHandler } from "./create-will-revision.action";
import { DeleteWillRevisionHandler } from "./delete-will-revision.action";
import { ReadWillRevisionHandler } from "./read-will-revision.action";
import { UpdateWillRevisionHandler } from "./update-will-revision.action";

export const Actions = {
  "kinvault/will-revision/read": ReadWillRevisionHandler,
  "kinvault/will-revision/record": RecordWillRevisionHandler,
  "kinvault/will-revision/update": UpdateWillRevisionHandler,
  "kinvault/will-revision/delete": DeleteWillRevisionHandler,
  "kinvault/will-revision/create": CreateWillRevisionHandler,
};
