<template>
  <PageHeader htag="h1" text="Kinvault Access">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create Referral
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      'kintin.friendlyName': 'Client',
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #actions="{ row }">
      <div class="buttons is-right">
        <Button
          v-if="hasWriteAccess"
          class="update"
          size="is-small"
          icon-right="Edit"
          @click="update(row)"
        />
        <Button
          v-if="hasWriteAccess"
          class="remove"
          size="is-small"
          icon-right="Trash"
          @click="remove(row)"
        />
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadKintinAccessForm } from "@/module/introducer.contact/form/read-kintin-access.form";
import { UpdateKintinAccessForm } from "@/module/introducer.contact/form/update-kintin-access.form";
import { IntroducerContactDetailsMixin } from "@/module/introducer.contact/mixin/introducer-contact-details.mixin";
import { AuthService } from "@/service/auth.service";
import { IKintinAccess, KintinAccess } from "@kinherit/sdk";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { IntroducerContactDetailsKinvaultAccessRoute } from ".";

export default defineComponent({
  name: IntroducerContactDetailsKinvaultAccessRoute,
  components: { MasterListPage, PageHeader, Button },
  mixins: [IntroducerContactDetailsMixin, AuthService.mixin()],
  data: () => ({
    filters: ReadKintinAccessForm(),
    columns: [
      {
        title: "Client",
        sort: true,
        map: (v: KintinAccess) => v.kintin.friendlyName,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<KintinAccess>>,
    rows: Array<KintinAccess>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKintinAccess,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("account-referral-code:write");
    },
  },
  methods: {
    async create(): Promise<void> {
      const kintinAccess = await UpdateKintinAccessForm().dialog({
        dialog: {
          title: "Create Kinvault Access",
        },
      });

      kintinAccess.introducerContact = this.introducerContact;
      kintinAccess.$persist();

      await window.Kernel.ActionBus.execute(
        "introducer/contact/kintin-access/create",
        {
          kintinAccess,
        },
      );

      this.refresh(this.filters.localData);
    },
    async update(kintinAccess: KintinAccess): Promise<void> {
      await UpdateKintinAccessForm(kintinAccess).dialog({
        dialog: {
          title: "Update Kinvault Access",
        },
      });

      await window.Kernel.ActionBus.execute(
        "introducer/contact/kintin-access/update",
        {
          kintinAccess,
        },
      );
    },
    async remove(kintinAccess: KintinAccess): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Kinvault Access",
          message: "Are you sure you want to delete this Kinvault Access?",
        },
      });

      await window.Kernel.ActionBus.execute(
        "introducer/contact/kintin-access/delete",
        {
          kintinAccess,
        },
      );

      this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<typeof ReadKintinAccessForm>["localData"],
    ): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      const data = await window.Kernel.ActionBus.execute(
        "introducer/contact/kintin-access/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
          introducerContact: this.introducerContact,
        },
      );

      this.rows = data.kintinAccess;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
