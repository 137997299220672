import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Order } from "@kinherit/sdk";
export interface PushOrderMessage {
  order: Order;
}

export interface PushOrderResponse {
  [key: string]: never;
}

export class PushOrderHandler extends ActionBase {
  constructor(private message: PushOrderMessage) {
    super();
  }

  public async execute(): Promise<PushOrderResponse> {
    await Api.resource("portal", "/v2/portal/order/{order}/push-to-xero", {
      order: this.message.order.id,
    })
      .method("put")
      .result();

    return {};
  }
}
