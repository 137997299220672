import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailAddress } from "@kinherit/sdk";
import { EmailCampaign, IEmailCampaign } from "@kinherit/sdk";
import { Profile } from "@kinherit/sdk";
import { Between, Like } from "@kinherit/orm";

export interface ReadEmailCampaignMessage {
  name?: null | string;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IEmailCampaign;
    direction: "asc" | "desc";
  };
}

export interface ReadEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
  profiles: Profile[];
  emailAddresses: EmailAddress[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadEmailCampaignHandler extends ActionBase {
  constructor(private message: ReadEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<ReadEmailCampaignResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign",
      this.message,
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(EmailCampaign).where({
      name: Like(this.message.name),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      emailCampaign: EmailCampaign.$inflate(
        response.emailCampaign,
        undefined,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      pagination: this.getPagination(response),
    };
  }
}
