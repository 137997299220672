import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface RecordBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral:
    | BrandedKinvaultSubscriptionReferral
    | string;
}

interface RecordBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export class RecordBrandedKinvaultSubscriptionReferralHandler extends ActionBase {
  constructor(
    private message: RecordBrandedKinvaultSubscriptionReferralMessage,
  ) {
    super();
  }

  public async execute(): Promise<RecordBrandedKinvaultSubscriptionReferralResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
      {
        brandedKinvaultSubscriptionReferral:
          typeof this.message.brandedKinvaultSubscriptionReferral,
      },
    )
      .method("get")

      .result();

    const brandedKinvaultSubscriptionReferral =
      BrandedKinvaultSubscriptionReferral.$inflate(
        response.brandedKinvaultSubscriptionReferral,
      ).first();

    if (!brandedKinvaultSubscriptionReferral) {
      throw new Error("Subscription not found");
    }

    return {
      brandedKinvaultSubscriptionReferral,
    };
  }
}
