import {
  UpdateIntroducerBillingContactProfileMessage,
  UpdateIntroducerBillingContactProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateIntroducerBillingContactProfileHandler =
  UpdateProfileHandler as new (
    message: UpdateIntroducerBillingContactProfileMessage,
  ) => UpdateProfileHandler<
    UpdateIntroducerBillingContactProfileMessage,
    UpdateIntroducerBillingContactProfileResponse
  >;
