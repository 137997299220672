import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferral,
  AccountReferralCode,
  IAccountReferral,
  IntroducerCompany,
  IntroducerContact,
  Lead,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyAccountReferralLeadsMessage {
  introducerCompany: IntroducerCompany | string;
  referralCode: AccountReferralCode | string;
  query?: QueryMask<typeof Lead>;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyAccountReferralLeadsResponse {
  leads: Array<Lead>;
  profiles: Array<Profile>;
  introducerContacts: Array<IntroducerContact>;
  referrals: Array<AccountReferral>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerCompanyAccountReferralLeadsHandler extends ActionBase {
  constructor(
    private message: ReadIntroducerCompanyAccountReferralLeadsMessage,
  ) {
    super();
  }

  public async execute(): Promise<ReadIntroducerCompanyAccountReferralLeadsResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-referral/{introducerCompany}/referrals/{referralCode}/leads",
      {
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
        referralCode:
          "string" === typeof this.message.referralCode
            ? this.message.referralCode
            : this.message.referralCode.id,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    if (this.message.query) {
      request.buildQuery(Lead).where(this.message.query);
    }

    const response = await request.result();

    return {
      leads: Lead.$inflate(response.lead, this.message.sort, response.$rootIds),
      profiles: Profile.$inflate(response.profile),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      referrals: AccountReferral.$inflate(response.accountReferral),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
