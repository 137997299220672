import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IRole, Role } from "@kinherit/sdk";

export interface ReadRoleMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IRole;
    direction: "asc" | "desc";
  };
}

interface ReadRoleResponse {
  roles: Array<Role>;
}

export class ReadRoleHandler extends ActionBase {
  constructor(private message: ReadRoleMessage) {
    super();
  }

  public async execute(): Promise<ReadRoleResponse> {
    const response = await Api.resource("core", "/v2/core/select/role")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "role",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false)
      .result();

    return {
      roles: Role.$inflate(response.role, this.message.sort, response.$rootIds),
    };
  }
}
