import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  IntroducerCompany,
  IntroducerContact,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadIntroducerContactMessage {
  query?: QueryMask<typeof IntroducerContact>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerContactResponse {
  introducerContacts: Array<IntroducerContact>;
  profiles: Array<Profile>;
  introducerCompanies: Array<IntroducerCompany>;
}

export class ReadIntroducerContactHandler extends ActionBase {
  constructor(private message: ReadIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContactResponse> {
    const request = Api.resource("core", "/v2/core/select/introducer-contact")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(IntroducerContact).where(this.message.query ?? {});

    const response = await request.result();

    return {
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
        undefined,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
    };
  }
}
