import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Session } from "../../model/session.model";

interface RequestMultiFactorAuthMessage {
  session: Session;
  methodId: string;
}

export interface RequestMultiFactorAuthResult {
  session: Session;
  multiFactorSessionId: string;
  code?: number;
  type: "email" | "sms" | "totp";
}

export class RequestMultiFactorAuthHandler extends ActionBase {
  constructor(private message: RequestMultiFactorAuthMessage) {
    super();
  }

  public async execute(): Promise<RequestMultiFactorAuthResult> {
    const activeSession = this.message.session;

    const response = await Api.resource("auth", "/v2/auth/multi-factor/request")
      .method("put")
      .body({
        methodId: this.message.methodId,
      })
      .result();

    return {
      session: activeSession,
      multiFactorSessionId: response.session.id,
      code: response.session.code,
      type: response.session.type,
    };
  }
}
