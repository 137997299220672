import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk";

export class SyncXeroContactsHandler extends ActionBase {
  constructor(private _message: {}) {
    super();
  }

  public async execute(): Promise<void> {
    await Api.resource("external", "/v2/external/xero/contacts/sync", {})
      .method("put")
      .result();
  }
}
