import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address } from "@kinherit/sdk";

interface CreateAddressMessage {
  address: Address;
}

interface CreateAddressResponse {
  address: Address;
}

export class CreateAddressHandler extends ActionBase {
  constructor(private message: CreateAddressMessage) {
    super();
  }

  public async execute(): Promise<CreateAddressResponse> {
    const { address: addressData } = await Api.resource(
      "portal",
      "/v2/portal/address",
    )
      .method("post")

      .body({
        city: this.message.address.city,
        country: this.message.address.country,
        line1: this.message.address.line1,
        line2: this.message.address.line2,
        postcode: this.message.address.postcode,
        primaryMailing: this.message.address.primaryMailing,
        primaryResidential: this.message.address.primaryResidential,
        state: this.message.address.state,
        profile: this.message.address.profile?.id,
      })
      .result();

    const address = Address.$inflate(addressData).first();

    if (!address) {
      throw new Error("Failed to create address");
    }

    this.message.address.$delete();

    return { address };
  }
}
