import {
  UpdateIntroducerContactProfileMessage,
  UpdateIntroducerContactProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateIntroducerContactProfileHandler =
  UpdateProfileHandler as new (
    message: UpdateIntroducerContactProfileMessage,
  ) => UpdateProfileHandler<
    UpdateIntroducerContactProfileMessage,
    UpdateIntroducerContactProfileResponse
  >;
