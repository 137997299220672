import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, CpdSession, Website } from "@kinherit/sdk";

export interface CreateCpdSessionMessage {
  cpdSession: CpdSession;
}

export interface CreateCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  addresses: Array<Address>;
  websites: Array<Website>;
}

export class CreateCpdSessionHandler extends ActionBase {
  constructor(private message: CreateCpdSessionMessage) {
    super();
  }

  public async execute(): Promise<CreateCpdSessionResponse> {
    const request = Api.resource("portal", "/v2/portal/cpd-session", {})
      .method("post")

      .body({
        address:
          null === this.message.cpdSession.address
            ? null
            : {
                line1: this.message.cpdSession.address.$data.line1,
                line2: this.message.cpdSession.address.$data.line2,
                city: this.message.cpdSession.address.$data.city,
                country: this.message.cpdSession.address.$data.country,
                postcode: this.message.cpdSession.address.$data.postcode,
                state: this.message.cpdSession.address.$data.state,
              },
        allowBookings: this.message.cpdSession.$data.allowBookings,
        date: this.message.cpdSession.$data.date,
        duration: this.message.cpdSession.$data.duration,
        outcomes: this.message.cpdSession.$data.outcomes,
        title: this.message.cpdSession.$data.title,
        type: this.message.cpdSession.$data.type,
        websites: this.message.cpdSession.websites.map((website) => ({
          url: website.$data.url,
        })),
        isPrivate: this.message.cpdSession.$data.isPrivate,
      });

    const response = await request.result();

    this.message.cpdSession.$delete({
      address: true,
    });

    this.message.cpdSession.$delete();

    return {
      cpdSessions: CpdSession.$inflate(
        response.cpdSession,
        undefined,
        response.$rootIds,
      ),
      addresses: Address.$inflate(
        response.address,
        undefined,
        response.$rootIds,
      ),
      websites: Website.$inflate(
        response.website,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
