import { ComputeAccountsReportHandler } from "@/module/report/action/compute-accounts-report.action";
import { ComputeDownloadTimeSinceLeadReportHandler } from "@/module/report/action/compute-download-time-since-lead.action";
import { ComputeLeadsByEpReportHandler } from "@/module/report/action/compute-leads-by-ep.action";
import { ComputeAgreementsReportHandler } from "./compute-agreements-report.action";
import { ComputeAppointmentsBreakdownReportHandler } from "./compute-appointments-breakdown-report.action";
import { ComputeAppointmentsReportHandler } from "./compute-appointments-report.action";
import { ComputeCashflowByEpReportHandler } from "./compute-cashflow-by-ep-report.action";
import { ComputeCashflowReportHandler } from "./compute-cashflow-report.action";
import { ComputeCompaniesReportHandler } from "./compute-companies-report.action";
import { ComputeDocstorageReportHandler } from "./compute-docstorage-report.action";
import { ComputeDownloadReportHandler } from "./compute-download-report.action";
import { ComputeLeadsReportHandler } from "./compute-leads-report.action";
import { ComputeProductsReportHandler } from "./compute-products-report.action";
import { ComputeSignupsReportHandler } from "./compute-signups-report.action";

export const Actions = {
  "report/accounts": ComputeAccountsReportHandler,
  "report/leads": ComputeLeadsReportHandler,
  "report/leads/by-ep": ComputeLeadsByEpReportHandler,
  "report/cashflow": ComputeCashflowReportHandler,
  "report/cashflow/by-ep": ComputeCashflowByEpReportHandler,
  "report/companies": ComputeCompaniesReportHandler,
  "report/agreements": ComputeAgreementsReportHandler,
  "report/appointments": ComputeAppointmentsReportHandler,
  "report/appointments/breakdown": ComputeAppointmentsBreakdownReportHandler,
  "report/products": ComputeProductsReportHandler,
  "report/download": ComputeDownloadReportHandler,
  "report/signups": ComputeSignupsReportHandler,
  "report/docstorage": ComputeDocstorageReportHandler,
  "report/time-since-lead": ComputeDownloadTimeSinceLeadReportHandler,
};
