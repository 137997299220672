import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Adviser, Person, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadAdviserMessage {
  query?: QueryMask<typeof Adviser>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadAdviserResponse {
  advisers: Array<Adviser>;
  profiles: Array<Profile>;
  people: Array<Person>;
}

export class ReadAdviserHandler extends ActionBase {
  constructor(private message: ReadAdviserMessage) {
    super();
  }

  public async execute(): Promise<ReadAdviserResponse> {
    const request = Api.resource("core", "/v2/core/select/adviser")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(Adviser).where(this.message.query ?? {});

    const response = await request.result();

    return {
      advisers: Adviser.$inflate(
        response.adviser,
        undefined,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      people: Person.$inflate(response.person),
    };
  }
}
