import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk/api";

type AcuityReconcileMessage = {
  minDate: string;
  maxDate: string;
};

export class AcuityReconcileAction extends ActionBase {
  constructor(private message: AcuityReconcileMessage) {
    super();
  }

  public async execute(): Promise<void> {
    await Api.resource("portal", "/v2/portal/appointment/reconcile")
      .method("put")
      .body({
        minDate: this.message.minDate,
        maxDate: this.message.maxDate,
      })
      .result();
  }
}
