import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign, Profile } from "@kinherit/sdk";

export interface RemoveEmailCampaignProfileMessage {
  emailCampaign: EmailCampaign;
  profile: Profile;
}

export interface RemoveEmailCampaignProfileResponse {
  [key: string]: never;
}

export class RemoveEmailCampaignProfileHandler extends ActionBase {
  constructor(private message: RemoveEmailCampaignProfileMessage) {
    super();
  }

  public async execute(): Promise<RemoveEmailCampaignProfileResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/remove",
      this.message,
    )
      .method("delete")

      .result();

    return {};
  }
}
