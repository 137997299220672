<template>
  <PageHeader htag="h1" text="Email Campaigns">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create Email Campaign
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    default-sort-direction="asc"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      name: 'Name',
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { CreateEmailCampaign } from "@/config/model.config";
import { ReadEmailCampaignForm } from "@/module/admin.email-campaign/form/read-email-campaign.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { EmailCampaign, IEmailCampaign } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { EmailCampaignsMasterListRoute } from ".";
import { UpdateEmailCampaignForm } from "../form/update-email-campaign.form";
import {
  EmailCampaignDetailsParams,
  EmailCampaignDetailsRoute,
} from "./details";

export default defineComponent({
  name: EmailCampaignsMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  mixins: [AuthService.mixin()],
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("email-campaign:write");
    },
  },
  data: () => ({
    filters: ReadEmailCampaignForm(),
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Description",
        field: "description",
      },
      {
        title: "Created",
        map: (campaign) => campaign.createdAt.formatDate,
      },
    ] satisfies Array<TableColumn<EmailCampaign>>,
    rows: Array<EmailCampaign>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IEmailCampaign,
      direction: "desc" as "desc" | "asc",
    },
    period: null as "any" | "forthcoming" | "recent" | null,
  }),
  methods: {
    handleClick(cpd: EmailCampaign, event: MouseEvent): void {
      const params: EmailCampaignDetailsParams = { emailCampaign: cpd.id };

      window.Kernel.visitRoute(
        {
          name: EmailCampaignDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadEmailCampaignForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "admin/email-campaign/campaign/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.emailCampaign;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const emailCampaign = CreateEmailCampaign();

      try {
        await UpdateEmailCampaignForm(emailCampaign).dialog({
          dialog: {
            title: "Create Email Campaign",
          },
        });
      } catch {
        emailCampaign.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute(
        "admin/email-campaign/campaign/create",
        {
          emailCampaign,
        },
      );

      this.refresh(this.filters.localData);
    },
  },
});
</script>
