import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinAccess } from "@kinherit/sdk";

interface CreateKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface CreateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class CreateKintinAccessHandler extends ActionBase {
  constructor(private message: CreateKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinAccessResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-user-access",
    )
      .method("post")
      .body({
        introducerContact: this.message.kintinAccess.$data.introducerContact,
        accessType: this.message.kintinAccess.$data.accessType,
        admin: this.message.kintinAccess.$data.admin,
        allowAccess: this.message.kintinAccess.$data.allowAccess,
        assets: this.message.kintinAccess.$data.assets,
        care: this.message.kintinAccess.$data.care,
        contacts: this.message.kintinAccess.$data.contacts,
        death: this.message.kintinAccess.$data.death,
        kintin: this.message.kintinAccess.$data.kintin,
        legal: this.message.kintinAccess.$data.legal,
        misc: this.message.kintinAccess.$data.misc,
        settings: this.message.kintinAccess.$data.settings,
      })

      .result();

    const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to create kinvault access");
    }

    this.message.kintinAccess.$delete();

    return {
      kintinAccess,
    };
  }
}
