import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinCheckFollowUp } from "@kinherit/sdk";

interface DeleteKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface DeleteKintinCheckFollowUpResponse {
  [key: string]: never;
}

export class DeleteKintinCheckFollowUpHandler extends ActionBase {
  constructor(private message: DeleteKintinCheckFollowUpMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinCheckFollowUpResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups/{kintinCheckFollowUp}",
      {
        kintin: this.message.kintin,
        kintinCheckFollowUp: this.message.kintinCheckFollowUp.id,
      },
    )
      .method("delete")
      .result();

    this.message.kintinCheckFollowUp.$delete({
      note: true,
    });

    return {};
  }
}
