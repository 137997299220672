import { GetDefaultBrandedKinvaultReferralCodeHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-default-referral-code/read-branded-kinvault-default-referral-code.action";
import { ComputeBrandedKinvaultKinvaultFeesHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-invitation/compute-branded-kinvault-kinvault-fees.action";
import { DownloadBrandedKinvaultKinvaultFeesHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-invitation/download-branded-kinvault-kinvault-fees.action";
import { PushBrandedKinvaultKinvaultFeesHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-invitation/push-branded-kinvault-fees-to-xero.action";
import { SendBrandedKinvaultInvitationEmailFollowup1Handler } from "@/module/admin.branded-kinvault/action/branded-kinvault-invitation/send-branded-kinvault-invitation-email-followup1.action";
import { SendBrandedKinvaultInvitationEmailFollowup2Handler } from "@/module/admin.branded-kinvault/action/branded-kinvault-invitation/send-branded-kinvault-invitation-email-followup2.action";
import { CreateBrandedKinvaultPricingSchemaHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-kinvault-price-schema/create-branded-kinvault-kinvault-price-schema.action";
import { DeleteBrandedKinvaultPricingSchemaHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-kinvault-price-schema/delete-branded-kinvault-kinvault-price-schema.action";
import { ReadBrandedKinvaultPricingSchemaHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-kinvault-price-schema/read-branded-kinvault-kinvault-price-schema.action";
import { UpdateBrandedKinvaultPricingSchemaHandler } from "@/module/admin.branded-kinvault/action/branded-kinvault-kinvault-price-schema/update-branded-kinvault-kinvault-price-schema.action";
import { CreateDefaultBrandedKinvaultReferralCodeHandler } from "./branded-kinvault-default-referral-code/create-branded-kinvault-default-referral-code.action";
import { DeleteDefaultBrandedKinvaultReferralCodeHandler } from "./branded-kinvault-default-referral-code/delete-branded-kinvault-default-referral-code.action";
import { RecordDefaultBrandedKinvaultReferralCodeHandler } from "./branded-kinvault-default-referral-code/record-branded-kinvault-default-referral-code.action";
import { UpdateDefaultBrandedKinvaultReferralCodeHandler } from "./branded-kinvault-default-referral-code/update-branded-kinvault-default-referral-code.action";
import { CreateBrandedKinvaultInvitationHandler } from "./branded-kinvault-invitation/create-branded-kinvault-invitation.action";
import { CreateEmailCampaignHandler } from "./branded-kinvault-invitation/create-email-campaign.action";
import { DeleteBrandedKinvaultInvitationHandler } from "./branded-kinvault-invitation/delete-branded-kinvault-invitation.action";
import { ReadBrandedKinvaultInvitationHandler } from "./branded-kinvault-invitation/read-branded-kinvault-invitation.action";
import { SendBrandedKinvaultInvitationEmailHandler } from "./branded-kinvault-invitation/send-branded-kinvault-invitation-email.action";
import { UpdateBrandedKinvaultInvitationHandler } from "./branded-kinvault-invitation/update-branded-kinvault-invitation.action";
import { UploadBrandedKinvaultInvitationCsvHandler } from "./branded-kinvault-invitation/upload-branded-kinvault-invitation-csv.action";
import { ValidateBrandedKinvaultInvitationCsvHandler } from "./branded-kinvault-invitation/validate-branded-kinvault-invitation-csv.action";
import { CreateBrandedKinvaultSubscriptionReferralCodeHandler } from "./branded-kinvault-subscription-referral-code/create-branded-kinvault-subscription-referral-code.action";
import { DeleteBrandedKinvaultSubscriptionReferralCodeHandler } from "./branded-kinvault-subscription-referral-code/delete-branded-kinvault-subscription-referral-code.action";
import { ReadBrandedKinvaultSubscriptionReferralCodeHandler } from "./branded-kinvault-subscription-referral-code/read-branded-kinvault-subscription-referral-code.action";
import { RecordBrandedKinvaultSubscriptionReferralCodeHandler } from "./branded-kinvault-subscription-referral-code/record-branded-kinvault-subscription-code.action";
import { UpdateBrandedKinvaultSubscriptionReferralCodeHandler } from "./branded-kinvault-subscription-referral-code/update-branded-kinvault-subscription-code.action";
import { CreateBrandedKinvaultHandler } from "./branded-kinvault/create-branded-kinvault.action";
import { DeleteBrandedKinvaultHandler } from "./branded-kinvault/delete-branded-kinvault.action";
import { ReadBrandedKinvaultHandler } from "./branded-kinvault/read-branded-kinvault.action";
import { RecordBrandedKinvaultHandler } from "./branded-kinvault/record-branded-kinvault.action";
import { UpdateBrandedKinvaultHandler } from "./branded-kinvault/update-branded-kinvault.action";
import { RecordThemeKinvaultHandler } from "./kinvault-theme/record-kinvault-theme.action";
import { ReplaceThemeKinvaultHandler } from "./kinvault-theme/replace-kinvault-theme.action";
import { UpdateBrandedKinvaultProfileHandler } from "./profile/update-branded-kinvault-profile.action";
import { CreateBrandedKinvaultSubscriptionReferralHandler } from "./subscription-referral/create-branded-kinvault-subscription-referral.action";
import { DeleteBrandedKinvaultSubscriptionReferralHandler } from "./subscription-referral/delete-branded-kinvault-subscription-referral.action";
import { ReadBrandedKinvaultSubscriptionReferralHandler } from "./subscription-referral/read-branded-kinvault-subscription-referral.action";
import { RecordBrandedKinvaultSubscriptionReferralHandler } from "./subscription-referral/record-branded-kinvault-subscription-referral.action";
import { UpdateBrandedKinvaultSubscriptionReferralHandler } from "./subscription-referral/update-branded-kinvault-subscription-referral.action";

export const Actions = {
  // branded-kinvault
  "admin/branded-kinvault/branded-kinvault/read": ReadBrandedKinvaultHandler,
  "admin/branded-kinvault/branded-kinvault/record":
    RecordBrandedKinvaultHandler,
  "admin/branded-kinvault/branded-kinvault/update":
    UpdateBrandedKinvaultHandler,
  "admin/branded-kinvault/branded-kinvault/delete":
    DeleteBrandedKinvaultHandler,
  "admin/branded-kinvault/branded-kinvault/create":
    CreateBrandedKinvaultHandler,

  // subscription referral code
  "admin/branded-kinvault/subscription-referral-code/record":
    RecordBrandedKinvaultSubscriptionReferralCodeHandler,
  "admin/branded-kinvault/subscription-referral-code/read":
    ReadBrandedKinvaultSubscriptionReferralCodeHandler,
  "admin/branded-kinvault/subscription-referral-code/update":
    UpdateBrandedKinvaultSubscriptionReferralCodeHandler,
  "admin/branded-kinvault/subscription-referral-code/delete":
    DeleteBrandedKinvaultSubscriptionReferralCodeHandler,
  "admin/branded-kinvault/subscription-referral-code/create":
    CreateBrandedKinvaultSubscriptionReferralCodeHandler,

  // profile
  "admin/branded-kinvault/profile/update": UpdateBrandedKinvaultProfileHandler,

  // theme settings
  "admin/branded-kinvault/kinvault-theme/update": ReplaceThemeKinvaultHandler,
  "admin/branded-kinvault/kinvault-theme/record": RecordThemeKinvaultHandler,

  // subscription referrals
  "admin/branded-kinvault/subscription-referral/read":
    ReadBrandedKinvaultSubscriptionReferralHandler,
  "admin/branded-kinvault/subscription-referral/record":
    RecordBrandedKinvaultSubscriptionReferralHandler,
  "admin/branded-kinvault/subscription-referral/update":
    UpdateBrandedKinvaultSubscriptionReferralHandler,
  "admin/branded-kinvault/subscription-referral/delete":
    DeleteBrandedKinvaultSubscriptionReferralHandler,
  "admin/branded-kinvault/subscription-referral/create":
    CreateBrandedKinvaultSubscriptionReferralHandler,

  // default referral code
  // Not ReadDefaultBrandedKinvaultReferralCodeHandler as that clashes with select options?
  "admin/branded-kinvault/default-referral-code/read":
    GetDefaultBrandedKinvaultReferralCodeHandler,
  "admin/branded-kinvault/default-referral-code/record":
    RecordDefaultBrandedKinvaultReferralCodeHandler,
  "admin/branded-kinvault/default-referral-code/create":
    CreateDefaultBrandedKinvaultReferralCodeHandler,
  "admin/branded-kinvault/default-referral-code/update":
    UpdateDefaultBrandedKinvaultReferralCodeHandler,
  "admin/branded-kinvault/default-referral-code/delete":
    DeleteDefaultBrandedKinvaultReferralCodeHandler,

  // invitations
  "admin/branded-kinvault/invitation/read":
    ReadBrandedKinvaultInvitationHandler,
  "admin/branded-kinvault/invitation/update":
    UpdateBrandedKinvaultInvitationHandler,
  "admin/branded-kinvault/invitation/delete":
    DeleteBrandedKinvaultInvitationHandler,
  "admin/branded-kinvault/invitation/create":
    CreateBrandedKinvaultInvitationHandler,
  "admin/branded-kinvault/invitation/csv/validate":
    ValidateBrandedKinvaultInvitationCsvHandler,
  "admin/branded-kinvault/invitation/csv/upload":
    UploadBrandedKinvaultInvitationCsvHandler,
  "admin/branded-kinvault/invitation/send":
    SendBrandedKinvaultInvitationEmailHandler,
  "admin/branded-kinvault/invitation/email-campaign/create":
    CreateEmailCampaignHandler,
  "admin/branded-kinvault/invitation/send/followup1":
    SendBrandedKinvaultInvitationEmailFollowup1Handler,
  "admin/branded-kinvault/invitation/send/followup2":
    SendBrandedKinvaultInvitationEmailFollowup2Handler,
  "admin/branded-kinvault/kinvault-fees/compute":
    ComputeBrandedKinvaultKinvaultFeesHandler,
  "admin/branded-kinvault/kinvault-fees/download":
    DownloadBrandedKinvaultKinvaultFeesHandler,
  "admin/branded-kinvault/kinvault-fees/push-to-xero":
    PushBrandedKinvaultKinvaultFeesHandler,

  // kinvault pricing schema
  "admin/branded-kinvault/kinvault-pricing-schema/read":
    ReadBrandedKinvaultPricingSchemaHandler,
  "admin/branded-kinvault/kinvault-pricing-schema/update":
    UpdateBrandedKinvaultPricingSchemaHandler,
  "admin/branded-kinvault/kinvault-pricing-schema/delete":
    DeleteBrandedKinvaultPricingSchemaHandler,
  "admin/branded-kinvault/kinvault-pricing-schema/create":
    CreateBrandedKinvaultPricingSchemaHandler,
};
