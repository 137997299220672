import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Lead, Option } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

interface ConvertLeadToKintinMessage {
  lead: Lead;
  type: Option;
  status: Option;
  nextAction: DateTime;
  notify: boolean;
}

interface ConvertLeadToKintinResponse {
  kintin: Kintin;
}

export class ConvertLeadToKintinHandler extends ActionBase {
  constructor(private message: ConvertLeadToKintinMessage) {
    super();
  }

  public async execute(): Promise<ConvertLeadToKintinResponse> {
    const request = Api.resource("portal", "/v2/portal/lead/{lead}/to-kintin", {
      lead: this.message.lead.id,
    })
      .method("post")

      .body({
        type: this.message.type.id,
        status: this.message.status.id,
        nextAction: this.message.nextAction.formatMachine,
        notify: this.message.notify,
      });

    const response = await request.result();

    const kintin = Kintin.$inflate(response.kintin).first();

    if (!kintin) {
      throw new Error("Unable to create Kintin");
    }

    return {
      kintin,
    };
  }
}
