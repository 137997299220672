import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferralCode,
  Address,
  CompanyPartnerPortalPermissions,
  EmailAddress,
  IntroducerCompany,
  IntroducerNetwork,
  PhoneNumber,
  Profile,
  Tag,
  User,
  Website,
  XeroContact,
} from "@kinherit/sdk";

interface RecordIntroducerCompanyMessage {
  introducerCompany: string;
}

interface RecordIntroducerCompanyResponse {
  introducerCompanies: Array<IntroducerCompany>;
  network: Array<IntroducerNetwork>;
  profiles: Array<Profile>;
  users: Array<User>;
  addresses: Array<Address>;
  emailAddresses: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
  websites: Array<Website>;
  referralCodes: Array<AccountReferralCode>;
  tags: Array<Tag>;
  partnerPortalPermissions: Array<CompanyPartnerPortalPermissions>;
  xeroContacts: Array<XeroContact>;
}

export class RecordIntroducerCompanyHandler extends ActionBase {
  constructor(private message: RecordIntroducerCompanyMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerCompanyResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}",
      {
        introducerCompany: this.message.introducerCompany,
      },
    )
      .method("get")

      .result();

    return {
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      network: IntroducerNetwork.$inflate(response.introducerNetwork),
      profiles: Profile.$inflate(response.profile),
      users: User.$inflate(response.user),
      addresses: Address.$inflate(response.address),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      websites: Website.$inflate(response.website),
      tags: Tag.$inflate(response.tag),
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      partnerPortalPermissions: CompanyPartnerPortalPermissions.$inflate(
        response.companyPartnerPortalPermissions,
      ),
      xeroContacts: XeroContact.$inflate(response.xeroContact),
    };
  }
}
