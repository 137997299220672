import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCompany, Profile } from "@kinherit/sdk";

interface CreateIntroducerCompanyMessage {
  introducerCompany: IntroducerCompany;
}

interface CreateIntroducerCompanyResponse {
  introducerCompany: IntroducerCompany;
  profiles: Array<Profile>;
}

export class CreateIntroducerCompanyHandler extends ActionBase {
  constructor(private message: CreateIntroducerCompanyMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerCompanyResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company",
    )
      .method("post")

      .body({
        assignedTo: this.message.introducerCompany.$data.assignedTo,
        defaultLegalAssistant:
          this.message.introducerCompany.$data.defaultLegalAssistant,
        seniorAdviser: this.message.introducerCompany.$data.seniorAdviser,
        description: this.message.introducerCompany.$data.description,
        status: this.message.introducerCompany.$data.status,
        stage: this.message.introducerCompany.$data.stage,
        type: this.message.introducerCompany.$data.type,
        introducedBy: this.message.introducerCompany.$data.introducedBy,
        profile: {
          organisationName:
            this.message.introducerCompany.profile.$data.organisationName,
          organisationNumber:
            this.message.introducerCompany.profile.$data.organisationNumber,
          phoneNumbers: this.message.introducerCompany.profile.phoneNumbers.map(
            (phoneNumber) => ({
              tel: phoneNumber.tel,
            }),
          ),
          emails: this.message.introducerCompany.profile.emails.map(
            (email) => ({
              email: email.email,
            }),
          ),
        },
      })
      .result();

    const introducerCompany = IntroducerCompany.$inflate(
      response.introducerCompany,
    ).first();

    if (!introducerCompany) {
      throw new Error("Failed to create introducercompany");
    }

    this.message.introducerCompany.$delete({
      profile: {
        addresses: true,
        phoneNumbers: true,
        emails: true,
        websites: true,
      },
    });

    return {
      introducerCompany,
      profiles: Profile.$inflate(response.profile),
    };
  }
}
