import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinAccess } from "@kinherit/sdk";

interface DeleteKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface DeleteKintinAccessResponse {
  [key: string]: never;
}

export class DeleteKintinAccessHandler extends ActionBase {
  constructor(private message: DeleteKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinAccessResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-user-access/{kintinAccess}",
      this.message,
    )
      .method("delete")

      .result();

    this.message.kintinAccess.$delete();

    return {};
  }
}
