<template>
  <div class="introducer-company-details-contracts">
    <PageHeader htag="h2" text="Contracts">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="create"
        >
          Create Contract
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      class="contracts-table"
      :columns="columns"
      :rows="rows"
      is-nested
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #signed="scope">
        <Icon
          v-if="scope.row.isSigned"
          icon="Success"
          size="is-small"
          class="is-success"
        />
        <Icon v-else icon="Cross" size="is-small" class="is-warning" />
      </template>
      <template #keyTerms="{ row }: { row: IntroducerContract }">
        <span
          v-if="
            row.type?.value === 'agentintroducer' ||
            row.type?.value === 'parentagentintroducer'
          "
        >
          <!-- Check if both months and percent are not null/empty -->
          <template
            v-if="hasValue(row.ai_5a_months) && hasValue(row.ai_5a_percent)"
          >
            {{ row.ai_5a_months }} months @ {{ row.ai_5a_percent }}%<br />
          </template>
          <!-- Display error if one is empty/null and the other is not -->
          <template
            v-else-if="
              hasValue(row.ai_5a_months) !== hasValue(row.ai_5a_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period A</strong
            >
          </template>

          <template
            v-if="hasValue(row.ai_5b_months) && hasValue(row.ai_5b_percent)"
          >
            {{ row.ai_5b_months }} months @ {{ row.ai_5b_percent }}%<br />
          </template>
          <template
            v-else-if="
              hasValue(row.ai_5b_months) !== hasValue(row.ai_5b_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period B</strong
            >
          </template>

          <template
            v-if="hasValue(row.ai_5c_months) && hasValue(row.ai_5c_percent)"
          >
            {{ row.ai_5c_months }} months @ {{ row.ai_5c_percent }}
          </template>
          <template
            v-else-if="
              hasValue(row.ai_5c_months) !== hasValue(row.ai_5c_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period C</strong
            >
          </template>
        </span>
        <span
          v-else-if="
            row.type?.value === 'introducer' ||
            row.type?.value === 'masternetwork'
          "
        >
          <template
            v-if="hasValue(row.i_4c_months) !== hasValue(row.i_4c_percent)"
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period</strong
            >
          </template>
          {{ row.i_4c_months }} months @ {{ row.i_4c_percent }}%
          <span v-if="row.i_4c_percent === 0"> - Zero Rated</span>
        </span>
        <span v-else-if="row.type?.value === 'parentnetwork'">
          <em>Inherited</em>
        </span>
        <span v-else>
          <em>N/A</em>
        </span>
      </template>
      <template #actions="scope">
        <Button
          @click="update(scope.row)"
          :icon-left="StyleService.icon.edit.icon"
          size="is-small"
          color="is-plain"
          aria-label="Edit contract"
          class="edit-item"
          tooltip="Edit contract"
        />
        <Button
          @click="deleteContact(scope.row)"
          :icon-left="StyleService.icon.delete.icon"
          size="is-small"
          color="is-plain"
          aria-label="Delete contract"
          class="delete-item"
          tooltip="Delete contract"
        />
      </template>
      <template #dated="{ row }: { row: IntroducerContract }">
        <span v-if="row.datedAt">
          {{ row.datedAt.formatDate }} -
          <span v-if="row.datedTill">{{ row.datedTill.formatDate }} </span>
          <i v-else>Perpetual</i>
        </span>
        <i v-else>Not Dated</i>
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateIntroducerContractForm } from "@/module/introducer.contract/form/update-introducer-contract.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerContract, IntroducerContract } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsContractsParams,
  IntroducerCompanyDetailsContractsRoute,
} from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsContractsRoute,
  mixins: [
    IntroducerCompanyDetailsMixin,
    AuthService.mixin({
      sessionRequired: true,
    }),
  ],
  components: {
    Icon,
    MasterListPage,
    PageHeader,
    Button,
  },
  emits: ["create-contract"],
  data: () => ({
    StyleService,
    columns: [
      {
        title: "Type",
        sort: true,
        map: (v: IntroducerContract) => v.type?.text,
      },
      {
        title: "Key Terms",
        sort: false,
        slot: "keyTerms",
      },
      {
        title: "Sent",
        sort: true,
        map: (v: IntroducerContract) => v.sentAt?.formatDate,
      },
      {
        title: "Dated",
        sort: true,
        slot: "dated",
      },
      {
        title: "Signed",
        sort: true,
        slot: "signed",
      },
      {
        title: "Notes",
        sort: true,
        mapHtml: (v: IntroducerContract) => v.notes ?? ``,
      },
      {
        title: "Actions",
        slot: "actions",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerContract>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerContract,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    showContextMenu(): boolean {
      return this.$auth.hasPermission("introducer-company:write");
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    hasValue(value: string | null | undefined | number) {
      return value !== null && value !== "";
    },
    async refresh(): Promise<void> {
      const { contracts, pagination } = await window.Kernel.ActionBus.execute(
        "introducer/company/contracts/read",
        {
          introducerCompany: (
            this.$params as IntroducerCompanyDetailsContractsParams
          ).introducerCompany,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = contracts;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },

    async update(introducerContract: IntroducerContract): Promise<void> {
      if (!this.$auth.hasPermission("introducer-company:write")) {
        return;
      }

      if (!this.introducerCompany) {
        return;
      }

      try {
        await UpdateIntroducerContractForm(introducerContract).dialog({
          dialog: {
            title: "Create Contract",
          },
        });
      } catch (e) {
        introducerContract.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.execute(
        "introducer/company/contract/update",
        {
          introducerContract,
        },
      );

      await this.refresh();
    },
    create(): void {
      this.$emit("create-contract");
    },
    async deleteContact(introducerContract: IntroducerContract): Promise<void> {
      if (!this.$auth.hasPermission("introducer-company:write")) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Delete ${introducerContract.name}`,
          message: `Are you sure you want to delete ${introducerContract.name}?`,
        },
      });

      if (!this.introducerCompany) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "introducer/company/contract/delete",
        {
          introducerContract,
        },
      );

      await this.refresh();
    },
  },
});
</script>
