import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinCheck, Note } from "@kinherit/sdk";

interface UpdateKintinCheckMessage {
  kintin: string;
  kintinCheck: KintinCheck;
}

interface UpdateKintinCheckResponse {
  kintinCheck: KintinCheck;
  notes: Note[];
}

export class UpdateKintinCheckHandler extends ActionBase {
  constructor(private message: UpdateKintinCheckMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinCheckResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-check/{kintin}/checks/{kintinCheck}",
      {
        kintin: this.message.kintin,
        kintinCheck: this.message.kintinCheck.$data.id,
      },
    )
      .method("patch")
      .body({
        checkType: this.message.kintinCheck.$data.checkType,
        checkValue: this.message.kintinCheck.$data.checkValue,
        note: {
          completedAt: this.message.kintinCheck.note.$data.completedAt,
          kintin: this.message.kintin,
          name: this.message.kintinCheck.note.$data.name,
          notes: this.message.kintinCheck.note.$data.notes,
          dueAt: this.message.kintinCheck.note.$data.dueAt,
          pinned: this.message.kintinCheck.note.$data.pinned,
        },
        kintin: this.message.kintin,
        step: this.message.kintinCheck.$data.step,
      })

      .result();

    const kintinCheck = KintinCheck.$inflate(response.kintinCheck).first();

    if (!kintinCheck) {
      throw new Error("Failed to update kintin check");
    }

    return {
      kintinCheck,
      notes: Note.$inflate(response.note),
    };
  }
}
