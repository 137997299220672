import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  INotification,
  Kintin,
  Note,
  Notification,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadNotificationsMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof INotification;
    direction: "asc" | "desc";
  };
  query?: QueryMask<typeof Notification>;
}

interface ReadNotificationsResponse {
  notifications: Array<Notification>;
  users: Array<User>;
  notes: Array<Note>;
  profiles: Array<Profile>;
  kintins: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadNotificationsHandler extends ActionBase {
  constructor(private message: ReadNotificationsMessage) {
    super();
  }

  public async execute(): Promise<ReadNotificationsResponse> {
    const request = Api.resource("portal", "/v2/portal/dashboard")
      .method("get")

      .paginate(this.message.pagination ?? false)
      .sort(this.message.sort);

    request.buildQuery(Notification).where(this.message.query ?? {});

    const response = await request.result();

    return {
      notifications: Notification.$inflate(
        response.notification,
        this.message.sort,
        response.$rootIds,
      ),
      users: User.$inflate(response.user),
      notes: Note.$inflate(response.note),
      profiles: Profile.$inflate(response.profile),
      kintins: Kintin.$inflate(response.kintin),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
