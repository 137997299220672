import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailAddress,
  EmailLog,
  EmailNamedAttachment,
  EmailTemplate,
  FileLog,
  IEmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
  Profile,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEmailLog;
    direction: "asc" | "desc";
  };
  endpoint: "filter-by-email-address" | "filter-by-owner";
};

type Response = {
  emailLogs: Array<EmailLog>;
  emailAddresses: Array<EmailAddress>;
  introducerCompanies: Array<IntroducerCompany>;
  introducerContacts: Array<IntroducerContact>;
  kintins: Array<Kintin>;
  leads: Array<Lead>;
  officers: Array<Person>;
  brandedKinvaults: Array<BrandedKinvault>;
  profiles: Array<Profile>;
  files: Array<FileLog>;
  emailTemplates: Array<EmailTemplate>;
  emailNamedAttachments: Array<EmailNamedAttachment>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
};

export class ReadEmailLogHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log",
      [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/email-log",
      [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log",
      [Person.$name]: "/v2/portal/officer-email-log/{owner}/email-log",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log",
    } as const;

    const route = routes[this.message.owner.$name];

    const request = Api.resource(
      "portal",
      `${route}/${this.message.endpoint}`,
      {
        owner: this.message.owner,
      },
    )
      .method("get")
      .paginate(this.message.pagination ?? false)

      .sort(this.message.sort ?? false);

    const response = await request.result();

    return {
      emailLogs: EmailLog.$inflate(
        response.emailLog,
        this.message.sort,
        response.$rootIds,
      ),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      kintins: Kintin.$inflate(response.kintin),
      leads: Lead.$inflate(response.lead),
      officers: Person.$inflate(response.person),
      brandedKinvaults: BrandedKinvault.$inflate(response.brandedKinvault),
      emailNamedAttachments: EmailNamedAttachment.$inflate(
        response.emailNamedAttachment,
      ),
      profiles: Profile.$inflate(response.profile),
      files: FileLog.$inflate(response.fileLog),
      emailTemplates: EmailTemplate.$inflate(response.emailTemplate),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    } as R;
  }
}
