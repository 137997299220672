import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContact } from "@kinherit/sdk";

interface DeleteIntroducerContactMessage {
  introducerContact: string | IntroducerContact;
}

interface DeleteIntroducerContactResponse {
  [key: string]: never;
}

export class DeleteIntroducerContactHandler extends ActionBase {
  constructor(private message: DeleteIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerContactResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-contact/{introducerContact}",
      {
        introducerContact: this.message.introducerContact,
      },
    )
      .method("delete")

      .result();

    IntroducerContact.$delete(this.message.introducerContact);

    return {};
  }
}
