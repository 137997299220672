import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, CpdSession, Website } from "@kinherit/sdk";

export interface UpdateCpdSessionMessage {
  cpdSession: CpdSession;
}

export interface UpdateCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  addresses: Array<Address>;
  websites: Array<Website>;
}

export class UpdateCpdSessionHandler extends ActionBase {
  constructor(private message: UpdateCpdSessionMessage) {
    super();
  }

  public async execute(): Promise<UpdateCpdSessionResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/cpd-session/{cpdSession}",
      {
        cpdSession: this.message.cpdSession.$data.id,
      },
    )
      .method("patch")

      .body({
        address:
          null === this.message.cpdSession.address
            ? null
            : {
                line1: this.message.cpdSession.address.$data.line1,
                line2: this.message.cpdSession.address.$data.line2,
                city: this.message.cpdSession.address.$data.city,
                country: this.message.cpdSession.address.$data.country,
                postcode: this.message.cpdSession.address.$data.postcode,
                state: this.message.cpdSession.address.$data.state,
              },
        allowBookings: this.message.cpdSession.$data.allowBookings,
        date: this.message.cpdSession.$data.date,
        duration: this.message.cpdSession.$data.duration,
        outcomes: this.message.cpdSession.$data.outcomes,
        title: this.message.cpdSession.$data.title,
        type: this.message.cpdSession.$data.type,
        inviteDescription: this.message.cpdSession.$data.inviteDescription,
        isPrivate: this.message.cpdSession.$data.isPrivate,
      });

    const response = await request.result();

    const websites = this.message.cpdSession.websites;

    const localWebsiteIds = websites.pluck("id");
    const remoteWebsiteIds = response.website?.pluck("id") ?? [];
    const newWebsiteIds = localWebsiteIds.remove(...remoteWebsiteIds);
    const deletedWebsiteIds = remoteWebsiteIds.remove(...localWebsiteIds);

    for (const websiteId of newWebsiteIds) {
      const website = websites.find(
        (website) => website.$data.id === websiteId,
      );

      if (undefined === website) {
        throw new Error("Website not found");
      }

      await Api.resource(
        "portal",
        "/v2/portal/cpd-session/{cpdSession}/websites",
        {
          cpdSession: this.message.cpdSession.$data.id,
        },
      )
        .method("post")

        .body({
          url: website.$data.url,
        })
        .result();

      website.$persist();
    }

    for (const websiteId of deletedWebsiteIds) {
      await Api.resource(
        "portal",
        "/v2/portal/cpd-session/{cpdSession}/websites/{website}",
        {
          cpdSession: this.message.cpdSession.$data.id,
          website: websiteId,
        },
      )
        .method("delete")
        .result();
    }

    return await window.Kernel.ActionBus.execute(
      "cpd/session/record",
      this.message,
    );
  }
}
