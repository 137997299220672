<template>
  <PageHeader htag="h1" text="Outbound Referrals">
    <template #buttons>
      <!--
        // v-if="hasWritePermission"
      -->
      <Button class="create" @click="createIntroducerOutsource">
        Create Entry
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @row-clicked="handleClick"
    @refresh="refresh"
    :sort-by-options="{
      datedAt: 'Referral Date',
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #status="{ row }">
      <strong
        :class="{
          'has-text-success': row.status?.value === 'completed',
          'has-text-purple': row.status?.value === 'inprogress',
        }"
        >{{ row.status?.text }}</strong
      >
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadIntroducerOutsourceForm } from "@/module/introducer.outsource/form/read-introducer-outsource.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerOutsource, IntroducerOutsource } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerOutsourceMasterListRoute } from ".";
import { CreateIntroducerOutsourceForm } from "../form/create-introducer-outsource.form";
import {
  IntroducerOutsourceDetailsParams,
  IntroducerOutsourceDetailsRoute,
} from "./details";
export default defineComponent({
  name: IntroducerOutsourceMasterListRoute,
  mixins: [AuthService.mixin()],
  components: { MasterListPage, PageHeader, Button },
  data: () => ({
    filters: ReadIntroducerOutsourceForm(),
    columns: [
      {
        title: "Referral Date",
        sort: true,
        map: (v: IntroducerOutsource) => v.datedAt?.formatDate,
      },
      {
        title: "Client",
        sort: true,
        map: (v: IntroducerOutsource) => v.clientName ?? "Not Set",
      },
      {
        title: "Company",
        sort: true,
        map: (v: IntroducerOutsource) =>
          v.company?.profile?.organisationName
            ? v.company?.profile?.organisationName
            : "Not Set",
      },
      {
        title: "Type",
        sort: true,
        map: (v: IntroducerOutsource) => v.type?.text,
      },
      {
        title: "Status",
        sort: true,
        slot: "status",
      },
      {
        title: "Next Action",
        sort: true,
        map: (v: IntroducerOutsource) =>
          v.nextActionAt ? v.nextActionAt?.formatDate : "Not Set",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerOutsource>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "datedAt" as keyof IIntroducerOutsource,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-outsource:write");
    },
  },
  methods: {
    handleClick(
      introduceroutsource: IntroducerOutsource,
      event: MouseEvent,
    ): void {
      const params: IntroducerOutsourceDetailsParams = {
        introducerOutsource: introduceroutsource.id,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerOutsourceDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerOutsourceForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "introducer/outsource/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.introducerOutsources;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createIntroducerOutsource(): Promise<void> {
      const introducerOutsource = await CreateIntroducerOutsourceForm().dialog({
        dialog: {
          title: "Create Entry",
        },
      });

      await window.Kernel.ActionBus.execute("introducer/outsource/create", {
        introducerOutsource,
      });

      this.refresh(this.filters.localData);
    },
  },
});
</script>
