import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinCheckFollowUp } from "@kinherit/sdk";

interface UpdateKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface UpdateKintinCheckFollowUpResponse {
  kintinCheckFollowUp: KintinCheckFollowUp;
}

export class UpdateKintinCheckFollowUpHandler extends ActionBase {
  constructor(private message: UpdateKintinCheckFollowUpMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinCheckFollowUpResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups/{kintinCheckFollowUp}",
      {
        kintin: this.message.kintin,
        kintinCheckFollowUp: this.message.kintinCheckFollowUp.$data.id,
      },
    )
      .method("patch")
      .body({
        note: {
          completedAt: this.message.kintinCheckFollowUp.note.$data.completedAt,
          kintin: this.message.kintin,
          name: this.message.kintinCheckFollowUp.note.$data.name,
          notes: this.message.kintinCheckFollowUp.note.$data.notes,
          dueAt: this.message.kintinCheckFollowUp.note.$data.dueAt,
          pinned: this.message.kintinCheckFollowUp.note.$data.pinned,
        },
        step: this.message.kintinCheckFollowUp.$data.step,
        kintin: this.message.kintin,
      })

      .result();

    const kintinCheckFollowUp = KintinCheckFollowUp.$inflate(
      response.kintinCheckFollowUp,
    ).first();

    if (!kintinCheckFollowUp) {
      throw new Error("Failed to update kintin check");
    }

    return {
      kintinCheckFollowUp,
    };
  }
}
