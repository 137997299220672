import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

export interface ModifyPdfMessage {
  pdfDocument: File;
  selectedWatermarkOption: number;
  selectedPasswordProtectionOption: number;
  selectedRestrictEditingOption: number;
  selectedRestrictPrintingOption: number;
  documentPassword: string | null;
}

export interface ModifyPdfResponse {}

export class ModifyPdfHandler extends ActionBase {
  constructor(public readonly message: ModifyPdfMessage) {
    super();
  }

  public async execute(): Promise<ModifyPdfResponse> {
    if (!this.message.pdfDocument) {
      throw new Error("No file selected");
    }
    const uploadedFile = this.message.pdfDocument;
    let fileName = uploadedFile.name;

    // For conformed copy, add CC_ prefix to the file name
    if (this.message.selectedWatermarkOption === 2) {
      fileName = "CC_" + fileName;
    }

    // For draft, add DRAFT_ prefix to the file name
    if (this.message.selectedWatermarkOption === 1) {
      fileName = "DRAFT_" + fileName;
    }

    // If PW Protection is enabled, add _pw suffix to the file name but beford extension
    if (this.message.selectedPasswordProtectionOption === 1) {
      const fileExtension = fileName.split(".").pop();
      fileName = fileName.replace("." + fileExtension, "_pw." + fileExtension);
    }

    console.log(uploadedFile.name, uploadedFile.size, uploadedFile.type);
    await Api.resource("portal", "/v2/portal/document-utils/modify")
      .method("post")
      .files({
        uploadedFile: uploadedFile,
      })
      .params({
        selectedWatermarkOption: this.message.selectedWatermarkOption,
        selectedPasswordProtectionOption:
          this.message.selectedPasswordProtectionOption,
        selectedRestrictEditingOption:
          this.message.selectedRestrictEditingOption,
        selectedRestrictPrintingOption:
          this.message.selectedRestrictPrintingOption,
        documentPassword: this.message.documentPassword ?? "",
      })
      .download({
        fileName: fileName,
        encoding: "application/pdf",
      });
    return {};
  }
}
