import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, IsNotNull, IsNull, Like } from "@kinherit/orm/index";
import {
  Api,
  IWillRevision,
  Kintin,
  Profile,
  QueryMask,
  User,
  WillRevision,
} from "@kinherit/sdk";

export interface ReadWillRevisionMessage {
  search?: string;
  created?: null | [number, number];
  approved?: boolean | null;
  query?: QueryMask<typeof WillRevision>;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IWillRevision;
    direction: "asc" | "desc";
  };
}

interface ReadWillRevisionResponse {
  willRevisions: Array<WillRevision>;
  profiles: Array<Profile>;
  kintins: Array<Kintin>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadWillRevisionHandler extends ActionBase {
  constructor(private message: ReadWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<ReadWillRevisionResponse> {
    const request = Api.resource("portal", "/v2/portal/will-revision")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    if (this.message.query) {
      request.buildQuery(WillRevision).where(this.message.query);
    } else {
      request.buildQuery(WillRevision).where({
        kintin: {
          friendlyName: Like(this.message.search),
        },
        approvedAt:
          null === this.message.approved || undefined === this.message.approved
            ? undefined
            : true === this.message.approved
              ? IsNotNull()
              : IsNull(),
        createdAt: Between(this.message.created),
      });
    }

    const response = await request.result();

    return {
      willRevisions: WillRevision.$inflate(
        response.willRevision,
        this.message.sort,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      kintins: Kintin.$inflate(response.kintin),
      users: User.$inflate(response.user),
      pagination: this.getPagination(response),
    };
  }
}
