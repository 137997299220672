import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferralCode,
  IAccountReferralCode,
  IntroducerContact,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerContactAccountReferralCodesMessage {
  introducerContact: IntroducerContact | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferralCode;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerContactAccountReferralCodesResponse {
  referralCodes: Array<AccountReferralCode>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerContactAccountReferralCodesHandler extends ActionBase {
  constructor(
    private message: ReadIntroducerContactAccountReferralCodesMessage,
  ) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContactAccountReferralCodesResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-contact-referral/{introducerContact}/referral-codes",
      {
        introducerContact:
          "string" === typeof this.message.introducerContact
            ? this.message.introducerContact
            : this.message.introducerContact.id,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    const response = await request.result();

    return {
      referralCodes: AccountReferralCode.$inflate(
        response.accountReferralCode,
        this.message.sort,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
