import { ActionBase } from "@kinherit/framework/action-bus/base";
import { In, Equal } from "@kinherit/orm/index";
import { Api, IOption, Option } from "@kinherit/sdk";

export interface ReadOptionMessage {
  group: string | Array<string>;
  sort: {
    by: keyof IOption;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadOptionResponse {
  options: Array<Option>;
}

export class ReadOptionHandler extends ActionBase {
  constructor(private message: ReadOptionMessage) {
    super();
  }

  public async execute(): Promise<ReadOptionResponse> {
    const request = Api.resource("core", "/v2/core/option")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(Option).where({
      group: Array.isArray(this.message.group)
        ? In(this.message.group)
        : Equal(this.message.group),
    });

    const result = await request.result();

    return {
      options: Option.$inflate(result.option, this.message.sort),
    };
  }
}
