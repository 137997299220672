import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Gift } from "@kinherit/sdk";

interface CreateKintinGiftMessage {
  kintin: string;
  gift: Gift;
}

interface CreateKintinGiftResponse {
  gift: Gift;
}

export class CreateKintinGiftHandler extends ActionBase {
  constructor(private message: CreateKintinGiftMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinGiftResponse> {
    const { gift: giftData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-gift/{kintin}/gift",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        kintin: this.message.kintin,
        address: this.message.gift.$data.address,
        forPerson: this.message.gift.$data.forPerson,
        sentBy: this.message.gift.$data.sentBy,
        type: this.message.gift.$data.type,
        classGiftType: this.message.gift.$data.classGiftType,
        amount: this.message.gift.$data.amount
          ? {
              amount: this.message.gift.$data.amount.amount,
              type: this.message.gift.$data.amount.type,
            }
          : undefined,
        notes: this.message.gift.$data.notes,
        onSecondDeath: this.message.gift.$data.onSecondDeath,
        asPrimaryResidence: this.message.gift.$data.asPrimaryResidence,
        charityName: this.message.gift.$data.charityName,
        charityNo: this.message.gift.$data.charityNo,
        ifPredeceased: this.message.gift.$data.ifPredeceased,
        outputPreference: this.message.gift.$data.outputPreference,
      })
      .result();

    const gift = Gift.$inflate(giftData).first();

    if (!gift) {
      throw new Error("Failed to create gift");
    }

    if (this.message.gift.kintin) {
      this.message.gift.kintin.addGifts(gift);
    }

    if (this.message.gift.forPerson) {
      this.message.gift.forPerson.addReceivingGifts(gift);
    }

    if (this.message.gift.sentBy) {
      this.message.gift.sentBy.addSendingGifts(gift);
    }

    this.message.gift.$delete();

    return { gift };
  }
}
