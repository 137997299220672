import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  EmailAddress,
  Kintin,
  KintinAccess,
  Option,
  PhoneNumber,
  Profile,
  User,
} from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";

interface CreateKintinUserMessage {
  kintin: Kintin;
  user: User;
  accessType: "owner" | "edit" | "readonly" | "adviser" | "none";
}

interface CreateKintinUserResponse {
  user: User;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
  kintinAccess: KintinAccess;
}

export class CreateKintinUserHandler extends ActionBase {
  constructor(private message: CreateKintinUserMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinUserResponse> {
    const status = Option.$findOneByOrThrow({
      group: "userStatus",
      value: "new",
    }).id;

    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-user-access/{kintin}/create-user",
      {
        kintin: this.message.kintin.id,
      },
    )
      .method("post")
      .body({
        credential: {
          username: this.message.user.credential.username,
        },
        displayUserInPortal: false,
        profile: {
          firstName: this.message.user.profile.$data.firstName,
          lastName: this.message.user.profile.$data.lastName,
          emails: this.message.user.profile.emails.map((email) => ({
            email: email.$data.email,
            primary: email.$data.primary,
          })),
          phoneNumbers: this.message.user.profile.phoneNumbers.map(
            (phoneNumber) => ({
              tel: phoneNumber.$data.tel,
            }),
          ),
        },
        status,
        isEstatePlanner: false,
        isLegalAssistant: false,
      })

      .result();

    const user = User.$inflate(response.user).first();

    if (!user) {
      throw new Error("Failed to create user");
    }

    const { kintinAccess } = await window.Kernel.ActionBus.execute(
      "kinvault/kintin/kintin-user-access/create",
      {
        kintin: this.message.kintin,
        kintinAccess: new KintinAccess({
          id: Uuid.generate(),
          accessType: this.message.accessType,
          allowAccess: false,
          user: user.id,
          kintin: this.message.kintin.id,
          createdAt: new DateTime().formatMachine,
          legal: "none",
          assets: "none",
          contacts: "none",
          admin: "none",
          care: "none",
          death: "none",
          misc: "none",
          settings: "none",
        }).$persist(),
      },
    );

    this.message.user.$delete();

    return {
      user,
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      kintinAccess,
    };
  }
}
