import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, Property, QueryMask } from "@kinherit/sdk";

export interface ReadPropertyMessage {
  query?: QueryMask<typeof Address>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadPropertyResponse {
  addresses: Array<Address>;
  properties: Array<Property>;
}

export class ReadPropertyHandler extends ActionBase {
  constructor(private message: ReadPropertyMessage) {
    super();
  }

  public async execute(): Promise<ReadPropertyResponse> {
    const request = Api.resource("core", "/v2/core/select/property")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(Address).where(this.message.query ?? {});

    const response = await request.result();

    return {
      properties: Property.$inflate(
        response.property,
        undefined,
        response.$rootIds,
      ),
      addresses: Address.$inflate(response.address),
    };
  }
}
