import { ChangeMyPasswordHandler } from "@/module/admin.user/action/change-my-password.action";
import { CreateUserHandler } from "@/module/admin.user/action/create-user.action";
import { DeleteUserHandler } from "@/module/admin.user/action/delete-user-action";
import { ReadStaffUserHandler } from "@/module/admin.user/action/read-staff-user.action";
import { RequestPasswordChangeHandler } from "@/module/admin.user/action/request-password-change.action";
import { ResendUserMfAuthMethodHandler } from "@/module/admin.user/action/resend-user-mf-auth-method.action";
import { UpdateUserMfAuthMethodHandler } from "@/module/admin.user/action/update-user-mf-auth-method.action";
import { CreateBrandedKinvaultMembershipHandler } from "./create-branded-kinvault-membership.action";
import { CreateUserKintinAccessHandler } from "./create-user-kintin-access.action";
import { CreateUserMfAuthMethodHandler } from "./create-user-mf-auth-method.action";
import { DeleteBrandedKinvaultMembershipHandler } from "./delete-branded-kinvault-membership.action";
import { DeleteUserKintinAccessHandler } from "./delete-user-kintin-access.action";
import { DeleteUserMfAuthMethodHandler } from "./delete-user-mf-auth-method.action";
import { ReadUserActivityHandler } from "./read-user-activity.action";
import { ReadUserAssignedKintinsHandler } from "./read-user-assigned-kintins.action";
import { ReadUserMfAuthMethodsHandler } from "./read-user-mf-auth-methods.action";
import { ReadUserHandler } from "./read-user.action";
import { RecordUserHandler } from "./record-user.action";
import { UpdateBrandedKinvaultMembershipHandler } from "./update-branded-kinvault-membership.action";
import { UpdateUserKintinAccessHandler } from "./update-user-kintin-access.action";
import { UpdateUserProfileHandler } from "./update-user-profile.action";
import { UpdateUserHandler } from "./update-user.action";

export const Actions = {
  "admin/user/read": ReadUserHandler,
  "admin/user/staff/read": ReadStaffUserHandler,
  "admin/user/record": RecordUserHandler,
  "admin/user/update": UpdateUserHandler,
  "admin/user/profile/update": UpdateUserProfileHandler,
  "admin/user/kintin-access/create": CreateUserKintinAccessHandler,
  "admin/user/kintin-access/update": UpdateUserKintinAccessHandler,
  "admin/user/kintin-access/delete": DeleteUserKintinAccessHandler,
  "admin/user/branded-kinvault-membership/create":
    CreateBrandedKinvaultMembershipHandler,
  "admin/user/branded-kinvault-membership/delete":
    DeleteBrandedKinvaultMembershipHandler,
  "admin/user/branded-kinvault-membership/update":
    UpdateBrandedKinvaultMembershipHandler,
  "admin/user/assigned-kintins/read": ReadUserAssignedKintinsHandler,
  "admin/user/mf-auth-methods/read": ReadUserMfAuthMethodsHandler,
  "admin/user/mf-auth-methods/create": CreateUserMfAuthMethodHandler,
  "admin/user/mf-auth-methods/resend": ResendUserMfAuthMethodHandler,
  "admin/user/mf-auth-methods/delete": DeleteUserMfAuthMethodHandler,
  "admin/user/mf-auth-methods/update": UpdateUserMfAuthMethodHandler,
  "admin/user/activity/read": ReadUserActivityHandler,
  "admin/user/create": CreateUserHandler,
  "admin/user/change-my-password": ChangeMyPasswordHandler,
  "admin/user/request-password-change": RequestPasswordChangeHandler,
  "admin/user/delete": DeleteUserHandler,
};
