import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferralCode } from "@kinherit/sdk";

interface CreateAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode;
}

interface CreateAccountReferralCodeResponse {
  accountReferralCode: AccountReferralCode;
}

export class CreateAccountReferralCodeHandler extends ActionBase {
  constructor(private message: CreateAccountReferralCodeMessage) {
    super();
  }

  public async execute(): Promise<CreateAccountReferralCodeResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/account-referral-code",
    )
      .method("post")
      .body({
        category: this.message.accountReferralCode.$data.category,
        code: this.message.accountReferralCode.$data.code,
        company: this.message.accountReferralCode.$data.company,
        isForUsers: this.message.accountReferralCode.$data.isForUsers,
        name: this.message.accountReferralCode.$data.name,
        notes: this.message.accountReferralCode.$data.notes,
        isFeeSacrifice: this.message.accountReferralCode.$data.isFeeSacrifice,
        isSalarySacrifice:
          this.message.accountReferralCode.$data.isSalarySacrifice,
        isFriendsAndFamily:
          this.message.accountReferralCode.$data.isFriendsAndFamily,
      })
      .result();

    const accountReferralCode = AccountReferralCode.$inflate(
      response.accountReferralCode,
      undefined,
      response.$rootIds,
    ).first();

    if (!accountReferralCode) {
      throw new Error("Failed to create account referral code");
    }

    this.message.accountReferralCode.$delete();

    return {
      accountReferralCode,
    };
  }
}
