import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerNetwork, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerNetworkMessage {
  query?: QueryMask<typeof IntroducerNetwork>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
}

export class ReadIntroducerNetworkHandler extends ActionBase {
  constructor(private message: ReadIntroducerNetworkMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerNetworkResponse> {
    const request = Api.resource("core", "/v2/core/select/introducer-network")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(IntroducerNetwork).where(this.message.query ?? {});

    const response = await request.result();

    return {
      introducerNetworks: IntroducerNetwork.$inflate(
        response.introducerNetwork,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
