import { Module } from "@/config/module.config";
import { Actions } from "./action";

Module.register({
  name: "core",
  routes: [],
  actions: Actions,
  session: true,
  permissions: [],
});

export default {};
