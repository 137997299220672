import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface UpdateKintinSignDocMessage {
  signDoc: SignDoc;
  kintin: Kintin | string;
}

export interface UpdateKintinSignDocResponse {
  signDoc: SignDoc;
}

export class UpdateKintinSignDocHandler extends ActionBase {
  constructor(private message: UpdateKintinSignDocMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinSignDocResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
        signDoc: this.message.signDoc.id,
      },
    )
      .method("patch")
      .body({
        person: this.message.signDoc.$data.person,
        data: this.message.signDoc.$data.data,
        kintin: this.message.signDoc.$data.kintin,
        type: this.message.signDoc.$data.type,
        ipAddress: this.message.signDoc.$data.ipAddress,
        userAgent: this.message.signDoc.$data.userAgent,
        signedAt: this.message.signDoc.$data.signedAt,
        stripeSessions: this.message.signDoc.$data.stripeSessions,
        clauses: this.message.signDoc.$data.clauses,
        conditions: this.message.signDoc.$data.conditions,
        products: this.message.signDoc.$data.products,
      })
      .result();

    const signDoc = SignDoc.$inflate(response.signDoc).first();

    if (!signDoc) {
      throw new Error("SignDoc not found");
    }

    await Api.resource(
      "portal",
      "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/disclaimer",
      {
        kintin: signDoc.$data.kintin,
        signDoc: signDoc.$data.id,
      },
    )
      .method("put")
      .result();

    return {
      signDoc,
    };
  }
}
