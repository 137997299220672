import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

interface AddKnowledgeBaseCategoryDocumentMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory | string;
  knowledgeBaseDocument: KnowledgeBaseDocument | string;
}

interface AddKnowledgeBaseCategoryDocumentResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export class AddKnowledgeBaseCategoryDocumentHandler extends ActionBase {
  constructor(private message: AddKnowledgeBaseCategoryDocumentMessage) {
    super();
  }

  public async execute(): Promise<AddKnowledgeBaseCategoryDocumentResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/add",
      {
        knowledgeBaseCategory:
          "string" === typeof this.message.knowledgeBaseCategory
            ? this.message.knowledgeBaseCategory
            : this.message.knowledgeBaseCategory.id,
        knowledgeBaseDocument:
          "string" === typeof this.message.knowledgeBaseDocument
            ? this.message.knowledgeBaseDocument
            : this.message.knowledgeBaseDocument.id,
      },
    )
      .method("post")

      .result();

    const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    ).first();

    if (!knowledgeBaseCategory) {
      throw new Error("Failed to add knowledge base category document");
    }

    return {
      knowledgeBaseCategory,
    };
  }
}
