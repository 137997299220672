import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  BrandedKinvaultSubscriptionReferralCode,
  IBrandedKinvaultSubscriptionReferralCode,
} from "@kinherit/sdk";

interface ReadBrandedKinvaultSubscriptionReferralCodeMessage {
  name?: string;
  code?: string;
  brandedKinvault: string;
  created?: null | [number, number];
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort: {
    by: keyof IBrandedKinvaultSubscriptionReferralCode;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCodes: BrandedKinvaultSubscriptionReferralCode[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadBrandedKinvaultSubscriptionReferralCodeHandler extends ActionBase {
  constructor(
    private message: ReadBrandedKinvaultSubscriptionReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<ReadBrandedKinvaultSubscriptionReferralCodeResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral-code",
    )
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(BrandedKinvaultSubscriptionReferralCode).where({
      brandedKinvault: Equal(this.message.brandedKinvault),
      name: Like(this.message.name),
      code: Like(this.message.code),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    const brandedKinvaultSubscriptionReferralCodes =
      BrandedKinvaultSubscriptionReferralCode.$inflate(
        response.brandedKinvaultSubscriptionReferralCode,
        undefined,
        response.$rootIds,
      );

    return {
      brandedKinvaultSubscriptionReferralCodes,
      pagination: this.getPagination(response),
    };
  }
}
