<template>
  <PageHeader htag="h1" text="Storage Requests" />
  <MasterListPage
    v-if="$data.filters"
    :key="pagination.currentPage"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      requestedAt: 'Requested',
      createdAt: 'Created',
    }"
    :tracking-field="(row: PhysicalStorageFileLog) => row.id"
    v-model:value="selected"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #tableControls>
      <Button
        :text="`Bulk Update ${
          selected.length > 0 ? `(${selected.length})` : ''
        }`"
        :color="selected.length > 0 ? 'is-positive' : null"
        size="is-small"
        :is-disabled="selected.length === 0"
        @click="bulkActions"
      />
    </template>

    <template #by="{ row }">
      <Avatar :name="row.createdBy" />
    </template>
    <template #client="{ row }">
      <a
        class="client-name"
        tabindex="0"
        @click="(event: MouseEvent) => handleClientClick(row)"
        @keyup.enter="(event: KeyboardEvent) => handleClientClick(row)"
      >
        {{ row.file?.subscription?.kintin.friendlyName }}
      </a>
      <br />
      <small class="is-family-monospace">{{
        row.file?.subscription?.kintin.ref
      }}</small>
    </template>
    <template #request="{ row }">
      <Icon
        icon="Plus"
        class="has-text-success"
        v-if="row.logAction.value === 'added'"
      />
      <Icon
        icon="Cross"
        class="has-text-danger"
        v-if="row.logAction.value === 'removed'"
      />
      <Icon
        icon="Reset"
        class="has-text-info"
        v-if="row.logAction.value === 'updated'"
      />
      {{ row.logAction.text }}
    </template>
    <template #actions="{ row }">
      <span v-if="row.completedAt !== null">
        <Icon icon="Tick" class="has-text-success" /> Completed
      </span>
      <Button
        v-if="row.completedAt === null"
        text="Mark Complete"
        color="is-success"
        size="is-small"
        is-outlined
        @click="markComplete(row)"
      />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import {
  KintinDetailsSubscriptionParams,
  KintinDetailsSubscriptionRoute,
} from "@/module/kinvault.kintin/page/details/documents/subscriptions";
import { ReadStorageRequestForm } from "@/module/kinvault.storage-request/form/read-storage-request.form";
import { AuthService } from "@/service/auth.service";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Icon } from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { IPhysicalStorageFileLog, PhysicalStorageFileLog } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { KinvaultStorageRequestMasterListRoute } from ".";

export default defineComponent({
  name: KinvaultStorageRequestMasterListRoute,
  components: { MasterListPage, Avatar, Button, PageHeader, Icon },
  data: () => ({
    filters: ReadStorageRequestForm(),
    selected: Array<PhysicalStorageFileLog>(),
    columns: [
      // {
      //   title: "Ref",
      //   sort: true,
      //   //map: (v) => v.file?.subscription?.kintin.ref,
      // },
      {
        title: "Client",
        sort: true,
        slot: "client",
        //map: (v) =>
        //  v.file?.subscription?.kintin.friendlyName ?? ``,
      },
      {
        title: "By",
        slot: "by",
        class: "is-narrow",
      },
      {
        title: "Requested",
        sort: true,
        map: (v) => v.requestedAt.formatDate,
      },
      {
        title: "Request",
        sort: true,
        slot: "request",
        //map: (v) => v.logAction.text,
      },
      {
        title: "File",
        sort: true,
        map: (v) => v.file?.title,
      },
      {
        slot: "actions",
        class: "is-narrow",
        blockClicks: true,
      },
    ] as Array<TableColumn<PhysicalStorageFileLog>>,
    rows: Array<PhysicalStorageFileLog>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "requestedAt" as keyof IPhysicalStorageFileLog,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    // @todo implement actual bulk action
    async bulkActions(): Promise<void> {
      // Define an interface for grouping
      interface ClientGroup {
        clientName: string;
        files: PhysicalStorageFileLog[];
      }

      // Define the type for the grouped object
      type GroupedByClient = { [clientId: string]: ClientGroup };

      // Group the selected items by client
      const groupedByClient = this.$data.selected.reduce<GroupedByClient>(
        (acc, v) => {
          const clientId = v.file.subscription.kintin.ref;
          const clientName = v.file.subscription.kintin.friendlyName;
          if (!acc[clientId]) {
            acc[clientId] = {
              clientName: clientName ?? "",
              files: [],
            };
          }
          acc[clientId].files.push(v);
          return acc;
        },
        {},
      );

      // Build the dialog HTML content
      let dialogHtml = "";
      for (const [clientId, group] of Object.entries(groupedByClient)) {
        dialogHtml += `<strong>${group.clientName}</strong> - <small>${clientId}</small><br/>`;
        group.files.forEach((v) => {
          const filename = v.file.title;
          dialogHtml += `${filename} -> ${v.logAction.text}<br/>`;
        });
        dialogHtml += "<br/>"; // Separate groups
      }

      // Display the dialog
      await OpenAlertDialog({
        dialog: {
          title: `Bulk Mark Requests as Complete?`,
          html: dialogHtml,
        },
        button: {
          ok: {
            text: "Mark Complete",
            color: "is-success",
          },
        },
      });

      const user = AuthService.loggedInUser;

      if (!user) {
        throw new Error("User not found");
      }

      await this.$data.selected.forEachAsync(
        async (v) => {
          v.completedAt = new DateTime();
          v.confirmedBy = user.profile.fullName;
          await window.Kernel.ActionBus.execute(
            "kinvault/storage-request/update",
            {
              physicalStorageFileLog: v,
            },
          );
        },
        {
          parallel: true,
        },
      );

      await this.refresh(this.filters.localData);
    },
    handleClientClick(storage: any): void {
      window.Kernel.visitRoute({
        name: KintinDetailsSubscriptionRoute,
        params: {
          kintin: storage.file.subscription.kintin.id,
          subscription: storage.file.subscription.id,
        } satisfies KintinDetailsSubscriptionParams,
      });
    },
    async markComplete(
      physicalStorageFileLog: PhysicalStorageFileLog,
    ): Promise<void> {
      const kintin = physicalStorageFileLog.file.subscription.kintin;
      const filename = physicalStorageFileLog.file.title;

      await OpenAlertDialog({
        dialog: {
          title: `Mark Request as Complete`,
          html: `Client: <strong>${kintin.friendlyName}</strong><br/>File: <strong>${filename}</strong> <br/>Request: <strong>${physicalStorageFileLog.logAction.text}</strong>`,
        },
        button: {
          ok: {
            text: "Mark Complete",
            color: "is-success",
          },
        },
      });

      const user = AuthService.loggedInUser;

      if (!user) {
        throw new Error("User not found");
      }

      physicalStorageFileLog.completedAt = new DateTime();
      physicalStorageFileLog.confirmedBy = user.profile.fullName;

      await window.Kernel.ActionBus.execute("kinvault/storage-request/update", {
        physicalStorageFileLog,
      });

      await this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<typeof ReadStorageRequestForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "kinvault/storage-request/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );
      this.rows = data.physicalStorageFileLogs;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
