import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ActiveSession } from "../../model/active-session.model";
import { Session } from "../../model/session.model";

interface CompleteMultiFactorAuthMessage {
  session: Session;
  code: number;
  multiFactorSessionId: string;
}

interface CompleteMultiFactorAuthResult {
  session: Session;
  activeSession: ActiveSession;
}

export class CompleteMultiFactorAuthHandler extends ActionBase {
  constructor(private message: CompleteMultiFactorAuthMessage) {
    super();
  }

  public async execute(): Promise<CompleteMultiFactorAuthResult> {
    const session = this.message.session;

    const response = await Api.resource(
      "auth",
      "/v2/auth/multi-factor/complete",
    )
      .method("put")
      .body({
        code: this.message.code,
        session: this.message.multiFactorSessionId,
      })
      .result();

    const newSession = Session.$create({
      id: session.username,
      methods: [],
      mfaRequired: false,
      token: response.token,
      user: session.user,
    });

    newSession.$persist();

    const activeSession = ActiveSession.fromSession(newSession).$persist();

    await window.Kernel.ActionBus.execute("auth/session/complete", {
      activeSession,
    });

    return { session: newSession, activeSession };
  }
}
