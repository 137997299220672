<template>
  <Card :title="title">
    <template v-if="rows.length" #buttons>
      <Button text="Merge All" @click="mergeAll" />
    </template>
    <p v-if="rows.length === 0">No requested changes found</p>
    <Table
      v-if="rows.length"
      :columns="columns"
      :rows="rows"
      compress-empty-message
      empty-message="No changes found"
      is-fullwidth
      is-fixed
    >
      <template #actions="{ index }">
        <Button text="Merge" @click="merge(index)" />
      </template>
      <template #action="{ row }">
        <span v-if="row.action === 'Created'" class="badge is-success">
          {{ row.action }}
        </span>
        <span v-else-if="row.action === 'Deleted'" class="badge is-danger">
          {{ row.action }}
        </span>
        <span v-else class="badge is-warning">{{ row.action }}</span>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import {
  Address,
  EmailAddress,
  IAddress,
  IEmailAddress,
  IPerson,
  IPhoneNumber,
  IProfile,
  Option,
  Person,
  PhoneNumber,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { PropType, defineComponent } from "vue";
import { KinvaultKintinDetailsPostPaymentMergeContactsParams } from "../page/details/documents/post-payment";

export default defineComponent({
  name: "PersonChangesSummary",
  components: { Card, Table, Button },
  props: {
    person: {
      type: Person as PropType<Person>,
      required: true,
    },
  },
  emits: ["merge"],
  data: () => ({
    columns: [
      {
        title: "ID",
        field: "id",
      },
      {
        title: "Action",
        slot: "action",
      },
      {
        title: "Type",
        map: (row: any) => (row.field ?? row.type).toSentenceCase(),
      },
      {
        title: "Current Value",
        field: "currentValue",
      },
      {
        title: "New Value",
        field: "newValue",
      },
      {
        slot: "actions",
      },
    ] satisfies TableColumn[],
  }),
  computed: {
    $params(): KinvaultKintinDetailsPostPaymentMergeContactsParams {
      return this.$route
        .params as KinvaultKintinDetailsPostPaymentMergeContactsParams;
    },

    currentPerson(): IPerson {
      const person = this.person;

      if (!person) {
        return {} as IPerson;
      }

      return person.$data;
    },
    newPerson(): Partial<IPerson> {
      const person = this.person;

      if (!person) {
        return {};
      }

      const draft = person.getDraft();

      const personChanges: Partial<IPerson> = {};

      Object.keys(draft.person).forEach((_key) => {
        const key = _key as keyof IPerson;
        if (draft.person[key] !== person.$data[key]) {
          personChanges[key] = draft.person[key] as any;
        }
      });

      return personChanges;
    },

    currentProfile(): IProfile {
      const person = this.person;

      if (!person) {
        return {} as IProfile;
      }

      return person.profile.$data;
    },
    newProfile(): Partial<IProfile> {
      const person = this.person;

      if (!person) {
        return {};
      }

      const draft = person.getDraft();

      const profileChanges: Partial<IProfile> = {};

      Object.keys(draft.profile).forEach((_key) => {
        const key = _key as keyof IProfile;
        if (draft.profile[key] !== person.profile.$data[key]) {
          profileChanges[key] = draft.profile[key] as any;
        }
      });

      return profileChanges;
    },

    newAddress(): IAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();
      const newAddress = draft.addresses;
      const currentAddress = person.profile.addresses;

      return newAddress
        .filter(({ id }) => !currentAddress.some((a) => a.id === id))
        .filter(
          (address) =>
            !currentAddress.some((a) => {
              return (
                a.line1 === address.line1 &&
                a.line2 === address.line2 &&
                a.city === address.city &&
                a.postcode === address.postcode &&
                a.country === address.country &&
                a.state === address.state
              );
            }),
        );
    },
    deletedAddesses(): IAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newAddress = draft.addresses;
      const currentAddress = person.profile.addresses;

      return currentAddress
        .filter(({ id }) => !newAddress.some((a) => a.id === id))
        .filter(
          (address) =>
            !newAddress.some((a) => {
              return (
                a.line1 === address.line1 &&
                a.line2 === address.line2 &&
                a.city === address.city &&
                a.postcode === address.postcode &&
                a.country === address.country &&
                a.state === address.state
              );
            }),
        )
        .pluck("$data");
    },
    updatedAddresses(): IAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newAddress = draft.addresses;
      const currentAddress = person.profile.addresses;

      return currentAddress
        .filter(({ id }) => newAddress.some((a) => a.id === id))
        .filter((address) => {
          const existing = newAddress.find((a) => a.id === address.id);

          return (
            existing &&
            existing.line1 !== address.line1 &&
            existing.line2 !== address.line2 &&
            existing.city !== address.city &&
            existing.postcode !== address.postcode &&
            existing.country !== address.country &&
            existing.state !== address.state
          );
        })
        .pluck("$data");
    },

    newEmailAddresses(): IEmailAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newEmailAddresses = draft.emailAddresses;
      const currentEmailAddresses = person.profile.emails;

      return newEmailAddresses
        .filter(
          ({ id }) => !currentEmailAddresses.some((email) => email.id === id),
        )
        .filter(
          (emailAddress) =>
            !currentEmailAddresses.some(
              (email) => email.email === emailAddress.email,
            ),
        );
    },
    deletedEmailAddresses(): IEmailAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newEmailAddresses = draft.emailAddresses;
      const currentEmailAddresses = person.profile.emails;

      return currentEmailAddresses
        .filter(({ id }) => !newEmailAddresses.some((email) => email.id === id))
        .filter(
          (email) =>
            !newEmailAddresses.some(
              (emailAddress) => email.email === emailAddress.email,
            ),
        )
        .pluck("$data");
    },
    updatedEmailAddresses(): IEmailAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newEmailAddresses = draft.emailAddresses;
      const currentEmailAddresses = person.profile.emails;

      return currentEmailAddresses
        .filter(({ id }) => newEmailAddresses.some((email) => email.id === id))
        .filter((email) => {
          const existing = newEmailAddresses.find(
            (emailAddress) => email.id === emailAddress.id,
          );

          return existing && email.email !== existing.email;
        })
        .pluck("$data");
    },

    newPhoneNumbers(): IPhoneNumber[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newPhoneNumbers = draft.phoneNumbers;
      const currentPhoneNumbers = person.profile.phoneNumbers;

      return newPhoneNumbers
        .filter(
          ({ id }) => !currentPhoneNumbers.some((phone) => phone.id === id),
        )
        .filter(
          (phoneNumber) =>
            !currentPhoneNumbers.some((phone) => phone.tel === phoneNumber.tel),
        );
    },
    deletedPhoneNumbers(): IPhoneNumber[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newPhoneNumbers = draft.phoneNumbers;
      const currentPhoneNumbers = person.profile.phoneNumbers;

      return currentPhoneNumbers
        .filter(({ id }) => !newPhoneNumbers.some((phone) => phone.id === id))
        .filter(
          (phone) =>
            !newPhoneNumbers.some(
              (phoneNumber) => phone.tel === phoneNumber.tel,
            ),
        )
        .pluck("$data");
    },
    updatedPhoneNumbers(): IPhoneNumber[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      const draft = person.getDraft();

      const newPhoneNumbers = draft.phoneNumbers;
      const currentPhoneNumbers = person.profile.phoneNumbers;

      return currentPhoneNumbers
        .filter(({ id }) => newPhoneNumbers.some((phone) => phone.id === id))
        .filter((phone) => {
          const existing = newPhoneNumbers.find(
            (phoneNumber) => phone.id === phoneNumber.id,
          );

          return existing && phone.tel !== existing.tel;
        })
        .pluck("$data");
    },

    title(): string {
      const draftProfile = this.person.getDraft().profile;
      const newName = `${draftProfile.firstName} ${draftProfile.lastName}`;

      const currentProfile = this.person.profile;
      const currentName = `${currentProfile.firstName} ${currentProfile.lastName}`;

      if (currentName === newName) {
        return currentName as string;
      }

      return `${currentName} -> ${newName}`;
    },

    currentAddresses(): Address[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      return person.profile.addresses;
    },
    currentEmailAddresses(): EmailAddress[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      return person.profile.emails;
    },
    currentPhoneNumbers(): PhoneNumber[] {
      const person = this.person;

      if (!person) {
        return [];
      }

      return person.profile.phoneNumbers;
    },

    rows(): Array<{
      currentValue: string;
      newValue: string;
      type: "person" | "profile" | "address" | "email" | "phone";
      id: string;
      action: "Created" | "Deleted" | "Updated";
      field:
        | "organisationName"
        | "organisationNumber"
        | "title"
        | "firstName"
        | "middleNames"
        | "lastName"
        | "phoneNumbers"
        | "emails"
        | "addresses"
        | "dateOfBirth";
    }> {
      const rows: Array<{
        currentValue: string;
        newValue: string;
        type: "person" | "profile" | "address" | "email" | "phone";
        id: string;
        action: "Created" | "Deleted" | "Updated";
        field:
          | "organisationName"
          | "organisationNumber"
          | "title"
          | "firstName"
          | "middleNames"
          | "lastName"
          | "phoneNumbers"
          | "emails"
          | "addresses"
          | "dateOfBirth";
      }> = [];

      Object.keys(this.newPerson).forEach((_key) => {
        if (!["dateOfBirth"].includes(_key) || _key.startsWith("draft")) {
          return;
        }

        const key = _key as keyof IPerson;
        let currentValue = this.currentPerson[key];
        let newValue = this.newPerson[key];

        if (Array.isArray(currentValue) || Array.isArray(newValue)) {
          return;
        }

        if (key === "dateOfBirth") {
          console.log(currentValue, newValue);
          if (currentValue === typeof Number) {
            currentValue =
              undefined !== currentValue && null !== currentValue
                ? DateTime.fromString(currentValue).formatYMD
                : null;
          } else {
            currentValue =
              undefined !== currentValue && null !== currentValue
                ? DateTime.fromString(currentValue as string).formatYMD
                : null;
          }
          if (newValue === typeof Number && newValue !== null) {
            newValue =
              undefined !== newValue && null !== newValue
                ? DateTime.fromString(newValue).formatYMD
                : null;
          } else {
            newValue =
              undefined !== newValue && null !== newValue
                ? DateTime.fromString(newValue as string).formatYMD
                : null;
          }
        }

        rows.push({
          currentValue: currentValue as any,
          newValue: newValue as any,
          type: "person",
          id: this.currentPerson.id,
          action: "Updated",
          field: key as
            | "organisationName"
            | "organisationNumber"
            | "title"
            | "firstName"
            | "middleNames"
            | "lastName"
            | "phoneNumbers"
            | "emails"
            | "addresses"
            | "dateOfBirth",
        });
      });

      Object.keys(this.newProfile)
        .remove("person")
        .forEach((_key) => {
          const key = _key as keyof IProfile;
          let currentValue = this.currentProfile[key];
          let newValue = this.newProfile[key];

          if (["createdAt", "updatedAt", "fullName"].includes(_key)) {
            return;
          }

          if (key === "title") {
            currentValue = Option.$findOneBy({
              group: "titles",
              id: currentValue as string,
            })?.text;

            newValue = Option.$findOneBy({
              group: "titles",
              id: newValue as string,
            })?.text;
          }

          if (Array.isArray(currentValue) || Array.isArray(newValue)) {
            return;
          }

          rows.push({
            currentValue: currentValue as any,
            newValue: newValue as any,
            type: "profile",
            id: this.currentProfile.id,
            action: "Updated",
            field: key as
              | "organisationName"
              | "organisationNumber"
              | "title"
              | "firstName"
              | "middleNames"
              | "lastName"
              | "phoneNumbers"
              | "emails"
              | "addresses"
              | "dateOfBirth",
          });
        });

      this.newAddress.forEach((address) => {
        rows.push({
          currentValue: "",
          newValue: [
            address.line1,
            address.line2,
            address.city,
            address.postcode,
            address.country,
          ]
            .filter(Boolean)
            .join(", "),
          type: "address",
          id: address.id,
          action: "Created",
          field: "addresses",
        });
      });

      this.deletedAddesses.forEach((address) => {
        rows.push({
          currentValue: address.summary ?? address.id,
          newValue: "",
          type: "address",
          id: address.id,
          action: "Deleted",
          field: "addresses",
        });
      });

      this.updatedAddresses.forEach((address) => {
        rows.push({
          currentValue: [
            address.line1,
            address.line2,
            address.city,
            address.postcode,
            address.country,
          ]
            .filter(Boolean)
            .join(", "),
          newValue: [
            address.line1,
            address.line2,
            address.city,
            address.postcode,
            address.country,
          ]
            .filter(Boolean)
            .join(", "),
          type: "address",
          id: address.id,
          action: "Updated",
          field: "addresses",
        });
      });

      this.newEmailAddresses.forEach((emailAddress) => {
        rows.push({
          currentValue: "",
          newValue: emailAddress.email,
          type: "email",
          id: emailAddress.id,
          action: "Created",
          field: "emails",
        });
      });

      this.deletedEmailAddresses.forEach((emailAddress) => {
        rows.push({
          currentValue: emailAddress.email,
          newValue: "",
          type: "email",
          id: emailAddress.id,
          action: "Deleted",
          field: "emails",
        });
      });

      this.updatedEmailAddresses.forEach((emailAddress) => {
        rows.push({
          currentValue: emailAddress.email,
          newValue: emailAddress.email,
          type: "email",
          id: emailAddress.id,
          action: "Updated",
          field: "emails",
        });
      });

      this.newPhoneNumbers.forEach((phoneNumber) => {
        rows.push({
          currentValue: "",
          newValue: phoneNumber.tel,
          type: "phone",
          id: phoneNumber.id,
          action: "Created",
          field: "phoneNumbers",
        });
      });

      this.deletedPhoneNumbers.forEach((phoneNumber) => {
        rows.push({
          currentValue: phoneNumber.tel,
          newValue: "",
          type: "phone",
          id: phoneNumber.id,
          action: "Deleted",
          field: "phoneNumbers",
        });
      });

      this.updatedPhoneNumbers.forEach((phoneNumber) => {
        rows.push({
          currentValue: phoneNumber.tel,
          newValue: phoneNumber.tel,
          type: "phone",
          id: phoneNumber.id,
          action: "Updated",
          field: "phoneNumbers",
        });
      });

      return rows;
    },
  },
  methods: {
    async merge(index: number): Promise<void> {
      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-doc/merge-contacts",
        {
          person: this.person,
          kintin: this.$params.kintin,
          property: this.rows[index].field,
        },
      );
      this.$emit("merge");
    },
    // async replace(index: number): Promise<void> {
    //   let items: (PhoneNumber | EmailAddress | Address)[] = [];

    //   switch (this.rows[index].field) {
    //     case "addresses":
    //       items = this.currentAddresses;
    //       break;
    //     case "emails":
    //       items = this.currentEmailAddresses;
    //       break;
    //     case "phoneNumbers":
    //       items = this.currentPhoneNumbers;
    //       break;
    //   }

    //   const name = {
    //     addresses: "address",
    //     emails: "email",
    //     phoneNumbers: "phone number",
    //   }[this.rows[index].field as "addresses" | "emails" | "phoneNumbers"];

    //   const item = await OpenAutocompleteDialog({
    //     dialog: {
    //       title: `Replace this ${name} with...`,
    //     },
    //     list: {
    //       options: () => items,
    //       mapOptions: {
    //         value: "id",
    //         label: {
    //           addresses: "summary",
    //           emails: "email",
    //           phoneNumbers: "tel",
    //         }[
    //           this.rows[index].field as "addresses" | "emails" | "phoneNumbers"
    //         ],
    //       },
    //     },
    //   });

    //   await window.Kernel.ActionBus.execute(
    //     "kinvault/kintin/sign-doc/merge-contacts",
    //     {
    //       person: this.person,
    //       kintin: this.$params.kintin,
    //       property: this.rows[index].field,
    //       target: item.id,
    //     },
    //   );

    //   this.$emit("merge");
    // },
    canReplace(index: number): boolean {
      switch (this.rows[index].field) {
        case "addresses":
          return this.currentAddresses.length > 0;
        case "emails":
          return this.currentEmailAddresses.length > 0;
        case "phoneNumbers":
          return this.currentPhoneNumbers.length > 0;
      }

      return false;
    },
    async mergeAll(): Promise<void> {
      await this.rows.forEachAsync(async (row) => {
        // if (this.canReplace(index)) {
        //   const type = {
        //     addresses: "address",
        //     emails: "email",
        //     phoneNumbers: "phone number",
        //   }[row.field as "addresses" | "emails" | "phoneNumbers"];
        //   try {
        //     await OpenAlertDialog({
        //       dialog: {
        //         title: row.newValue,
        //         message: `Do you want to replace ${type} with an existing ${type}?`,
        //       },
        //       button: {
        //         ok: {
        //           text: "Replace",
        //         },
        //         cancel: {
        //           text: "Add",
        //         },
        //       },
        //     });

        //     await this.replace(this.rows.indexOf(row));
        //   } catch (e) {
        //     await this.merge(this.rows.indexOf(row));
        //   }
        // }
        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/sign-doc/merge-contacts",
          {
            person: this.person,
            kintin: this.$params.kintin,
            property: row.field,
          },
        );
      });

      this.$emit("merge");
    },
  },
});
</script>
