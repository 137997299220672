import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Gift } from "@kinherit/sdk";

interface DeleteKintinGiftMessage {
  gift: Gift;
}

interface DeleteKintinGiftResponse {
  [key: string]: never;
}

export class DeleteKintinGiftHandler extends ActionBase {
  constructor(private message: DeleteKintinGiftMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinGiftResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-gift/{kintin}/gift/{gift}",
      {
        kintin: this.message.gift.$data.kintin,
        gift: this.message.gift.$data.id,
      },
    )
      .method("delete")

      .result();

    this.message.gift.$delete();

    return {};
  }
}
