import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  CallLog,
  CommunicationNote,
  INote,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  Kintin,
  Lead,
  Note,
  Person,
  QueryMask,
  StatusLog,
} from "@kinherit/sdk";

type Controllers =
  | "/v2/portal/kintin-note/{owner}/notes"
  | "/v2/portal/lead-note/{owner}/notes"
  | "/v2/portal/introducer-company-note/{owner}/notes"
  | "/v2/portal/introducer-contact-note/{owner}/notes"
  | "/v2/portal/officer-note/{owner}/notes"
  | "/v2/portal/introducer-outsource-note/{owner}/notes";

export type ReadNoteMessage = {
  pinned?: boolean | null;
  name?: string | null;
  createdAt?: [number, number] | null;
  query?: QueryMask<typeof Note>;
  type?: string | null;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof INote;
    direction: "asc" | "desc";
  };
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string;
    }
  | {
      statusLog: StatusLog | string;
    }
);

export interface ReadNotesResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
  callLogs: Array<CallLog>;
  statusLogs: Array<StatusLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadNoteHandler extends ActionBase {
  constructor(private message: ReadNoteMessage) {
    super();
  }

  public async execute(): Promise<ReadNotesResponse> {
    let path = "" as any;
    const params = {} as any;
    const message = this.message;

    if ("kintin" in message && message.kintin) {
      path = "/v2/portal/kintin-note/{owner}/notes";
      params.owner = message.kintin;
    } else if ("lead" in message && message.lead) {
      path = "/v2/portal/lead-note/{owner}/notes";
      params.owner = message.lead;
    } else if ("introducerCompany" in message && message.introducerCompany) {
      path = "/v2/portal/introducer-company-note/{owner}/notes";
      params.owner = message.introducerCompany;
    } else if ("introducerContact" in message && message.introducerContact) {
      path = "/v2/portal/introducer-contact-note/{owner}/notes";
      params.owner = message.introducerContact;
    } else if ("person" in message && message.person) {
      path = "/v2/portal/officer-note/{owner}/notes";
      params.owner = message.person;
    } else if (
      "introducerOutsource" in message &&
      message.introducerOutsource
    ) {
      path = "/v2/portal/introducer-outsource-note/{owner}/notes";
      params.owner = message.introducerOutsource;
    } else {
      throw new Error("Invalid message");
    }

    const request = Api.resource("portal", path as Controllers, params)
      .method("get")

      .paginate({
        currentPage: this.message.pagination?.currentPage ?? 1,
        perPage: this.message.pagination?.perPage ?? 10,
      })
      .sort(this.message.sort);

    request.buildQuery(Note).where({
      pinned: Equal(this.message.pinned),
      name: Like(this.message.name),
      createdAt: Between(this.message.createdAt),
      ...this.message.query,
      type: Equal(this.message.type),
    });

    const response = await request.result();

    return {
      notes: Note.$inflate(response.note, this.message.sort, response.$rootIds),
      communicationNotes: CommunicationNote.$inflate(
        response.communicationNote,
      ),
      introducerNotes: IntroducerNote.$inflate(response.introducerNote),
      statusLogs: StatusLog.$inflate(response.statusLog),
      callLogs: CallLog.$inflate(response.callLog),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
