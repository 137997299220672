import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Person } from "@kinherit/sdk";
export interface MergeSignDocContactMessage {
  person: Person | string;
  kintin: Kintin | string;
  property:
    | "organisationName"
    | "organisationNumber"
    | "title"
    | "firstName"
    | "middleNames"
    | "lastName"
    | "phoneNumbers"
    | "emails"
    | "addresses"
    | "dateOfBirth";
}

export interface MergeSignDocContactResponse {
  [key: string]: never;
}

export class MergeSignDocContactHandler extends ActionBase {
  constructor(private message: MergeSignDocContactMessage) {
    super();
  }

  public async execute(): Promise<MergeSignDocContactResponse> {
    const person =
      "string" === typeof this.message.person
        ? this.message.person
        : this.message.person.$data.id;
    const kintin =
      "string" === typeof this.message.kintin
        ? this.message.kintin
        : this.message.kintin.$data.id;

    await Api.resource("portal", "/v2/portal/kintin-sign-doc/merge-contacts")
      .method("post")
      .body({
        person,
        kintin,
        property: this.message.property,
      })
      .result();

    return {};
  }
}
