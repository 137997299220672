import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm/index";
import { Api, EntityHistory, IEntityHistory } from "@kinherit/sdk";

export interface ReadUserActivityMessage {
  user: string;
  subject?: null | string;
  action?: null | "INSERT" | "SOFT_REMOVE" | "RECOVER" | "UPDATE" | "REMOVE";
  date?: null | [number, number];
  route?: null | string;
  origin?: null | string;
  request?: null | string;
  objectId?: null | string;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEntityHistory;
    direction: "asc" | "desc";
  };
}

interface ReadUserActivityResponse {
  logs: Array<EntityHistory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadUserActivityHandler extends ActionBase {
  constructor(private message: ReadUserActivityMessage) {
    super();
  }

  public async execute(): Promise<ReadUserActivityResponse> {
    const request = Api.resource("portal", "/v2/portal/user/{user}/activity", {
      user: this.message.user,
    })
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "date",
        sortDirection: this.message.sort?.direction ?? "desc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(EntityHistory).where({
      entityName: Like(this.message.subject?.replaceAll(" ", "")),
      action: Equal(this.message.action),
      date: Between(this.message.date),
      route: Equal(this.message.route),
      origin: Equal(this.message.origin),
      request: Equal(this.message.request),
      objectId: Equal(this.message.objectId),
    });

    const response = await request.result();

    return {
      logs: EntityHistory.$inflate(
        response.entityHistory,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
