import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  EmailAddress,
  IntroducerBillingContact,
  IntroducerCompany,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

export interface CreateIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface CreateIntroducerBillingContactResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
}

export class CreateIntroducerBillingContactHandler extends ActionBase {
  constructor(private message: CreateIntroducerBillingContactMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerBillingContactResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts",
      {
        introducerCompany: this.message.introducerCompany,
      },
    )
      .method("post")

      .body({
        agentRemittanceType:
          this.message.introducerBillingContact.$data.agentRemittanceType,
        company: this.message.introducerBillingContact.$data.company,
        introducerRemittanceType:
          this.message.introducerBillingContact.$data.introducerRemittanceType,
        notes: this.message.introducerBillingContact.$data.notes,
        profile: {
          knownAs: this.message.introducerBillingContact.profile.$data.knownAs,
          jobTitle:
            this.message.introducerBillingContact.profile.$data.jobTitle,
          firstName:
            this.message.introducerBillingContact.profile.$data.firstName,
          middleNames:
            this.message.introducerBillingContact.profile.$data.middleNames,
          lastName:
            this.message.introducerBillingContact.profile.$data.lastName,
          title: this.message.introducerBillingContact.profile.$data.title,
          organisationName:
            this.message.introducerBillingContact.profile.$data
              .organisationName,
          organisationNumber:
            this.message.introducerBillingContact.profile.$data
              .organisationNumber,
          suffix: this.message.introducerBillingContact.profile.$data.suffix,
          addresses:
            this.message.introducerBillingContact.profile.addresses.map(
              (address) => ({
                line1: address.$data.line1,
                line2: address.$data.line2,
                city: address.$data.city,
                postcode: address.$data.postcode,
                country: address.$data.country,
                state: address.$data.state,
              }),
            ),
          phoneNumbers:
            this.message.introducerBillingContact.profile.phoneNumbers.map(
              (phoneNumber) => ({
                tel: phoneNumber.$data.tel,
              }),
            ),
          emails: this.message.introducerBillingContact.profile.emails.map(
            (emailAddress) => ({
              email: emailAddress.$data.email,
            }),
          ),
          websites: this.message.introducerBillingContact.profile.websites.map(
            (website) => ({
              url: website.$data.url,
            }),
          ),
        },
      });

    const response = await request.result();

    this.message.introducerBillingContact.$delete();

    return {
      introducerBillingContacts: IntroducerBillingContact.$inflate(
        response.introducerBillingContact,
        undefined,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
    };
  }
}
