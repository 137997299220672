<template>
  <SidebarPage
    :key="key"
    v-if="kintin && !loading"
    class="kintin-details"
    :name="kintin.friendlyName"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #below>
      <span v-if="kintin.estatePlanners.isNotEmpty()">
        <Avatar
          v-for="ep in kintin.estatePlanners"
          hide-tooltip
          size="is-small"
          :name="ep?.profile.fullName ?? undefined"
          :key="ep?.id"
        />
      </span>
      <span v-if="kintin.legalAssistants.isNotEmpty()">
        <Avatar
          v-for="la in kintin.legalAssistants"
          hide-tooltip
          size="is-small"
          :name="la?.profile.fullName ?? undefined"
          :key="la?.id"
          class="ml-1"
        />
      </span>
      <Badge size="is-small" color="is-plain">{{
        kintin.ref.replace(/(\d{4})/g, "$1 ").trim()
      }}</Badge>
    </template>
    <template #sidebar>
      <RouterChildView name="sidebar">
        <KintinDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView
        name="default"
        @create-order="createOrder"
        @create-subscription="createSubscription"
        @create-post-payment="createPostPayment"
        @create-upload-form="createUploadForm"
        @create-credit-note="createCreditNote"
        @add-user="addUser"
      >
        <KintinDetailsSummary @add-user="addUser" />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  CreateOrder,
  CreateSignDoc,
  CreateUploadForm,
} from "@/config/model.config";
import { UpdateCreditNoteForm } from "@/module/core/forms/update-credit-note.form";
import { UpdateKintinSettingsForm } from "@/module/kinvault.kintin/form/update-kintin-settings.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { UpdateSubscriptionForm } from "@/module/kinvault.subscription/form/update-subscription.form";
import { UpdateOrderForm } from "@/module/order/form/update-order.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { UpdateReferralForm } from "@/shared/form/referral/update-referral.form";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Badge } from "@kinherit/framework/component.display/badge";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs/types";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import {
  OpenAlertDialog,
  OpenDangerDialog,
} from "@kinherit/framework/global/dialog";
import { Option, Person } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsParams, KinvaultKintinDetailsRoute } from ".";
import { CreateKintinUserForm } from "../../form/create-kintin-user.form";
import { UpdateSignDocDisclaimerForm } from "../../form/update-sign-doc-disclaimer.form";
import { UpdateUploadForm } from "../../form/update-upload-form.form";
import KintinDetailsSidebar from "./KintinDetails.Sidebar.vue";
import {
  KinvaultKintinDetailsAddressBookAccountHoldersRoute,
  KinvaultKintinDetailsAddressBookAddressesRoute,
  KinvaultKintinDetailsAddressBookAdvisersRoute,
  KinvaultKintinDetailsAddressBookAllRoute,
  KinvaultKintinDetailsAddressBookAttorneysRoute,
  KinvaultKintinDetailsAddressBookBeneficiariesRoute,
  KinvaultKintinDetailsAddressBookCharitiesRoute,
  KinvaultKintinDetailsAddressBookChildrenRoute,
  KinvaultKintinDetailsAddressBookCompaniesRoute,
  KinvaultKintinDetailsAddressBookDependentsRoute,
  KinvaultKintinDetailsAddressBookExclusionsRoute,
  KinvaultKintinDetailsAddressBookExecutorsRoute,
  KinvaultKintinDetailsAddressBookGiftRecipientsRoute,
  KinvaultKintinDetailsAddressBookGuardiansRoute,
  KinvaultKintinDetailsAddressBookRoute,
  KinvaultKintinDetailsAddressBookTrusteesRoute,
} from "./address-book";
import { KintinDetailsCallScriptRoute } from "./call-script";
import { KintinDetailsCallScriptClientSummaryRoute } from "./call-script/client-summary";
import { KintinDetailsCallScriptFamilyGuardiansRoute } from "./call-script/family-guardians";
import { KintinDetailsCallScriptFollowUpRoute } from "./call-script/follow-up";
import { KintinDetailsCallScriptIntroRoute } from "./call-script/intro";
import { KintinDetailsCallScriptKinvaultRoute } from "./call-script/kinvault";
import { KintinDetailsCallScriptPropertyAssetsRoute } from "./call-script/property-assets";
import { KintinDetailsCallScriptWhoDoesWhatRoute } from "./call-script/who-does-what";
import { KintinDetailsCallScriptWhoGetsWhatRoute } from "./call-script/who-gets-what";
import { KintinDetailsCallScriptWillReviewRoute } from "./call-script/will-review";
import { KinvaultKintinDetailsChangeLogRoute } from "./change-log";
import { KinvaultKintinDetailsDocumentsRoute } from "./documents";
import { KintinDetailsCreditNotesRoute } from "./documents/credit-notes";
import { KinvaultKintinDetailsFileVaultRoute } from "./documents/file-vault";
import { KintinDetailsOrderRoute } from "./documents/orders";
import {
  KinvaultKintinDetailsPostPaymentMergeContactsRoute,
  KinvaultKintinDetailsPostPaymentRoute,
} from "./documents/post-payment";
import { KintinDetailsProcessRoute } from "./documents/process";
import { KintinDetailsSubscriptionRoute } from "./documents/subscriptions";
import { KintinDetailsUploadFormRoute } from "./documents/upload-forms";
import { KintinDetailsEmailLogRoute } from "./email-log";
import { KinvaultKintinDetailsNotesRoute } from "./notes";
import { KinvaultKintinDetailsSummaryRoute } from "./summary";
import KintinDetailsSummary from "./summary/KintinDetails.Summary.vue";
import { KinvaultKintinDetailsWillRoute } from "./will";

export default defineComponent({
  name: KinvaultKintinDetailsRoute,
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  components: {
    PageNotFound,
    SidebarPage,
    RouterChildView,
    KintinDetailsSummary,
    KintinDetailsSidebar,
    Avatar,
    Badge,
  },
  computed: {
    showContextMenu(): boolean {
      return (
        this.$auth.hasPermission("kintin:write") ||
        this.$auth.hasPermission("order:write")
      );
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: KinvaultKintinDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Notes",
        route: {
          name: KinvaultKintinDetailsNotesRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.notes.icon,
      },
      {
        label: "Emails",
        route: {
          name: KintinDetailsEmailLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.email.icon,
      },
      {
        label: "Address Book",
        route: {
          name: KinvaultKintinDetailsAddressBookRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.addressBook.icon,
        nestedRoutes: [
          KinvaultKintinDetailsAddressBookCharitiesRoute,
          KinvaultKintinDetailsAddressBookGuardiansRoute,
          KinvaultKintinDetailsAddressBookAccountHoldersRoute,
          KinvaultKintinDetailsAddressBookCompaniesRoute,
          KinvaultKintinDetailsAddressBookAddressesRoute,
          KinvaultKintinDetailsAddressBookDependentsRoute,
          KinvaultKintinDetailsAddressBookTrusteesRoute,
          KinvaultKintinDetailsAddressBookAdvisersRoute,
          KinvaultKintinDetailsAddressBookExclusionsRoute,
          KinvaultKintinDetailsAddressBookAllRoute,
          KinvaultKintinDetailsAddressBookExecutorsRoute,
          KinvaultKintinDetailsAddressBookAttorneysRoute,
          KinvaultKintinDetailsAddressBookChildrenRoute,
          KinvaultKintinDetailsAddressBookBeneficiariesRoute,
          KinvaultKintinDetailsAddressBookGiftRecipientsRoute,
        ],
      },
      {
        label: "Call Script",
        route: {
          name: KintinDetailsCallScriptRoute,
          params: ctx.$params,
        },
        nestedRoutes: [
          KintinDetailsCallScriptWillReviewRoute,
          KintinDetailsCallScriptIntroRoute,
          KintinDetailsCallScriptFamilyGuardiansRoute,
          KintinDetailsCallScriptPropertyAssetsRoute,
          KintinDetailsCallScriptWhoGetsWhatRoute,
          KintinDetailsCallScriptWhoDoesWhatRoute,
          KintinDetailsCallScriptClientSummaryRoute,
          KintinDetailsCallScriptFollowUpRoute,
          KintinDetailsCallScriptKinvaultRoute,
        ],
        icon: StyleService.icon.phone.icon,
      },
      {
        label: "Documents",
        route: {
          name: KinvaultKintinDetailsDocumentsRoute,
          params: ctx.$params,
        },
        nestedRoutes: [
          KintinDetailsCreditNotesRoute,
          KintinDetailsProcessRoute,
          KintinDetailsOrderRoute,
          KinvaultKintinDetailsPostPaymentRoute,
          KintinDetailsSubscriptionRoute,
          KintinDetailsUploadFormRoute,
          KinvaultKintinDetailsFileVaultRoute,
          KinvaultKintinDetailsPostPaymentMergeContactsRoute,
        ],
        icon: StyleService.icon.file.icon,
      },
      {
        label: "Will",
        route: {
          name: KinvaultKintinDetailsWillRoute,
        },
        nestedRoutes: [KinvaultKintinDetailsWillRoute],
        icon: StyleService.icon.file.icon,
      },
      // {
      //   label: "Calls",
      //   route: {
      //     name: KinvaultKintinDetailsCallsRoute,
      //     params: ctx.$params,
      //   },
      //   icon: StyleService.icon.phone.icon,
      // },
      {
        label: "Changes",
        route: {
          name: KinvaultKintinDetailsChangeLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.history.icon,
      },
    ] as Array<TabOptions>,
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.execute(
      "kinvault/kintin/record",
      to.params as KinvaultKintinDetailsParams,
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      const items = [
        {
          title: "Create Note",
          icon: StyleService.icon.notes.icon,
          line1: "Create a new note",
          action: this.createNote,
          premissions: ["kintin:write"],
        },
        {
          title: "Create Order",
          icon: StyleService.icon.order.icon,
          line1: "Create a new order",
          action: this.createOrder,
          premissions: ["order:write"],
        },
        {
          title: "Create Post Payment",
          icon: StyleService.icon.postPayment.icon,
          line1: "Create a new post payment",
          action: this.createPostPayment,
          premissions: ["order:write"],
        },
        {
          title: "Create Subscription",
          icon: StyleService.icon.subscription.icon,
          line1: "Create a new Subscription",
          action: this.createSubscription,
          premissions: ["order:write"],
        },
        {
          title: "Create Upload Form",
          icon: StyleService.icon.uploadForm.icon,
          line1: "Create a new Upload Form",
          action: this.createUploadForm,
          premissions: ["order:write"],
        },
        {
          title: "Manage Referral",
          line1: `Manage ${this.kintin?.friendlyName} referral`,
          icon: StyleService.icon.referral.icon,
          permissions: "kintin:write",
          action: this.manageReferral,
        },
        {
          title: "Create Credit note",
          permission: ["order:write", "kintin:write", "trust-reg:write"],
          line1: "Create a credit note for this order",
          icon: StyleService.icon.creditNote.icon,
          action: this.createCreditNote,
        },
        {
          title: "Duplicate",
          line1: "Duplicate this account",
          permission: "kintin:write",
          icon: StyleService.icon.copy.icon,
          action: this.duplicate,
        },
      ];

      if (this.kintin?.type.value === "joint") {
        items.push({
          title: "Switch Account Holders",
          line1: "Switch the primary and secondary account holders",
          icon: StyleService.icon.sync.icon,
          permission: "kintin:write",
          action: this.switchAccountHolders,
        });
      }

      items.push({
        title: "Settings",
        line1: "Manage account settings",
        icon: StyleService.icon.edit.icon,
        permissions: "kintin:write",
        action: this.manageSettings,
      });

      const contextMenu = this.$auth.openContextMenu({
        request: {
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
          items,
        },
        filter: "some",
        path: "premissions",
        callback: (item) => item.action(),
      });

      await contextMenu();
    },
    async switchAccountHolders(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Switch Account Holders`,
          message: `Are you sure you want to switch the primary and secondary account holders?`,
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/switch-account-holders",
        {
          kintin: this.kintin,
        },
      );

      window.location.reload();
    },
    async duplicate(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Duplicate ${this.kintin.friendlyName}`,
          message: `Are you sure you want to duplicate this account?`,
        },
      });

      const result = await window.Kernel.ActionBus.execute(
        "kinvault/kintin/duplicate",
        {
          kintin: this.kintin,
        },
      );

      try {
        await OpenAlertDialog({
          dialog: {
            title: "Account duplicated",
            message: `The account has been duplicated successfully.`,
          },
          button: {
            ok: {
              text: "View",
            },
            cancel: {
              text: "Close",
            },
          },
        });

        window.Kernel.visitRoute(
          {
            name: KinvaultKintinDetailsSummaryRoute,
            params: {
              kintin: result.kintin,
            } as KinvaultKintinDetailsParams,
          },
          true,
        );
      } catch {
        this.key++;
      }
    },
    async createNote(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      const note = await UpdateNoteForm({
        data: "create-note",
        kintin: this.kintin,
      }).dialog({
        dialog: {
          title: "Create note",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      await window.Kernel.ActionBus.execute("core/note/create", {
        note: note,
        kintin: this.kintin,
      });

      this.key++;
    },
    async manageReferral(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      const referral = this.kintin.referral;

      if (!referral) {
        return DangerDialog({
          dialog: {
            title: `Error`,
            message: `This account does not have an account referral`,
          },
        });
      }

      try {
        await UpdateReferralForm({ data: referral }).dialog({
          dialog: {
            title: `Manage ${this.kintin.friendlyName} referral`,
          },
        });
      } catch (e) {
        referral.$restore();
        return;
      }

      referral.$persist();

      await window.Kernel.ActionBus.execute("core/account-referral/update", {
        referral,
      });

      this.kintin.$restore();
      this.key++;
    },
    async createOrder(): Promise<void> {
      const order = CreateOrder({
        kintin: this.kintin?.id,
        status: Option.$findOneByOrThrow({
          group: "orderStatus",
          value: "new",
        }).id,
      });

      order.createdBy = this.$auth.loggedInUser;

      try {
        await UpdateOrderForm({
          referralCode: this.kintin?.referral?.referralCode ?? undefined,
          data: order,
        }).dialog({
          dialog: {
            title: "Create order",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch {
        order.$delete({
          orderItems: true,
        });
        return;
      }

      order.kintin = this.kintin;

      await window.Kernel.ActionBus.execute("order/order/create", {
        order: order,
      });

      this.key++;
    },
    async createSubscription(): Promise<void> {
      if (!this.$auth.hasPermission("kintin:write") || !this.kintin) {
        return;
      }

      const subscription = await UpdateSubscriptionForm().dialog({
        dialog: {
          title: "Create subscription",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      subscription.kintin = this.kintin;
      subscription.$persist();

      await window.Kernel.ActionBus.execute("kinvault/subscription/create", {
        subscription,
      });

      this.key++;
    },
    async createPostPayment(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      const { primaryPerson, secondaryPerson } = this.kintin;

      const item = await OpenContextMenu({
        items: [
          {
            title: "Contacts Signoff",
            icon: StyleService.icon.contact.icon,
            line1: "Create a contacts signoff",
            action: this.createContactsPostPayment,
          },
          {
            title: "Disclaimer",
            icon: StyleService.icon.signFile.icon,
            line1: "Create a disclaimer",
            action: this.createDisclaimerPostPayment,
          },
          {
            title: `Identity Verification - ${
              primaryPerson?.profile.fullName ?? "Primary Person"
            }`,
            icon: StyleService.icon.person.icon,
            line1: "Create an identity verification",
            action: () =>
              this.createIdentityVerificationPostPayment(primaryPerson),
          },
          {
            title: `Identity Verification - ${
              secondaryPerson?.profile.fullName ?? "Secondary Person"
            }`,
            icon: StyleService.icon.person.icon,
            line1: "Create an identity verification",
            hide: !secondaryPerson,
            action: () =>
              this.createIdentityVerificationPostPayment(secondaryPerson),
          },
          {
            title: "Direct Debit Request (Subscription required)",
            icon: StyleService.icon.accounting.icon,
            line1: "Create a direct debit request",
            action: this.createDirectDebitRequestPostPayment,
          },
          {
            title: "Document Creation Signoff",
            icon: StyleService.icon.file.icon,
            line1: "Final document sign off",
            action: this.createDocumentCreationPostPayment,
          },
        ],
        trackingField: "title",
        line1Field: "line1",
        titleField: "title",
        iconLeftField: "icon",
        hideField: "hide",
      });

      await item.action();
    },
    async createDocumentCreationPostPayment(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const signDoc = CreateSignDoc();

      signDoc.kintin = kintin;
      signDoc.type = Option.$findOneByOrThrow({
        group: "signDocType",
        value: "documentCreation",
      });
      signDoc.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-docs/create",
        {
          signDoc,
          kintin,
        },
      );

      this.key++;
    },
    async createContactsPostPayment(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const signDoc = CreateSignDoc();

      signDoc.kintin = kintin;
      signDoc.type = Option.$findOneByOrThrow({
        group: "signDocType",
        value: "contacts",
      });
      signDoc.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-docs/create",
        {
          signDoc,
          kintin,
        },
      );

      this.key++;
    },
    async createDisclaimerPostPayment(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const form = await UpdateSignDocDisclaimerForm();

      const signDoc = form.localData;

      try {
        await form.dialog({
          dialog: {
            title: "Create disclaimer",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch {
        signDoc.$delete();
        return;
      }

      signDoc.kintin = kintin;
      signDoc.type = Option.$findOneByOrThrow({
        group: "signDocType",
        value: "customdisclaimer",
      });
      signDoc.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-docs/create",
        {
          signDoc,
          kintin,
        },
      );

      this.key++;
    },
    async createIdentityVerificationPostPayment(
      person: Person | null,
    ): Promise<void> {
      if (!person) {
        return;
      }

      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const signDoc = CreateSignDoc();

      signDoc.person = person;
      signDoc.kintin = kintin;
      signDoc.type = Option.$findOneByOrThrow({
        group: "signDocType",
        value: "identitystripe",
      });
      signDoc.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-docs/create",
        {
          signDoc,
          kintin,
        },
      );

      this.key++;
    },
    async createDirectDebitRequestPostPayment(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const signDoc = CreateSignDoc();

      signDoc.kintin = kintin;
      signDoc.type = Option.$findOneByOrThrow({
        group: "signDocType",
        value: "directdebit",
      });
      signDoc.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/sign-docs/create",
        {
          signDoc,
          kintin,
        },
      );

      this.key++;
    },
    async createUploadForm(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const uploadForm = CreateUploadForm({
        validTill: new DateTime().add({ day: 90 }).formatMachine,
      });

      try {
        await UpdateUploadForm(uploadForm).dialog({
          dialog: {
            title: "Create upload form",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch {
        uploadForm.$delete();
        return;
      }

      uploadForm.kintin = kintin;
      uploadForm.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/upload-forms/create",
        {
          uploadForm,
          kintin,
        },
      );

      this.key++;
    },
    async manageSettings(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const type = kintin.type;

      try {
        await UpdateKintinSettingsForm(kintin).dialog({
          dialog: {
            title: "Manage settings",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        kintin.$restore();
        kintin.callScript.$restore();
        throw e;
      }

      const newType = kintin.type;

      kintin.type = type;
      kintin.$persist();
      kintin.callScript.$persist();

      await window.Kernel.ActionBus.execute("kinvault/kintin/update", {
        kintin,
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/call-script/update",
        {
          kintin,
        },
      );

      this.key++;

      if (newType.value !== type.value) {
        if (newType.value === "single") {
          await OpenDangerDialog({
            dialog: {
              title: "Change account type",
              message: `Changing the account type to single will remove all data related to the secondary person. Are you sure you want to continue?`,
            },
          });
        }

        await window.Kernel.ActionBus.execute("kinvault/kintin/change-type", {
          kintin,
          type: newType.value as "single" | "joint",
        });

        window.location.reload();
      }
    },
    async createCreditNote(): Promise<void> {
      const form = UpdateCreditNoteForm();
      const creditNote = form.localData;

      try {
        await form.dialog({
          dialog: {
            title: "Create Credit Note",
          },
        });
      } catch {
        creditNote.$delete();
        return;
      }

      creditNote.relatedKintin = this.kintin;

      await window.Kernel.ActionBus.execute("core/credit-note/update", {
        creditNote,
      });

      this.key++;
    },
    async addUser(): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const { user, accessType } = await CreateKintinUserForm().dialog({
        dialog: {
          title: "Add user",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/users/create", {
        user,
        kintin,
        accessType,
      });

      this.key++;
    },
  },
});
</script>
