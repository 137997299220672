import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ISignDoc, Kintin, Person, Profile, SignDoc } from "@kinherit/sdk";

export interface ReadKintinSignDocsMessage {
  kintin: string | Kintin;
  pinned?: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof ISignDoc;
    direction: "asc" | "desc";
  };
}

export interface ReadKintinSignDocsResponse {
  signDocs: Array<SignDoc>;
  people: Array<Person>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKintinSignDocsHandler extends ActionBase {
  constructor(private message: ReadKintinSignDocsMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinSignDocsResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/kintin-sign-doc/{kintin}/sign-docs",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
      },
    )
      .method("get")

      .paginate({
        currentPage: this.message.pagination?.currentPage ?? 1,
        perPage: this.message.pagination?.perPage ?? 10,
      })
      .sort(
        this.message.sort
          ? {
              by: this.message.sort.by,
              direction: this.message.sort.direction,
            }
          : undefined,
      );

    const response = await request.result();

    return {
      signDocs: SignDoc.$inflate(
        response.signDoc,
        this.message.sort,
        response.$rootIds,
      ),
      people: Person.$inflate(response.person),
      profiles: Profile.$inflate(response.profile),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
