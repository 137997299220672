import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvault } from "@kinherit/sdk/index";
import { DateTime } from "@kinherit/ts-common/index";

export class DownloadBrandedKinvaultKinvaultFeesHandler extends ActionBase {
  constructor(
    private readonly data: {
      brandedKinvault: string | BrandedKinvault;
      startDate: DateTime;
      endDate: DateTime;
    },
  ) {
    super();
  }

  public async execute(): Promise<void> {
    const { startDate, endDate } = this.data;
    const startDateFormatted = startDate.formatYMD;
    const endDateFormatted = endDate.formatYMD;
    await window.Kernel.Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-fees/{brandedKinvault}/download",
      {
        brandedKinvault: this.data.brandedKinvault,
      },
    )
      .method("get")
      .params({
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      })
      .download({
        encoding: "application/pdf",
        fileName: `Kinvault Fees - ${startDateFormatted} - ${endDateFormatted}.pdf`,
      });
  }
}
