import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, In, Like } from "@kinherit/orm/index";
import {
  AccountReferral,
  AccountReferralCode,
  Api,
  EmailAddress,
  ILead,
  Lead,
  PhoneNumber,
  Profile,
  User,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";

export interface ReadLeadMessage {
  search?: null | string;
  assignedUser: Array<string>;
  ownedBy: Array<string>;
  status: Array<string>;
  referral: Array<string>;
  tags: Array<string>;
  created?: null | [number, number];
  dueBy?: null | "today" | "tomorrow" | "overdue";
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof ILead;
    direction: "asc" | "desc";
  };
}

interface ReadLeadResponse {
  leads: Array<Lead>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  users: Array<User>;
  referralCodes: Array<AccountReferralCode>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadLeadHandler extends ActionBase {
  constructor(private message: ReadLeadMessage) {
    super();
  }

  public async execute(): Promise<ReadLeadResponse> {
    const { dueBy } = this.message;

    const today = new DateTime();

    const todayDateRange: [number, number] = [
      today.setTime(0, 0, 0, 0).timestamp,
      today.setTime(23, 59, 59, 999).timestamp,
    ];

    const tomorrow = new DateTime().add(1, "day");

    const tomorrowDateRange: [number, number] = [
      tomorrow.setTime(0, 0, 0, 0).timestamp,
      tomorrow.setTime(23, 59, 59, 999).timestamp,
    ];
    const yesterday = new DateTime().sub(1, "day");

    const overdueDateRange: [number, number] = [
      0,
      yesterday.setTime(23, 59, 59, 999).timestamp,
    ];

    const nextActionAtDateRange =
      dueBy === "today"
        ? todayDateRange
        : dueBy === "tomorrow"
          ? tomorrowDateRange
          : dueBy === "overdue"
            ? overdueDateRange
            : null;

    const request = Api.resource("portal", "/v2/portal/lead")
      .method("get")

      .paginate({
        currentPage: this.message.pagination?.currentPage ?? 1,
        perPage: this.message.pagination?.perPage ?? 10,
      })
      .sort({
        sortBy: this.message.sort?.by ?? "createdAt",
        sortDirection: this.message.sort?.direction ?? "desc",
      });

    const sharedConditions = {
      ownedBy: {
        id: In(this.message.ownedBy),
      },
      assignedTo: {
        id: In(this.message.assignedUser),
      },
      status: {
        id: In(this.message.status),
      },
      referral: {
        nextActionAt: Between(nextActionAtDateRange),
        referralCode: {
          id: In(this.message.referral),
        },
      },
      tags: {
        id: In(this.message.tags),
      },
      createdAt: Between(this.message.created),
    };

    if (!this.message.search) {
      request.buildQuery(Lead).where(sharedConditions);
    } else {
      request.buildQuery(Lead).where([
        {
          profile: {
            fullName: Like(this.message.search),
          },
          ...sharedConditions,
        },
        {
          profile: {
            emails: {
              email: Like(this.message.search),
            },
          },
          ...sharedConditions,
        },
        {
          profile: {
            phoneNumbers: {
              tel: Like(this.message.search),
            },
          },
          ...sharedConditions,
        },
      ]);
    }

    const response = await request.result();

    return {
      leads: Lead.$inflate(response.lead, this.message.sort, response.$rootIds),
      profiles: Profile.$inflate(response.profile),
      referrals: AccountReferral.$inflate(response.accountReferral),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      users: User.$inflate(response.user),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
