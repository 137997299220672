import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, BrandedKinvaultPricingSchema } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultPricingSchemaMessage {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

interface DeleteBrandedKinvaultPricingSchemaResponse {}

export class DeleteBrandedKinvaultPricingSchemaHandler extends ActionBase {
  constructor(private message: DeleteBrandedKinvaultPricingSchemaMessage) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultPricingSchemaResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-pricing-schema/{brandedKinvaultPricingSchema}",
      this.message,
    ).method("delete");

    await request.result();

    this.message.brandedKinvaultPricingSchema.$delete();

    return {};
  }
}
