import {
  UpdateKintinPersonProfileMessage,
  UpdateKintinPersonProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateKintinPersonProfileHandler = UpdateProfileHandler as new (
  message: UpdateKintinPersonProfileMessage,
) => UpdateProfileHandler<
  UpdateKintinPersonProfileMessage,
  UpdateKintinPersonProfileResponse
>;
