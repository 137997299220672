import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Order, OrderItem } from "@kinherit/sdk";

export interface CreateOrdersMessage {
  order: Order;
}

export interface CreateOrdersResponse {
  order: Order;
  orderItems: Array<OrderItem>;
}

export class CreateOrderHandler extends ActionBase {
  constructor(private message: CreateOrdersMessage) {
    super();
  }

  public async execute(): Promise<CreateOrdersResponse> {
    const request = Api.resource("portal", "/v2/portal/order")
      .method("post")

      .body({
        kintin: this.message.order.$data.kintin,
        status: this.message.order.$data.status,
        paymentType: this.message.order.$data.paymentType,
        extrasPrice: this.message.order.$data.extrasPrice,
        discountValue: this.message.order.$data.discountValue,
        discountPercentage: this.message.order.$data.discountPercentage,
        coolingOff: this.message.order.$data.coolingOff,
        paidAt: this.message.order.$data.paidAt,
        invoiceNumber: this.message.order.$data.invoiceNumber,
        notes: this.message.order.$data.notes,
        paymentSignKey: this.message.order.$data.paymentSignKey,
        transactionId: this.message.order.$data.transactionId,
        overrideListPrice: this.message.order.$data.overrideListPrice,
        feesPayOutIntro: this.message.order.$data.feesPayOutIntro,
        feesPayoutAgent: this.message.order.$data.feesPayoutAgent,
        feesPayOutIntroDate: this.message.order.$data.feesPayOutIntroDate,
        feesPayOutAgentDate: this.message.order.$data.feesPayOutAgentDate,
        feesPayOutParentAgent: this.message.order.$data.feesPayOutParentAgent,
        feesPayOutParentAgentDate:
          this.message.order.$data.feesPayOutParentAgentDate,
        feesConfirmed: this.message.order.$data.feesConfirmed,
        discountReason: this.message.order.$data.discountReason,
        xeroIntroducerBillId: this.message.order.$data.xeroIntroducerBillId,
        xeroAgentBillId: this.message.order.$data.xeroAgentBillId,
        billingName: this.message.order.$data.billingName,
        billingAddress1: this.message.order.$data.billingAddress1,
        billingAddress2: this.message.order.$data.billingAddress2,
        billingCity: this.message.order.$data.billingCity,
        billingRegion: this.message.order.$data.billingRegion,
        billingPostalCode: this.message.order.$data.billingPostalCode,
        xeroParentAgentBillId: this.message.order.$data.xeroParentAgentBillId,
        createdBy: this.message.order.$data.createdBy,
      });

    const response = await request.result();
    const order = Order.$inflate(response.order).first();

    if (!order) {
      throw new Error("Failed to create order");
    }

    const orderItems = this.message.order.orderItems;

    await orderItems.forEachAsync(async (orderItem) => {
      await window.Kernel.ActionBus.execute("order/order-item/create", {
        order,
        orderItem,
      });
    });

    await window.Kernel.ActionBus.execute("order/order/record", {
      order: order,
    });

    this.message.order.$delete({
      orderItems: true,
    });

    return {
      order: Order.$findOneOrThrow(order.id),
      orderItems: OrderItem.$findBy({
        order: {
          id: order.id,
        },
      }),
    };
  }
}
