import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

interface UpdateKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface UpdateKnowledgeBaseDocumentResponse {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
}

export class UpdateKnowledgeBaseDocumentHandler extends ActionBase {
  constructor(private message: UpdateKnowledgeBaseDocumentMessage) {
    super();
  }

  public async execute(): Promise<UpdateKnowledgeBaseDocumentResponse> {
    const localCategories = this.message.knowledgeBaseDocument.$data.categories;

    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
      {
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
      },
    )
      .method("patch")

      .body({
        title: this.message.knowledgeBaseDocument.title,
        content: this.message.knowledgeBaseDocument.content,
      })
      .result();

    const knowledgeBaseCategories = KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    );

    const knowledgeBaseDocuments = KnowledgeBaseDocument.$inflate(
      response.knowledgeBaseDocument,
    );

    const knowledgeBaseDocument = knowledgeBaseDocuments.first();

    if (!knowledgeBaseDocument) {
      throw new Error("KnowledgeBaseDocument not found");
    }

    const remoteCategories = knowledgeBaseDocument.$data.categories ?? [];

    const existngCategories = localCategories.intersection(remoteCategories);
    const newCategories = remoteCategories.filter(
      (category) => !existngCategories.includes(category),
    );
    const removedCategories = localCategories.filter(
      (category) => !existngCategories.includes(category),
    );

    await newCategories.forEachAsync(async (category) => {
      await window.Kernel.ActionBus.execute(
        "knowledge-base/category/document/add",
        {
          knowledgeBaseCategory: category,
          knowledgeBaseDocument: knowledgeBaseDocument,
        },
      );
    });

    await removedCategories.forEachAsync(async (category) => {
      await window.Kernel.ActionBus.execute(
        "knowledge-base/category/document/remove",
        {
          knowledgeBaseCategory: category,
          knowledgeBaseDocument: knowledgeBaseDocument,
        },
      );
    });

    return {
      knowledgeBaseDocument,
      knowledgeBaseCategories,
    };
  }
}
