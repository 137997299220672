import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  Api,
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

type Response = {};

export class SendEmailLogHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}",
      [Kintin.$name]:
        "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}",
      [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}",
      [Person.$name]:
        "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}",
    } as const;

    const route = routes[this.message.owner.$name];

    await Api.resource("portal", `${route}/send` as never, {
      owner: this.message.owner,
      emailLog: this.message.emailLog,
    })
      .method("post")
      .result();

    this.message.emailLog.$delete();

    return {} as R;
  }
}
