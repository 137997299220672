import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface SendBrandedKinvaultInvitationEmailFollowup2Message {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface SendBrandedKinvaultInvitationEmailFollowup2Response {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export class SendBrandedKinvaultInvitationEmailFollowup2Handler extends ActionBase {
  constructor(
    private message: SendBrandedKinvaultInvitationEmailFollowup2Message,
  ) {
    super();
  }

  public async execute(): Promise<SendBrandedKinvaultInvitationEmailFollowup2Response> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}",
      {
        brandedKinvaultInvitation: this.message.brandedKinvaultInvitation,
        invitationType: "followup2",
      },
    )
      .method("put")
      .result();

    const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
    ).first();

    if (!brandedKinvaultInvitation) {
      throw new Error(
        "Failed to send branded kinvault invitation followup email",
      );
    }

    return {
      brandedKinvaultInvitation,
    };
  }
}
