import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface Message {
  file: File;
}

interface Response {
  duplicateProfiles: {
    emailAddress: string;
    profiles: string[];
  }[];
  emailAddressColumnTitle: string;
  unmatchedEmailAddresses: string[];
}

export class ValidateEmailCampaignMembersCsvHandler extends ActionBase {
  constructor(private message: Message) {
    super();
  }

  public async execute(): Promise<Response> {
    const { file } = this.message;

    return await Api.resource(
      "portal",
      "/v2/portal/email-campaign/members/csv/validate",
    )
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();
  }
}
