<template>
  <Card
    v-if="loaded"
    title="Theme"
    subtitle="Update this branded Kinvault's theme"
  >
    <Form v-if="$data.form" is-borderless :config="$data.form" />
    <div class="buttons">
      <Button @click="updateTheme" cy-ref="update-theme">Update Theme</Button>
    </div>
  </Card>
</template>

<script lang="ts">
import { UpdateKinvaultThemeSettingsForm } from "@/module/admin.branded-kinvault/form/update-kinvault-theme.form";
import { SnackBarService } from "@/service/snack-bar.service";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { ThemeKinvault } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsThemeRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsThemeRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: {
    Form,
    Button,
    Card,
  },
  data() {
    return {
      loaded: false,
      form: null as null | FormBuilder<() => ThemeKinvault, string>,
    };
  },
  async mounted() {
    await this.editTheme();
  },
  methods: {
    async updateTheme() {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return;
      }

      const { id } = brandedKinvault.$data;

      brandedKinvault.kinvaultTheme.$persist();
      brandedKinvault.kinvaultTheme.login.$persist();
      brandedKinvault.kinvaultTheme.header.$persist();
      brandedKinvault.kinvaultTheme.footer.$persist();

      await window.Kernel.ActionBus.execute(
        "admin/branded-kinvault/kinvault-theme/update",
        {
          brandedKinvault: id,
          themeKinvault: brandedKinvault.kinvaultTheme,
        },
      );

      SnackBarService.toast({
        text: "Theme updated successfully",
        type: "success",
      });
    },
    async editTheme(): Promise<void> {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return;
      }

      const { id, kinvaultTheme } = brandedKinvault.$data;

      await this.loadTheme(id, kinvaultTheme);

      this.form = UpdateKinvaultThemeSettingsForm(
        brandedKinvault.kinvaultTheme as ThemeKinvault,
      );

      this.loaded = true;
    },
    async loadTheme(brandedKinvault: string, themeKinvault: string) {
      await window.Kernel.ActionBus.execute(
        "admin/branded-kinvault/kinvault-theme/record",
        {
          brandedKinvault,
          themeKinvault,
        },
      );
    },
  },
});
</script>
