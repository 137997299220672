import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Person, User } from "@kinherit/sdk";

export type DownloadLpaHealthMessage = {
  kintin: Kintin;
  person: Person;
  user: User;
};

export class DownloadLpaHealthAction extends ActionBase {
  constructor(private message: DownloadLpaHealthMessage) {
    super();
  }

  async execute(): Promise<void> {
    const { person, user, kintin } = this.message;

    const primaryPerson = kintin.primaryPerson;
    const secondaryPerson = kintin.secondaryPerson;

    const surname = [
      primaryPerson.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/person/{person}/specialist/{user}/lpa-health",
      {
        person: person,
        user: user,
      },
    )
      .method("get")
      .params({
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: ` ${kintin.ref} LPA Health - ${surname}`,
      });
  }
}
