import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThanOrEqual,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import { IOrder, Order } from "@kinherit/sdk";
import { DateTime, Time } from "@kinherit/ts-common";

export interface DownloadIntroducerFeeKintinCsvMessage {
  referralCode: Array<string>;
  agent: Array<string>;
  period: null | [number, number];
  reconciled: null | boolean;
  showOrders: "waitingForPayment" | "within21DayCoolOffPeriod" | null;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
}

export class DownloadIntroducerFeeKintinCsvHandler extends ActionBase {
  constructor(private message: DownloadIntroducerFeeKintinCsvMessage) {
    super();
  }

  public async execute(): Promise<void> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee/kintin/csv",
    )
      .method("get")
      .paginate({
        currentPage: 1,
        perPage: 1000,
      })
      .sort({
        sortBy: this.message.sort?.by ?? "paidAt",
        sortDirection: this.message.sort?.direction ?? "desc",
      });

    let paidAt = undefined;

    if (this.message.period) {
      paidAt = Between([
        DateTime.fromDate(
          new Date(this.message.period[1], this.message.period[0] - 1, 1),
        ).timestamp,
        DateTime.fromDate(
          new Date(this.message.period[1], this.message.period[0], 1),
        ).timestamp,
      ]);
    }

    // Day 21 -> onwards after payment
    if (this.message.showOrders === "waitingForPayment") {
      paidAt = LessThanOrEqual(
        DateTime.fromDate(new Date())
          .sub(Time.fromArray(0, 0, 0, 0, 21))
          .setTime(0, 0, 0, 0).timestamp,
      );
    }
    // Day 0 -> 21 after payment
    if (this.message.showOrders === "within21DayCoolOffPeriod") {
      paidAt = MoreThanOrEqual(
        DateTime.fromDate(new Date())
          .sub(Time.fromArray(0, 0, 0, 0, 21))
          .setTime(0, 0, 0, 0).timestamp,
      );
    }

    let kintin = undefined;

    if (this.message.agent.length > 0) {
      kintin = {
        referral: {
          referralCode: {
            company: {
              introducedBy: {
                id: In(this.message.agent),
              },
            },
          },
        },
      };
    } else {
      kintin = {
        referral: {
          referralCode: {
            id: In(this.message.referralCode),
          },
        },
      };
    }

    request.buildQuery(Order).where({
      kintin,
      paidAt,
      feesConfirmed: Equal(this.message.reconciled),
      xeroIntroducerBillId: IsNotNull(),
    });

    await request.download({
      fileName: "fees.csv",
      encoding: "text/csv",
    });
  }
}
