import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Person, WillRevision } from "@kinherit/sdk";

interface DownloadKintinWillRevisionContentMessage {
  willContent: string[];
  person: Person;
  draft: boolean;
  hasLetterOfWishes: boolean;
  willRevision: WillRevision | null;
  reference: string;
  mowBusinessContent: string;
  mowIipContent: string;
  mowNrbContent: string;
  mowResContent: string;
}

export class DownloadKintinWillRevisionContentHandler extends ActionBase {
  constructor(private message: DownloadKintinWillRevisionContentMessage) {
    super();
  }

  public async execute(): Promise<void> {
    const request = Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-will-revision/download",
    )
      .method("put")
      .body({
        watermark: this.message.draft,
        willContent: this.message.willContent,
        testator: this.message.person.profile.fullName ?? "",
        reference: this.message.reference,
        hasLetterOfWishes: this.message.hasLetterOfWishes,
        mowBusinessContent: this.message.mowBusinessContent,
        mowIipContent: this.message.mowIipContent,
        mowNrbContent: this.message.mowNrbContent,
        mowResContent: this.message.mowResContent,
      });

    if (this.message.willRevision) {
      await request.download({
        encoding: "application/pdf",
        fileName: `${this.message.person.profile.firstName} ${this.message.person.profile.lastName} - Will - ${this.message.willRevision.createdAt.formatDateTime}.pdf`,
      });
      return;
    }

    await request.download({
      encoding: "application/pdf",
      fileName: `${this.message.person.profile.firstName} ${this.message.person.profile.lastName} - Will.pdf`,
    });
  }
}
