import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface CreateEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}

export interface CreateEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
}

export class CreateEmailCampaignLogHandler extends ActionBase {
  constructor(private message: CreateEmailCampaignLogMessage) {
    super();
  }

  public async execute(): Promise<CreateEmailCampaignLogResponse> {
    const request = Api.resource("portal", "/v2/portal/email-campaign-log", {})
      .method("post")

      .body({
        campaign: this.message.emailCampaignLog.$data.campaign,
        scheduledToSendAt:
          this.message.emailCampaignLog.$data.scheduledToSendAt,
        body: this.message.emailCampaignLog.$data.body,
        title: this.message.emailCampaignLog.$data.title,
        subject: this.message.emailCampaignLog.$data.subject,
        template: this.message.emailCampaignLog.$data.template,
        data: this.message.emailCampaignLog.$data.data,
        from: this.message.emailCampaignLog.$data.from,
        status: this.message.emailCampaignLog.$data.status,
      });

    const response = await request.result();

    const emailCampaignLog = EmailCampaignLog.$inflate(
      response.emailCampaignLog,
    ).first();

    if (!emailCampaignLog) {
      throw new Error("Failed to create email log");
    }

    await window.Kernel.ActionBus.execute(
      "admin/email-campaign/campaign-log/attachment/upload",
      {
        emailCampaignLog,
        files: this.message.files,
      },
    );

    this.message.emailCampaignLog.$delete();

    return {
      emailCampaignLog: EmailCampaignLog.$inflate(
        response.emailCampaignLog,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
