<template>
  <div class="kintin-details-address-book-advisers">
    <PageHeader htag="h2" text="Advisers" v-if="kintin">
      <template #buttons>
        <Button text="Add Adviser" @click="createAdviser" class="add-button" />
        <Button
          v-if="kintin.type.value === 'joint'"
          text="Copy"
          @click="copy"
          class="copy-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4"
      >
      </Tabs>
      <AdviserList
        v-if="!isPeopleTab"
        :kintin="kintin"
        :advisers="$data.filtered.advisers"
        @reload="filter"
      />
      <PersonList
        v-if="isPeopleTab"
        :kintin="kintin"
        :people="$data.filtered.people"
        @reload="filter"
      />
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<script lang="ts">
import { CreateAdviser } from "@/config/model.config";
import AdviserList from "@/module/kinvault.kintin/component/lists/AdviserList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdateAdviserForm } from "@/module/kinvault.kintin/form/update-adviser.form";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import { Tabs } from "@kinherit/framework/component.display/tabs";

import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Adviser, Kintin, Person } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CopyRolesForm } from "../../form/copy-roles.form";

export default defineComponent({
  name: `AdvisersContainer`,
  components: {
    Tabs,
    Json,
    AdviserList,
    Button,
    PageHeader,
    PersonList,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  emits: ["reload"],
  data: () => ({
    filtered: {
      advisers: Array<Adviser>(),
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.advisers = Adviser.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin?.primaryPerson?.id,
                this.kintin?.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      this.filtered.people = this.filtered.advisers
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");

      this.$emit("reload");
    },
    async createAdviser() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const adviser = CreateAdviser({
        forPerson,
      });

      try {
        await UpdateAdviserForm({
          data: adviser,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create adviser",
          },
        });
      } catch {
        adviser.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/adviser/create", {
        adviser,
        kintin: this.kintin,
      });

      this.filter();
    },
    async copy() {
      const forPerson =
        this.selectedTab === 0
          ? this.kintin.secondaryPerson
          : this.kintin.primaryPerson;

      let name: string = "";

      if (forPerson?.profile.fullName) {
        name = forPerson?.profile.fullName;
      }

      const type =
        this.selectedTab === 0 ? "secondary person" : "primary person";

      name = name === "" ? type.ucFirst() : `${name} (${type})`;

      const { rolesToCopy } = await CopyRolesForm(
        this.$data.filtered.advisers.filter(
          (a) => a.person.id !== forPerson?.id,
        ),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy to ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (adviser) => {
        const newAdviser = CreateAdviser({
          ...adviser.$data,
          forPerson: forPerson?.id,
          id: Uuid.generate(),
        });

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/adviser/create",
          {
            adviser: newAdviser,
            kintin: this.kintin,
          },
        );
      });

      this.selectedTab = this.selectedTab === 0 ? 1 : 0;
      this.filter();
    },
  },
});
</script>
