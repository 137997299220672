import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinAccess } from "@kinherit/sdk";

interface UpdateKintinAccessMessage {
  kintin: string | Kintin;
  kintinAccess: KintinAccess;
}

interface UpdateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class UpdateKintinAccessHandler extends ActionBase {
  constructor(private message: UpdateKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinAccessResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-user-access/{kintin}/assigned-users/{kintinAccess}",
      {
        kintin: this.message.kintin,
        kintinAccess: this.message.kintinAccess,
      },
    )
      .method("patch")
      .body({
        accessType: this.message.kintinAccess.$data.accessType,
        allowAccess: this.message.kintinAccess.$data.allowAccess,
        kintin: this.message.kintinAccess.$data.kintin,
        user: this.message.kintinAccess.$data.user,
      })

      .result();

    const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to update kintin check");
    }

    return {
      kintinAccess,
    };
  }
}
