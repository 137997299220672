<template>
  <PageHeader htag="h1" text="CPD Sessions">
    <template #buttons>
      <Button
        v-if="hasWritePermission"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create CPD Session
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      allowBookings: 'Allow Bookings',
      createdAt: 'Created',
      date: 'Date',
      duration: 'Duration',
      title: 'Title',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #bookable="{ row }: { row: CpdSession }">
      <div
        :class="{
          badge: true,
          'is-small': true,
          'is-success': row.allowBookings,
          'is-danger': !row.allowBookings,
        }"
      >
        {{ row.allowBookings ? "Yes" : "No" }}
      </div>
    </template>
    <template #isPrivate="{ row }: { row: CpdSession }">
      <div
        :class="{
          badge: true,
          'is-small': true,
          'is-danger': row.isPrivate,
          'is-success': !row.isPrivate,
        }"
      >
        {{ row.isPrivate ? "Yes" : "No" }}
      </div>
    </template>
    <template #participants="{ row }: { row: CpdSession }">
      <div
        :class="{
          badge: true,
          'is-small': true,
          'is-success': row.introducerCount > 0,
          'is-danger': row.introducerCount === 0,
        }"
      >
        {{ row.introducerCount }}
      </div>
    </template>
    <template #registrations="{ row }: { row: CpdSession }">
      <div
        :class="{
          badge: true,
          'is-small': true,
          'is-success': row.registrationCount > 0,
          'is-danger': row.registrationCount === 0,
        }"
      >
        {{ row.registrationCount }}
      </div>
    </template>
    <template #unmatched="{ row }: { row: CpdSession }">
      <div
        :class="{
          badge: true,
          'is-small': true,
          'is-success': row.unmachedRegistrationCount === 0,
          'is-danger': row.unmachedRegistrationCount > 0,
        }"
      >
        {{ row.unmachedRegistrationCount }}
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadCpdForm } from "@/module/cpd/form/read-cpd.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { CpdSession, ICpdSession } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { CpdSessionsMasterListRoute } from ".";
import { UpdateCpdSessionForm } from "../form/update-cpd-session.form";
import { CpdDetailsParams, CpdDetailsRoute } from "./details";

export default defineComponent({
  name: CpdSessionsMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  mixins: [AuthService.mixin()],
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("cpd-session:write");
    },
  },
  data: () => ({
    filters: ReadCpdForm(),
    columns: [
      {
        title: "Date",
        sort: true,
        map: (session: CpdSession) => session.date.formatDateTime,
      },
      {
        title: "Title",
        sort: true,
        field: "title",
      },
      {
        title: "Duration",
        sort: true,
        map: (session: CpdSession) => `${session.duration.toMinutes()} minutes`,
      },
      {
        title: "Bookable",
        slot: "bookable",
      },
      {
        title: "Participants",
        slot: "participants",
      },
      {
        title: "Registrations",
        sort: true,
        slot: "registrations",
      },
      {
        title: "Unmatched",
        sort: true,
        slot: "unmatched",
      },
      {
        title: "Private",
        slot: "isPrivate",
      },
    ] as Array<TableColumn>,
    rows: Array<CpdSession>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "date" as keyof ICpdSession,
      direction: "asc" as "desc" | "asc",
    },
    period: null as "any" | "forthcoming" | "recent" | null,
  }),
  mounted(): void {
    this.period = this.filters.localData?.period ?? "forthcoming";
  },
  methods: {
    handleClick(cpd: CpdSession, event: MouseEvent): void {
      const params: CpdDetailsParams = { cpdSession: cpd.id };

      window.Kernel.visitRoute(
        {
          name: CpdDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadCpdForm>["localData"],
    ): Promise<void> {
      if (this.period !== formData.period) {
        this.period = formData?.period ?? "forthcoming";

        this.sort.by = "date";
        this.sort.direction = this.period === "recent" ? "desc" : "asc";
      }

      const data = await window.Kernel.ActionBus.execute("cpd/session/read", {
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.cpdSessions;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const cpdSession = await UpdateCpdSessionForm().dialog({
        dialog: {
          title: "Create CPD Session",
        },
      });

      await window.Kernel.ActionBus.execute("cpd/session/create", {
        cpdSession,
      });

      this.refresh(this.filters.localData);
    },
  },
});
</script>
