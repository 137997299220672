import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinAccess } from "@kinherit/sdk";

interface DeleteUserKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface DeleteUserKintinAccessResponse {
  [key: string]: never;
}

export class DeleteUserKintinAccessHandler extends ActionBase {
  constructor(private message: DeleteUserKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<DeleteUserKintinAccessResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-user-access/{kintinAccess}",
      {
        kintinAccess: this.message.kintinAccess,
      },
    )
      .method("delete")
      .result();

    this.message.kintinAccess.$delete();

    return {};
  }
}
