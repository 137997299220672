import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IPerson, Person, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadPersonMessage {
  query?: QueryMask<typeof Person>;
  sort?: {
    by: keyof IPerson;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadPersonResponse {
  people: Array<Person>;
  profiles: Array<Profile>;
}

export class ReadPersonHandler extends ActionBase {
  constructor(private message: ReadPersonMessage) {
    super();
  }

  public async execute(): Promise<ReadPersonResponse> {
    const request = Api.resource("core", "/v2/core/select/person")
      .method("get")

      .paginate(this.message.pagination ?? false)
      .sort(this.message.sort);

    request.buildQuery(Person).where(this.message.query ?? {});

    const response = await request.result();

    return {
      people: Person.$inflate(response.person, undefined, response.$rootIds),
      profiles: Profile.$inflate(response.profile),
    };
  }
}
