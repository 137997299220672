import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Adviser, Kintin } from "@kinherit/sdk";

interface UpdateAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface UpdateAdviserResponse {
  adviser: Adviser;
}

export class UpdateAdviserHandler extends ActionBase {
  constructor(private message: UpdateAdviserMessage) {
    super();
  }

  public async execute(): Promise<UpdateAdviserResponse> {
    const { adviser: adviserData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-adviser/{kintin}/person/adviser/{adviser}",
      {
        kintin: this.message.kintin,
        adviser: this.message.adviser.$data.id,
      },
    )
      .method("patch")

      .body({
        defaultTrustIfa: this.message.adviser.$data.defaultTrustIfa,
        notes: this.message.adviser.$data.notes,
        person: this.message.adviser.$data.person,
        forPerson: this.message.adviser.$data.forPerson,
      })
      .result();

    const adviser = Adviser.$inflate(adviserData).first();

    if (!adviser) {
      throw new Error("Failed to update adviser");
    }

    return { adviser };
  }
}
