import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, Kintin } from "@kinherit/sdk";

type createRoyalMailOrderMessage = {
  kintin: string | Kintin;
  address: string | Address;
};

interface createRoyalMailOrderResponse {
  [key: string]: never;
}

export class createRoyalMailOrderHandler extends ActionBase {
  constructor(private message: createRoyalMailOrderMessage) {
    super();
  }

  public async execute(): Promise<createRoyalMailOrderResponse> {
    const kintin =
      "string" === typeof this.message.kintin
        ? this.message.kintin
        : this.message.kintin.id;
    const address =
      "string" === typeof this.message.address
        ? this.message.address
        : this.message.address.id;

    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/create-royal-mail-order",
    )
      .method("get")
      .params({
        kintin,
        address,
      })
      .result();

    return {};
  }
}
