import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between } from "@kinherit/orm/index";
import { Api, EmailAddress, EmailLog, IEmailLog } from "@kinherit/sdk";

export interface ReadEmailLogMessage {
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IEmailLog;
    direction: "asc" | "desc";
  };
}

interface ReadEmailLogResponse {
  emailLogs: Array<EmailLog>;
  emailAddresses: Array<EmailAddress>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadEmailLogHandler extends ActionBase {
  constructor(private message: ReadEmailLogMessage) {
    super();
  }

  public async execute(): Promise<ReadEmailLogResponse> {
    const request = Api.resource("portal", "/v2/portal/email-log")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(EmailLog).where({
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      emailLogs: EmailLog.$inflate(
        response.emailLog,
        this.message.sort,
        response.$rootIds,
      ),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),

      pagination: this.getPagination(response),
    };
  }
}
