import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface CreateKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface CreateKintinStorageRequestResponse {
  physicalStorageFile: PhysicalStorageFile;
}

export class CreateKintinStorageRequestHandler extends ActionBase {
  constructor(private message: CreateKintinStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinStorageRequestResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request",
      {
        kintin: this.message.kintin,
        subscription: this.message.subscription,
      },
    )
      .method("post")
      .body({
        length: this.message.storageRequest.$data.length,
        notes: this.message.storageRequest.$data.notes,
        subscription: this.message.subscription.id,
        title: this.message.storageRequest.$data.title,
        kintin: this.message.kintin.toString(),
      })

      .result();

    const physicalStorageFile = PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ).first();

    if (!physicalStorageFile) {
      throw new Error("Failed to Create storage request");
    }

    this.message.storageRequest.$delete();

    return {
      physicalStorageFile,
    };
  }
}
