import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface UpdateIntroducerContactPartnerPortalPermissionsMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface UpdateIntroducerContactPartnerPortalPermissionsResponse {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

export class UpdateIntroducerContactPartnerPortalPermissionsHandler extends ActionBase {
  constructor(
    private message: UpdateIntroducerContactPartnerPortalPermissionsMessage,
  ) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerContactPartnerPortalPermissionsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
      {
        introducerContact: this.message.contactPartnerPortalPermissions.contact,
        contactPartnerPortalPermissions:
          this.message.contactPartnerPortalPermissions.$id,
      },
    )
      .method("patch")
      .body({
        clientsRead: this.message.contactPartnerPortalPermissions.clientsRead,
        clientsCreate:
          this.message.contactPartnerPortalPermissions.clientsCreate,
        feesRead: this.message.contactPartnerPortalPermissions.feesRead,
        invitationsCreate:
          this.message.contactPartnerPortalPermissions.invitationsCreate,
      })
      .result();

    const partnerPortalPermissions = ContactPartnerPortalPermissions.$inflate(
      response.contactPartnerPortalPermissions,
    ).first();

    if (!partnerPortalPermissions) {
      throw new Error("Failed to update partner portal permission");
    }

    return {
      contactPartnerPortalPermissions: partnerPortalPermissions,
    };
  }
}
