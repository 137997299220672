import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Person } from "@kinherit/sdk";

interface CreateKintinPersonMessage {
  person: Person;
  kintin: Kintin;
}

interface CreateKintinPersonResponse {
  person: Person;
}

export class CreateKintinPersonHandler extends ActionBase {
  constructor(private message: CreateKintinPersonMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinPersonResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-person/{kintin}/person",
      {
        kintin: this.message.kintin.id,
      },
    )
      .method("post")

      .body({
        kintin: this.message.kintin.id,
        notificationDate: this.message.person.$data.notificationDate,
        notifications: this.message.person.$data.notifications,
        gender: this.message.person.$data.gender,
        maritalStatus: this.message.person.$data.maritalStatus,
        notifyOnDeath: this.message.person.$data.notifyOnDeath,
        under18ForPlanningPurposes:
          this.message.person.$data.under18ForPlanningPurposes,
        requiresCare: this.message.person.$data.requiresCare,
        isTrusted: this.message.person.$data.isTrusted,
        dateOfBirth: this.message.person.$data.dateOfBirth,
        relationToPrimaryPerson:
          this.message.person.$data.relationToPrimaryPerson,
        relationToSecondaryPerson:
          this.message.person.$data.relationToSecondaryPerson,
        dateOfDeath: this.message.person.$data.dateOfDeath,
        profile: {
          title: this.message.person.profile.$data.title,
          firstName: this.message.person.profile.$data.firstName,
          middleNames: this.message.person.profile.$data.middleNames,
          lastName: this.message.person.profile.$data.lastName,
          suffix: this.message.person.profile.$data.suffix,
          organisationName: this.message.person.profile.$data.organisationName,
          organisationNumber:
            this.message.person.profile.$data.organisationNumber,
          addresses: this.message.person.profile.addresses.map((address) => ({
            line1: address.$data.line1,
            line2: address.$data.line2,
            city: address.$data.city,
            country: address.$data.country,
            state: address.$data.state,
            postcode: address.$data.postcode,
          })),
          emails: this.message.person.profile.emails.map((email) => ({
            email: email.$data.email,
          })),
          phoneNumbers: this.message.person.profile.phoneNumbers.map(
            (phoneNumber) => ({
              tel: phoneNumber.$data.tel,
            }),
          ),
          websites: this.message.person.profile.websites.map((website) => ({
            url: website.$data.url,
          })),
        },
        type: this.message.person.$data.type,
      })
      .result();

    const personData = response.person?.first();

    if (!personData) {
      throw new Error(`Failed to create person`);
    }

    this.message.person.$delete();

    const { people } = await window.Kernel.ActionBus.execute(
      "kinvault/kintin/person/record",
      {
        person: personData.id,
        kintin: this.message.kintin.id,
      },
    );

    const person = people.first();

    if (!person) {
      throw new Error(`Failed to create person`);
    }

    return { person };
  }
}
