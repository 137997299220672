import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface RecordBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode:
    | BrandedKinvaultSubscriptionReferralCode
    | string;
}

interface RecordBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

export class RecordBrandedKinvaultSubscriptionReferralCodeHandler extends ActionBase {
  constructor(
    private message: RecordBrandedKinvaultSubscriptionReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<RecordBrandedKinvaultSubscriptionReferralCodeResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
      {
        brandedKinvaultSubscriptionReferralCode:
          this.message.brandedKinvaultSubscriptionReferralCode,
      },
    )
      .method("get")

      .result();

    const brandedKinvaultSubscriptionReferralCode =
      BrandedKinvaultSubscriptionReferralCode.$inflate(
        repsonse.brandedKinvaultSubscriptionReferralCode,
        undefined,
        repsonse.$rootIds,
      ).first();

    if (!brandedKinvaultSubscriptionReferralCode) {
      throw new Error("Failed to record subscription referral code");
    }

    return {
      brandedKinvaultSubscriptionReferralCode,
    };
  }
}
