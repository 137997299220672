import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCpd } from "@kinherit/sdk";

interface GenerateCpdCertificateMessage {
  introducerCpd: IntroducerCpd;
}

export class GenerateCpdCertificateHandler extends ActionBase {
  constructor(private message: GenerateCpdCertificateMessage) {
    super();
  }

  public async execute(): Promise<void> {
    const name = this.message.introducerCpd.contact.profile.fullName;
    await Api.resource(
      "portal",
      "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}/certificate",
      {
        introducerCpd: this.message.introducerCpd,
      },
    )
      .method("get")

      .download({
        encoding: "application/pdf",
        fileName: `CPD Certificate - ${name}`,
      });
  }
}
