import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinAccess } from "@kinherit/sdk";

interface UpdateKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface UpdateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class UpdateKintinAccessHandler extends ActionBase {
  constructor(private message: UpdateKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinAccessResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-user-access/{kintinAccess}",
      this.message,
    )
      .method("patch")
      .body({
        accessType: this.message.kintinAccess.$data.accessType,
        admin: this.message.kintinAccess.$data.admin,
        allowAccess: this.message.kintinAccess.$data.allowAccess,
        assets: this.message.kintinAccess.$data.assets,
        care: this.message.kintinAccess.$data.care,
        contacts: this.message.kintinAccess.$data.contacts,
        death: this.message.kintinAccess.$data.death,
        kintin: this.message.kintinAccess.$data.kintin,
        legal: this.message.kintinAccess.$data.legal,
        misc: this.message.kintinAccess.$data.misc,
        brandedKinvault: this.message.kintinAccess.$data.brandedKinvault,
        settings: this.message.kintinAccess.$data.settings,
        user: this.message.kintinAccess.$data.user,
      })

      .result();

    const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to create kinvault access");
    }

    return {
      kintinAccess,
    };
  }
}
