import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Order } from "@kinherit/sdk";
export interface DeleteOrdersMessage {
  order: Order;
}

export interface DeleteOrdersResponse {
  [key: string]: never;
}

export class DeleteOrderHandler extends ActionBase {
  constructor(private message: DeleteOrdersMessage) {
    super();
  }

  public async execute(): Promise<DeleteOrdersResponse> {
    await Api.resource("portal", "/v2/portal/order/{order}", {
      order: this.message.order.id,
    })
      .method("delete")
      .result();

    this.message.order.$delete();

    return {};
  }
}
