import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinAccess } from "@kinherit/sdk";

interface CreateKintinAccessMessage {
  kintin: string | Kintin;
  kintinAccess: KintinAccess;
}

interface CreateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class CreateKintinAccessHandler extends ActionBase {
  constructor(private message: CreateKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinAccessResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-user-access/{kintin}/assigned-users",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")
      .body({
        accessType: this.message.kintinAccess.$data.accessType,
        allowAccess: this.message.kintinAccess.$data.allowAccess,
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.$data.id,
        user: this.message.kintinAccess.$data.user,
      })

      .result();

    const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to create kintin check");
    }

    this.message.kintinAccess.$delete();

    return {
      kintinAccess,
    };
  }
}
