import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, Kintin, QueryMask } from "@kinherit/sdk";

export interface ReadKintinAddressMessage {
  query?: QueryMask<typeof Address>;
  kintin: string | Kintin;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadKintinAddressResponse {
  addresses: Array<Address>;
}

export class ReadKintinAddressHandler extends ActionBase {
  constructor(private message: ReadKintinAddressMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinAddressResponse> {
    const request = Api.resource(
      "core",
      "/v2/core/select/address/kintin/{kintin}",
      {
        kintin: this.message.kintin,
      },
    )
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(Address).where(this.message.query ?? {});

    const response = await request.result();

    return {
      addresses: Address.$inflate(
        response.address,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
