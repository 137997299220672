import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog } from "@kinherit/sdk";

interface CreateKintinFileMessage {
  file: FileLog;
  uploadedFile: File | null;
}

interface CreateKintinFileResponse {
  file: FileLog;
}

export class CreateKintinFileHandler extends ActionBase {
  constructor(private message: CreateKintinFileMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinFileResponse> {
    const kintin = this.message.file.$data.kintin;

    if (!kintin) {
      throw new Error("Kintin is required");
    }

    const { fileLog: fileData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files",
      {
        kintin,
      },
    )
      .method("post")

      .body({
        type: this.message.file.$data.type,
        subType: this.message.file.$data.subType,
        tab: this.message.file.$data.tab,
        fileName: this.message.file.$data.fileName,
        fileExtension: this.message.file.$data.fileExtension,
        fileSize: this.message.file.$data.fileSize,
        kintin: this.message.file.$data.kintin,
        notes: this.message.file.$data.notes,
        isRichText: this.message.file.isRichText,
      })
      .result();

    const file = FileLog.$inflate(fileData).first();

    if (!file) {
      throw new Error("Failed to create file");
    }

    if (this.message.uploadedFile) {
      await Api.resource(
        "portal",
        "/v2/portal/kintin-file/{kintin}/files/{file}/upload",
        {
          kintin,
          file: file.$data.id,
        },
      )
        .method("put")

        .files({
          uploadedFile: this.message.uploadedFile,
        })
        .result();
    }

    this.message.file.$delete();

    return { file };
  }
}
