import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Executor, Kintin } from "@kinherit/sdk";

interface CreateExecutorMessage {
  kintin: string | Kintin;
  executor: Executor;
}

interface CreateExecutorResponse {
  executor: Executor;
}

export class CreateExecutorHandler extends ActionBase {
  constructor(private message: CreateExecutorMessage) {
    super();
  }

  public async execute(): Promise<CreateExecutorResponse> {
    const { executor: executorData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-executor/{kintin}/person/executor",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        forPerson: this.message.executor.$data.forPerson,
        person: this.message.executor.$data.person,
        isReserve: this.message.executor.$data.isReserve,
        notes: this.message.executor.$data.notes,
        professionalSupport: this.message.executor.$data.professionalSupport,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const executor = Executor.$inflate(executorData).first();

    if (!executor) {
      throw new Error("Failed to create executor");
    }

    this.message.executor.$delete();

    return { executor };
  }
}
