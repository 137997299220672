import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Person } from "@kinherit/sdk";

interface DeleteKintinPersonMessage {
  person: string | Person;
  kintin: string | Kintin;
}

interface DeleteKintinPersonResponse {}

export class DeleteKintinPersonHandler extends ActionBase {
  constructor(private message: DeleteKintinPersonMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinPersonResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-person/{kintin}/person/{person}",
      {
        kintin: this.message.kintin,
        person: this.message.person,
      },
    )
      .method("delete")

      .result();

    Person.$delete(this.message.person);

    return {};
  }
}
