import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog, Kintin } from "@kinherit/sdk";

type DownloadKintinUploadFormSubmissionMessage = {
  submission: FileLog | string;
  kintin: Kintin | string;
};

interface DownloadKintinUploadFormSubmissionResponse {
  [key: string]: never;
}

export class DownloadKintinUploadFormSubmissionHandler extends ActionBase {
  constructor(private message: DownloadKintinUploadFormSubmissionMessage) {
    super();
  }

  public async execute(): Promise<DownloadKintinUploadFormSubmissionResponse> {
    const { url } = await Api.resource(
      "portal",
      "/v2/portal/kintin-upload-form/{kintin}/upload-form-submission/{submission}/download",
      {
        kintin: this.message.kintin,
        submission: this.message.submission,
      },
    )
      .method("get")

      .result();

    OpenAlertDialog({
      dialog: {
        title: "Download Ready",
        html: `
            Click <a href="${url}" target="_blank">here</a> to download the file.
          `,
      },
      button: {
        ok: {
          text: "Close",
        },
        cancel: {
          show: false,
        },
      },
    });

    return {};
  }
}
