import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog, IFileLog, Kintin } from "@kinherit/sdk";

interface ReadKintinFileMessage {
  kintin: Kintin | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IFileLog;
    direction: "asc" | "desc";
  };
}

interface ReadKintinFileResponse {
  fileLogs: Array<FileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKintinFileHandler extends ActionBase {
  constructor(private message: ReadKintinFileMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinFileResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.$data.id,
      },
    )
      .method("get")
      .sort(this.message.sort)
      .paginate(this.message.pagination)

      .result();

    return {
      fileLogs: FileLog.$inflate(
        response.fileLog,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
