import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";

export interface RecordEmailCampaignMessage {
  emailCampaign: EmailCampaign | string;
}

export interface RecordEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export class RecordEmailCampaignHandler extends ActionBase {
  constructor(private message: RecordEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<RecordEmailCampaignResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign/{emailCampaign}",
      this.message,
    ).method("get");
    const response = await request.result();

    return {
      emailCampaign: EmailCampaign.$inflate(
        response.emailCampaign,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
