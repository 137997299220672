import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk";

interface UpdateIntroducerCompanyPartnerPortalPermissionsMessage {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

interface UpdateIntroducerCompanyPartnerPortalPermissionsResponse {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

export class UpdateIntroducerCompanyPartnerPortalPermissionsHandler extends ActionBase {
  constructor(
    private message: UpdateIntroducerCompanyPartnerPortalPermissionsMessage,
  ) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerCompanyPartnerPortalPermissionsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-partner-portal/{introducerCompany}/company-partner-portal-permissions/{companyPartnerPortalPermissions}",
      {
        introducerCompany: this.message.companyPartnerPortalPermissions.company,
        companyPartnerPortalPermissions:
          this.message.companyPartnerPortalPermissions.$id,
      },
    )
      .method("patch")
      .body({
        allowPartnerPortal:
          this.message.companyPartnerPortalPermissions.allowPartnerPortal,
        enableClients:
          this.message.companyPartnerPortalPermissions.enableClients,
        enableFees: this.message.companyPartnerPortalPermissions.enableFees,
        viewOrderProducts:
          this.message.companyPartnerPortalPermissions.viewOrderProducts,
        enableStandaloneKinvault:
          this.message.companyPartnerPortalPermissions.enableStandaloneKinvault,
      })
      .result();

    const partnerPortalPermissions = CompanyPartnerPortalPermissions.$inflate(
      response.companyPartnerPortalPermissions,
    ).first();

    if (!partnerPortalPermissions) {
      throw new Error("Failed to update partner portal permission");
    }

    return {
      companyPartnerPortalPermissions: partnerPortalPermissions,
    };
  }
}
