import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Property } from "@kinherit/sdk";

export interface DownloadNoticeOfSeveranceMessage {
  kintin: Kintin;
  property: Property;
}

export class DownloadNoticeOfSeveranceHandler extends ActionBase {
  constructor(private message: DownloadNoticeOfSeveranceMessage) {
    super();
  }

  public async execute() {
    const { kintin, property } = this.message;

    const primaryPerson = kintin.primaryPerson;
    const secondaryPerson = kintin.secondaryPerson;

    const surname = [
      primaryPerson.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/notice-of-severance",
    )
      .method("get")
      .params({
        kintin: kintin.id,
        property: property.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${kintin.ref} Notice of Severance - ${surname}`,
      });
  }
}
