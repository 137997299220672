import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface DownloadKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: FileLog;
}

interface DownloadKnowledgeBaseDocumentFileLogResponse {}

export class DownloadKnowledgeBaseDocumentFileLogHandler extends ActionBase {
  constructor(private message: DownloadKnowledgeBaseDocumentFileLogMessage) {
    super();
  }

  public async execute(): Promise<DownloadKnowledgeBaseDocumentFileLogResponse> {
    const { url } = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}/download",
      {
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
        fileLog: this.message.file.id,
      },
    )
      .method("get")
      .result();

    OpenAlertDialog({
      dialog: {
        title: "Download Ready",
        html: `
            <a href="${url}" target="_blank" class='button is-large is-fullwidth is-themed is-positive is-outlined is-rounded'>
            <span class="icon is-normal"><i class="fa-download fa-regular is-unselectable"></i></span> Click here to download the file
            <a href="${url}" target="_blank">
          `,
      },
      button: {
        ok: {
          text: "Close",
        },
        cancel: {
          show: false,
        },
      },
    });

    return {};
  }
}
