<template>
  <OrdersPage
    read-action="order/order/read"
    :read-params="$params"
    :has-write-permission="hasWritePermission"
    @create-order="$emit('create-order')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { KintinDetailsOrderRoute } from ".";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import OrdersPage from "@/shared/component/order/OrdersPage.vue";

export default defineComponent({
  name: KintinDetailsOrderRoute,
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  components: {
    OrdersPage,
  },
  emits: ["create-order"],
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("kintin:write");
    },
  },
});
</script>
