import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Trustee } from "@kinherit/sdk";

interface CreateTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface CreateTrusteeResponse {
  trustee: Trustee;
}

export class CreateTrusteeHandler extends ActionBase {
  constructor(private message: CreateTrusteeMessage) {
    super();
  }

  public async execute(): Promise<CreateTrusteeResponse> {
    const { trustee: trusteeData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-trustee/{kintin}/person/trustee",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        forPerson: this.message.trustee.$data.forPerson,
        person: this.message.trustee.$data.person,
        isReserve: this.message.trustee.$data.isReserve,
        notes: this.message.trustee.$data.notes,
        attainmentAge: this.message.trustee.$data.attainmentAge,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const trustee = Trustee.$inflate(trusteeData).first();

    if (!trustee) {
      throw new Error("Failed to create trustee");
    }

    this.message.trustee.$delete();

    return { trustee };
  }
}
