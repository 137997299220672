import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Like } from "@kinherit/orm";
import { IIntroducerNetwork, IntroducerNetwork } from "@kinherit/sdk";

export interface ReadIntroducerNetworkMessage {
  name?: string;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerNetwork;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerNetworkHandler extends ActionBase {
  constructor(private message: ReadIntroducerNetworkMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerNetworkResponse> {
    const request = Api.resource("portal", "/v2/portal/introducer-network")
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(IntroducerNetwork).where({
      name: Like(`%${this.message.name}%`),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      introducerNetworks: IntroducerNetwork.$inflate(
        response.introducerNetwork,
        this.message.sort,
        response.$rootIds,
      ),

      pagination: this.getPagination(response),
    };
  }
}
