import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Tag } from "@kinherit/sdk";
export class DeleteTagAction extends ActionBase {
  constructor(private message: Tag) {
    super();
  }

  async execute(): Promise<{}> {
    await Api.resource("portal", "/v2/portal/tag/{tag}", {
      tag: this.message.id,
    })
      .method("delete")
      .result();

    this.message.$delete();

    return {};
  }
}
