<template>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :title="title"
    @row-clicked="handleClick"
    :has-context-menu="hasContextMenu"
    @context-menu="(event) => $emit('context-menu', event)"
    @create="(event) => $emit('create', event)"
    :has-create-button="hasCreateButton"
    :sort-by-options="{
      createdAt: 'Created',
      title: 'Title',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #buttons>
      <slot name="buttons" />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadKnowledgeBaseDocumentForm } from "@/module/knowledge-base/form/read-knowledge-base-document.form";
import {
  KnowledgeBaseDocumentDetailsParams,
  KnowledgeBaseDocumentDetailsRoute,
} from "@/module/knowledge-base/page";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  IKnowledgeBaseDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KnowledgeBaseDocumentMasterList",
  components: { MasterListPage },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    hasCreateButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["context-menu", "create", "update", "delete"],
  data: () => ({
    filters: ReadKnowledgeBaseDocumentForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: KnowledgeBaseDocument) => v.title,
      },
    ] as Array<TableColumn>,
    rows: Array<KnowledgeBaseDocument>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "title" as keyof IKnowledgeBaseDocument,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    category(): KnowledgeBaseCategory | null {
      if (this.categoryId) {
        return KnowledgeBaseCategory.$findOne(this.categoryId);
      }
      return null;
    },
    title(): string {
      return `${this.category?.title} Documents` ?? "Knowledge Base Documents";
    },
  },
  methods: {
    handleClick(
      knowledgebasedocument: KnowledgeBaseDocument,
      event: MouseEvent,
    ): void {
      const params: KnowledgeBaseDocumentDetailsParams = {
        document: knowledgebasedocument.id,
        category: this.categoryId,
      };

      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseDocumentDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadKnowledgeBaseDocumentForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "knowledge-base/document/read",
        {
          ...formData,
          category: this.categoryId,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.knowledgeBaseDocuments;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
