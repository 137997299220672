import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk/api";

export interface ReadInvoicesMessage {
  type: "KINHERIT" | "TRUREG";
  modifiedSince?: string;
  where?: string;
  order?: string;
  invoiceIds?: string[];
  invoiceNumbers?: string[];
  contactIds?: string[];
  statuses?: string[];
  page?: number;
  includeArchived?: boolean;
  createdByMyApp?: boolean;
  unitdp?: number;
  summaryOnly?: boolean;
}

export class ReadInvoicesHandler extends ActionBase {
  constructor(private message: ReadInvoicesMessage) {
    super();
  }

  public async execute() {
    return await Api.resource("external", "/v2/external/xero/invoices")
      .method("get")
      .params(this.message)
      .result();
  }
}
