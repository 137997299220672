import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Order, OrderItem } from "@kinherit/sdk";

export interface UpdateOrderItemMessage {
  order: Order | string;
  orderItem: OrderItem;
}

export interface UpdateOrderItemResponse {
  orderItems: Array<OrderItem>;
}

export class UpdateOrderItemHandler extends ActionBase {
  constructor(private message: UpdateOrderItemMessage) {
    super();
  }

  public async execute(): Promise<UpdateOrderItemResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/order/{order}/order-items/{orderItem}",
      {
        order: this.message.order,
        orderItem: this.message.orderItem,
      },
    )
      .method("patch")

      .body({
        price: this.message.orderItem.$data.price,
        quantity: this.message.orderItem.$data.quantity,
        recurring: this.message.orderItem.$data.recurring,
        requiresDd: this.message.orderItem.$data.requiresDd,
        text: this.message.orderItem.$data.text,
        value: this.message.orderItem.$data.value,
      });

    const response = await request.result();

    return {
      orderItems: OrderItem.$inflate(
        response.orderItem,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
