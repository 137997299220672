<template>
  <BeneficiariesContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<script lang="ts">
import { CreateBeneficiary } from "@/config/model.config";
import BeneficiariesContainer from "@/module/kinvault.kintin/component/address-book/BeneficiariesContainer.vue";
import { UpdateBeneficiaryForm } from "@/module/kinvault.kintin/form/update-beneficiary.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Beneficiary, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookBeneficiariesRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookBeneficiariesRoute,
  components: {
    BeneficiariesContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      beneficiaries: {
        firstLine: Array<Beneficiary>(),
        secondLine: Array<Beneficiary>(),
        reserve: Array<Beneficiary>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson?.profile.fullName) {
        names.push(this.kintin?.primaryPerson?.profile.fullName);
      }
      if (this.kintin?.secondaryPerson?.profile.fullName) {
        names.push(this.kintin?.secondaryPerson?.profile.fullName);
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const beneficiaries = selectedPerson?.appointedBeneficiary ?? [
        ...(this.kintin?.primaryPerson?.appointedBeneficiary ?? []),
        ...(this.kintin?.secondaryPerson?.appointedBeneficiary ?? []),
      ];

      // NB position doesn't start at 0.
      this.filtered.beneficiaries = beneficiaries.groupBy({
        firstLine: (b) => b.position === 0,
        secondLine: (b) => b.position === 1,
        reserve: (b) => b.position === 2,
      });

      this.filtered.people = beneficiaries
        .pluck("person")
        .unique("id")
        .cast<Person>()
        .filter(Boolean)
        .sortBy("profile.fullName");
    },
    async createBeneficiary() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const beneficiary = CreateBeneficiary({
        forPerson,
      });

      beneficiary.position = 0;

      try {
        await UpdateBeneficiaryForm({
          data: beneficiary,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create beneficiary",
          },
        });
      } catch {
        beneficiary.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/beneficiary/create",
        {
          beneficiary,
          kintin: this.kintin,
        },
      );

      this.$emit("reload");
    },
  },
});
</script>
