import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { MfAuthMethod } from "@kinherit/sdk";

interface UpdateUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface UpdateUserMfAuthMethodResponse {
  mfAuthMethod: MfAuthMethod;
}

export class UpdateUserMfAuthMethodHandler extends ActionBase {
  constructor(private message: UpdateUserMfAuthMethodMessage) {
    super();
  }

  public async execute(): Promise<UpdateUserMfAuthMethodResponse> {
    const credential = this.message.mfAuthMethod.credential;
    const respense = await Api.resource(
      "portal",
      "/v2/portal/user/{credential}/mf-auth-methods/{mfAuthMethod}",
      {
        credential,
        mfAuthMethod: this.message.mfAuthMethod,
      },
    )
      .method("patch")
      .body({
        type: this.message.mfAuthMethod.$data.type,
        alias: this.message.mfAuthMethod.$data.alias,
        device: this.message.mfAuthMethod.$data.device,
        key: this.message.mfAuthMethod.$data.key,
        credential: this.message.mfAuthMethod.$data.credential,
        setupCompleteAt: this.message.mfAuthMethod.$data.setupCompleteAt,
      })
      .result();

    const mfAuthMethod = MfAuthMethod.$inflate(respense.mfAuthMethod).first();

    if (!mfAuthMethod) {
      throw new Error("Failed to Update auth method");
    }

    this.message.mfAuthMethod.$delete();

    return {
      mfAuthMethod,
    };
  }
}
