import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  BrandedKinvault,
  ThemeCssVar,
  ThemeKinvault,
  ThemeKinvaultFooter,
  ThemeKinvaultHeader,
  ThemeKinvaultLogin,
} from "@kinherit/sdk";

export interface RecordThemeKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
  themeKinvault: ThemeKinvault | string;
}

interface RecordThemeKinvaultResponse {
  themeKinvault: ThemeKinvault;
  themeKinvaultLogin: ThemeKinvaultLogin;
  themeKinvaultFooter: ThemeKinvaultFooter;
  themeKinvaultHeader: ThemeKinvaultHeader;
  themeCssVars: Array<ThemeCssVar>;
}

export class RecordThemeKinvaultHandler extends ActionBase {
  constructor(private message: RecordThemeKinvaultMessage) {
    super();
  }

  public async execute(): Promise<RecordThemeKinvaultResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-theme/{brandedKinvault}/theme/{themeKinvault}",
      this.message,
    )
      .method("get")

      .result();

    const themeKinvault = ThemeKinvault.$inflate(
        response.themeKinvault,
      ).first(),
      themeKinvaultFooter = ThemeKinvaultFooter.$inflate(
        response.themeKinvaultFooter,
      ).first(),
      themeKinvaultHeader = ThemeKinvaultHeader.$inflate(
        response.themeKinvaultHeader,
      ).first(),
      themeKinvaultLogin = ThemeKinvaultLogin.$inflate(
        response.themeKinvaultLogin,
      ).first(),
      themeCssVars = ThemeCssVar.$inflate(response.themeCssVar);

    if (
      !themeKinvault ||
      !themeKinvaultFooter ||
      !themeKinvaultHeader ||
      !themeKinvaultLogin
    ) {
      throw new Error("Theme not found");
    }

    return {
      themeKinvault,
      themeKinvaultLogin,
      themeKinvaultFooter,
      themeKinvaultHeader,
      themeCssVars,
    };
  }
}
