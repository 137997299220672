import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Gift } from "@kinherit/sdk";

interface UpdateKintinGiftMessage {
  gift: Gift;
}

interface UpdateKintinGiftResponse {
  gift: Gift;
}

export class UpdateKintinGiftHandler extends ActionBase {
  constructor(private message: UpdateKintinGiftMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinGiftResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-gift/{kintin}/gift/{gift}",
      {
        kintin: this.message.gift.$data.kintin,
        gift: this.message.gift.$data.id,
      },
    )
      .method("patch")

      .body({
        kintin: this.message.gift.$data.kintin,
        address: this.message.gift.$data.address,
        forPerson: this.message.gift.$data.forPerson,
        sentBy: this.message.gift.$data.sentBy,
        type: this.message.gift.$data.type,
        classGiftType: this.message.gift.$data.classGiftType,
        amount: this.message.gift.$data.amount
          ? {
              amount: this.message.gift.$data.amount.amount,
              type: this.message.gift.$data.amount.type,
            }
          : undefined,
        notes: this.message.gift.$data.notes,
        onSecondDeath: this.message.gift.$data.onSecondDeath,
        asPrimaryResidence: this.message.gift.$data.asPrimaryResidence,
        charityName: this.message.gift.$data.charityName,
        charityNo: this.message.gift.$data.charityNo,
        ifPredeceased: this.message.gift.$data.ifPredeceased,
        sortOrder: this.message.gift.$data.sortOrder,
        outputPreference: this.message.gift.$data.outputPreference,
      })
      .result();

    const gift = Gift.$inflate(response.gift).first();

    if (!gift) {
      throw new Error("Failed to update gift");
    }

    return { gift };
  }
}
