import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThan,
  Like,
} from "@kinherit/orm/index";
import { Api, EmailCampaign, Profile, QueryMask } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export interface AddProfilesFromFilterMessage {
  emailCampaign: EmailCampaign;
  // general
  name?: string;
  emailAddress?: string;
  created?: null | [number, number];
  query?: QueryMask<typeof Profile>;
  newsletterCampaigns?: null | boolean;
  // introducer contact options
  introducerContactStatus?: string[];
  introducerContactCompanyStatus?: string[];
  introducerContactCompanyStage?: string[];
  introducerContactCompanyType?: string[];
  introducerContactCompanyNetwork?: string[];
  introducerContactTags?: string[];
  introducerContactCompanyTags?: string[];
  introducerContactCpdSessionType?: string[];
  introducerContactCompany?: string[];
  introducerContactNewsletter?: null | boolean;
  // introducer company options
  introducerCompanyStatus?: string[];
  introducerCompanyStage?: string[];
  introducerCompanyType?: string[];
  introducerCompanyNetwork?: string[];
  introducerCompanyTags?: string[];
  // kinvault
  kinvaultNoRecentLogins?: null | boolean;
  // lead
  leadStatus?: string[];
  leadReferralCode?: string[];
}

interface AddProfilesFromFilterResponse {}

export class AddProfilesFromFilterHandler extends ActionBase {
  constructor(private message: AddProfilesFromFilterMessage) {
    super();
  }

  public async execute(): Promise<AddProfilesFromFilterResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign/profile/{emailCampaign}/add-filtered-profiles",
      {
        emailCampaign: this.message.emailCampaign,
      },
    )
      .method("post")

      .paginate(false);

    request.buildQuery(Profile).where({
      newsletterCampaigns: Equal(this.message.newsletterCampaigns),
      fullName: Like(this.message.name),
      emails: {
        primary: Equal(true),
        email: Like(this.message.emailAddress),
      },
      person: {
        primaryOwnerOfKintin: {
          id: this.message.kinvaultNoRecentLogins ? IsNotNull() : undefined,
          assignedUsers: {
            accessType: this.message.kinvaultNoRecentLogins
              ? Equal("owner")
              : undefined,
            user: {
              credential: {
                lastLoggedIn: this.message.kinvaultNoRecentLogins
                  ? LessThan(new DateTime().sub(1, "year"))
                  : undefined,
              },
            },
          },
        },
      },
      introducerContact: {
        status: {
          id: In(this.message.introducerContactStatus),
        },
        cpds: {
          session: {
            type: {
              value: In(this.message.introducerContactCpdSessionType),
            },
          },
        },
        tags: {
          id: In(this.message.introducerContactTags),
        },
        companies: {
          id: In(this.message.introducerContactCompany),
          status: {
            id: In(this.message.introducerContactCompanyStatus),
          },
          stage: {
            id: In(this.message.introducerContactCompanyStage),
          },
          type: {
            id: In(this.message.introducerContactCompanyType),
          },
          network: {
            id: In(this.message.introducerContactCompanyNetwork),
          },
          tags: {
            id: In(this.message.introducerContactCompanyTags),
          },
        },
        newsLetter: Equal(this.message.introducerContactNewsletter),
      },
      introducerCompany: {
        status: {
          id: In(this.message.introducerCompanyStatus),
        },
        stage: {
          id: In(this.message.introducerCompanyStage),
        },
        type: {
          id: In(this.message.introducerCompanyType),
        },
        network: {
          id: In(this.message.introducerCompanyNetwork),
        },
        tags: {
          id: In(this.message.introducerCompanyTags),
        },
      },
      lead: {
        status: {
          id: In(this.message.leadStatus),
        },
        referral: {
          referralCode: {
            code: In(this.message.leadReferralCode),
          },
        },
      },
      createdAt: Between(this.message.created),
      ...this.message.query,
    });

    await request.result();

    return {};
  }
}
