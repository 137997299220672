import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Person } from "@kinherit/sdk";

interface DownloadWillReviewMessage {
  content: string;
  person: Person;
  kintin: Kintin;
  withPassword: boolean;
}

interface DownloadWillReviewResponse {
  [key: string]: never;
}

export class DownloadWillReviewHandler extends ActionBase {
  constructor(private message: DownloadWillReviewMessage) {
    super();
  }

  public async execute(): Promise<DownloadWillReviewResponse> {
    const { person, kintin, content } = this.message;

    const ref = kintin.ref;
    const name = person.profile.fullName;

    await Api.resource("portal", "/v2/portal/kintin-call-script/will-review")
      .method("post")
      .body({
        content,
        kintin: kintin.id,
        person: person.id,
        passwordProtected: this.message.withPassword,
      })

      .download({
        encoding: "application/pdf",
        fileName: `${ref} Will Review - ${name}`,
      });

    return {};
  }
}
