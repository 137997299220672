import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Address, Kintin } from "@kinherit/sdk";

interface UpdateKintinAddressMessage {
  address: Address;
  kintin: Kintin;
}

interface UpdateKintinAddressResponse {
  address: Address;
}

export class UpdateKintinAddressHandler extends ActionBase {
  constructor(private message: UpdateKintinAddressMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinAddressResponse> {
    const response = await Api.resource(
      "kinvault",
      "/v2/kinvault/{kintin}/address/{address}",
      {
        kintin: this.message.kintin,
        address: this.message.address,
      },
    )
      .method("patch")

      .body({
        line1: this.message.address.$data.line1,
        line2: this.message.address.$data.line2,
        city: this.message.address.$data.city,
        state: this.message.address.$data.state,
        postcode: this.message.address.$data.postcode,
        country: this.message.address.$data.country,
      })
      .result();

    const address = Address.$inflate(response.address).first();

    if (!address) {
      throw new Error(`Failed to update address`);
    }

    return { address };
  }
}
