import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerNetwork } from "@kinherit/sdk";

interface CreateIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork;
}

interface CreateIntroducerNetworkResponse {
  introducerNetwork: IntroducerNetwork;
}

export class CreateIntroducerNetworkHandler extends ActionBase {
  constructor(private message: CreateIntroducerNetworkMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerNetworkResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-network",
    )
      .method("post")

      .body({
        name: this.message.introducerNetwork.$data.name,
      })
      .result();

    const introducerNetwork = IntroducerNetwork.$inflate(
      response.introducerNetwork,
    ).first();

    if (!introducerNetwork) {
      throw new Error("Failed to create Introducer Network");
    }

    this.message.introducerNetwork.$delete();

    return {
      introducerNetwork,
    };
  }
}
