import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  FileLog,
  ILegacyEmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  LegacyEmailLog,
  Person,
  Profile,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof ILegacyEmailLog;
    direction: "asc" | "desc";
  };
};

type Response = {
  legacyEmailLogs: Array<LegacyEmailLog>;
  introducerCompanies: Array<IntroducerCompany>;
  introducerContacts: Array<IntroducerContact>;
  kintins: Array<Kintin>;
  leads: Array<Lead>;
  officers: Array<Person>;
  profiles: Array<Profile>;
  fileLogs: Array<FileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
};

export class ReadLegacyEmailLogHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/legacy-email-log",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/legacy-email-log",
      [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/legacy-email-log",
      [Lead.$name]: "/v2/portal/lead-email-log/{owner}/legacy-email-log",
      [Person.$name]: "/v2/portal/officer-email-log/{owner}/legacy-email-log",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/legacy-email-log",
    } as const;

    const route = routes[this.message.owner.$name];

    const request = Api.resource("portal", route, {
      owner: this.message.owner,
    })
      .method("get")
      .paginate(this.message.pagination ?? false)

      .sort(this.message.sort ?? false);

    const response = await request.result();

    return {
      legacyEmailLogs: LegacyEmailLog.$inflate(
        response.legacyEmailLog,
        this.message.sort,
        response.$rootIds,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      kintins: Kintin.$inflate(response.kintin),
      leads: Lead.$inflate(response.lead),
      officers: Person.$inflate(response.person),
      profiles: Profile.$inflate(response.profile),
      fileLogs: FileLog.$inflate(response.fileLog),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    } as R;
  }
}
