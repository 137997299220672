import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Exclusion, Kintin } from "@kinherit/sdk";

interface CreateKintinExclusionMessage {
  exclusion: Exclusion;
  kintin: Kintin;
}

interface CreateKintinExclusionResponse {
  exclusion: Exclusion;
}

export class CreateKintinExclusionHandler extends ActionBase {
  constructor(private message: CreateKintinExclusionMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinExclusionResponse> {
    const { exclusion: exclusionData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-exclusion/{kintin}/exclusion",
      {
        kintin: this.message.kintin.id,
      },
    )
      .method("post")

      .body({
        kintin: this.message.kintin.id,
        excludedBy: this.message.exclusion.$data.excludedBy,
        relationship: this.message.exclusion.$data.relationship,
        who: this.message.exclusion.$data.who,
        why: this.message.exclusion.$data.why,
      })
      .result();

    const exclusion = Exclusion.$inflate(exclusionData).first();

    if (!exclusion) {
      throw new Error("Failed to create exclusion");
    }

    this.message.exclusion.$delete();

    return { exclusion };
  }
}
