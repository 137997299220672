import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin } from "@kinherit/sdk/index";

export class SwitchAccountHoldersHandler extends ActionBase {
  constructor(
    private data: {
      kintin: Kintin;
    },
  ) {
    super();
  }

  async execute() {
    await Api.resource("portal", "/v2/portal/kintin/switch-account-holders")
      .method("put")
      .body({
        kintin: this.data.kintin.id,
      })
      .result();
  }
}
