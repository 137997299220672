import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory, QueryMask } from "@kinherit/sdk";

export interface ReadKnowledgeBaseCategoryMessage {
  query?: QueryMask<typeof KnowledgeBaseCategory>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadKnowledgeBaseCategoryResponse {
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
}

export class ReadKnowledgeBaseCategoryHandler extends ActionBase {
  constructor(private message: ReadKnowledgeBaseCategoryMessage) {
    super();
  }

  public async execute(): Promise<ReadKnowledgeBaseCategoryResponse> {
    const request = Api.resource(
      "core",
      "/v2/core/select/knowledge-base-category",
    )
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(KnowledgeBaseCategory).where(this.message.query ?? {});

    const response = await request.result();

    return {
      knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
        response.knowledgeBaseCategory,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
