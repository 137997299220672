import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  BrandedKinvault,
  IntroducerBillingContact,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
  Profile,
  User,
} from "@kinherit/sdk";

export interface UpdateLeadProfileMessage {
  lead: Lead;
}

export interface UpdateLeadProfileResponse {
  lead: Lead;
}

export interface UpdateBrandedKinvaultProfileMessage {
  brandedKinvault: BrandedKinvault;
}

export interface UpdateBrandedKinvaultProfileResponse {
  brandedKinvault: BrandedKinvault;
}

export interface UpdateUserProfileMessage {
  user: User;
}

export interface UpdateUserProfileResponse {
  user: User;
}

export interface UpdateIntroducerCompanyProfileMessage {
  introducerCompany: IntroducerCompany;
}

export interface UpdateIntroducerCompanyProfileResponse {
  introducerCompany: IntroducerCompany;
}

export interface UpdateIntroducerContactProfileMessage {
  introducerContact: IntroducerContact;
}

export interface UpdateIntroducerContactProfileResponse {
  introducerContact: IntroducerContact;
}

export interface UpdateKintinPersonProfileMessage {
  person: Person;
  kintin: Kintin;
}

export interface UpdateKintinPersonProfileResponse {
  person: Person;
}

export interface UpdateOfficerProfileMessage {
  officer: Person;
}

export interface UpdateOfficerProfileResponse {
  officer: Person;
}

export interface UpdateIntroducerBillingContactProfileMessage {
  introducerCompany: IntroducerCompany;
  introducerBillingContact: IntroducerBillingContact;
}

export interface UpdateIntroducerBillingContactProfileResponse {
  introducerBillingContact: IntroducerBillingContact;
}

type Message =
  | UpdateLeadProfileMessage
  | UpdateBrandedKinvaultProfileMessage
  | UpdateUserProfileMessage
  | UpdateIntroducerCompanyProfileMessage
  | UpdateIntroducerContactProfileMessage
  | UpdateKintinPersonProfileMessage
  | UpdateOfficerProfileMessage
  | UpdateIntroducerBillingContactProfileMessage;
type Response =
  | UpdateLeadProfileResponse
  | UpdateBrandedKinvaultProfileResponse
  | UpdateUserProfileResponse
  | UpdateIntroducerCompanyProfileResponse
  | UpdateIntroducerContactProfileResponse
  | UpdateKintinPersonProfileResponse
  | UpdateOfficerProfileResponse
  | UpdateIntroducerBillingContactProfileResponse;

type JsName =
  | "lead"
  | "brandedKinvault"
  | "proPartner"
  | "user"
  | "introducerCompany"
  | "introducerContact"
  | "person"
  | "officer"
  | "trustReg"
  | "introducerBillingContact";
type UrlPath =
  | "lead"
  | "branded-kinvault"
  | "pro-partner"
  | "user"
  | "introducer-company"
  | "introducer-contact"
  | "kintin"
  | "officer"
  | "trust-reg"
  | "introducer-billing-contact";
type RecordPath =
  | "lead/record"
  | "admin/branded-kinvault/branded-kinvault/record"
  | "admin/pro-partner/record"
  | "admin/user/record"
  | "introducer/company/record"
  | "introducer/contact/record"
  | "kinvault/kintin/person/record"
  | "kinvault/officer/record"
  | "trust-reg/record"
  | "introducer/company/billing-contacts/record";

export class UpdateProfileHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    let jsName: JsName;
    let urlPath: UrlPath;
    let recordPath: RecordPath;
    let params: any;

    if ("lead" in this.message) {
      jsName = "lead";
      urlPath = "lead";
      recordPath = "lead/record";
      params = {
        lead: this.message.lead.id,
        profile: this.message.lead.$data.profile,
      };
    } else if ("brandedKinvault" in this.message) {
      jsName = "brandedKinvault";
      urlPath = "branded-kinvault";
      recordPath = "admin/branded-kinvault/branded-kinvault/record";
      params = {
        brandedKinvault: this.message.brandedKinvault.id,
        profile: this.message.brandedKinvault.$data.profile,
      };
    } else if ("user" in this.message) {
      jsName = "user";
      urlPath = "user";
      recordPath = "admin/user/record";
      params = {
        user: this.message.user.id,
        profile: this.message.user.$data.profile,
      };
    } else if (
      "introducerCompany" in this.message &&
      !("introducerBillingContact" in this.message)
    ) {
      jsName = "introducerCompany";
      urlPath = "introducer-company";
      recordPath = "introducer/company/record";
      params = {
        introducerCompany: this.message.introducerCompany.id,
        profile: this.message.introducerCompany.$data.profile,
      };
    } else if ("introducerContact" in this.message) {
      jsName = "introducerContact";
      urlPath = "introducer-contact";
      recordPath = "introducer/contact/record";
      params = {
        introducerContact: this.message.introducerContact.id,
        profile: this.message.introducerContact.$data.profile,
      };
    } else if ("person" in this.message) {
      jsName = "person";
      urlPath = "kintin";
      recordPath = "kinvault/kintin/person/record";
      params = {
        person: this.message.person.id,
        kintin: this.message.kintin.id,
        profile: this.message.person.$data.profile,
      };
    } else if ("officer" in this.message) {
      jsName = "officer";
      urlPath = "officer";
      recordPath = "kinvault/officer/record";
      params = {
        officer: this.message.officer.id,
        profile: this.message.officer.$data.profile,
      };
    } else if ("introducerBillingContact" in this.message) {
      jsName = "introducerBillingContact";
      urlPath = "introducer-billing-contact";
      recordPath = "introducer/company/billing-contacts/record";
      params = {
        introducerBillingContact: this.message.introducerBillingContact.id,
        profile: this.message.introducerBillingContact.$data.profile,
      };
    } else {
      throw new Error("Invalid message");
    }

    // @ts-ignore
    const profile = this.message[jsName].profile as Profile;

    await Api.resource(
      "portal",
      `/v2/portal/${urlPath}/{${jsName}}/profile/{profile}/replace` as `/v2/portal/lead/{lead}/profile/{profile}/replace`,
      params,
    )
      .method("put")

      .body({
        title: profile.title?.id,
        firstName: profile.firstName,
        middleNames: profile.middleNames,
        lastName: profile.lastName,
        knownAs: profile.knownAs,
        newsletterCampaigns: profile.newsletterCampaigns,
        suffix: profile.suffix,
        organisationName: profile.organisationName,
        organisationNumber: profile.organisationNumber,
        jobTitle: profile.jobTitle,
        addresses: profile.addresses.map((address) => ({
          id: address.id,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          country: address.country,
          state: address.state,
          postcode: address.postcode,
          primaryMailing: address.primaryMailing,
          primaryResidential: address.primaryResidential,
        })),
        emails: profile.emails.map((email) => ({
          id: email.id,
          email: email.email,
          primary: email.primary,
        })),
        phoneNumbers: profile.phoneNumbers.map((phoneNumber) => ({
          id: phoneNumber.id,
          tel: phoneNumber.tel,
          primary: phoneNumber.primary,
        })),
        websites: profile.websites.map((website) => ({
          id: website.id,
          url: website.url,
        })),
      })
      .result();

    await window.Kernel.ActionBus.execute(recordPath, this.message as any);

    profile.$restore({
      addresses: true,
      emails: true,
      phoneNumbers: true,
      websites: true,
    });

    if ("lead" in this.message) {
      return { lead: Lead.$findOneOrThrow(this.message.lead.id) } as R;
    } else if ("brandedKinvault" in this.message) {
      return {
        brandedKinvault: BrandedKinvault.$findOneOrThrow(
          this.message.brandedKinvault.id,
        ),
      } as R;
    } else if ("user" in this.message) {
      return { user: User.$findOneOrThrow(this.message.user.id) } as R;
    } else if ("introducerCompany" in this.message) {
      return {
        introducerCompany: IntroducerCompany.$findOneOrThrow(
          this.message.introducerCompany.id,
        ),
      } as R;
    } else if ("introducerContact" in this.message) {
      return {
        introducerContact: IntroducerContact.$findOneOrThrow(
          this.message.introducerContact.id,
        ),
      } as R;
    } else if ("person" in this.message) {
      return {
        person: Person.$findOneOrThrow(this.message.person.id),
      } as R;
    } else if ("officer" in this.message) {
      return {
        officer: Person.$findOneOrThrow(this.message.officer.id),
      } as R;
    }

    throw new Error("Invalid message");
  }
}
