<template>
  <div class="page-3 columns">
    <div class="page column" :key="key">
      <div class="grey">
        <h1>3. My recommendations</h1>
        <p class="lead">
          These are all the products I recommend for your situation, to give you
          the best level of protection. We'll talk through each of them on the
          follow up call, so you can make a fully informed decision on what you
          want to do.
        </p>
      </div>

      <div class="white">
        <h2>My recommendations for you are:</h2>
        <table class="table">
          <tbody>
            <tr v-if="checkIsYes('5.3.0')">
              <td>
                Will &amp; Kinvault
                <span v-if="type === 'joint'"> for a couple</span>
              </td>
              <td>
                <span v-if="type === 'single'"
                  >&pound;{{ prices.singleWill }}</span
                ><span v-else>&pound;{{ prices.mirrorWill }}</span>
              </td>
              <td>(basic protection + planning)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.4')">
              <td>Estate Protection Trust*</td>
              <td>
                <span v-if="type === 'single'"
                  >&pound;{{ prices.singleEPT }}</span
                ><span v-else>&pound;{{ prices.mirrorEPT }}</span>
              </td>
              <td>(most powerful protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.5')">
              <td>Deed of Severance</td>
              <td>£{{ prices.dos }}</td>
              <td>(family home protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.6')">
              <td>Property Conveyance</td>
              <td>£{{ prices.conveyance }}</td>
              <td>(family home protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.7')">
              <td>Property Preservation Trust</td>
              <td>£{{ prices.ppt }}</td>
              <td>(family home protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.8')">
              <td>Business Clause or Trust</td>
              <td>from £{{ prices.businessClause }}</td>
              <td>(powerful business protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.11')">
              <td>Physical Will & Document Storage</td>
              <td>£{{ prices.docStorage }} a year</td>
              <td>(keep key documents secure)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.1') || checkIsYes('5.3.2')">
              <td>Lasting Powers of Attorney (Health)</td>
              <td>
                £{{ prices.lpaSingle }}
                <span v-if="type === 'joint'">per person</span>
              </td>
              <td>(incapacitation protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.1') || checkIsYes('5.3.3')">
              <td>Lasting Powers of Attorney (Finance)</td>
              <td>
                £{{ prices.lpaSingle }}
                <span v-if="type === 'joint'">per person</span>
              </td>
              <td>(incapacitation protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.9')">
              <td>Lasting Powers of Attorney (Business)</td>
              <td>£{{ prices.lpaSingle }}</td>
              <td>(incapacitation protection)</td>
            </tr>
            <tr v-if="checkIsYes('5.3.10')">
              <td>Property, Lifetime &amp; Gift Trust</td>
              <td>£{{ prices.giftTrust }}</td>
              <td>(inheritance tax optimisation)</td>
            </tr>
          </tbody>
        </table>
        <p v-if="checkIsYes('5.3.4')">
          <small
            >* As this is the most expensive product I've included a comparison
            sheet at the end showing the additional protections an Estate
            Protection Trust brings compared to a simple Will.</small
          >
        </p>
      </div>
      <div class="white">
        <div v-if="checkIsYes('5.3.0')" class="product-description">
          <h4>Kinherit Will &amp; Kinvault (our standard Will package)</h4>
          <p>
            <strong
              ><span v-if="type === 'single'"
                >&pound;{{ prices.singleWill }} for one person (inc VAT)</span
              ><span v-else
                >&pound;{{ prices.mirrorWill }} for a couple (inc VAT)</span
              ></strong
            ><br />This is our standard Will package, but it&rsquo;s a bit
            different to everyone else&rsquo;s. We give you a carefully
            considered, personalised Will (rather than an off-the-shelf option),
            which makes sure all the right people are named and covers
            everything you need. But the other key thing about our Wills is that
            they include your own online planning platform - our Kinvault. This
            helps you gather together all your life admin in one central place,
            which gives your executors and family exactly what they need to
            carry out your wishes. It ensures nothing is missed and that you
            won&rsquo;t have to pay extra fees to untangle things later on.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.4')" class="product-description">
          <h4>Estate Protection Trust</h4>
          <p>
            <strong
              ><span v-if="type === 'single'"
                >&pound;{{ prices.singleEPT }} for one person (inc VAT)</span
              ><span v-else
                >&pound;{{ prices.mirrorEPT }} for a couple (inc VAT)</span
              ></strong
            ><br />Putting a trust in place gives you much more control over
            what happens to your estate, and in particular what you
            <em>don&rsquo;t</em> want to happen – loss to divorces, double
            inheritance tax, re-marriage threats, etc. Effectively you ringfence
            the money, so it's not actually part of the beneficiary's estate,
            but they can still use it. It helps prevent others from accessing it
            (ex-spouses and HMRC for example), and can continue to be used by
            future generations for up to 125 years. It's also useful if your
            situation is more complex as it's much more flexible than a simple
            Will. We can help set this up in a way that's straightforward, so
            it's easy for your family to manage.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.5')" class="product-description">
          <h4>Deed of Severance</h4>
          <p>
            <strong>&pound;{{ prices.dos }} (inc VAT)</strong><br />There are
            many reasons for converting jointly-owned property to ‘tenants in
            common&rsquo; ownership, including that it gives strong protection
            against the property being force-sold to pay for care fees should
            one of the owners require it. We strongly recommend that couples
            instruct solicitiors at the time of purchasing a property to do so
            on a “tenants in common” basis.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.6')" class="product-description">
          <h4>Conveyance property to tenants in common</h4>
          <p>
            <strong
              >&pound;{{ prices.conveyance }} to convert a property from sole
              ownership to “tenants in common” ownership</strong
            ><br />There are many reasons for converting solely owned property
            to ‘tenants in common&rsquo; ownership, including that it gives
            strong protection against the property being force-sold to pay for
            care fees should the owner require it.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.7')" class="product-description">
          <h4>Property Preservation Trust</h4>
          <p>
            <strong
              >&pound;{{ prices.ppt }} to put a property into trust </strong
            ><br />There are many reasons for putting a property into trust,
            including that it gives strong protection against the property being
            force-sold to pay for care fees should you be required to pay them.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.8')" class="product-description">
          <h4>Business Clause / Business Trust</h4>
          <p>
            <strong
              >Business Clause &pound;{{ prices.businessClause }} / Business
              Trust &pound;{{ prices.businessTrust }}</strong
            ><br />If you own business assets that qualify for business relief,
            (e.g. most shares in private businesses and some public shares),
            it's important to separately identify these using a specific clause
            in your Will, if you want to claim the maximum tax relief available.
            If this isn't done, you can lose out on tax savings. A business
            trust takes things a step further and allows your beneficiaries more
            flexibility and control over what they do with these assets. It
            helps protect against future taxation for both immediate
            beneficiaries and future generations.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.9')" class="product-description">
          <h4>Lasting Power of Attorney Finance for Business assets</h4>
          <p>
            <strong
              >&pound;{{ prices.lpaSingle }} per power (inc VAT) plus
              application fee*</strong
            ><br />LPAs for businesses – these are literally just called
            Business LPA (it is essentially a Property &amp; Financial Affairs
            LPA which solely refers to the business). These are only needed if
            you need different attorneys for your business affairs compared to
            your personal affairs.
          </p>
          <p class="smaller">
            * If you choose to register your Power upfront with the government
            body known as the Office of the Public Guardian (OPG), then they
            charge a seperate registration fee of &pound;82 per power
            registered.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.10')" class="product-description">
          <h4>Property, Lifetime &amp; Gift Trust</h4>
          <p>
            <strong>from &pound;{{ prices.giftTrust }}</strong
            ><br />Set up specific trusts to make your estate more tax efficient
            and ensure things are managed exactly as you would wish.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.1')" class="product-description">
          <h4>Lasting Power of Attorney (Health and Finance)</h4>
          <p>
            <strong
              >&pound;{{ prices.lpaDouble }} per person (for both Powers, inc
              VAT) plus OPG fee*</strong
            ><br />We advise all our customers who don&rsquo;t have these in
            place to seriously consider adding them, as a key part of their end
            of life planning. If you reach a point where you can&rsquo;t make
            your own decisions on your welfare, you&rsquo;ll want someone you
            trust to take control. And if you're unable to manage your financial
            affairs, you need someone you trust to manage them for you without
            resorting to court orders. Not getting this sorted can have very
            distressing consequences for both you and your family, but it
            won&rsquo;t happen unless you arrange it in advance.
          </p>
          <p class="smaller">
            * If you choose to register your Power upfront with the government
            body known as the Office of the Public Guardian (OPG), then they
            charge a seperate registration fee of &pound;82 per power
            registered.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.2')" class="product-description">
          <h4>Lasting Power of Attorney (Health)</h4>
          <p>
            <strong
              >&pound;{{ prices.lpaSingle }} per person (inc VAT) plus OPG
              fee*</strong
            ><br />We advise all our customers who don&rsquo;t have this in
            place to seriously consider adding it to their standard Will
            package. It will allow you to choose someone you trust to make your
            welfare decisions for you, instead of responsibility defaulting to
            your local authority. Not getting this sorted can have very
            distressing consequences for both you and your family.
          </p>
          <p class="smaller">
            * If you choose to register your Power upfront with the government
            body known as the Office of the Public Guardian (OPG), then they
            charge a seperate registration fee of &pound;82 per power
            registered.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.3')" class="product-description">
          <h4>Lasting Power of Attorney (Finance)</h4>
          <p>
            <strong
              >&pound;{{ prices.lpaSingle }} per person (inc VAT) plus OPG
              fee*</strong
            ><br />We advise all our customers who don&rsquo;t have this in
            place to seriously consider adding it to their standard Will
            package. It will allow you to choose someone you trust to make
            financial decisions for you, instead of them needing to obtain court
            orders. Not getting this sorted can have very distressing
            consequences for both you and your family.
          </p>
          <p class="smaller">
            * If you choose to register your Power upfront with the government
            body known as the Office of the Public Guardian (OPG), then they
            charge a seperate registration fee of &pound;82 per power
            registered.
          </p>
        </div>

        <div v-if="checkIsYes('5.3.11')" class="product-description">
          <h4>Physical document storage</h4>
          <p>
            <strong>&pound;{{ prices.docStorage }} per year (inc VAT)</strong
            ><br />Although your Kinvault will hold digital copies, it is
            essential that you securely store all of your original, signed,
            paper legal documents. Your Trusted People need to locate them
            promptly when the time comes, as these originals are required (for
            example, when obtaining probate or for making decisions as an
            attorney). We offer a physical document storage option in our secure
            storage facility at an additional cost payable by annual direct
            debit. With our Secure Physical Document Storage you’ll have peace
            of mind that your documents are available to your trusted people
            when they need them. You’ll also benefit from an extension to our
            annual complimentary rewrite of your Will and Trusts for the
            duration in which you are utilising our Secure Physical Document
            Storage.
          </p>
        </div>
      </div>
    </div>
    <div
      class="help-content box column"
      v-if="kintin"
      style="height: fit-content"
    >
      <!--@todo-->
      <!--Button
        @click="resetRecommendations"
        :icon-left="ThemeIconName.Reset"
        class="is-small is-pulled-right"
      >
        Reset
      </Button-->
      <ConfirmCheck
        @reload="key++"
        label="Kinherit Will & Kinvault"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.0'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Lasting Power of Attorney (Both)"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.1'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Lasting Power of Attorney (Health)"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.2'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Lasting Power of Attorney (Finance)"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.3'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Estate Protection Trust"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.4'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Deed of Severance"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.5'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Conveyance property to tenants in common"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.6'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Property Preservation Trust"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.7'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Dealing with your Business Assets: Clause or Trust"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.8'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Lasting Power of Attorney Finance for Business assets"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.9'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Property, Lifetime & Gift Trust"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.10'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
      <ConfirmCheck
        @reload="key++"
        label="Physical document storage"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.3.11'"
        @update="updateCheckValue"
        :checks="checks"
        :kintin-checks="kintinChecks"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { Product } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { ClientSummaryMixin } from "../../../mixin/client-summary.mixin";
import ConfirmCheck from "../shared/ConfirmCheck.vue";

export default defineComponent({
  name: "ClientSummary.Page3",
  mixins: [ClientSummaryMixin, KinvaultKintinDetailsCallScriptMixin],
  components: { ConfirmCheck },
  data: () => ({
    ThemeIconName,
    key: 0,
    //@todo get these off product fixtures
    // prices: {
    //   singleWill: 345,
    //   mirrorWill: 495,
    //   singleEPT: 1250,
    //   mirrorEPT: 1650,
    //   dos: 215,
    //   conveyance: 995,
    //   ppt: 4500,
    //   businessClause: 400,
    //   businessTrust: 800,
    //   lpaSingle: 195,
    //   lpaDouble: 390,
    //   giftTrust: 1495,
    //   docStorage: 50,
    // },
    products: [] as Product[],
  }),
  async mounted() {
    const { products } = await window.Kernel.ActionBus.execute(
      "core/select/product/read",
      {
        pagination: false,
      },
    );

    this.products = products;
  },
  computed: {
    prices(): {
      singleWill: number;
      mirrorWill: number;
      singleEPT: number;
      mirrorEPT: number;
      dos: number;
      conveyance: number;
      ppt: number;
      businessClause: number;
      businessTrust: number;
      lpaSingle: number;
      lpaDouble: number;
      giftTrust: number;
      docStorage: number;
    } {
      return {
        singleWill:
          this.products
            .filterBy("value", "standardwill")
            .findBy("type", "single")?.price.amount ?? 0,
        mirrorWill:
          this.products
            .filterBy("value", "standardwill")
            .findBy("type", "joint")?.price.amount ?? 0,
        singleEPT:
          this.products
            .filterBy("value", "assetprotection")
            .findBy("type", "single")?.price.amount ?? 0,
        mirrorEPT:
          this.products
            .filterBy("value", "assetprotection")
            .findBy("type", "joint")?.price.amount ?? 0,
        dos: this.products.findBy("value", "dos")?.price.amount ?? 0,
        conveyance:
          this.products.findBy("value", "conveyancing")?.price.amount ?? 0,
        ppt: this.products.findBy("value", "pptrust")?.price.amount ?? 0,
        businessClause:
          this.products.findBy("value", "businessclauses")?.price.amount ?? 0,
        businessTrust:
          this.products.findBy("value", "businesstrust")?.price.amount ?? 0,
        lpaSingle:
          this.products.filterBy("value", "lpahw").findBy("type", "single")
            ?.price.amount ?? 0,
        lpaDouble:
          this.products.filterBy("value", "lpahw").findBy("type", "joint")
            ?.price.amount ?? 0,
        giftTrust:
          this.products.findBy("value", "gifttrust")?.price.amount ?? 0,
        docStorage:
          this.products.findBy("value", "docstorage")?.price.amount ?? 0,
      };
    },
  },
  methods: {
    resetRecommendations() {},
  },
});
</script>
