import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin } from "@kinherit/sdk";

export interface UpdateKintinMessage {
  kintin: Kintin;
}

interface UpdateKintinResponse {
  kintin: Kintin;
}

export class UpdateKintinHandler extends ActionBase {
  constructor(private message: UpdateKintinMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}",
      {
        kintin: this.message.kintin,
      },
    )
      .method("patch")

      .body({
        whyEol: this.message.kintin.$data.whyEol,
        legalAssistants: this.message.kintin.$data.legalAssistants,
        estatePlanners: this.message.kintin.$data.estatePlanners,
        statusUpdatedAt: this.message.kintin.$data.statusUpdatedAt,
        statusChangeReason: this.message.kintin.$data.statusChangeReason,
        externalLeadId: this.message.kintin.$data.externalLeadId,
        primaryPerson: this.message.kintin.$data.primaryPerson,
        secondaryPerson: this.message.kintin.$data.secondaryPerson,
        type: this.message.kintin.$data.type,
        status: this.message.kintin.$data.status,
        callScript: this.message.kintin.$data.callScript,
        permissions: this.message.kintin.$data.permissions,
        sourceKintin: this.message.kintin.$data.sourceKintin,
        process: this.message.kintin.$data.process,
        stage: this.message.kintin.$data.stage,
        referral: {
          nextActionAt: this.message.kintin.referral?.$data.nextActionAt,
        },
        isActive: this.message.kintin.$data.isActive,
        isEscalated: this.message.kintin.$data.isEscalated,
      })
      .result();

    const kintin = Kintin.$inflate(response.kintin).first();

    if (!kintin) {
      throw new Error("Failed to update kintin");
    }

    return {
      kintin,
    };
  }
}
