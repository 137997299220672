import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  EmailAddress,
  IntroducerContact,
  PhoneNumber,
  Profile,
} from "@kinherit/sdk";

interface CreateIntroducerContactMessage {
  introducerContact: IntroducerContact;
}

interface CreateIntroducerContactResponse {
  introducerContact: IntroducerContact;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
}

export class CreateIntroducerContactHandler extends ActionBase {
  constructor(private message: CreateIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerContactResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact",
    )
      .method("post")
      .body({
        companies: this.message.introducerContact.companies.pluck("id"),
        newsLetter: null,
        status: this.message.introducerContact.$data.status,
        callStatus: this.message.introducerContact.$data.callStatus,
        profile: {
          jobTitle: this.message.introducerContact.profile.$data.jobTitle,
          firstName: this.message.introducerContact.profile.$data.firstName,
          lastName: this.message.introducerContact.profile.$data.lastName,
          emails: this.message.introducerContact.profile.emails.map(
            (email) => ({
              email: email.$data.email,
            }),
          ),
          phoneNumbers: this.message.introducerContact.profile.phoneNumbers.map(
            (phoneNumber) => ({
              tel: phoneNumber.$data.tel,
            }),
          ),
        },
      })

      .result();

    const introducerContact = IntroducerContact.$inflate(
      response.introducerContact,
    ).first();

    if (!introducerContact) {
      throw new Error("Failed to create user");
    }

    this.message.introducerContact.$delete({
      profile: {
        addresses: true,
        emails: true,
        phoneNumbers: true,
        websites: true,
      },
    });

    return {
      introducerContact,
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    };
  }
}
