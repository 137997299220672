import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Equal } from "@kinherit/orm";
import { CpdSession, IntroducerCpd } from "@kinherit/sdk";

interface DownloadIntroducerCpdCsvMessage {
  session: CpdSession;
}

export class DownloadIntroducerCpdCsvHandler extends ActionBase {
  constructor(private message: DownloadIntroducerCpdCsvMessage) {
    super();
  }

  public async execute(): Promise<void> {
    const request = Api.resource(
      "portal",
      "/v2/portal/cpd-session/introducer-cpd/csv",
    )
      .method("get")

      .paginate({
        currentPage: 1,
        perPage: 1000,
      });

    request.buildQuery(IntroducerCpd).where({
      session: {
        id: Equal(this.message.session.id),
      },
    });

    await request.download({
      fileName: "introducer-cpd.csv",
      encoding: "text/csv",
    });
  }
}
