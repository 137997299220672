import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Person, User } from "@kinherit/sdk";
export type DownloadLpaFinancialMessage = {
  kintin: Kintin;
  person: Person;
  user: User;
};

export class DownloadLpaFinancialAction extends ActionBase {
  constructor(private message: DownloadLpaFinancialMessage) {
    super();
  }

  async execute(): Promise<void> {
    const { person, user, kintin } = this.message;

    const primaryPerson = kintin.primaryPerson;
    const secondaryPerson = kintin.secondaryPerson;

    const surname = [
      primaryPerson.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/person/{person}/specialist/{user}/lpa-financial",
      {
        person: person,
        user: user,
      },
    )
      .method("get")
      .params({
        kintin: kintin.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: ` ${kintin.ref} LPA Financial - ${surname}`,
      });
  }
}
