import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

type Response = {
  emailLog: EmailLog;
};

export class CreateEmailLogHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log",
      [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/email-log",
      [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log",
      [Person.$name]: "/v2/portal/officer-email-log/{owner}/email-log",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log",
    } as const;

    const route = routes[this.message.owner.$name];

    const owner = this.message.owner;

    const request = Api.resource("portal", route, {
      owner: this.message.owner,
    })
      .method("post")

      .body({
        template: this.message.emailLog.$data.template,
        status: this.message.emailLog.$data.status,
        data: this.message.emailLog.$data.data,
        preloadedFiles: this.message.emailLog.$data.preloadedFiles,
        cc: this.message.emailLog.$data.cc,
        bcc: this.message.emailLog.$data.bcc,
        to: this.message.emailLog.$data.to,
        from: this.message.emailLog.$data.from,
        html: this.message.emailLog.$data.html,
        markdown: this.message.emailLog.$data.markdown,
        title: this.message.emailLog.$data.title,
        subject: this.message.emailLog.$data.subject,
        sentAt: this.message.emailLog.$data.sentAt,
        introducerCompany: owner instanceof IntroducerCompany ? owner.id : null,
        introducerContact: owner instanceof IntroducerContact ? owner.id : null,
        lead: owner instanceof Lead ? owner.id : null,
        kintin: owner instanceof Kintin ? owner.id : null,
        person: owner instanceof Person ? owner.id : null,
        brandedKinvault: owner instanceof BrandedKinvault ? owner.id : null,
      });

    const response = await request.result();

    const emailLog = EmailLog.$inflate(response.emailLog).first();

    if (!emailLog) {
      throw new Error("Failed to create email log");
    }

    await window.Kernel.ActionBus.execute("core/email-log/attachment/upload", {
      owner: this.message.owner,
      emailLog,
      files: this.message.files,
    });

    await window.Kernel.ActionBus.execute(
      "core/email-log/named-attachment/create",
      {
        owner: this.message.owner,
        emailLog,
        files: this.message.requiredFiles,
      },
    );

    this.message.emailLog.$delete();

    return {
      emailLog,
    } as R;
  }
}
