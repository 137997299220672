import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IProduct, Product, QueryMask } from "@kinherit/sdk";

export interface ReadProductMessage {
  query?: QueryMask<typeof Product>;
  sort?: {
    by: keyof IProduct;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadProductResponse {
  products: Array<Product>;
}

export class ReadProductHandler extends ActionBase {
  constructor(private message: ReadProductMessage) {
    super();
  }

  public async execute(): Promise<ReadProductResponse> {
    const request = Api.resource("core", "/v2/core/select/product")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "publicText",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(Product).where(this.message.query ?? {});

    const response = await request.result();

    return {
      products: Product.$inflate(
        response.product,
        this.message.sort,
        response.$rootIds,
      ),
    };
  }
}
