import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerBillingContact, IntroducerCompany } from "@kinherit/sdk";

export interface UpdateIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface UpdateIntroducerBillingContactResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
}

export class UpdateIntroducerBillingContactHandler extends ActionBase {
  constructor(private message: UpdateIntroducerBillingContactMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerBillingContactResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts/{introducerBillingContact}",
      {
        introducerCompany: this.message.introducerCompany,
        introducerBillingContact: this.message.introducerBillingContact,
      },
    )
      .method("patch")

      .body({
        agentRemittanceType:
          this.message.introducerBillingContact.$data.agentRemittanceType,
        company: this.message.introducerBillingContact.$data.company,
        introducerRemittanceType:
          this.message.introducerBillingContact.$data.introducerRemittanceType,
        notes: this.message.introducerBillingContact.$data.notes,
      });

    const response = await request.result();

    return {
      introducerBillingContacts: IntroducerBillingContact.$inflate(
        response.introducerBillingContact,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
