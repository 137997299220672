import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  FileLog,
  IUploadForm,
  Kintin,
  SignDoc,
  UploadForm,
} from "@kinherit/sdk";

export interface ReadKintinUploadFormsMessage {
  kintin: string | Kintin;
  pinned?: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IUploadForm;
    direction: "asc" | "desc";
  };
}

export interface ReadKintinUploadFormsResponse {
  uploadForms: Array<UploadForm>;
  uploadFormSubmissions: Array<FileLog>;
  signDocs: Array<SignDoc>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKintinUploadFormsHandler extends ActionBase {
  constructor(private message: ReadKintinUploadFormsMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinUploadFormsResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/kintin-upload-form/{kintin}/upload-forms",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
      },
    )
      .method("get")

      .paginate({
        currentPage: this.message.pagination?.currentPage ?? 1,
        perPage: this.message.pagination?.perPage ?? 10,
      })
      .sort(
        this.message.sort
          ? {
              by: this.message.sort.by,
              direction: this.message.sort.direction,
            }
          : undefined,
      );

    const response = await request.result();

    return {
      uploadForms: UploadForm.$inflate(
        response.uploadForm,
        this.message.sort,
        response.$rootIds,
      ),
      uploadFormSubmissions: FileLog.$inflate(response.fileLog),
      signDocs: SignDoc.$inflate(response.signDoc),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
