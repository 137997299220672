import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Like } from "@kinherit/orm/index";
import {
  AccountReferral,
  AccountReferralCode,
  IAccountReferral,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyAccountReferralsMessage {
  introducerCompany: IntroducerCompany | string;
  name: null | string;
  created: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyAccountReferralsResponse {
  leads: Array<Lead>;
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  introducerContacts: Array<IntroducerContact>;
  referrals: Array<AccountReferral>;
  referralCodes: Array<AccountReferralCode>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerCompanyAccountReferralsHandler extends ActionBase {
  constructor(private message: ReadIntroducerCompanyAccountReferralsMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerCompanyAccountReferralsResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-referral/{introducerCompany}/referrals",
      {
        introducerCompany: this.message.introducerCompany,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request
      .buildQuery(AccountReferral)
      .where({
        kintin: {
          friendlyName: Like(this.message.name),
        },
        createdAt: Between(this.message.created),
      })
      .orWhere({
        lead: {
          profile: {
            fullName: Like(this.message.name),
          },
        },
        createdAt: Between(this.message.created),
      });

    const response = await request.result();

    return {
      referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
      leads: Lead.$inflate(response.lead),
      kintins: Kintin.$inflate(response.kintin),
      profiles: Profile.$inflate(response.profile),
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
      ),
      referrals: AccountReferral.$inflate(
        response.accountReferral,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
