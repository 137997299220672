import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerNetwork } from "@kinherit/sdk";

export interface DeleteIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork | string;
}

interface DeleteIntroducerNetworkResponse {
  [key: string]: never;
}

export class DeleteIntroducerNetworkHandler extends ActionBase {
  constructor(private message: DeleteIntroducerNetworkMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerNetworkResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-network/{introducerNetwork}",
      {
        introducerNetwork: this.message.introducerNetwork,
      },
    )
      .method("delete")

      .result();

    IntroducerNetwork.$delete(this.message.introducerNetwork);

    return {};
  }
}
