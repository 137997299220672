import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseDocument } from "@kinherit/sdk";

interface DeleteKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface DeleteKnowledgeBaseDocumentResponse {
  [key: string]: never;
}

export class DeleteKnowledgeBaseDocumentHandler extends ActionBase {
  constructor(private message: DeleteKnowledgeBaseDocumentMessage) {
    super();
  }

  public async execute(): Promise<DeleteKnowledgeBaseDocumentResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
      {
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
      },
    )
      .method("delete")
      .result();

    return {};
  }
}
