import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultMembership } from "@kinherit/sdk";

interface UpdateBrandedKinvaultMembershipMessage {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

interface UpdateBrandedKinvaultMembershipResponse {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

export class UpdateBrandedKinvaultMembershipHandler extends ActionBase {
  constructor(private message: UpdateBrandedKinvaultMembershipMessage) {
    super();
  }

  public async execute(): Promise<UpdateBrandedKinvaultMembershipResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-membership/{brandedKinvaultMembership}",
      this.message,
    ).method("patch");
    request.body({
      allowAccess: this.message.brandedKinvaultMembership.$data.allowAccess,
      brandedKinvault:
        this.message.brandedKinvaultMembership.$data.brandedKinvault,
      user: this.message.brandedKinvaultMembership.$data.user,
    });

    const result = await request.result();

    const brandedKinvaultMembership = BrandedKinvaultMembership.$inflate(
      result.brandedKinvaultMembership,
    ).first();

    if (!brandedKinvaultMembership) {
      throw new Error("Failed to update branded kinvault membership");
    }

    return {
      brandedKinvaultMembership,
    };
  }
}
