import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin } from "@kinherit/sdk";

export interface DownloadStageEClosingLetterMessage {
  kintin: Kintin;
}

export class DownloadStageEClosingLetterHandler extends ActionBase {
  constructor(private message: DownloadStageEClosingLetterMessage) {
    super();
  }

  public async execute() {
    const primaryPerson = this.message.kintin.primaryPerson;
    const secondaryPerson = this.message.kintin.secondaryPerson;

    const surname = [
      primaryPerson.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/stage-e-closing-letter",
    )
      .method("get")
      .params({
        ...this.message,
        kintin: this.message.kintin.id,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${this.message.kintin.ref} Stage E Closing Letter - ${surname}`,
      });
  }
}
