import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface DeleteDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface DeleteDefaultBrandedKinvaultReferralCodeResponse {
  [key: string]: never;
}

export class DeleteDefaultBrandedKinvaultReferralCodeHandler extends ActionBase {
  constructor(
    private message: DeleteDefaultBrandedKinvaultReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<DeleteDefaultBrandedKinvaultReferralCodeResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
      {
        defaultBrandedKinvaultReferralCode:
          this.message.defaultBrandedKinvaultReferralCode,
      },
    )
      .method("delete")

      .result();

    this.message.defaultBrandedKinvaultReferralCode.$delete();

    return {};
  }
}
