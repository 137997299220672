import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";

export interface CreateEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface CreateEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export class CreateEmailCampaignHandler extends ActionBase {
  constructor(private message: CreateEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<CreateEmailCampaignResponse> {
    const request = Api.resource("portal", "/v2/portal/email-campaign", {})
      .method("post")

      .body({
        description: this.message.emailCampaign.$data.description,
        name: this.message.emailCampaign.$data.name,
      });

    const response = await request.result();

    this.message.emailCampaign.$delete();

    return {
      emailCampaign: EmailCampaign.$inflate(
        response.emailCampaign,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
