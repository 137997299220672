import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, EmailCampaignLog, FileLog } from "@kinherit/sdk";
type Message = {
  emailCampaignLog: EmailCampaignLog;
  fileLog: FileLog | string;
};

type Response = {};

export class DeleteEmailCampaignLogAttachmentHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign-log/{emailCampaignLog}/attachment/{fileLog}",
      this.message,
    )
      .method("delete")
      .result();

    return {} as R;
  }
}
