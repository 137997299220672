<template>
  <div class="executor-list">
    <ExecutorSummary
      v-for="role in executors.sortBy('sortOrder', 'asc')"
      :key="`executor-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!executors.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import ExecutorSummary from "@/module/kinvault.kintin/component/summary-cards/ExecutorSummary.vue";
import { UpdateExecutorForm } from "@/module/kinvault.kintin/form/update-executor.form";
import Card from "@kinherit/framework/component.layout/card";
import { Executor, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExecutorList",
  components: { ExecutorSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    executors: {
      type: Array as () => Executor[],
      required: true,
    },
  },
  methods: {
    async edit(executor: Executor): Promise<void> {
      try {
        await UpdateExecutorForm({
          data: executor,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Executor",
          },
        });
      } catch {
        executor.$restore();
        return;
      }

      const kintin = this.kintin;

      await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
        person: executor.person,
        kintin: this.kintin,
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/executor/update", {
        executor,
        kintin,
      });

      this.$emit("reload");
    },
    async remove(executor: Executor): Promise<void> {
      const kintin = this.kintin;

      await DangerDialog({
        dialog: {
          title: "Delete Executor",
          message: "Are you sure you want to remove this executor?",
        },
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/executor/delete", {
        executor,
        kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
