<template>
  <Card title="Appointments">
    <p>
      Appointments booked by group. Cancelled calls are included in the numbers.
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      :footer="footer"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
      <template #actions="{ row }">
        <Button
          is-compact
          is-outlined
          color="is-plain"
          @click="handleRowClick(row)"
        >
          View
        </Button>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import { defineComponent } from "vue";
import { AppointmentsBreakdownReportRoute, AppointmentsReportRoute } from ".";
import { hasCurrentRowHighlighting } from "../data/helper-functions";

import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { TAppointmentReportResult } from "../action/compute-appointments-report.action";
import { ReadAppointmentsReportFilters } from "../form/read-appointments-report.form";
export default defineComponent({
  name: AppointmentsReportRoute,
  components: {
    Card,
    MasterListFilters,
    Button,
    Table,
  },
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadAppointmentsReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Enquiry",
        class: "has-text-weight-bold has-text-brown",
        map: (results: TAppointmentReportResult) => results.enquiryCount,
      },
      {
        title: "Info",
        class: "has-text-weight-bold has-text-positive",
        map: (results: TAppointmentReportResult) => results.call1Count,
      },
      {
        title: "Option",
        class: "has-text-weight-bold has-text-purple",
        map: (results: TAppointmentReportResult) => results.call2Count,
      },
      {
        title: "KV / Off",
        class: "has-text-weight-bold has-text-teal",
        map: (results: TAppointmentReportResult) =>
          results.kinvaultOfficerCount,
      },
      {
        title: "Will Review",
        map: (results: TAppointmentReportResult) => results.willReviewCount,
      },
      {
        title: "Other",
        map: (results: TAppointmentReportResult) => results.otherCount,
      },
      {
        title: "Cancelled",
        mapHtml: (results: TAppointmentReportResult) => {
          return results.cancelCount
            ? `
          <strong class="has-text-danger">${
            results.cancelCount
          }</strong> <small class="has-text-grey">
            (${((results.cancelCount / results.appointmentCount) * 100).toFixed(
              2,
            )}%)
            </small>`
            : "-";
        },
      },
      {
        title: "Total", // the total number of appointments
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult) => results.appointmentCount,
      },
      {
        title: "",
        slot: "actions",
      },
    ],
    footer: [
      "Total",
      // Enquiry
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.enquiryCount, 0),
      },
      // Call 1
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.call1Count, 0),
      },
      // Call 2
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.call2Count, 0),
      },
      // KV / Off
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce(
            (total, result) => total + result.kinvaultOfficerCount,
            0,
          ),
      },
      // Will Review
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.willReviewCount, 0),
      },
      // Other
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.otherCount, 0),
      },
      // Cancelled
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.cancelCount, 0),
      },
      // Total
      {
        class: "has-text-weight-bold",
        map: (results: TAppointmentReportResult[]) =>
          results.reduce((total, result) => total + result.appointmentCount, 0),
      },
    ],
  }),
  methods: {
    async load() {
      const result = await window.Kernel.ActionBus.execute(
        "report/appointments",
        this.filters.localData,
      );

      this.datasets = result.datasets;
      this.labels = result.labels.map((label) => label.toString());
      this.results = result.results;
    },
    async handleRowClick(row: TAppointmentReportResult) {
      const { granularity } = this.filters.localData;
      const { year, week, month, quarter } = row;
      const period =
        granularity === "week"
          ? week
          : granularity === "month"
            ? month
            : granularity === "quarter"
              ? quarter
              : year;
      window.Kernel.Router.push({
        name: AppointmentsBreakdownReportRoute,
        params: {
          year,
          granularity,
          period,
        },
      });
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>
