import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  Attorney,
  EmailAddress,
  Executor,
  Guardian,
  Kintin,
  Person,
  PhoneNumber,
  Profile,
  Trustee,
  Website,
} from "@kinherit/sdk";

interface RecordOfficerMessage {
  officer: string | Person;
}

interface RecordOfficerResponse {
  officer: Person;
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  people: Array<Person>;
  trustees: Array<Trustee>;
  attorneys: Array<Attorney>;
  executors: Array<Executor>;
  guardians: Array<Guardian>;
  addresses: Array<Address>;
  emails: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
  websites: Array<Website>;
}

export class RecordOfficerHandler extends ActionBase {
  constructor(private message: RecordOfficerMessage) {
    super();
  }

  public async execute(): Promise<RecordOfficerResponse> {
    const id =
      "string" === typeof this.message.officer
        ? this.message.officer
        : this.message.officer.id;

    const request = Api.resource("portal", "/v2/portal/officer/{person}", {
      person: id,
    }).method("get");
    request.buildQuery(Kintin).where([
      {
        primaryPerson: {
          appointedExecutor: {
            id: id,
          },
        },
      },
      {
        primaryPerson: {
          appointedAttorney: {
            id: id,
          },
        },
      },
      {
        primaryPerson: {
          appointedTrustee: {
            id: id,
          },
        },
      },
      {
        primaryPerson: {
          appointedGuardian: {
            id: id,
          },
        },
      },
      {
        secondaryPerson: {
          appointedExecutor: {
            id: id,
          },
        },
      },
      {
        secondaryPerson: {
          appointedAttorney: {
            id: id,
          },
        },
      },
      {
        secondaryPerson: {
          appointedTrustee: {
            id: id,
          },
        },
      },
      {
        secondaryPerson: {
          appointedGuardian: {
            id: id,
          },
        },
      },
    ]);

    const result = await request.result();

    const people = Person.$inflate(result.person);

    const officer = people.find((person) => person.id === id);

    if (!officer) {
      throw new Error("Officer not found");
    }

    return {
      officer,
      kintins: Kintin.$inflate(result.kintin),
      profiles: Profile.$inflate(result.profile),
      people,
      trustees: Trustee.$inflate(result.trustee),
      attorneys: Attorney.$inflate(result.attorney),
      executors: Executor.$inflate(result.executor),
      guardians: Guardian.$inflate(result.guardian),
      addresses: Address.$inflate(result.address),
      emails: EmailAddress.$inflate(result.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(result.phoneNumber),
      websites: Website.$inflate(result.website),
    };
  }
}
