<template>
  <RadioField
    v-if="null !== options"
    :label="computedLabel"
    v-model:value="computedValue"
    :options="options"
    :direction="direction"
    :is-loading="loading"
    is-button
    :is-reversed="isReversed"
  />
  <RadioField
    v-else-if="check?.checkType === 'yes_no'"
    :label="computedLabel"
    v-model:value="computedStringValue"
    :options="
      isReversed
        ? {
            yes: yesLabel,
            no: noLabel,
          }
        : {
            no: noLabel,
            yes: yesLabel,
          }
    "
    :direction="direction"
    :is-loading="loading"
    is-button
  />
  <RadioField
    v-else-if="check?.checkType === 'yes_maybe_no'"
    :label="computedLabel"
    v-model:value="computedValue"
    :options="[noLabel, yesLabel, maybeLabel]"
    :direction="direction"
    :is-loading="loading"
    is-button
    :is-reversed="isReversed"
  />
  <CheckboxField
    v-else-if="check?.checkType === 'checkbox'"
    :label="computedLabel"
    v-model:value="computedValue"
    :direction="direction"
    :is-loading="loading"
  />
  <Button
    v-if="!hideAddNote"
    text="Add Follow Up"
    class="mt-2 mb-4"
    size="is-small"
    @click="addFollowUp"
    is-compact
  />
  <Message
    v-for="(flag, index) in flags"
    :key="`flag-${index}`"
    class="mt-2 mb-5"
    size="is-small"
    title-heading-tag="h6"
    :has-border-left="false"
    :text="flag.message"
    :title="
      {
        flag: 'Flag' as const,
        action: 'Action' as const,
        ask: 'If Asked' as const,
        say: 'Say' as const,
      }[flag.type]!
    "
    :color="
      {
        flag: 'is-danger' as const,
        action: 'is-warning' as const,
        ask: 'is-info' as const,
        say: 'is-success' as const,
      }[flag.type]!
    "
  />
  <Message
    v-for="(followUp, index) in $data.followUps"
    :key="`follow-up-${index}`"
    class="mb-1"
    title-heading-tag="h6"
    size="is-small"
    :title="check?.name === followUp.note?.name ? null : followUp.note?.name"
  >
    <span v-html="followUp.note?.notes" />
    <div class="buttons">
      <Button
        :icon-left="$style.icon.edit.icon"
        aria-label="Edit Follow Up"
        size="is-small"
        @click="editFollowUp(followUp)"
        is-compact
      />
      <Button
        :icon-left="$style.icon.delete.icon"
        aria-label="Delete Follow Up"
        size="is-small"
        @click="deleteFollowUp(followUp)"
        is-compact
        color="is-danger"
      />
    </div>
  </Message>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateKintinCheckFollowUpForm } from "@/module/kinvault.kintin/form/update-kintin-check-follow-up.form";
import { StyleService } from "@/service/style.service";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import CheckboxField from "@kinherit/framework/component.input/checkbox-field";
import { RadioField } from "@kinherit/framework/component.input/radio-field";
import {
  Check,
  Kintin,
  KintinCheck,
  KintinCheckFollowUp,
  Note,
} from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: `ConfirmCheck`,
  components: { RadioField, CheckboxField, Message, Button },
  mixins: [StyleService.mixin],
  props: {
    step: {
      type: String as PropType<`${string}.${string}.${string}`>,
      required: true,
    },
    kintin: {
      type: Object as PropType<Kintin>,
      required: true,
    },
    options: {
      type: [Array, Object] as PropType<
        Array<string> | Record<string | number, string | number>
      >,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    yesLabel: {
      type: String,
      default: "Yes",
    },
    noLabel: {
      type: String,
      default: "No",
    },
    maybeLabel: {
      type: String,
      default: "Maybe",
    },
    on: {
      type: Object as PropType<
        Partial<
          Record<
            "yes" | "no",
            Partial<Record<"flag" | "action" | "ask" | "say", Array<string>>>
          >
        >
      >,
      default: null,
    },
    hideAddNote: {
      type: Boolean,
      default: false,
    },
    hideFieldLabel: {
      type: Boolean,
      default: false,
    },
    kintinChecks: {
      type: Array as PropType<KintinCheck[] | null>,
      default: null,
    },
    checks: {
      type: Array as PropType<Check[] | null>,
      default: null,
    },
    isReversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
    update: (step: `${string}.${string}.${string}`, value: number) => true,
  },
  data: () => ({
    kintinCheck: null as null | KintinCheck,
    check: null as null | Check,
    loading: false,
    followUps: [] as KintinCheckFollowUp[],
  }),
  mounted(): void {
    if (this.kintinChecks) {
      this.kintinCheck = this.kintinChecks.findBy("step", this.step) ?? null;
    } else {
      this.kintinCheck = KintinCheck.$findOneBy({
        step: this.step,
        kintin: {
          id: this.kintin.id,
        },
      });
    }

    if (this.checks) {
      this.check = this.checks.findBy("step", this.step) ?? null;
    } else {
      this.check = Check.$findOneByOrThrow({
        step: this.step,
      });
    }

    if (this.kintin) {
      this.followUps = this.kintin.checkFollowUps.filter(
        (followUp) => followUp.step === this.step,
      );
    }
  },
  computed: {
    direction(): "is-vertical" | "is-horizontal" {
      if (null === this.options) {
        return "is-horizontal";
      }

      const options = Array.isArray(this.options)
        ? this.options
        : Object.values(this.options);

      const hasYes = options.includes("Yes");
      const hasNo = options.includes("No");
      const has2 = options.length === 2;

      const isCompact = hasYes && hasNo && has2;

      if (isCompact) {
        return "is-horizontal";
      }

      return "is-vertical";
    },
    computedValue: {
      get(): number | boolean | null {
        if (!this.kintinCheck || null === this.kintinCheck?.checkValue) {
          return null;
        }

        if (this.kintinCheck?.checkType === "checkbox") {
          return this.kintinCheck?.checkValue === 1;
        }

        return this.kintinCheck?.checkValue ?? 0;
      },
      set(value: number) {
        this.loading = true;
        if (!this.kintin) {
          return;
        }

        switch (value as any) {
          case false:
            value = 0;
            break;
          case true:
            value = 1;
            break;
        }

        if (!this.kintinCheck) {
          const check = Check.$findOneByOrThrow({
            step: this.step,
          });

          const kintinCheck = new KintinCheck({
            id: Uuid.generate(),
            kintin: this.kintin.id,
            checkValue: value,
            step: this.step,
            note: new Note({
              id: Uuid.generate(),
              completedAt: new DateTime().formatMachine,
              type: "kintinCheck",
              name: check.name,
              pinned: false,
              createdAt: new DateTime().formatMachine,
              isPublic: false,
            }).$persist().id,
            checkType: check.checkType,
            createdAt: new DateTime().formatMachine,
          }).$persist();

          window.Kernel.ActionBus.execute(
            "kinvault/kintin/kintin-check/create",
            {
              kintin: this.kintin.id,
              kintinCheck,
              replace: false,
            },
            {
              hideLoading: true,
            },
          ).then((response) => {
            kintinCheck.$delete();
            this.kintinCheck =
              response.kintinChecks.findBy("step", this.step) ?? null;
            this.loading = false;
            this.$nextTick(() => {
              this.$emit("update", this.step, value);
            });
          });

          return;
        }

        this.kintinCheck.checkValue = value;
        this.kintinCheck.note?.$persist();
        this.kintinCheck.$persist();

        window.Kernel.ActionBus.execute(
          "kinvault/kintin/kintin-check/update",
          {
            kintin: this.kintin.id,
            kintinCheck: this.kintinCheck as KintinCheck,
          },
          {
            hideLoading: true,
          },
        );

        this.loading = false;
        this.$nextTick(() => {
          this.$emit("update", this.step, value);
        });
      },
    },
    computedStringValue: {
      get(): "yes" | "no" | "maybe" | null {
        switch (this.computedValue) {
          case 0:
            return "no";
          case 1:
            return "yes";
          case 2:
            return "maybe";
        }

        return null;
      },
      set(value: "yes" | "no" | "maybe") {
        switch (value) {
          case "yes":
            this.computedValue = 1;
            break;
          case "no":
            this.computedValue = 0;
            break;
          case "maybe":
            this.computedValue = 2;
            break;
        }
      },
    },
    computedLabel(): string {
      if (this.hideFieldLabel) {
        return "";
      }

      let label =
        this.label ?? this.kintinCheck?.note.name ?? this.check?.name ?? "";

      label += ` <small><i>(${this.step}`;

      if (this.kintinCheck?.note.completedAt) {
        label += ` - ${this.kintinCheck.note.completedAt.format(
          "DD MMM YY @ HH:II",
        )}`;
      }

      label += `)</i></small>`;

      return label;
    },
    flags(): {
      message: string;
      type: "flag" | "action" | "ask" | "say";
    }[] {
      if (!this.on) {
        return [];
      }

      const flags: {
        message: string;
        type: "flag" | "action" | "ask" | "say";
      }[] = [];

      ["yes", "no"].forEach((_key) => {
        const trigger = _key as "yes" | "no";
        ["flag", "action", "ask", "say"].forEach((_type) => {
          const type = _type as "flag" | "action" | "ask" | "say";
          const message = this.on[trigger]?.[type]?.[0];

          if (!message) {
            return;
          }

          if (trigger === "yes" && this.computedValue !== 1) {
            return;
          } else if (trigger === "no" && this.computedValue !== 0) {
            return;
          }

          flags.push({
            message,
            type,
          });
        });
      });

      return flags;
    },
  },
  methods: {
    async addFollowUp(): Promise<void> {
      if (!this.check) {
        return;
      }

      const followUp = new KintinCheckFollowUp({
        id: Uuid.generate(),
        note: new Note({
          id: Uuid.generate(),
          name: this.check.name
            ? `${this.check.name} - Follow-Up`
            : "Follow-Up",
          completedAt: null,
          pinned: false,
          type: "kintinCheckFollowUp",
          kintin: this.kintin.id,
          createdAt: new DateTime().formatMachine,
          isPublic: false,
        }).$persist().id,
        step: this.step,
        kintin: this.kintin.id,
        createdAt: new DateTime().formatMachine,
      }).$persist();

      try {
        await UpdateKintinCheckFollowUpForm(followUp).dialog({
          dialog: {
            title: "Add New Follow-Up Item",
          },
          button: {
            ok: {
              text: "Add Follow-Up",
            },
          },
        });
      } catch {
        followUp.$delete({
          note: true,
        });
        return;
      }

      followUp.$persist();
      this.kintin.addCheckFollowUps(followUp);
      this.kintin.$persist();

      const { kintinCheckFollowUp } = await window.Kernel.ActionBus.execute(
        "kinvault/kintin/kintin-check-follow-up/create",
        {
          kintin: this.kintin.id,
          kintinCheckFollowUp: followUp,
        },
        {
          hideLoading: true,
        },
      );

      this.followUps.push(kintinCheckFollowUp);
    },
    async editFollowUp(followUp: KintinCheckFollowUp): Promise<void> {
      try {
        await UpdateKintinCheckFollowUpForm(followUp).dialog({
          dialog: {
            title: "Update Follow-Up Item",
          },
        });
      } catch (e) {
        followUp.$restore();
        throw e;
      }

      followUp.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/kintin-check-follow-up/update",
        {
          kintin: this.kintin.id,
          kintinCheckFollowUp: followUp,
        },
        {
          hideLoading: true,
        },
      );
    },
    async deleteFollowUp(followUp: KintinCheckFollowUp): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Follow-Up Item",
          message: "Are you sure you want to delete this follow up item?",
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/kintin-check-follow-up/delete",
        {
          kintin: this.kintin.id,
          kintinCheckFollowUp: followUp,
        },
        {
          hideLoading: true,
        },
      );

      this.followUps = this.followUps.filter((fu) => fu.id !== followUp.id);
    },
  },
});
</script>
