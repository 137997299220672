import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { WillRevision } from "@kinherit/sdk";

interface DeleteWillRevisionMessage {
  willRevision: WillRevision;
}

interface DeleteWillRevisionResponse {
  [key: string]: never;
}

export class DeleteWillRevisionHandler extends ActionBase {
  constructor(private message: DeleteWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<DeleteWillRevisionResponse> {
    await Api.resource("portal", "/v2/portal/will-revision/{willRevision}", {
      willRevision: this.message.willRevision.id,
    })
      .method("delete")
      .result();

    this.message.willRevision.$delete();

    return {};
  }
}
