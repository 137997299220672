import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThan,
  Like,
} from "@kinherit/orm/index";
import { Api, EmailAddress, IProfile, Profile, QueryMask } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export interface ReadProfileMessage {
  // general
  name?: string;
  emailAddress?: string;
  created?: null | [number, number];
  query?: QueryMask<typeof Profile>;
  newsletterCampaigns?: null | boolean;
  // introducer contact options
  introducerContactStatus?: string[];
  introducerContactCompanyStatus?: string[];
  introducerContactCompanyStage?: string[];
  introducerContactCompanyType?: string[];
  introducerContactCompanyNetwork?: string[];
  introducerContactTags?: string[];
  introducerContactCompanyTags?: string[];
  introducerContactCpdSessionType?: string[];
  introducerContactCompany?: string[];
  // introducer company options
  introducerCompanyStatus?: string[];
  introducerCompanyStage?: string[];
  introducerCompanyType?: string[];
  introducerCompanyNetwork?: string[];
  introducerCompanyTags?: string[];
  introducerContactNewsletter?: null | boolean;
  // kinvault
  kinvaultNoRecentLogins?: null | boolean;
  // lead
  leadStatus?: string[];
  leadReferralCode?: string[];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IProfile;
    direction: "asc" | "desc";
  };
}

interface ReadProfileResponse {
  profiles: Array<Profile>;
  emailAddresses: EmailAddress[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadProfileHandler extends ActionBase {
  constructor(private message: ReadProfileMessage) {
    super();
  }

  public async execute(): Promise<ReadProfileResponse> {
    const request = Api.resource("portal", "/v2/portal/email-campaign/profile")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(Profile).where({
      newsletterCampaigns: Equal(this.message.newsletterCampaigns),
      fullName: Like(this.message.name),
      emails: {
        primary: Equal(true),
        email: Like(this.message.emailAddress),
      },
      person: {
        primaryOwnerOfKintin: {
          id: this.message.kinvaultNoRecentLogins ? IsNotNull() : undefined,
          assignedUsers: {
            accessType: this.message.kinvaultNoRecentLogins
              ? Equal("owner")
              : undefined,
            user: {
              credential: {
                lastLoggedIn: this.message.kinvaultNoRecentLogins
                  ? LessThan(new DateTime().sub(1, "year"))
                  : undefined,
              },
            },
          },
        },
      },
      introducerContact: {
        status: {
          id: In(this.message.introducerContactStatus),
        },
        cpds: {
          session: {
            type: {
              value: In(this.message.introducerContactCpdSessionType),
            },
          },
        },
        tags: {
          id: In(this.message.introducerContactTags),
        },
        companies: {
          id: In(this.message.introducerContactCompany),
          status: {
            id: In(this.message.introducerContactCompanyStatus),
          },
          stage: {
            id: In(this.message.introducerContactCompanyStage),
          },
          type: {
            id: In(this.message.introducerContactCompanyType),
          },
          network: {
            id: In(this.message.introducerContactCompanyNetwork),
          },
          tags: {
            id: In(this.message.introducerContactCompanyTags),
          },
        },
        newsLetter: Equal(this.message.introducerContactNewsletter),
      },
      introducerCompany: {
        status: {
          id: In(this.message.introducerCompanyStatus),
        },
        stage: {
          id: In(this.message.introducerCompanyStage),
        },
        type: {
          id: In(this.message.introducerCompanyType),
        },
        network: {
          id: In(this.message.introducerCompanyNetwork),
        },
        tags: {
          id: In(this.message.introducerCompanyTags),
        },
      },
      lead: {
        status: {
          id: In(this.message.leadStatus),
        },
        referral: {
          referralCode: {
            code: In(this.message.leadReferralCode),
          },
        },
      },
      createdAt: Between(this.message.created),
      ...this.message.query,
    });

    const response = await request.result();

    return {
      profiles: Profile.$inflate(
        response.profile,
        this.message.sort,
        response.$rootIds,
      ),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      pagination: this.getPagination(response),
    };
  }
}
