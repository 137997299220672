import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment | string;
}

export interface DeleteIntroducerFeePaymentResponse {}

export class DeleteIntroducerFeePaymentHandler extends ActionBase {
  constructor(private message: DeleteIntroducerFeePaymentMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerFeePaymentResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
      this.message,
    )
      .method("delete")
      .result();

    IntroducerFeePayment.$delete(this.message.introducerFeePayment);

    return {};
  }
}
