import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin } from "@kinherit/sdk/index";

export class ChangeKintinTypeHandler extends ActionBase {
  constructor(private data: { kintin: Kintin; type: "single" | "joint" }) {
    super();
  }

  async execute() {
    await Api.resource("portal", "/v2/portal/kintin/change-type")
      .method("put")
      .body({
        kintin: this.data.kintin.id,
        type: this.data.type,
      })
      .result();
  }
}
