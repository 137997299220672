import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, BrandedKinvaultPricingSchema } from "@kinherit/sdk";

export interface CreateBrandedKinvaultPricingSchemaMessage {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

interface CreateBrandedKinvaultPricingSchemaResponse {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

export class CreateBrandedKinvaultPricingSchemaHandler extends ActionBase {
  constructor(private message: CreateBrandedKinvaultPricingSchemaMessage) {
    super();
  }

  public async execute(): Promise<CreateBrandedKinvaultPricingSchemaResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-pricing-schema",
    )
      .method("post")
      .body({
        name: this.message.brandedKinvaultPricingSchema.name,
        schema: this.message.brandedKinvaultPricingSchema.schema,
        brandedKinvaults:
          this.message.brandedKinvaultPricingSchema.$data.brandedKinvaults,
        startDate:
          this.message.brandedKinvaultPricingSchema.startDate?.formatMachine,
        endDate:
          this.message.brandedKinvaultPricingSchema.endDate?.formatMachine,
        isDefault: this.message.brandedKinvaultPricingSchema.isDefault,
      });

    const response = await request.result();

    const brandedKinvaultPricingSchema = BrandedKinvaultPricingSchema.$inflate(
      response.brandedKinvaultPricingSchema,
      undefined,
      response.$rootIds,
    ).first();

    if (!brandedKinvaultPricingSchema) {
      throw new Error("Failed to create branded kinvault pricing schema");
    }

    this.message.brandedKinvaultPricingSchema.$delete();

    return {
      brandedKinvaultPricingSchema,
    };
  }
}
