import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
}

export interface DeleteIntroducerFeePaymentRunResponse {}

export class DeleteIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: DeleteIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerFeePaymentRunResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
      this.message,
    )
      .method("delete")
      .result();

    IntroducerFeePaymentRun.$delete(this.message.introducerFeePaymentRun);

    return {};
  }
}
