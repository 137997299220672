import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Check, ICheck } from "@kinherit/sdk";

export interface ReadCheckMessage {
  sort?: {
    by: keyof ICheck;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadCheckResponse {
  checks: Array<Check>;
}

export class ReadCheckHandler extends ActionBase {
  constructor(private message: ReadCheckMessage) {
    super();
  }

  public async execute(): Promise<ReadCheckResponse> {
    const response = await Api.resource("core", "/v2/core/select/check")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "name",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false)
      .result();

    return {
      checks: Check.$inflate(
        response.check,
        this.message.sort,
        response.$rootIds,
      ),
    };
  }
}
