import { Singleton } from "@kinherit/orm";
import { Session } from "./session.model";

export interface ActiveSessionData {
  username: string;
  token: string;
  mfaRequired: boolean;
  user: string;
}

export class ActiveSession extends Singleton<ActiveSessionData> {
  readonly $storage = "local";

  public get token(): string {
    return this.$data.token;
  }

  public get username(): string {
    return this.$data.username;
  }

  public get mfaRequired(): boolean {
    return this.$data.mfaRequired;
  }

  public static fromSession(session: Session): ActiveSession {
    return new this({
      username: session.username,
      token: session.token,
      mfaRequired: session.mfaRequired,
      user: session.user,
    });
  }

  public get user(): string {
    return this.$data.user;
  }
}
