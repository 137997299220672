import { calculateStartEndDates } from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeAppointmentsBreakdownReportMessage {
  created: [number, number] | null;
  assignedUser?: string | null;
}

export interface ComputeAppointmentsBreakdownReportResponse {
  data: Array<{
    groupName: string;
    total: number;
    cancelled: number;
    appointmentTypes: Array<{
      id: string;
      total: number;
      cancelled: number;
      outcomes: Array<{
        id: string;
        count: number;
      }>;
      cancelledOutcomes: Array<{
        id: string;
        count: number;
      }>;
    }>;
  }>;
}
export class ComputeAppointmentsBreakdownReportHandler extends ActionBase {
  constructor(private message: ComputeAppointmentsBreakdownReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeAppointmentsBreakdownReportResponse> {
    const { created, assignedUser } = this.message || {};

    const { startDate, endDate } = calculateStartEndDates(
      "custom",
      created,
      null,
    );

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/appointments/breakdown",
      {},
    )
      .method("get")
      .params({
        startDate,
        endDate,
        assignedUser: assignedUser ?? null,
      })

      .result();

    return response;
  }
}
