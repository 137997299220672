import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContract } from "@kinherit/sdk";

interface DeleteIntroducerCompanyIntroducerContractMessage {
  introducerContract: IntroducerContract;
}

interface DeleteIntroducerCompanyIntroducerContractResponse {
  [key: string]: never;
}

export class DeleteIntroducerCompanyIntroducerContractHandler extends ActionBase {
  constructor(
    private message: DeleteIntroducerCompanyIntroducerContractMessage,
  ) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerCompanyIntroducerContractResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-contract/{introducerCompany}/contracts/{introducerContract}",
      {
        introducerCompany: this.message.introducerContract.$data.company,
        introducerContract: this.message.introducerContract.$data.id,
      },
    )
      .method("delete")

      .result();

    this.message.introducerContract.$delete();

    return {};
  }
}
