import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CpdSessionRegistration } from "@kinherit/sdk";

interface DeleteCpdSessionRegistrationMessage {
  cpdSessionRegistration: CpdSessionRegistration;
}

interface DeleteCpdSessionRegistrationResponse {
  [key: string]: never;
}

export class DeleteCpdSessionRegistrationHandler extends ActionBase {
  constructor(private message: DeleteCpdSessionRegistrationMessage) {
    super();
  }

  public async execute(): Promise<DeleteCpdSessionRegistrationResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/cpd-session/cpd-session-registration/{cpdSessionRegistration}",
      {
        cpdSessionRegistration: this.message.cpdSessionRegistration,
      },
    )
      .method("delete")

      .result();

    this.message.cpdSessionRegistration.$delete();

    return {};
  }
}
