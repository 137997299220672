import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface RecordIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem | string;
}

export interface RecordIntroducerFeePaymentItemResponse {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export class RecordIntroducerFeePaymentItemHandler extends ActionBase {
  constructor(private message: RecordIntroducerFeePaymentItemMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerFeePaymentItemResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-item/{introducerFeePaymentItem}",
      this.message,
    ).method("get");

    const response = await request.result();

    const introducerFeePaymentItem = IntroducerFeePaymentItem.$inflate(
      response.introducerFeePaymentItem,
    ).first();

    if (!introducerFeePaymentItem) {
      throw new Error("Failed to find introducerFeePaymentItem");
    }

    return {
      introducerFeePaymentItem,
    };
  }
}
