import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinAccess } from "@kinherit/sdk";

interface UpdateUserKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface UpdateUserKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export class UpdateUserKintinAccessHandler extends ActionBase {
  constructor(private message: UpdateUserKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<UpdateUserKintinAccessResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/kintin-user-access/{kintinAccess}",
      {
        kintinAccess: this.message.kintinAccess,
      },
    ).method("patch");
    request.body({
      accessType: this.message.kintinAccess.accessType,
      kintin: this.message.kintinAccess.kintin?.id,
    });

    const result = await request.result();

    const kintinAccess = KintinAccess.$inflate(result.kintinAccess).first();

    if (!kintinAccess) {
      throw new Error("Failed to update kintin user access");
    }

    return {
      kintinAccess,
    };
  }
}
