import { ChangeTokenPasswordHandler } from "@/module/auth/action/change-password/change-token-password.action";
import { CompleteMultiFactorAuthHandler } from "./multi-factor/complete-multi-factor-auth.action";
import { RequestMultiFactorAuthHandler } from "./multi-factor/request-multi-factor-auth.action";
import { CompleteSessionHandler } from "./session/complete-session.action";
import { LogoutSessionHandler } from "./session/logout-session.action";
import { RequestSessionHandler } from "./session/request-session.action";
import { UniqueUsernameAction } from "@/module/auth/action/unique-username.action";

export const Actions = {
  "auth/multi-factor/request": RequestMultiFactorAuthHandler,
  "auth/multi-factor/complete": CompleteMultiFactorAuthHandler,
  "auth/session/request": RequestSessionHandler,
  "auth/session/complete": CompleteSessionHandler,
  "auth/session/logout": LogoutSessionHandler,
  "auth/change-password": ChangeTokenPasswordHandler,
  "auth/unique-username": UniqueUsernameAction,
};
