import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Option, Profile, User } from "@kinherit/sdk";

interface CreateUserMessage {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  roles: Array<string>;
}

interface CreateUserResponse {
  user: User;
  profiles: Array<Profile>;
}

export class CreateUserHandler extends ActionBase {
  constructor(private message: CreateUserMessage) {
    super();
  }

  public async execute(): Promise<CreateUserResponse> {
    const status = Option.$findOneByOrThrow({
      group: "userStatus",
      value: "new",
    }).id;

    const response = await Api.resource("portal", "/v2/portal/user")
      .method("post")
      .body({
        credential: {
          username: this.message.emailAddress,
        },
        displayUserInPortal: true,
        profile: {
          firstName: this.message.firstName,
          lastName: this.message.lastName,
          emails: [
            {
              email: this.message.emailAddress,
              primary: true,
            },
          ],
          phoneNumbers:
            "" !== this.message.phoneNumber.trim()
              ? [{ tel: this.message.phoneNumber, primary: true }]
              : undefined,
        },
        roles: this.message.roles,
        status,
        isEstatePlanner: false,
        isLegalAssistant: false,
      })

      .result();

    const user = User.$inflate(response.user).first();

    if (!user) {
      throw new Error("Failed to create user");
    }

    return {
      user,
      profiles: Profile.$inflate(response.profile),
    };
  }
}
