import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Like, In, Between } from "@kinherit/orm/index";
import { Api, EmailTemplate, IEmailTemplate } from "@kinherit/sdk";

export interface ReadEmailTemplateMessage {
  search?: string;
  type?: Array<string>;
  created?: [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEmailTemplate;
    direction: "asc" | "desc";
  };
}

interface ReadEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadEmailTemplateHandler extends ActionBase {
  constructor(private message: ReadEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<ReadEmailTemplateResponse> {
    const request = Api.resource("portal", "/v2/portal/email-template")
      .method("get")

      .paginate(this.message.pagination ?? false)
      .sort({
        sortBy: this.message.sort?.by ?? "createdAt",
        sortDirection: this.message.sort?.direction ?? "desc",
      });

    request.buildQuery(EmailTemplate).where([
      {
        name: Like(this.message.search),
        type: {
          id: In(this.message.type),
        },
        createdAt: Between(this.message.created),
      },
      {
        subject: Like(this.message.search),
        type: {
          id: In(this.message.type),
        },
        createdAt: Between(this.message.created),
      },
      {
        body: Like(this.message.search),
        type: {
          id: In(this.message.type),
        },
        createdAt: Between(this.message.created),
      },
    ]);

    const response = await request.result();

    return {
      emailTemplates: EmailTemplate.$inflate(
        response.emailTemplate,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
