<template>
  <div v-if="!loading" class="introducer-company-details-client-progress">
    <PageHeader htag="h2" text="Referrals" />
    <Card title="Referral Codes">
      <Table
        class="referral-codes-table"
        is-scrollable
        enable-per-page
        v-model:page="pagination.currentPage"
        @update:page="refresh"
        v-model:perPage="pagination.perPage"
        @update:perPage="refresh"
        :total-pages="pagination.lastPage"
        :total-records="pagination.count"
        :columns="computedReferralColumns"
        :rows="referralCodes"
        is-fullwidth
        is-narrow
        is-hoverable
        v-model:sortBy="sort.by"
        @update:sortBy="refresh"
        v-model:sortDirection="sort.direction"
        @update:sortDirection="refresh"
        :sort-by-options="{
          createdAt: 'Created',
        }"
      >
        <template #action="{ row }">
          <Button
            @click="() => referralClicked(row)"
            size="is-small"
            color="is-primary"
            is-outlined
            class="view-referral"
          >
            View Leads
          </Button>

          <Button
            @click="() => deleteReferralCode(row)"
            :icon-left="StyleService.icon.delete.icon"
            size="is-small"
            color="is-plain"
            aria-label="Delete referral code"
            class="delete-referral"
            tooltip="Delete referral code"
          />
        </template>
      </Table>
    </Card>
    <Card
      v-for="(title, index) in orderAssignmentKeys"
      :key="`assignment-table-${index}`"
      :title="title"
    >
      <Table
        @click="assignmentClicked"
        :total-records="assignments[title]?.length ?? 0"
        :columns="assignmentColumns"
        :rows="assignments[title]"
        is-fullwidth
        is-narrow
        is-hoverable
        is-clickable
        copy-to-clipboard
      />
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { NotEqual } from "@kinherit/orm/index";
import {
  AccountReferralCode,
  IAccountReferral,
  Kintin,
  Lead,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsClientProgressParams,
  IntroducerCompanyDetailsClientProgressRoute,
} from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsClientProgressRoute,
  mixins: [IntroducerCompanyDetailsMixin, AuthService.mixin()],
  components: {
    Card,
    Table,
    Button,
    PageHeader,
  },
  data: () => ({
    StyleService,
    loading: false,
    referralColumns: [
      {
        title: "Code",
        sort: true,
        map: (referral: AccountReferralCode) => referral.code,
      },
      {
        title: "Category",
        sort: true,
        map: (v: AccountReferralCode) => v.category?.text ?? "No Category",
      },
      {
        title: "Created",
        sort: true,
        map: (referral: AccountReferralCode) => referral.createdAt.formatDate,
      },
      {
        title: "Notes",
        sort: true,
        map: (referral: AccountReferralCode) => referral.notes,
      },
      {
        title: "",
        slot: "action",
        permission: "introducer-company:write",
        blockClicks: true,
        style: {
          textAlign: "right",
        },
      },
    ] as Array<TableColumn>,
    assignmentColumns: [
      {
        title: "Type",
        map: (v: Lead | Kintin) => v.$name,
      },
      {
        title: "Introduced on",
        sort: true,
        map: (v: Lead | Kintin) => v.createdAt.formatDate,
      },
      {
        title: "Client",
        sort: true,
        map: (v: Lead | Kintin) =>
          (v instanceof Lead ? v.profile.fullName : v.friendlyName) ?? ``,
      },
      {
        title: "Stage",
        sort: true,
        map: (v: Lead | Kintin) =>
          (v instanceof Lead ? "Unqualified Lead" : v.stage?.text) ?? ``,
      },
      {
        title: "Status",
        sort: true,
        map: (v: Lead | Kintin) => v.status?.text ?? ``,
      },
    ],
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferral,
      direction: "desc" as "desc" | "asc",
    },
    referralCodes: Array<AccountReferralCode>(),
    assignments: {} as Record<string, Array<Lead | Kintin>>,
    orderAssignmentKeys: Array<string>(),
  }),
  computed: {
    computedReferralColumns() {
      return this.referralColumns.filter(
        this.$auth.filterAllPermissions("permission"),
      );
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      this.loading = true;
      const { referralCodes, pagination } =
        await window.Kernel.ActionBus.execute(
          "introducer/company/referral-codes/read",
          {
            introducerCompany: (
              this.$params as IntroducerCompanyDetailsClientProgressParams
            ).introducerCompany,
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.orderAssignmentKeys = [];
      this.referralCodes = referralCodes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.loading = false;
    },
    async referralClicked(referralCode: AccountReferralCode): Promise<void> {
      this.loading = true;
      if (!this.introducerCompany) {
        return;
      }

      const { kintins } = await window.Kernel.ActionBus.execute(
        "introducer/company/referral/kintins/read",
        {
          introducerCompany: this.introducerCompany,
          referralCode: referralCode,
          pagination: false,
        },
      );

      const { leads } = await window.Kernel.ActionBus.execute(
        "introducer/company/referral/leads/read",
        {
          introducerCompany: this.introducerCompany,
          referralCode: referralCode,
          query: {
            status: {
              value: NotEqual("converted"),
            },
          },
          pagination: false,
        },
      );

      this.assignments = {};

      leads.forEach((v) => {
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ] ??= [];
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ].push(v);
      });

      kintins.forEach((v) => {
        if (null === v.referral?.contact) {
          this.assignments[`Unassigned`] ??= [];
          this.assignments[`Unassigned`].push(v);
          return;
        }

        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ] ??= [];
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ].push(v);
      });

      this.orderAssignmentKeys = Object.keys(this.assignments)
        .remove(`Unassigned`)
        .sort();
      this.orderAssignmentKeys.unshift(`Unassigned`);

      this.loading = false;
    },
    assignmentClicked(assignment: Lead | Kintin, event: MouseEvent): void {
      if (assignment instanceof Lead) {
        window.Kernel.visitRoute(
          {
            name: LeadDetailsRoute,
            params: {
              lead: assignment.id,
            } as LeadDetailsParams,
          },
          event.ctrlKey,
        );
      } else {
        window.Kernel.visitRoute(
          {
            name: KinvaultKintinDetailsRoute,
            params: {
              kintin: assignment.id,
            } as KinvaultKintinDetailsParams,
          },
          event.ctrlKey,
        );
      }
    },
    async deleteReferralCode(referralCode: AccountReferralCode): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Delete "${referralCode.code}"`,
          message: `Are you sure you want to delete this referral code from ${this.introducerCompany?.profile.organisationName}?`,
        },
      });

      await window.Kernel.ActionBus.execute(
        "admin/account-referral-code/delete",
        {
          accountReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
  },
});
</script>
