import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface DeleteKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  fileLog: FileLog;
}

interface DeleteKnowledgeBaseDocumentFileLogResponse {}

export class DeleteKnowledgeBaseDocumentFileLogHandler extends ActionBase {
  constructor(private message: DeleteKnowledgeBaseDocumentFileLogMessage) {
    super();
  }

  public async execute(): Promise<DeleteKnowledgeBaseDocumentFileLogResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}",
      this.message,
    )
      .method("delete")
      .result();

    this.message.fileLog.$delete();

    return {};
  }
}
