import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign } from "@kinherit/sdk";
import { Profile } from "@kinherit/sdk";

export interface AddEmailCampaignProfileMessage {
  emailCampaign: EmailCampaign;
  profile: Profile;
}

export interface AddEmailCampaignProfileResponse {
  [key: string]: never;
}

export class AddEmailCampaignProfileHandler extends ActionBase {
  constructor(private message: AddEmailCampaignProfileMessage) {
    super();
  }

  public async execute(): Promise<AddEmailCampaignProfileResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/add",
      this.message,
    )
      .method("post")

      .result();

    return {};
  }
}
