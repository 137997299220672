<template>
  <div v-if="kintin">
    <PageHeader htag="h2" text="File Vault" />
    <Card
      title="LPAs"
      subtitle="Lasting Power of Attorneys, Lifetime trust documents etc."
    >
      <FileVaultDocuments
        v-model:value="files.legalBefore"
        :tabs="tabs"
        tabs-class="before-death"
        :type="$data.types.legalBefore"
        :has-write-access="hasWriteAccess"
        :kintin="kintin"
        @save="save"
        @delete="remove"
      />
    </Card>
    <Card title="Will" subtitle="Your Will and related documents">
      <FileVaultDocuments
        v-model:value="files.legalAfter"
        :tabs="tabs"
        tabs-class="after-death"
        :type="$data.types.legalAfter"
        :has-write-access="hasWriteAccess"
        :kintin="kintin"
        @save="save"
        @delete="remove"
      />
    </Card>
    <Card
      v-if="hasPhysicalStorage"
      title="Kinherit Physical Document Storage"
      subtitle="Details and information on location of physical key documents."
    >
      <FileVaultDocuments
        v-model:value="files.physicalStorage"
        :type="$data.types.physicalStorage"
        tabs-class="kinherit-storage"
        :has-write-access="false"
        :kintin="kintin"
        @save="save"
        @delete="remove"
      />
    </Card>
  </div>
</template>

<script lang="ts">
import FileVaultDocuments from "@/module/kinvault.kintin/component/FileVaultDocuments.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Card from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { ThemeIconNameType } from "@kinherit/framework/theme/prop/icon";
import { FileLog, IFileLog, Option } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsFileVaultRoute } from ".";
import { Equal } from "@kinherit/orm/index";

export default defineComponent({
  name: KinvaultKintinDetailsFileVaultRoute,
  components: { FileVaultDocuments, Card, PageHeader },
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  data: () => ({
    columns: [
      {
        title: "Name",
        sort: true,
        field: "fileName",
      },
      {
        title: "Type",
        sort: true,
        map: (v: FileLog) =>
          `${v.type && v.type.text} ${v.subType && `(${v.subType.text})`}`,
      },
      {
        title: "Notes",
        sort: true,
        field: "notes",
      },
      {
        title: "Created",
        sort: true,
        map: (v: FileLog) => v.createdAt.formatDateTime,
      },
    ] as Array<TableColumn>,
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "fileName" as keyof IFileLog,
      direction: "asc" as "asc" | "desc",
    },
    types: {
      legalBefore: Option.$findOneByOrThrow({
        group: "fileLocations",
        value: "legal-before",
      }),
      legalAfter: Option.$findOneByOrThrow({
        group: "fileLocations",
        value: "legal-after",
      }),
      physicalStorage: Option.$findOneByOrThrow({
        group: "fileLocations",
        value: "physicalstorage",
      }),
    },
    files: {
      legalBefore: Array<FileLog>(),
      legalAfter: Array<FileLog>(),
      physicalStorage: Array<FileLog>(),
      remoteIds: Array<string>(),
    },
    hasPhysicalStorage: false,
  }),
  computed: {
    hasWriteAccess() {
      return this.$auth.hasPermission("kintin:write");
    },
    tabs():
      | Array<{
          label: string;
          icon?: ThemeIconNameType;
          ref: FileLog["$data"]["tab"];
        }>
      | undefined {
      const kintin = this.kintin;

      if (!kintin) {
        return [];
      }

      if (null !== kintin.primaryPerson && null !== kintin.secondaryPerson) {
        return [kintin.primaryPerson, kintin.secondaryPerson].map(
          (person, index) => ({
            label: person.profile.fullName ?? `Person ${index + 1}`,
            icon: "Person",
            ref: index === 0 ? "primary" : "secondary",
          }),
        );
      }

      return undefined;
    },
  },
  mounted(): void {
    this.refresh();
    this.checkForPhysicalStorage();
  },
  methods: {
    async refresh(): Promise<void> {
      this.files = {
        legalBefore: Array<FileLog>(),
        legalAfter: Array<FileLog>(),
        physicalStorage: Array<FileLog>(),
        remoteIds: Array<string>(),
      };

      const data = await window.Kernel.ActionBus.execute(
        "kinvault/kintin/file/read",
        {
          ...this.$params,
          sort: { by: "fileName", direction: "asc" },
          pagination: false,
        },
      );

      for (const file of data.fileLogs) {
        if (file.$data.type === this.types.legalAfter.id) {
          this.files.legalAfter.push(file);
        } else if (file.$data.type === this.types.legalBefore.id) {
          this.files.legalBefore.push(file);
        } else if (file.$data.type === this.types.physicalStorage.id) {
          this.files.physicalStorage.push(file);
        }

        this.files.remoteIds.push(file.$data.id);
      }
      console.log(JSON.parse(JSON.stringify(this.files)));

      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async checkForPhysicalStorage(): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "kinvault/subscription/read",
        {
          query: {
            kintin: {
              id: Equal(this.$params.kintin),
            },
            productSku: {
              value: Equal("docstorage"),
            },
          },
          pagination: {
            perPage: 1,
          },
        },
      );

      this.hasPhysicalStorage = data.subscriptions.length > 0;
    },
    async save({
      fileLog,
      uploadedFile,
    }: {
      fileLog: FileLog;
      uploadedFile: File | null;
    }): Promise<void> {
      fileLog.kintin = this.kintin;

      if (this.files.remoteIds.includes(fileLog.id)) {
        await window.Kernel.ActionBus.execute("kinvault/kintin/file/update", {
          fileLog,
          uploadedFile,
        });
      } else {
        await window.Kernel.ActionBus.execute("kinvault/kintin/file/create", {
          file: fileLog,
          uploadedFile,
        });
      }

      await this.refresh();
    },
    async remove(fileLog: FileLog): Promise<void> {
      await window.Kernel.ActionBus.execute("kinvault/kintin/file/delete", {
        fileLog,
      });

      await this.refresh();
    },
  },
});
</script>
