<template>
  <div class="exclusion-list">
    <ExclusionSummary
      v-for="role in exclusions"
      :key="`exclusion-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!exclusions.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import ExclusionSummary from "@/module/kinvault.kintin/component/summary-cards/ExclusionSummary.vue";
import { UpdateExclusionForm } from "@/module/kinvault.kintin/form/update-exclusion.form";
import Card from "@kinherit/framework/component.layout/card";
import { Exclusion, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExclusionList",
  components: { ExclusionSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    exclusions: {
      type: Array as () => Exclusion[],
      required: true,
    },
  },
  methods: {
    async edit(exclusion: Exclusion): Promise<void> {
      try {
        await UpdateExclusionForm({
          data: exclusion,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Exclusion",
          },
        });
      } catch {
        exclusion.$restore();
        return;
      }

      const kintin = this.kintin;

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/exclusion/update",
        {
          exclusion,
          kintin,
        },
      );

      this.$emit("reload");
    },
    async remove(exclusion: Exclusion): Promise<void> {
      const kintin = this.kintin;

      await DangerDialog({
        dialog: {
          title: "Delete Exclusion",
          message: "Are you sure you want to remove this exclusion?",
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/exclusion/delete",
        {
          exclusion,
          kintin,
        },
      );

      this.$emit("reload");
    },
  },
});
</script>
