import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferral } from "@kinherit/sdk";

interface RecordAccountReferralMessage {
  referral: string;
}

interface RecordAccountReferralResponse {
  referrals: Array<AccountReferral>;
}

export class RecordAccountReferralHandler extends ActionBase {
  constructor(private message: RecordAccountReferralMessage) {
    super();
  }

  public async execute(): Promise<RecordAccountReferralResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/account-referral/{accountReferral}",
      {
        accountReferral: this.message.referral,
      },
    ).method("get");
    const result = await request.result();

    return {
      referrals: AccountReferral.$inflate(
        result.accountReferral,
        undefined,
        result.$rootIds,
      ),
    };
  }
}
