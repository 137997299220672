import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { MfAuthMethod } from "@kinherit/sdk";

interface ResendUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface ResendUserMfAuthMethodResponse {}

export class ResendUserMfAuthMethodHandler extends ActionBase {
  constructor(private message: ResendUserMfAuthMethodMessage) {
    super();
  }

  public async execute(): Promise<ResendUserMfAuthMethodResponse> {
    await Api.resource("portal", "/v2/portal/user/{mfAuthMethod}/resend", {
      mfAuthMethod: this.message.mfAuthMethod,
    })
      .method("put")

      .result();

    return {};
  }
}
