import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/index";

interface RecordDefaultOrganisatioMessage {
  defaultBrandedKinvaultReferralCode:
    | DefaultBrandedKinvaultReferralCode
    | string;
}

interface RecordDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export class RecordDefaultBrandedKinvaultReferralCodeHandler extends ActionBase {
  constructor(private message: RecordDefaultOrganisatioMessage) {
    super();
  }

  public async execute(): Promise<RecordDefaultBrandedKinvaultReferralCodeResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
      {
        defaultBrandedKinvaultReferralCode:
          this.message.defaultBrandedKinvaultReferralCode,
      },
    )
      .method("get")

      .result();

    const defaultBrandedKinvaultReferralCode =
      DefaultBrandedKinvaultReferralCode.$inflate(
        repsonse.defaultBrandedKinvaultReferralCode,
        undefined,
        repsonse.$rootIds,
      ).first();

    if (!defaultBrandedKinvaultReferralCode) {
      throw new Error("Failed to record default referral code");
    }

    return {
      defaultBrandedKinvaultReferralCode,
    };
  }
}
