import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  Equal,
  In,
  LessThanOrEqual,
  Like,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import {
  Api,
  BrandedKinvaultPricingSchema,
  IBrandedKinvaultPricingSchema,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultPricingSchemaMessage {
  name: string | null;
  brandedKinvaults: string[];
  dated: [number, number] | null;
  created: [number, number] | null;
  defaultOnly: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IBrandedKinvaultPricingSchema;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultPricingSchemaResponse {
  brandedKinvaultPricingSchemas: Array<BrandedKinvaultPricingSchema>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadBrandedKinvaultPricingSchemaHandler extends ActionBase {
  constructor(private message: ReadBrandedKinvaultPricingSchemaMessage) {
    super();
  }

  public async execute(): Promise<ReadBrandedKinvaultPricingSchemaResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-pricing-schema",
    )
      .method("get")
      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(BrandedKinvaultPricingSchema).where({
      name: Like(this.message.name),
      brandedKinvaults: {
        id: In(this.message.brandedKinvaults),
      },
      startDate: LessThanOrEqual(this.message.dated?.[0]),
      endDate: MoreThanOrEqual(this.message.dated?.[1]),
      createdAt: Between(this.message.created),
      isDefault: this.message.defaultOnly ? Equal(true) : undefined,
    });

    const response = await request.result();

    return {
      brandedKinvaultPricingSchemas: BrandedKinvaultPricingSchema.$inflate(
        response.brandedKinvaultPricingSchema,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
