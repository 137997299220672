import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinCheck, Note } from "@kinherit/sdk";

interface CreateKintinCheckMessage {
  kintin: string;
  kintinCheck: KintinCheck;
  replace: boolean;
}

interface CreateKintinCheckResponse {
  kintinChecks: Array<KintinCheck>;
  notes: Array<Note>;
}

export class CreateKintinCheckHandler extends ActionBase {
  constructor(private message: CreateKintinCheckMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinCheckResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-check/{kintin}/checks",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")
      .body({
        checkType: this.message.kintinCheck.$data.checkType,
        checkValue: this.message.kintinCheck.$data.checkValue,
        note: {
          completedAt: this.message.kintinCheck.note.$data.completedAt,
          kintin: this.message.kintin,
          name: this.message.kintinCheck.note.$data.name,
          notes: this.message.kintinCheck.note.$data.notes,
          dueAt: this.message.kintinCheck.note.$data.dueAt,
          pinned: this.message.kintinCheck.note.$data.pinned,
          type: "kintinCheck",
        },
        kintin: this.message.kintin,
        step: this.message.kintinCheck.$data.step,
      })

      .result();

    const kintinChecks = KintinCheck.$inflate(response.kintinCheck);
    const notes = Note.$inflate(response.note);

    this.message.kintinCheck.$delete();

    if (this.message.replace) {
      const replaced = kintinChecks.find(
        (kintinCheck) =>
          kintinCheck.step === this.message.kintinCheck.$data.step,
      );

      if (!replaced) {
        throw new Error("Failed to create kintin check");
      }

      this.message.kintinCheck.$sync(replaced);
    }

    return {
      kintinChecks,
      notes,
    };
  }
}
