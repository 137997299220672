import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface UpdateKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface UpdateKintinStorageRequestResponse {
  physicalStorageFile: PhysicalStorageFile;
}

export class UpdateKintinStorageRequestHandler extends ActionBase {
  constructor(private message: UpdateKintinStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinStorageRequestResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}",
      {
        kintin: this.message.kintin,
        subscription: this.message.subscription,
        physicalStorageFile: this.message.storageRequest,
      },
    )
      .method("patch")
      .body({
        length: this.message.storageRequest.$data.length,
        file: this.message.storageRequest.$data.file,
        notes: this.message.storageRequest.$data.notes,
        subscription: this.message.storageRequest.$data.subscription,
        title: this.message.storageRequest.$data.title,
      })

      .result();

    const physicalStorageFile = PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ).first();

    if (!physicalStorageFile) {
      throw new Error("Failed to update storage request");
    }

    return {
      physicalStorageFile,
    };
  }
}
