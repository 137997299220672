<template>
  <div class="guardian-list">
    <GuardianSummary
      v-for="role in guardians.sortBy('sortOrder', 'asc')"
      :key="`guardian-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!guardians.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import GuardianSummary from "@/module/kinvault.kintin/component/summary-cards/GuardianSummary.vue";
import { UpdateGuardianForm } from "@/module/kinvault.kintin/form/update-guardian.form";
import Card from "@kinherit/framework/component.layout/card";
import { Guardian, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuardianList",
  components: { GuardianSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    guardians: {
      type: Array as () => Guardian[],
      required: true,
    },
  },
  methods: {
    async edit(guardian: Guardian): Promise<void> {
      try {
        await UpdateGuardianForm({
          data: guardian,
          kintin: this.kintin,
        }).then((form) =>
          form.dialog({
            dialog: {
              title: "Edit Guardian",
            },
          }),
        );
      } catch {
        guardian.$restore();
        return;
      }

      const kintin = this.kintin;

      await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
        person: guardian.person,
        kintin: this.kintin,
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/guardian/update", {
        guardian,
        kintin,
      });

      this.$emit("reload");
    },
    async remove(guardian: Guardian): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Guardian",
          message: "Are you sure you want to delete this guardian?",
        },
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/guardian/delete", {
        guardian,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
