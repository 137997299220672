<template>
  <PageHeader htag="h1" text="Users">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create User
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      'credential.lastLoggedIn': 'Last Login',
      'profile.fullName': 'Name',
      'credential.username': 'Username',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { UserTableColumns } from "@/config/table-columns.config";
import { ReadUserForm } from "@/module/admin.user/form/read-user.form";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { IUser, User } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AdminUserMasterListRoute } from ".";
import { CreateUserForm } from "../form/create-user.form";
import { AdminUserDetailsParams, AdminUserDetailsRoute } from "./details";

export default defineComponent({
  name: AdminUserMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  data: () => ({
    filters: ReadUserForm(),
    columns: UserTableColumns,
    rows: Array<User>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IUser,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("user:write");
    },
  },
  methods: {
    handleClick(user: User, event: MouseEvent): void {
      const params: AdminUserDetailsParams = { user: user.id };

      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadUserForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute("admin/user/read", {
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.users;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const user = await CreateUserForm().dialog({
        dialog: {
          title: "Create User",
        },
      });

      const result = await window.Kernel.ActionBus.execute(
        "admin/user/create",
        user,
      );

      await OpenAlertDialog({
        dialog: {
          title: "User Created",
          message: `Successfully create ${result.user.profile.fullName} `,
        },
        button: {
          ok: {
            text: "View User",
            color: "is-positive",
          },
          cancel: {
            text: "Return",
          },
        },
      });

      const params: AdminUserDetailsParams = { user: result.user.id };

      window.Kernel.visitRoute({
        name: AdminUserDetailsRoute,
        params,
      });
    },
  },
});
</script>
