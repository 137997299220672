import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  EmailAddress,
  IIntroducerBillingContact,
  IntroducerBillingContact,
  IntroducerCompany,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

export interface ReadIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerBillingContact;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerBillingContactResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerBillingContactHandler extends ActionBase {
  constructor(private message: ReadIntroducerBillingContactMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerBillingContactResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts",
      {
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
      },
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    const response = await request.result();

    return {
      introducerBillingContacts: IntroducerBillingContact.$inflate(
        response.introducerBillingContact,
        this.message.sort,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
