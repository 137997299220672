import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvault } from "@kinherit/sdk";

export interface UpdateBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault;
}

interface UpdateBrandedKinvaultResponse {
  brandedKinvault: BrandedKinvault;
}

export class UpdateBrandedKinvaultHandler extends ActionBase {
  constructor(private message: UpdateBrandedKinvaultMessage) {
    super();
  }

  public async execute(): Promise<UpdateBrandedKinvaultResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/{brandedKinvault}",
      {
        brandedKinvault: this.message.brandedKinvault.id,
      },
    )
      .method("patch")
      .body({
        systemEmail: this.message.brandedKinvault.$data.systemEmail,
        type: this.message.brandedKinvault.$data.type,
        clientPoolType: this.message.brandedKinvault.$data.clientPoolType,
        frontendUrl: this.message.brandedKinvault.$data.frontendUrl,
        notes: this.message.brandedKinvault.$data.notes,
        siteMode: this.message.brandedKinvault.$data.siteMode,
        allowDefaultAssetAccess:
          this.message.brandedKinvault.$data.allowDefaultAssetAccess,
        useSetupWizard: this.message.brandedKinvault.$data.useSetupWizard,
        useBrandedKinvaultInvitations:
          this.message.brandedKinvault.$data.useBrandedKinvaultInvitations,
        allowPublicRegistration:
          this.message.brandedKinvault.$data.allowPublicRegistration,
        useUserInvitations:
          this.message.brandedKinvault.$data.useUserInvitations,
        restrictUserEmailDomain:
          this.message.brandedKinvault.$data.restrictUserEmailDomain,
        allowedEmailDomains:
          this.message.brandedKinvault.$data.allowedEmailDomains,
        xeroContact: this.message.brandedKinvault.$data.xeroContact,
        ownerCompanies: this.message.brandedKinvault.$data.ownerCompanies,
        memberCompanies: this.message.brandedKinvault.$data.memberCompanies,
      });
    const result = await request.result();

    const brandedKinvault = BrandedKinvault.$inflate(
      result.brandedKinvault,
    ).first();

    if (!brandedKinvault) {
      throw new Error("Failed to update branded kinvault");
    }

    return {
      brandedKinvault,
    };
  }
}
