import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IKintin, Kintin, QueryMask } from "@kinherit/sdk";

export interface ReadKintinMessage {
  query?: QueryMask<typeof Kintin> | Array<QueryMask<typeof Kintin>>;
  sort?: {
    by: keyof IKintin;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadKintinResponse {
  kintins: Array<Kintin>;
}

export class ReadKintinHandler extends ActionBase {
  constructor(private message: ReadKintinMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinResponse> {
    const request = Api.resource("core", "/v2/core/select/kintin")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "friendlyName",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(Kintin).where(this.message.query ?? {});

    const response = await request.result();

    return {
      kintins: Kintin.$inflate(
        response.kintin,
        this.message.sort,
        response.$rootIds,
      ),
    };
  }
}
