import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Session } from "@/module/auth/model/session.model";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface RequestSessionMessage {
  username: string;
  password: string;
}

interface RequestSessionResponse {
  session: Session;
  activeSession: ActiveSession;
}

export class RequestSessionHandler extends ActionBase {
  constructor(private message: RequestSessionMessage) {
    super();
  }

  public async execute(): Promise<RequestSessionResponse> {
    const response = await Api.resource("auth", "/v2/auth/login")
      .method("put")
      .body(this.message)
      .result();

    const session = Session.$create({
      id: this.message.username,
      ...response,
    });

    session.$persist();

    const activeSession = ActiveSession.fromSession(session).$persist();

    if (false === session.mfaRequired) {
      await window.Kernel.ActionBus.execute("auth/session/complete", {
        activeSession,
      });
    }

    return { session, activeSession };
  }
}
