import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Api,
  IntroducerCompany,
  IntroducerContract,
  Order,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";

export interface DownloadIntroducerRemittanceMessage {
  introducerCompany: IntroducerCompany | string;
  introducerName?: string;
  brand: "kinherit";
  introducerCompanyType: "agent" | "introducer";
  downloadType:
    | "comp-adv-cli-pro"
    | "comp-adv-cli"
    | "comp-adv"
    | "comp"
    | "flying-colours"
    | "client-by-adviser"
    | "client-only"
    | "amount-only"
    | "sort-refer";
  introducerContract: IntroducerContract | string;
  orders: Array<Order | string>;
  feesType: "introducer" | "agent" | "parentAgent";
}

interface DownloadIntroducerRemittanceResponse {
  [key: string]: never;
}

export class DownloadIntroducerRemittanceHandler extends ActionBase {
  constructor(private message: DownloadIntroducerRemittanceMessage) {
    super();
  }

  public async execute(): Promise<DownloadIntroducerRemittanceResponse> {
    const remittanceType =
      this.message.feesType === "introducer"
        ? "Introducer"
        : this.message.feesType === "parentAgent"
          ? "PAgent"
          : "Agent";
    const fileName = `${
      new DateTime().formatYMD
    } ${remittanceType} Remittance - ${this.message.introducerName}.pdf`;

    await Api.resource("portal", `/v2/portal/introducer-fee/download`)
      .method("put")
      .body({
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
        introducerCompanyType: this.message.introducerCompanyType,
        brand: this.message.brand,
        introducerContract:
          "string" === typeof this.message.introducerContract
            ? this.message.introducerContract
            : this.message.introducerContract.id,
        orders: this.message.orders.map((order) =>
          "string" === typeof order ? order : order.id,
        ),
        feesType: this.message.feesType,
        downloadType: this.message.downloadType,
      })
      .download({
        encoding: "application/pdf",
        fileName: fileName,
      });

    return {};
  }
}
