import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface DeleteBrandedKinvaultInvitationMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface DeleteBrandedKinvaultInvitationResponse {
  [key: string]: never;
}

export class DeleteBrandedKinvaultInvitationHandler extends ActionBase {
  constructor(private message: DeleteBrandedKinvaultInvitationMessage) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultInvitationResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}",
      {
        brandedKinvaultInvitation: this.message.brandedKinvaultInvitation.id,
      },
    )
      .method("delete")
      .result();

    this.message.brandedKinvaultInvitation.$delete();

    return {};
  }
}
