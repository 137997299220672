import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePayment,
  IntroducerFeePayment,
  IntroducerFeePaymentItem,
  XeroContact,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentMessage {
  introducerFeePaymentRun: string;
  client?: string | null;
  xeroContact?: string | null;
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePayment;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentResponse {
  introducerFeePayments: Array<IntroducerFeePayment>;
  xeroContacts: XeroContact[];
  introducerFeePaymentItems: IntroducerFeePaymentItem[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerFeePaymentHandler extends ActionBase {
  constructor(private message: ReadIntroducerFeePaymentMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerFeePaymentResponse> {
    const request = Api.resource("portal", "/v2/portal/introducer-fee-payment")
      .method("get")
      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(IntroducerFeePayment).where({
      introducerFeePaymentRun: {
        id: Equal(this.message.introducerFeePaymentRun),
      },
      introducerFeePaymentItems: {
        order: {
          kintin: {
            friendlyName: Like(this.message.client),
          },
        },
      },
      xeroContact: {
        contactName: Like(this.message.xeroContact),
      },
      createdAt: Between(this.message.createdAt),
    });

    const response = await request.result();

    return {
      introducerFeePayments: IntroducerFeePayment.$inflate(
        response.introducerFeePayment,
        this.message.sort,
        response.$rootIds,
      ),
      xeroContacts: XeroContact.$inflate(response.xeroContact),
      introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
        response.introducerFeePaymentItem,
      ),
      pagination: this.getPagination(response),
    };
  }
}
