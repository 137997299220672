<template>
  <Card v-if="$data.form">
    <FormControls
      :form="$data.form"
      mode="update"
      :title="$data.form.localData.fullName ?? undefined"
      @cancel="$router.back"
      @save="save"
      :delete-button="false"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!profile" />
</template>

<script lang="ts">
import { KinvaultOfficerDetailsMixin } from "@/module/kinvault.officer/mixin/kinvault-officer-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsUpdateProfileRoute } from ".";

export default defineComponent({
  name: KinvaultOfficerDetailsUpdateProfileRoute,
  mixins: [
    AuthService.mixin({ sessionRequired: true, permissions: ["kintin:write"] }),
    KinvaultOfficerDetailsMixin,
  ],
  components: {
    PageNotFound,
    Card,
    FormControls,
  },
  data: () => ({
    form: null as null | FormBuilder<() => Profile, string>,
    profile: null as null | Profile,
  }),
  mounted(): void {
    if (null === this.$data.officer) {
      return;
    }

    this.profile = this.$data.officer.profile;
    this.form = UpdateProfileForm(this.profile as Profile);
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.$data.officer || null === this.profile) {
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/officer/profile/update", {
        officer: this.$data.officer,
      });

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.$data.form?.localData.$restore();
    },
  },
});
</script>
