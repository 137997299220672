import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCpd } from "@kinherit/sdk";

interface CreateIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface CreateIntroducerCpdResponse {
  introducerCpd: IntroducerCpd;
}

export class CreateIntroducerCpdHandler extends ActionBase {
  constructor(private message: CreateIntroducerCpdMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerCpdResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/cpd-session/introducer-cpd",
    )
      .method("post")

      .body({
        contact: this.message.introducerCpd.$data.contact,
        session: this.message.introducerCpd.$data.session,
        status: this.message.introducerCpd.$data.status,
        type: this.message.introducerCpd.$data.type,
      })
      .result();

    const introducerCpd = IntroducerCpd.$inflate(
      response.introducerCpd,
    ).first();

    if (!introducerCpd) {
      throw new Error("Failed to create Introducer Cpd");
    }

    this.message.introducerCpd.$delete();

    return { introducerCpd };
  }
}
