import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Api,
  FileLog,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";

export interface RecordKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument | string;
}

interface RecordKnowledgeBaseDocumentResponse {
  knowledgeBaseDocuments: Array<KnowledgeBaseDocument>;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  fileLogs: Array<FileLog>;
}

export class RecordKnowledgeBaseDocumentHandler extends ActionBase {
  constructor(private message: RecordKnowledgeBaseDocumentMessage) {
    super();
  }

  public async execute(): Promise<RecordKnowledgeBaseDocumentResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
      this.message,
    ).method("get");

    const response = await request.result();

    return {
      knowledgeBaseDocuments: KnowledgeBaseDocument.$inflate(
        response.knowledgeBaseDocument,
        undefined,
        response.$rootIds,
      ),
      knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
        response.knowledgeBaseCategory,
      ),
      fileLogs: FileLog.$inflate(response.fileLog),
    };
  }
}
