import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface CreateIntroducerContactPartnerPortalPermissionsMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface CreateIntroducerContactPartnerPortalPermissionsResponse {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

export class CreateIntroducerContactPartnerPortalPermissionsHandler extends ActionBase {
  constructor(
    private message: CreateIntroducerContactPartnerPortalPermissionsMessage,
  ) {
    super();
  }

  public async execute(): Promise<CreateIntroducerContactPartnerPortalPermissionsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions",
      {
        introducerContact:
          this.message.contactPartnerPortalPermissions.contact.$id,
      },
    )
      .method("post")
      .body({
        contact: this.message.contactPartnerPortalPermissions.contact.$id,
        company: this.message.contactPartnerPortalPermissions.company.$id,
        clientsRead:
          this.message.contactPartnerPortalPermissions.clientsRead ?? "none",
        clientsCreate:
          this.message.contactPartnerPortalPermissions.clientsCreate ?? false,
        feesRead:
          this.message.contactPartnerPortalPermissions.feesRead ?? "none",
        invitationsCreate:
          this.message.contactPartnerPortalPermissions.invitationsCreate ??
          false,
      })
      .result();

    const partnerPortalPermissions = ContactPartnerPortalPermissions.$inflate(
      response.contactPartnerPortalPermissions,
    ).first();

    if (!partnerPortalPermissions) {
      throw new Error("Failed to create partner portal permission");
    }

    return {
      contactPartnerPortalPermissions: partnerPortalPermissions,
    };
  }
}
