<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
    }"
  >
    <PageHeader htag="h2" text="Will Review">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <CallScriptSection title="Pre-call" :speech="false">
      Make sure you have the Will (if not – join the call anyway and just talk
      through the need to send it in to be reviewed). <br /><br />
      Then do a quick skim of the Will with the checklist below to hand, and jot
      down notes on the Will document as this is probably what the call will
      follow.
    </CallScriptSection>
    <CallScriptSection title="Preamble">
      <b>
        Hello there. Is that
        {{ primaryPerson?.profile.firstName }}
        {{ primaryPerson?.profile.lastName }}
        <span v-if="isJoint"
          >/ {{ secondaryPerson?.profile.firstName }}
          {{ secondaryPerson?.profile.lastName }}</span
        >?
      </b>
      <br />
      <em>[Pause]</em>
      <br />
      <br />
      <b>
        It's {{ $auth.loggedInUser?.profile.firstName }} calling from Kinherit.
        I have a Will-review call booked with you - Is it still a good time?
      </b>
      <br />
      <em>[If not, ask when’s a good time to call back]</em>
    </CallScriptSection>
    <CallScriptSecuritySection
      start="Great. So before we start, I need to do a quick security check if that’s OK?"
      :kintin="kintin"
      :kintin-checks="kintinChecks"
      :checks="checks"
    />
    <CallScriptSection title="Introduction">
      <b>
        OK great. So now we’ve got the security bit out of the way, can I start
        by saying it’s really good to meet you [both]!
        <br /><br />It’s probably helpful to quickly explain who I am and the
        process we’re going to go through today…. <br /><br />So, I’m [a
        qualified Estate planner / an Estate planning lawyer], which means I’m a
        specialist in helping people put their Wills and wider planning
        together. And I also review Wills written by other people – which is
        obviously what we have for you today. <br /><br />I’ll have a handful of
        questions for you – to check your Will matches your wishes – and I’ll
        also be looking at some of the key clauses we expect to find in Wills.
        <br /><br />If we find areas of concern I’ll raise them with you today
        and also in the report that I will send to you after the call.
        <br /><br />Is that all clear? Do you have any questions so far?
      </b>
    </CallScriptSection>
    <CallScriptSalutationSection
      :kintin="kintin"
      :kintin-checks="kintinChecks"
      :checks="checks"
    />
    <CallScriptAccountReferralSection
      :checks="checks"
      :kintin-checks="kintinChecks"
      step="2.3.1"
      :kintin="kintin"
      middle="
                  <b>
                  Can I ask, was there anything in particular that made you to think about reviewing your Will?
                  </b>
                  <br />
                  <em>[Pause. If low-engagement response say: “Was there anything specific, like a conversation or a change in circumstance?]</em>
                "
      end="
                  <b>
                  Oh great can you tell me more behind that?
                  </b>
                  <br />
                  <em>[Pause]</em>
                  <br />
                  <br />
                  <b>
                  That's great. It helps to know where you are coming from.
                  </b>
                "
    />
    <CallScriptSection title="Only Will?">
      <b>And is this your only Will?</b>
      <br /><br />
      <em>[If Yes]</em>
      <br />
      <b>
        It is worth destroying any prior Wills to ensure they don’t interfere
        with your current Will. (Obviously be careful not to accidentally
        destroy your current Will!)
      </b>
      <br /><br />
      <em>[If No]</em>
      <br />
      <b>
        If you ever change your Will, do make sure you destroy the prior
        versions.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Get Started">
      <b>
        OK so looking at your Will, the first thing I check for is the
        Revocations clause. [I see this on page X][I will check again after this
        call but struggling to spot it… it is a standard clause to cancel prior
        Wills and avoid dispute. I will put a flag down for this in the
        report]</b
      >
      <br />
      <em>
        Etc...do note which options trigger ‘output’ paragraphs… try to always
        talk through the bits where the report references to the talk-through!
      </em>
    </CallScriptSection>
    <Tabs :config="tabs" v-model:tab="tab">
      <template #input>
        <!-- Is a Revocation clause(s) present in the Will? (1.0.0)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.0"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Are Funeral wishes written into the Will? -->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.1"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          yes-label="Yes*"
          is-reversed
        />
        <!-- Are the Testator’s details all correct? (look for typos, marital status issues etc)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.2"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Has there been a subsequent marriage invalidating the Will?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.3"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!-- Are Beneficiary details all correct from a legal and benefits perspective?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.4"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Is there an appropriate number of ‘personal’ executors in the Will?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.5"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!--Is the Will-writer appointed as executor, or other obvious “moral-hazard” re probate?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.6"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!-- Is a professional appointed as executor by name (with unknown competence and cost at the time of death)?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.27"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--Have an appropriate number of guardians been listed?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.7"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!--Would we suggest financial provision for guardians be added to planning?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.8"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--Do we have drafting or other concerns about the gifts matching wishes or otherwise not being legally robust?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.9"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--Does Testator need R2R that isn’t properly covered in Will?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.10"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--Are there are gifts of property with multiple owners?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.11"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--How are those properties owned?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          v-if="checkIsYes('1.0.11')"
          step="1.0.12"
          :kintin="kintin"
          hide-add-note
          yes-label="Joint Tenants"
          no-label="Tenants in Common"
        />
        <!--Should the Joint Owned property be converted to Tenants in Common?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          v-if="checkIsYes('1.0.11')"
          step="1.0.13"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!--Is there an appropriate number of beneficiaries, properly defined?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.14"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Have any IHT concerns been identified (risk to avail. of RNRB, spousal exemption, Business Relief, etc)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.15"
          :kintin="kintin"
          hide-add-note
          yes-label="Yes*"
          is-reversed
        />
        <!-- (If needed) Is there an appropriate number of trustees listed, properly defined?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.16"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Is there a discretionary trust (or other estate protection trust) written into the Will?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.17"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Is there mention of STEP provisions?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.18"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Has the Will been properly signed, dated and witnessed, and with physical Will intact and in a known and accessible location?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.19"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Does the client have LPAs in place?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.20"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Does the client have wider end-of-life planning in place (clear lists of assets and gifts, and instructions on death covering eg utilities and pets and care)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.21"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Does the client have a handover-plan on death?-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.22"
          :kintin="kintin"
          hide-add-note
          no-label="No*"
          is-reversed
        />
        <!-- Are there other concerns re legal validity (add note)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.23"
          :kintin="kintin"
        />
        <!--  Are there other concerns re the roles as defined in the Will (add note)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.24"
          :kintin="kintin"
        />
        <!-- Are there other observations on tax planning, the home and estate protection (add note)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.25"
          :kintin="kintin"
        />
        <!-- Are there other concerns re legal validity (add note)-->
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="1.0.26"
          :kintin="kintin"
        />
      </template>
      <template #output>
        <WillReviewOutput :kintin="kintin" :checks="checkValues" />
      </template>
    </Tabs>
    <CallScriptSection title="Complete the Call">
      <b>
        OK so that is everything on the Will-review side, and I will put the
        conclusions in a report to send to you shortly. The password to the
        report will be [your] six-digit date of birth.
      </b>
      <br />
      <br />
      <b>
        After today, if you are happy to stick with your Will – we can still
        help you with wider handover planning. This is done via our Kinvault
        digital platform – I will send you a link to it in case of interest.
      </b>
      <br />
      <br />
      <b>
        And we can also do things like host physical Wills, and help with things
        like Lasting Powers of Attorney and Trust Registration.
      </b>
      <br />
      <br />
      <b> Is there anything else I can help you with today? </b>
      <br />
      <em>[pause, if nothing…]</em>
      <br />
      <br />
      <b> OK nice talking to you, thanks / bye </b>
    </CallScriptSection>
    <CallScriptSection>
      <Button
        text="Generate Will Review"
        @click="willReviewGenerate(false, primaryPerson)"
      />
      <Button
        text="Generate Will Review with Password"
        color="is-positive"
        @click="willReviewGenerate(true, primaryPerson)"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import WillReviewOutput from "@/module/kinvault.kintin/component/call-script/WillReviewOutput.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptAccountReferralSection from "@/module/kinvault.kintin/component/call-script/shared/ReferralSection.vue";
import CallScriptSalutationSection from "@/module/kinvault.kintin/component/call-script/shared/SalutationSection.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";

import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KintinDetailsCallScriptParams,
  KintinDetailsCallScriptRoute,
} from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptIntroRoute } from "../intro";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    Button,
    PageHeader,
    CallScriptSection,
    ConfirmCheck,
    CallScriptSecuritySection,
    CallScriptAccountReferralSection,
    CallScriptSalutationSection,
    Tabs,
    WillReviewOutput,
  },
  data: () => ({
    isFocused: false,
    tabs: [{ label: "Input" }, { label: "Output" }] satisfies TabOptions[],
    tab: 0,
  }),
  mounted(): void {
    if (!this.kintin) {
      return;
    }
  },
  methods: {
    async willReviewGenerate(withPassword: boolean, person: Person) {
      const kintin = this.kintin;

      const content = document
        .getElementsByClassName("will-review-output")
        .item(0)?.innerHTML;

      if (!kintin || !content) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/will-review/download",
        {
          kintin,
          person,
          content,
          withPassword,
        },
      );
    },
    async saveAndContinueHandler(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptIntroRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/update", {
        kintin: this.kintin,
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
        person: this.kintin.primaryPerson,
        kintin: this.kintin,
      });

      if (this.kintin.secondaryPerson) {
        await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
          person: this.kintin.secondaryPerson,
          kintin: this.kintin,
        });
      }

      const referral = this.kintin.referral;

      if (referral) {
        await window.Kernel.ActionBus.execute("core/account-referral/update", {
          referral,
        });
      }

      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
