import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface CreateIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export interface CreateIntroducerFeePaymentItemResponse {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export class CreateIntroducerFeePaymentItemHandler extends ActionBase {
  constructor(private message: CreateIntroducerFeePaymentItemMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerFeePaymentItemResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-item",
    )
      .method("post")
      .body({
        amount: this.message.introducerFeePaymentItem.amount,
        feeTypeId: this.message.introducerFeePaymentItem.feeTypeId,
        introducerFeePayment:
          this.message.introducerFeePaymentItem.introducerFeePayment.$id,
        name: this.message.introducerFeePaymentItem.name,
        order: this.message.introducerFeePaymentItem.order.$id,
        vat: this.message.introducerFeePaymentItem.vat,
      })
      .result();

    const introducerFeePaymentItem = IntroducerFeePaymentItem.$inflate(
      repsonse.introducerFeePaymentItem,
    ).first();

    if (!introducerFeePaymentItem) {
      throw new Error("Failed to create introducerfeepaymentitem");
    }

    this.message.introducerFeePaymentItem.$delete();

    return {
      introducerFeePaymentItem: introducerFeePaymentItem,
    };
  }
}
