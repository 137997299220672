import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  Adviser,
  Attorney,
  Beneficiary,
  EmailAddress,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Person,
  PhoneNumber,
  Profile,
  Trustee,
  Website,
} from "@kinherit/sdk";

interface RecordKintinPersonMessage {
  person: string | Person;
  kintin: string | Kintin;
}

interface RecordKintinPersonResponse {
  profiles: Array<Profile>;
  people: Array<Person>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  advisers: Array<Adviser>;
  attorneys: Array<Attorney>;
  beneficiaries: Array<Beneficiary>;
  executors: Array<Executor>;
  gifts: Array<Gift>;
  guardians: Array<Guardian>;
  trustees: Array<Trustee>;
}

export class RecordKintinPersonHandler extends ActionBase {
  constructor(private message: RecordKintinPersonMessage) {
    super();
  }

  public async execute(): Promise<RecordKintinPersonResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-person/{kintin}/person/{person}",
      {
        kintin: this.message.kintin,
        person: this.message.person,
      },
    )
      .method("get")

      .result();

    return {
      profiles: Profile.$inflate(response.profile),
      people: Person.$inflate(response.person),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
      advisers: Adviser.$inflate(response.adviser),
      attorneys: Attorney.$inflate(response.attorney),
      beneficiaries: Beneficiary.$inflate(response.beneficiary),
      executors: Executor.$inflate(response.executor),
      gifts: Gift.$inflate(response.gift),
      guardians: Guardian.$inflate(response.guardian),
      trustees: Trustee.$inflate(response.trustee),
    };
  }
}
