import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface Message {
  file: File;
}

interface Response {
  firstNameColumnTitle: string | null;
  lastNameColumnTitle: string | null;
  emailColumnTitle: string | null;
  adviserEmailColumnTitle: string | null;
  referenceColumnTitle: string | null;
  referralCodeColumnTitle: string | null;
  duplicateEmails: string[];
}

export class ValidateBrandedKinvaultInvitationCsvHandler extends ActionBase {
  constructor(private message: Message) {
    super();
  }

  public async execute(): Promise<Response> {
    const { file } = this.message;

    return await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/csv/validate",
    )
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();
  }
}
