import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ILead, Lead } from "@kinherit/sdk";

interface DeleteLeadMessage {
  lead: string | Lead;
  sort?: {
    by: keyof ILead;
    direction: "asc" | "desc";
  };
}

interface DeleteLeadResponse {
  [key: string]: never;
}

export class DeleteLeadHandler extends ActionBase {
  constructor(private message: DeleteLeadMessage) {
    super();
  }

  public async execute(): Promise<DeleteLeadResponse> {
    await Api.resource("portal", "/v2/portal/lead/{lead}", {
      lead: this.message.lead,
    })
      .method("delete")

      .result();

    Lead.$findOne(
      "string" === typeof this.message.lead
        ? this.message.lead
        : this.message.lead.id,
    )?.$delete();

    return {};
  }
}
