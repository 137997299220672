import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeLeadsByEpReportMessage {
  created: [number, number] | null;
  compareTo: string | null;
  referral: string | null;
  referrerCategory: string | null;
}

type TStatusCount = {
  status: string;
  count: number;
};

export interface ComputeLeadsByEpReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<TLeadsByEpReportResult>;
}

export type TLeadsByEpReportResult = {
  estatePlanner: string;
  estatePlannerId: string;
  leadStatuses: Array<TStatusCount>;
  kintinStatuses: Array<TStatusCount>;
  kintinStages: Array<TStatusCount>;
  lead: {
    count: number;
    pendingCount: number;
    dropoutCount: number;
    convertedCount: number;
  };
  account: {
    count: number;
    unpaidCount: number;
    notNowCount: number;
    dropoutCount: number;
    paidCount: number;
  };
};
export class ComputeLeadsByEpReportHandler extends ActionBase {
  constructor(private message: ComputeLeadsByEpReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeLeadsByEpReportResponse> {
    const { created, referral, compareTo, referrerCategory } =
      this.message || {};

    const calculatedDates = calculateStartEndDates(
      "custom",
      created,
      compareTo,
    );

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/leads/by-ep",
      {},
    )
      .method("get")
      .params({
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        referral: referral ?? null,
        referrerCategory: referrerCategory ?? null,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];

    const colours = ["#0896A4", "#006B76"];
    // For each dataset returned, create a new dataset object with the correct format
    // for the chart.js graph
    response.datasets.forEach((dataset: any, index: number) => {
      console.log(dataset);
      datasets.push({
        label: "Leads",
        stack: "Stack " + index,
        backgroundColor: colours[index],
        data: dataset.datasets.map((i: any) => {
          return i.lead.count;
        }),
      });
    });

    return {
      datasets: datasets,
      labels: response.labels.map((label) => label.toString()),
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
