import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaignLog, EmailTemplate, FileLog } from "@kinherit/sdk";

export interface RecordEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog | string;
}

export interface RecordEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
  emailTemplate: Array<EmailTemplate>;
  fileLogs: Array<FileLog>;
}

export class RecordEmailCampaignLogHandler extends ActionBase {
  constructor(private message: RecordEmailCampaignLogMessage) {
    super();
  }

  public async execute(): Promise<RecordEmailCampaignLogResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign-log/{emailCampaignLog}",
      this.message,
    ).method("get");
    const response = await request.result();

    return {
      emailCampaignLog: EmailCampaignLog.$inflate(
        response.emailCampaignLog,
        undefined,
        response.$rootIds,
      ),
      emailTemplate: EmailTemplate.$inflate(response.emailTemplate),
      fileLogs: FileLog.$inflate(response.fileLog),
    };
  }
}
