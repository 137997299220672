import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvault } from "@kinherit/sdk/index";
import { DateTime } from "@kinherit/ts-common/index";

export class ComputeBrandedKinvaultKinvaultFeesHandler extends ActionBase {
  constructor(
    private readonly data: {
      brandedKinvault: string | BrandedKinvault;
      year: string;
    },
  ) {
    super();
  }

  public async execute(): Promise<{
    months: {
      year: number;
      month: number;
      newUsers: number;
      renewedUsers: number;
      activeUsers: number;
      pricePerUnit: number;
      newUserTotalPrice: number;
      renewedUserTotalPrice: number;
      totalPrice: number;
    }[];
  }> {
    const startDate = DateTime.fromDate(
      new Date(this.data.year.toNumber(), 0, 1),
    ).formatYMD;
    const endDate = DateTime.fromDate(
      new Date(this.data.year.toNumber(), 11, 31),
    ).formatYMD;

    return await window.Kernel.Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-kinvault-fees/{brandedKinvault}",
      {
        brandedKinvault: this.data.brandedKinvault,
      },
    )
      .method("get")
      .params({
        startDate,
        endDate,
      })
      .result();
  }
}
