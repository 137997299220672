import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailTemplate } from "@kinherit/sdk";

export interface RecordEmailTemplateMessage {
  emailTemplate: EmailTemplate | string;
}

interface RecordEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export class RecordEmailTemplateHandler extends ActionBase {
  constructor(private message: RecordEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<RecordEmailTemplateResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/email-template/{emailTemplate}",
      {
        emailTemplate: this.message.emailTemplate,
      },
    )
      .method("get")

      .result();

    return {
      emailTemplates: EmailTemplate.$inflate(
        response.emailTemplate,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
