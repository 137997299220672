import { DownloadOfficerLetterHandler } from "@/module/kinvault.officer/action/download-offier-letter.action";
import { sendOfficerLetterHandler } from "@/module/kinvault.officer/action/send-offier-letter.action";
import { ReadOfficerHandler } from "./read-officer.action";
import { RecordOfficerHandler } from "./record-officer.action";
import { UpdateOfficerCallStatusHandler } from "./update-officer-call-status.action";
import { UpdateOfficerProfileHandler } from "./update-officer-profile.action";

export const Actions = {
  "kinvault/officer/read": ReadOfficerHandler,
  "kinvault/officer/record": RecordOfficerHandler,
  "kinvault/officer/profile/update": UpdateOfficerProfileHandler,
  "kinvault/officer/call-status/update": UpdateOfficerCallStatusHandler,
  "kinvault/officer/letter/download": DownloadOfficerLetterHandler,
  "kinvault/officer/letter/send": sendOfficerLetterHandler,
};
