<template>
  <PageHeader htag="h1" text="Trusted People"> </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :isClickable="false"
    is-expanded
    :sort-by-options="{
      friendlyName: 'Client',
      createdAt: `Created`,
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    is-fixed
  >
    <template #customer="scope">
      <a @click="(event) => kintinClicked(scope.row, event)">
        {{ scope.row.customer.friendlyName }}
      </a>
    </template>
    <template #details="{ row }">
      <Table
        v-if="row.people.length > 0"
        :key="`officer-table-${row.customer.id}`"
        :showHeaderControls="false"
        :show-footer-controls="false"
        :is-clickable="false"
        size="is-small"
        :rows="row.people"
        is-fixed
        :columns="[
          { title: 'Officers', slot: 'officer' } as TableColumn,
          { title: 'Trusted', slot: 'trusted' } as TableColumn,
          { title: 'Notifications', slot: 'notifications' } as TableColumn,
          { title: 'Age', slot: 'age' } as TableColumn,
          { title: 'Roles', slot: 'roles' } as TableColumn,
          { title: 'Status', slot: 'status' } as TableColumn,
        ]"
      >
        <template #officer="scope">
          <a
            class="view-officer"
            @click="(event) => officerClicked(scope.row, event)"
            >{{ scope.row.profile.fullName }}</a
          >
        </template>
        <template #trusted="{ row }">
          <Icon
            v-if="row.isTrusted"
            icon="Success"
            size="is-small"
            class="is-success"
          />
          <Icon v-else icon="Cross" size="is-small" class="is-warning" />
        </template>
        <template #notifications="{ row }">
          <Icon
            v-if="row.notifications"
            icon="Success"
            size="is-small"
            class="is-success"
          />
          <Icon v-else icon="Cross" size="is-small" class="is-warning" />
        </template>
        <template #age="{ row }">
          <span v-if="row.dateOfBirth">
            {{ row.dateOfBirth.getAge() }}
          </span>
        </template>
        <template #roles="scope">
          <template
            v-for="person in row.officers.filter(
              (officer: Officer) => officer.person.id === scope.row.id,
            )"
          >
            <Badge
              v-if="person.isReserve"
              size="is-small"
              style="background-color: var(--color-scheme-main-bis)"
              :key="`officer-reserve-${person.id}`"
            >
              {{ person.$name }} (Reserve)
            </Badge>
            <Badge
              v-else
              size="is-small"
              style="background-color: var(--color-scheme-main)"
              :key="`officer-${person.id}`"
            >
              <span v-if="person.$data.isCertProv"> Certificate Provider </span>
              <span v-else>{{ person.$name }}</span>
            </Badge>
          </template>
        </template>
        <template #status="scope">
          <OfficerCallStatusWidget :officer="scope.row" is-fullwidth />
        </template>
      </Table>
      <div v-else>
        <div class="columns">
          <div class="column">
            <p class="has-text-grey has-text-centered">No officers</p>
          </div>
        </div>
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { ReadOfficerForm } from "@/module/kinvault.officer/form/read-officer.form";
import { StyleService } from "@/service/style.service";
import Badge from "@kinherit/framework/component.display/badge";
import Icon from "@kinherit/framework/component.display/icon";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  Attorney,
  Executor,
  Guardian,
  IPerson,
  Kintin,
  Person,
  Trustee,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultOfficerMasterListRoute } from ".";
import OfficerCallStatusWidget from "../component/OfficerCallStatusWidget.vue";
import {
  KinvaultOfficerDetailsParams,
  KinvaultOfficerDetailsRoute,
} from "./details";

import { DateTime } from "@kinherit/ts-common";

type Officer = Trustee | Executor | Attorney | Guardian;

interface TableRow {
  customer: Kintin;
  people: Array<Person>;
  officers: Array<Officer>;
}

export default defineComponent({
  name: KinvaultOfficerMasterListRoute,
  mixins: [StyleService.mixin],
  components: {
    MasterListPage,
    OfficerCallStatusWidget,
    Table,
    Badge,
    PageHeader,
    Icon,
  },
  data: () => ({
    filters: ReadOfficerForm(),
    columns: [
      {
        title: "Customer",
        sort: true,
        slot: "customer",
      },
    ] as Array<TableColumn>,
    rows: Array<TableRow>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IPerson,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    today(): DateTime {
      return new DateTime();
    },
  },
  methods: {
    kintinClicked(row: TableRow, event: MouseEvent): void {
      const params: KinvaultKintinDetailsParams = { kintin: row.customer.id };

      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    officerClicked(person: Person, event: MouseEvent): void {
      const params: KinvaultOfficerDetailsParams = { officer: person.id };

      window.Kernel.visitRoute(
        {
          name: KinvaultOfficerDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadOfficerForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "kinvault/officer/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.kintins
        .map((kintin) => {
          const officers = [
            ...(kintin.primaryPerson?.appointedExecutor ?? []),
            ...(kintin.secondaryPerson?.appointedExecutor ?? []),
            ...(kintin.primaryPerson?.appointedAttorney ?? []),
            ...(kintin.secondaryPerson?.appointedAttorney ?? []),
            ...(kintin.primaryPerson?.appointedGuardian ?? []),
            ...(kintin.secondaryPerson?.appointedGuardian ?? []),
            ...(kintin.primaryPerson?.appointedTrustee ?? []),
            ...(kintin.secondaryPerson?.appointedTrustee ?? []),
          ];

          return {
            customer: kintin,
            people: officers
              .map((officer2) => officer2.person)
              .unique("id")
              .filter((person) => {
                const statuses = this.filters.localData?.status ?? [];

                if (statuses.length === 0) {
                  return true;
                }

                return statuses.includes(
                  person.$data.officerCallStatus as string,
                );
              })
              .sortBy("profile.fullName"),
            officers: officers.filter((officer2, index, array) => {
              return array.indexOf(officer2) === index;
            }),
          };
        })
        .flat();

      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

<style lang="scss">
.officer-row:hover {
  background-color: var(--color-scheme-main-bis);
}
</style>
