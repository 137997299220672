import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCompany } from "@kinherit/sdk";

interface DeleteIntroducerCompanyMessage {
  introducerCompany: string | IntroducerCompany;
}

interface DeleteIntroducerCompanyResponse {
  [key: string]: never;
}

export class DeleteIntroducerCompanyHandler extends ActionBase {
  constructor(private message: DeleteIntroducerCompanyMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerCompanyResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}",
      {
        introducerCompany: this.message.introducerCompany,
      },
    )
      .method("delete")

      .result();

    IntroducerCompany.$delete(this.message.introducerCompany);

    return {};
  }
}
