import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerOutsource } from "@kinherit/sdk";

export interface DeleteIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource | string;
}

interface DeleteIntroducerOutsourceResponse {
  [key: string]: never;
}

export class DeleteIntroducerOutsourceHandler extends ActionBase {
  constructor(private message: DeleteIntroducerOutsourceMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerOutsourceResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-outsource/{introducerOutsource}",
      {
        introducerOutsource: this.message.introducerOutsource,
      },
    )
      .method("delete")

      .result();

    if (this.message.introducerOutsource instanceof IntroducerOutsource) {
      this.message.introducerOutsource.$delete();
    } else {
      IntroducerOutsource.$findOne(this.message.introducerOutsource)?.$delete();
    }

    return {};
  }
}
