import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Currency } from "@kinherit/ts-common/index";

interface CreateXeroRepeatingInvoiceMessage {
  price: Currency;
  xeroContactId: string;
  subscriptionId: string;
  quantity?: number;
  description?: string;
  accountCode?: string;
}

interface CreateXeroRepeatingInvoiceResponse {}

export class CreateXeroRepeatingInvoiceHandler extends ActionBase {
  constructor(private message: CreateXeroRepeatingInvoiceMessage) {
    super();
  }

  public async execute(): Promise<CreateXeroRepeatingInvoiceResponse> {
    const response = await Api.resource(
      "external",
      "/v2/external/xero/create-repeating-invoice",
    )
      .method("post")

      .body({
        price: this.message.price?.amount ?? 0,
        xeroContactId: this.message.xeroContactId,
        subscriptionId: this.message.subscriptionId,
        quantity: this.message.quantity,
        description: this.message.description,
        accountCode: this.message.accountCode,
      })
      .result();

    console.log(response);
    return {};
  }
}
