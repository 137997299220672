import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinCheck, Note } from "@kinherit/sdk";

interface ReadKintinWillRevisionChecksMessage {
  kintin: Kintin | string;
}

interface ReadKintinWillRevisionChecksResponse {
  kintinChecks: KintinCheck[];
  notes: Note[];
}

export class ReadKintinWillRevisionChecksHandler extends ActionBase {
  constructor(private message: ReadKintinWillRevisionChecksMessage) {
    super();
  }

  public async execute(): Promise<ReadKintinWillRevisionChecksResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-will-revision/{kintin}/checks",
      {
        kintin: this.message.kintin,
      },
    )
      .method("get")

      .result();

    return {
      kintinChecks: KintinCheck.$inflate(response.kintinCheck),
      notes: Note.$inflate(response.note),
    };
  }
}
