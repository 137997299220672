import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface UpdateDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface UpdateDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export class UpdateDefaultBrandedKinvaultReferralCodeHandler extends ActionBase {
  constructor(
    private message: UpdateDefaultBrandedKinvaultReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<UpdateDefaultBrandedKinvaultReferralCodeResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
      {
        defaultBrandedKinvaultReferralCode:
          this.message.defaultBrandedKinvaultReferralCode,
      },
    )
      .method("patch")

      .body({
        code: this.message.defaultBrandedKinvaultReferralCode.$data.code,
        name: this.message.defaultBrandedKinvaultReferralCode.$data.name,
      })
      .result();

    const defaultBrandedKinvaultReferralCode =
      DefaultBrandedKinvaultReferralCode.$inflate(
        response.defaultBrandedKinvaultReferralCode,
        undefined,
        response.$rootIds,
      ).first();

    if (!defaultBrandedKinvaultReferralCode) {
      throw new Error("Failed to create subscription referral code");
    }

    return {
      defaultBrandedKinvaultReferralCode,
    };
  }
}
