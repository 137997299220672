import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePaymentRun,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentRunMessage {
  name?: string | null;
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePaymentRun;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentRunResponse {
  introducerFeePaymentRuns: Array<IntroducerFeePaymentRun>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: ReadIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerFeePaymentRunResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run",
    )
      .method("get")
      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(IntroducerFeePaymentRun).where({
      name: Like(this.message.name),
      createdAt: Between(this.message.createdAt),
    });

    const response = await request.result();

    return {
      introducerFeePaymentRuns: IntroducerFeePaymentRun.$inflate(
        response.introducerFeePaymentRun,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
