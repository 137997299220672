import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCompany } from "@kinherit/sdk";

interface UpdateIntroducerCompanyMessage {
  introducerCompany: IntroducerCompany;
}

interface UpdateIntroducerCompanyResponse {
  introducerCompany: IntroducerCompany;
}

export class UpdateIntroducerCompanyHandler extends ActionBase {
  constructor(private message: UpdateIntroducerCompanyMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerCompanyResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}",
      {
        introducerCompany: this.message.introducerCompany,
      },
    )
      .method("patch")
      .body({
        network: this.message.introducerCompany.$data.network,
        kinvaultStatus: this.message.introducerCompany.$data.kinvaultStatus,
        description: this.message.introducerCompany.$data.description,
        lastConversation: this.message.introducerCompany.$data.lastConversation,
        trustRegXeroContactId:
          this.message.introducerCompany.$data.trustRegXeroContactId,
        kintinCount: this.message.introducerCompany.$data.kintinCount,
        assignedTo: this.message.introducerCompany.$data.assignedTo,
        defaultLegalAssistant:
          this.message.introducerCompany.$data.defaultLegalAssistant,
        type: this.message.introducerCompany.$data.type,
        stage: this.message.introducerCompany.$data.stage,
        status: this.message.introducerCompany.$data.status,
        seniorAdviser: this.message.introducerCompany.$data.seniorAdviser,
        introducedBy: this.message.introducerCompany.$data.introducedBy,
        tags: this.message.introducerCompany.$data.tags,
        contractNotes: this.message.introducerCompany.$data.contractNotes,
        xeroContact: this.message.introducerCompany.$data.xeroContact,
      })
      .result();

    const introducerCompany = IntroducerCompany.$inflate(
      response.introducerCompany,
    ).first();

    if (!introducerCompany) {
      throw new Error("Failed to update introducer company");
    }

    return {
      introducerCompany,
    };
  }
}
