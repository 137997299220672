import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Order, OrderItem } from "@kinherit/sdk";

export interface UpdateOrdersMessage {
  order: Order;
}

export interface UpdateOrdersResponse {
  order: Order;
  orderItems: Array<OrderItem>;
}

export class UpdateOrderHandler extends ActionBase {
  constructor(private message: UpdateOrdersMessage) {
    super();
  }

  public async execute(): Promise<UpdateOrdersResponse> {
    await Api.resource("portal", "/v2/portal/order/{order}", {
      order: this.message.order.id,
    })
      .method("patch")

      .body({
        status: this.message.order.$data.status,
        paymentType: this.message.order.$data.paymentType,
        extrasPrice: this.message.order.$data.extrasPrice,
        discountValue: this.message.order.$data.discountValue,
        discountPercentage: this.message.order.$data.discountPercentage,
        coolingOff: this.message.order.$data.coolingOff,
        paidAt: this.message.order.$data.paidAt,
        invoiceNumber: this.message.order.$data.invoiceNumber,
        notes: this.message.order.$data.notes,
        paymentSignKey: this.message.order.$data.paymentSignKey,
        transactionId: this.message.order.$data.transactionId,
        overrideListPrice: this.message.order.$data.overrideListPrice,
        feesPayOutIntro: this.message.order.$data.feesPayOutIntro,
        feesPayoutAgent: this.message.order.$data.feesPayoutAgent,
        feesPayOutIntroDate: this.message.order.$data.feesPayOutIntroDate,
        feesPayOutAgentDate: this.message.order.$data.feesPayOutAgentDate,
        feesPayOutParentAgent: this.message.order.$data.feesPayOutParentAgent,
        feesPayOutParentAgentDate:
          this.message.order.$data.feesPayOutParentAgentDate,
        feesConfirmed: this.message.order.$data.feesConfirmed,
        discountReason: this.message.order.$data.discountReason,
        xeroIntroducerBillId: this.message.order.$data.xeroIntroducerBillId,
        xeroAgentBillId: this.message.order.$data.xeroAgentBillId,
        billingName: this.message.order.$data.billingName,
        billingAddress1: this.message.order.$data.billingAddress1,
        billingAddress2: this.message.order.$data.billingAddress2,
        billingCity: this.message.order.$data.billingCity,
        billingRegion: this.message.order.$data.billingRegion,
        billingPostalCode: this.message.order.$data.billingPostalCode,
        xeroParentAgentBillId: this.message.order.$data.xeroParentAgentBillId,
        createdBy: this.message.order.$data.createdBy,
      })
      .result();

    await Api.resource("portal", "/v2/portal/order/order-items")
      .method("put")

      .body({
        order: this.message.order.id,
        orderItems: this.message.order.orderItems.map((orderItem) => ({
          id: orderItem.id,
          text: orderItem.text,
          value: orderItem.value,
          price: { amount: orderItem.price.amount, type: orderItem.price.type },
          quantity: orderItem.quantity,
          requiresDd: orderItem.requiresDd,
          recurring: orderItem.recurring,
          product: orderItem.product?.id ?? null,
          vat: orderItem.vat,
        })),
      })
      .result();

    this.message.order.orderItems.forEach((orderItem) => orderItem.$delete());

    await window.Kernel.ActionBus.execute("order/order/record", {
      order: this.message.order,
    });

    return {
      order: Order.$findOneOrThrow(this.message.order.id),
      orderItems: OrderItem.$findBy({
        order: {
          id: this.message.order.id,
        },
      }),
    };
  }
}
