import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  CreditNote,
  ICreditNote,
  Kintin,
  Note,
  Order,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadCreditNoteMessage {
  query: QueryMask<typeof CreditNote>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof ICreditNote;
    direction: "asc" | "desc";
  };
}

export interface ReadCreditNoteResponse {
  creditNotes: Array<CreditNote>;
  notes: Array<Note>;
  users: Array<User>;
  profiles: Array<Profile>;
  orders: Array<Order>;
  kintins: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadCreditNoteHandler extends ActionBase {
  constructor(public readonly message: ReadCreditNoteMessage) {
    super();
  }

  public async execute(): Promise<ReadCreditNoteResponse> {
    const request = Api.resource("portal", "/v2/portal/credit-note")
      .method("get")
      .sort(this.message.sort)
      .paginate(this.message.pagination);
    request.buildQuery(CreditNote).where(this.message.query);

    const response = await request.result();

    return {
      creditNotes: CreditNote.$inflate(
        response.creditNote,
        this.message.sort,
        response.$rootIds,
      ),
      notes: Note.$inflate(response.note),
      users: User.$inflate(response.user),
      profiles: Profile.$inflate(response.profile),
      orders: Order.$inflate(response.order),
      kintins: Kintin.$inflate(response.kintin),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
