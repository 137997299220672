import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvault } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
}

interface DeleteBrandedKinvaultResponse {
  [key: string]: never;
}

export class DeleteBrandedKinvaultHandler extends ActionBase {
  constructor(private message: DeleteBrandedKinvaultMessage) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/{brandedKinvault}",
      this.message,
    ).method("delete");

    await request.result();

    return {};
  }
}
