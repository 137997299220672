<template>
  <PageHeader htag="h1" text="Staff Users">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create User
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :row-classes="rowClasses"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      'credential.lastLoggedIn': 'Last Login',
      'profile.fullName': 'Name',
      'credential.username': 'Username',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #name="{ row }"
      ><strong>{{ row.profile.fullName }}</strong
      ><br /><small> {{ row.credential.username }}</small>
    </template>
    <template #disabled="{ row }">{{ row.status?.text }} </template>
    <template #portal="{ row }">
      <Icon
        v-if="row.displayUserInPortal"
        icon="Tick"
        class="has-text-success"
      />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #emailNotifications="{ row }">
      <Icon
        v-if="row.sendEmailNotifications"
        icon="Tick"
        class="has-text-success"
      />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #smsNotifications="{ row }">
      <Icon
        v-if="row.sendSmsNotifications"
        icon="Tick"
        class="has-text-success"
      />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #isEstatePlanner="{ row }">
      <Icon v-if="row.isEstatePlanner" icon="Tick" class="has-text-success" />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #isLegalAssistant="{ row }">
      <Icon v-if="row.isLegalAssistant" icon="Tick" class="has-text-success" />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #acuity="{ row }">
      <Icon v-if="row.acuityUserId" icon="Tick" class="has-text-success" />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { IUser, User } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AdminStaffMasterListRoute } from ".";
import { CreateUserForm } from "../form/create-user.form";
import { ReadStaffUserForm } from "../form/read-staff-user.form";
import { AdminUserDetailsParams, AdminUserDetailsRoute } from "./details";

export const StaffUserTableColumns: Array<TableColumn> = [
  {
    title: "Name",
    sort: true,
    slot: "name",
  },
  {
    title: "Roles",
    map: (user: User) => user.roles.map((role) => role.role).join(", "),
  },
  {
    title: "Status",
    slot: "disabled",
  },
  {
    title: "Display Portal",
    slot: "portal",
  },
  {
    title: "EP",
    slot: "isEstatePlanner",
  },
  {
    title: "LA",
    slot: "isLegalAssistant",
  },
  {
    title: "Has Acuity",
    slot: "acuity",
  },
  {
    title: "Email Notifications",
    slot: "emailNotifications",
  },
  {
    title: "SMS",
    slot: "smsNotifications",
  },
];

export default defineComponent({
  name: AdminStaffMasterListRoute,
  components: { MasterListPage, Button, PageHeader, Icon },
  data: () => ({
    filters: ReadStaffUserForm(),
    columns: StaffUserTableColumns,
    rows: Array<User>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IUser,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("user:write");
    },
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light is-qualified-out":
          scope.row.status?.value === "disabled",
      };
    },
    handleClick(user: User, event: MouseEvent): void {
      const params: AdminUserDetailsParams = { user: user.id };

      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadStaffUserForm>["localData"],
    ): Promise<void> {
      const requestData = {
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
        roles: ["staff"],
      };

      const data = await window.Kernel.ActionBus.execute(
        "admin/user/staff/read",
        requestData,
      );

      this.rows = data.users;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const user = await CreateUserForm().dialog({
        dialog: {
          title: "Create User",
        },
      });

      const result = await window.Kernel.ActionBus.execute(
        "admin/user/create",
        user,
      );

      await OpenAlertDialog({
        dialog: {
          title: "User Created",
          message: `Successfully create ${result.user.profile.fullName} `,
        },
        button: {
          ok: {
            text: "View User",
            color: "is-positive",
          },
          cancel: {
            text: "Return",
          },
        },
      });

      const params: AdminUserDetailsParams = { user: result.user.id };

      window.Kernel.visitRoute({
        name: AdminUserDetailsRoute,
        params,
      });
    },
  },
});
</script>
