import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Lead } from "@kinherit/sdk";

interface UpdateLeadSettingsMessage {
  lead: Lead;
}

interface UpdateLeadSettingsResponse {
  lead: Lead;
}

export class UpdateLeadSettingsHandler extends ActionBase {
  constructor(private message: UpdateLeadSettingsMessage) {
    super();
  }

  public async execute(): Promise<UpdateLeadSettingsResponse> {
    const response = await Api.resource("portal", "/v2/portal/lead/{lead}", {
      lead: this.message.lead,
    })
      .method("patch")

      .body({
        ownedBy: this.message.lead.$data.ownedBy,
        assignedTo: this.message.lead.$data.assignedTo,
        status: this.message.lead.$data.status,
        tags: this.message.lead.$data.tags,
        externalLeadId: this.message.lead.$data.externalLeadId,
        description: this.message.lead.$data.description,
      })
      .result();

    const lead = Lead.$inflate(response.lead).first();

    if (!lead) {
      throw new Error("Failed to create lead");
    }

    return {
      lead,
    };
  }
}
