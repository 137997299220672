import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CreditNote } from "@kinherit/sdk";

export interface UpdateCreditNoteMessage {
  creditNote: CreditNote;
}

export interface UpdateCreditNoteResponse {
  creditNote: CreditNote;
}

export class UpdateCreditNoteHandler extends ActionBase {
  constructor(public readonly message: UpdateCreditNoteMessage) {
    super();
  }

  public async execute(): Promise<UpdateCreditNoteResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/credit-note/{creditNote}",
      {
        creditNote: this.message.creditNote,
      },
    )
      .method("patch")
      .body({
        issuedAt: this.message.creditNote.$data.issuedAt,
        issuedBy: this.message.creditNote.$data.issuedBy,
        note: {
          completedAt: this.message.creditNote.note.$data.completedAt,
          dueAt: this.message.creditNote.note.$data.dueAt,
          kintin: this.message.creditNote.note.$data.kintin,
          name: this.message.creditNote.note.$data.name,
          notes: this.message.creditNote.note.$data.notes,
          pinned: this.message.creditNote.note.$data.pinned,
          type: "creditNote",
        },
        relatedKintin: this.message.creditNote.$data.relatedKintin,
        relatedUser: this.message.creditNote.$data.relatedUser,
        initialValue: this.message.creditNote.$data.initialValue,
        remainingValue: this.message.creditNote.$data.remainingValue,
      })

      .result();

    const creditNote = CreditNote.$inflate(response.creditNote).first();

    if (!creditNote) {
      throw new Error("Failed to update credit note");
    }

    return {
      creditNote,
    };
  }
}
