import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaign, Profile } from "@kinherit/sdk";

export interface AddEmailCampaignProfilesMessage {
  emailCampaign: EmailCampaign;
  profiles: Profile[];
}

export interface AddEmailCampaignProfilesResponse {
  [key: string]: never;
}

export class AddEmailCampaignProfilesHandler extends ActionBase {
  constructor(private message: AddEmailCampaignProfilesMessage) {
    super();
  }

  public async execute(): Promise<AddEmailCampaignProfilesResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/{emailCampaign}",
      this.message,
    )
      .method("patch")

      .body({
        profiles: this.message.profiles.pluck("id").map((id) => ({ id })),
      })
      .result();

    return {};
  }
}
