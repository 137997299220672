import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinAccess } from "@kinherit/sdk";

export interface RevokeKintinAccessMessage {
  kintin: string | Kintin;
}

interface RevokeKintinAccessResponse {
  kintinAccess: Array<KintinAccess>;
}

export class RevokeKintinAccessHandler extends ActionBase {
  constructor(private message: RevokeKintinAccessMessage) {
    super();
  }

  public async execute(): Promise<RevokeKintinAccessResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}/assigned-users/revoke-access",
      {
        kintin: this.message.kintin,
      },
    )
      .method("put")
      .result();

    return {
      kintinAccess: KintinAccess.$inflate(response.kintinAccess),
    };
  }
}
