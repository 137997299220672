import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  EmailAddress,
  IProfile,
  Person,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

export interface ReadSearchProfileMessage {
  name: string | null;
  address: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  website: string | null;
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort?: {
    by: keyof IProfile;
    direction: "asc" | "desc";
  };
}

export interface ReadSearchProfileResponse {
  profiles: Array<Profile>;
  addresses: Array<Address>;
  emailAddresses: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
  websites: Array<Website>;
  person: Array<Person>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadSearchProfileHandler extends ActionBase {
  constructor(public readonly message: ReadSearchProfileMessage) {
    super();
  }

  public async execute(): Promise<ReadSearchProfileResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/global-search/profile",
    )
      .method("get")
      .sort(this.message.sort)
      .params({
        name: this.message.name ?? undefined,
        address: this.message.address ?? undefined,
        emailAddress: this.message.emailAddress ?? undefined,
        phoneNumber: this.message.phoneNumber ?? undefined,
        website: this.message.website ?? undefined,
      })
      .paginate(this.message.pagination)

      .result();

    return {
      profiles: Profile.$inflate(response.profile),
      addresses: Address.$inflate(response.address),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      websites: Website.$inflate(response.website),
      person: Person.$inflate(response.person),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
