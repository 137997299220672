import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Subscription } from "@kinherit/sdk";

interface UpdateSubscriptionMessage {
  subscription: Subscription;
}

interface UpdateSubscriptionResponse {
  subscription: Subscription;
}

export class UpdateSubscriptionHandler extends ActionBase {
  constructor(private message: UpdateSubscriptionMessage) {
    super();
  }

  public async execute(): Promise<UpdateSubscriptionResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/subscription/{subscription}",
      {
        subscription: this.message.subscription.id,
      },
    )
      .method("patch")

      .body({
        status: this.message.subscription.$data.status,
        kintin: this.message.subscription.$data.kintin,
        productSku: this.message.subscription.$data.productSku,
        provider: this.message.subscription.$data.provider,
        billingDate: this.message.subscription.$data.billingDate ?? undefined,
        procXero: this.message.subscription.$data.procXero,
        procDd: this.message.subscription.$data.procDd,
        notes: this.message.subscription.$data.notes,
        subscriptionValue:
          this.message.subscription.$data.subscriptionValue ?? undefined,
        billingEndDate:
          this.message.subscription.$data.billingEndDate ?? undefined,
      })
      .result();

    const subscription = Subscription.$inflate(response.subscription).first();

    if (!subscription) {
      throw new Error("Subscription not found");
    }

    return {
      subscription,
    };
  }
}
