import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeProductsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
}

interface ComputeProductsReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    productCount: number;
    products: Array<{
      id?: string;
      count?: number;
    }>;
  }>;
}
export type TProductReportResult = {
  year: number;
  week: number;
  quarter: number;
  productCount: number;
  products: Array<{ group: string; count: number }>;
};
export class ComputeProductsReportHandler extends ActionBase {
  constructor(private message: ComputeProductsReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeProductsReportResponse> {
    const { year, created, granularity, assignedUser, compareTo } =
      this.message || {};

    const calculatedDates = calculateStartEndDates(year, created, compareTo);

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/products",
      {},
    )
      .method("get")
      .params({
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        granularity: granularity,
        assignedUser: assignedUser ?? null,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];
    return {
      datasets: datasets,
      labels: response.labels,
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
