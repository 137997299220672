import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, KintinAccess, User } from "@kinherit/sdk";

export interface ReadUserAssignedKintinsMessage {
  user: string | User;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof KintinAccess;
    direction: "asc" | "desc";
  };
}

interface ReadUserAssignedKintinsResponse {
  kintinAccess: Array<KintinAccess>;
  kintins: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadUserAssignedKintinsHandler extends ActionBase {
  constructor(private message: ReadUserAssignedKintinsMessage) {
    super();
  }

  public async execute(): Promise<ReadUserAssignedKintinsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/user/{user}/assigned-kintins",
      {
        user:
          "string" === typeof this.message.user
            ? this.message.user
            : this.message.user.id,
      },
    )
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "createdAt",
        sortDirection: this.message.sort?.direction ?? "desc",
      })
      .paginate(this.message.pagination ?? false)
      .result();

    return {
      kintinAccess: KintinAccess.$inflate(
        response.kintinAccess,
        this.message.sort,
        response.$rootIds,
      ),
      kintins: Kintin.$inflate(response.kintin),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
