<template>
  <div v-if="kintin" :key="renderKey">
    <PageHeader htag="h2" text="Subscriptions">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          text="Create Subscription"
          @click="$emit('create-subscription')"
        />
      </template>
    </PageHeader>
    <MasterListPage
      is-nested
      :columns="subscriptions.columns"
      :rows="subscriptions.rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :empty-message="'No Subscriptions Found'"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #status="scope">
        <span
          :class="
            `badge is-small is-block has-text-left is-` + scope.row.status.class
          "
        >
          {{ scope.row.status.text }}
        </span>
      </template>
      <template #files="scope">
        <span
          :class="`badge is-small is-${
            scope.row.$data.files?.length > 0 ? `success` : `light`
          }`"
        >
          {{ scope.row.$data.files?.length }}
        </span>
      </template>
      <template #product="scope">
        <code>{{ scope.row.productSku.publicText }}</code>
        {{ scope.row.subscriptionValue?.format }}
      </template>
      <template #progress="scope">
        <span
          :class="`badge is-small is-${scope.row.procDd ? `success` : `light`}`"
        >
          Direct Debit
        </span>
        <span
          :class="`badge is-small is-${
            scope.row.procXero ? `success` : `light`
          }`"
        >
          Xero Setup
        </span>
      </template>
      <template #actions="scope">
        <Button
          text="View Files"
          color="is-positive"
          is-outlined
          class="mr-1"
          @click="viewSubscription(scope.row)"
        />

        <Button
          :icon-left="$style.icon.edit.icon"
          class="edit-button mr-1"
          ariaLabel="Edit Subscription"
          color="is-plain"
          @click="updateSubscription(scope.row)"
        />
        <Button
          :icon-left="$style.icon.delete.icon"
          class="delete-button mr-1"
          ariaLabel="Delete Subscription"
          color="is-plain"
          @click="deleteSubscription(scope.row)"
        />
      </template>
    </MasterListPage>
    <Card
      v-if="subscriptions.selectedSubscription"
      :title="subscriptions.selectedSubscription.productSku.publicText"
      :subtitle="subscriptions.selectedSubscription.createdAt.formatDate"
    >
      <template v-if="hasWritePermission" #buttons>
        <Button
          @click="createStorageRequest"
          color="is-positive"
          text="Add File"
          :icon-right="$style.icon.add.icon"
        />
      </template>
      <Table
        is-scrollable
        :total-records="storageRequests.rows.length"
        :columns="storageRequests.columns"
        :rows="storageRequests.rows"
        is-fullwidth
        is-narrow
        is-hoverable
        is-clickable
        :empty-message="'No Files Found'"
      >
        <template #title="{ row }: { row: PhysicalStorageFile }">
          <strong>{{ row.title }}</strong>
          <span v-if="row.length" class="is-block">{{ row.length }}</span>
          <span v-if="row.notes" class="is-block">{{ row.notes }}</span>
        </template>
        <template #actions="{ row }: { row: PhysicalStorageFile }">
          <Button
            text="Upload Scan"
            size="is-small"
            color="is-warning"
            is-outlined
            :icon-left="$style.icon.upload.icon"
            class="mr-1"
            @click="uploadScan(row)"
          />

          <Button
            v-if="!!row.$data.file"
            text="View Scan"
            size="is-small"
            color="is-success"
            is-outlined
            :icon-left="$style.icon.download.icon"
            class="mr-1"
            @click="downloadScan(row)"
          />

          <Button
            text="View Log"
            size="is-small"
            color="is-info"
            is-outlined
            :icon-left="$style.icon.history.icon"
            class="mr-1"
            @click="createLog(row)"
          />

          <Button
            tooltip="Edit File Description"
            class="edit-button mr-1"
            color="is-plain"
            size="is-small"
            :icon-left="$style.icon.edit.icon"
            @click="updateStorageRequest(row)"
          />
          <Button
            size="is-small"
            color="is-plain"
            class="delete-button mr-1"
            tooltip="Delete File"
            :icon-left="$style.icon.delete.icon"
            @click="deleteStorageRequest(row)"
          />
        </template>
      </Table>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdatePhysicalStorageFileForm } from "@/module/kinvault.kintin/form/update-physical-storage-file.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { UpdateSubscriptionForm } from "@/module/kinvault.subscription/form/update-subscription.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  ISubscription,
  Kintin,
  PhysicalStorageFile,
  Subscription,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import type { KintinDetailsSubscriptionParams } from ".";
import { KintinDetailsSubscriptionRoute } from ".";

import { CreatePhysicalStorageFileLogForm } from "@/module/kinvault.kintin/form/create-physical-storage-file-log.form";
import { UploadKintinStorageRequestForm } from "@/module/kinvault.kintin/form/upload-kintin-storage-request.form";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";

export default defineComponent({
  name: KintinDetailsSubscriptionRoute,
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin(), StyleService.mixin],
  components: {
    Table,
    Card,
    Button,
    MasterListPage,
    PageHeader,
  },
  emits: ["create-subscription"],
  data: () => ({
    renderKey: 0,
    ThemeIconName,
    storageRequests: {
      columns: [
        {
          title: "Title",
          sort: "title",
          slot: "title",
        },
        {
          title: "Created",
          sort: true,
          map: (v: PhysicalStorageFile) => v.createdAt.formatDateTime,
        },
        {
          slot: "actions",
          class: "is-narrow has-text-right",
        },
      ],
      rows: Array<PhysicalStorageFile>(),
    },
    subscriptions: {
      columns: [
        {
          title: "Product",
          sort: true,
          slot: "product",
        },
        {
          title: "Status",
          sort: false,
          slot: "status",
        },
        {
          title: "Created",
          sort: true,
          map: (v: Subscription) => v.createdAt.formatDate,
        },
        {
          title: "Billing",
          sort: true,
          map: (v: Subscription) => v.billingDate?.formatDate,
        },
        {
          title: "Ended",
          sort: true,
          map: (v: Subscription) => v.billingEndDate?.formatDate,
        },
        {
          title: "Files",
          sort: true,
          slot: "files",
        },
        {
          title: "Progress",
          sort: false,
          slot: "progress",
        },
        {
          slot: "actions",
          sort: false,
          class: "is-narrow has-text-right",
        },
      ] as Array<TableColumn>,
      rows: Array<Subscription>(),
      selectedSubscription: null as Subscription | null,
    },
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ISubscription,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("kintin:write");
    },
  },
  updated() {
    const subscriptionId = (this.$params as KintinDetailsSubscriptionParams)
      .subscription;

    if (subscriptionId === this.subscriptions.selectedSubscription?.id) {
      return;
    }

    if (subscriptionId) {
      const subscription = Subscription.$findOne(subscriptionId);

      if (!subscription) {
        this.$router.push({
          name: KintinDetailsSubscriptionRoute,
          params: {
            kintin: this.$params.kintin,
          },
        });
      } else {
        this.subscriptions.selectedSubscription = subscription;
        this.storageRequests.rows = subscription.files.sortBy(
          "createdAt",
          "desc",
        );
      }
    }
  },
  methods: {
    async refresh(): Promise<void> {
      const { subscriptions, pagination } =
        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/subscriptions/read",
          {
            ...this.$params,
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.subscriptions.rows = subscriptions;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      if (this.subscriptions.selectedSubscription) {
        this.storageRequests.rows = PhysicalStorageFile.$findBy({
          subscription: {
            id: this.subscriptions.selectedSubscription.id,
          },
        }).sortBy("createdAt", "desc");
      }

      this.$forceUpdate();
    },
    viewSubscription(subscription: Subscription): void {
      window.Kernel.Router.replace({
        name: KintinDetailsSubscriptionRoute,
        params: {
          kintin: this.$params.kintin,
          subscription: subscription.id,
        } satisfies KintinDetailsSubscriptionParams,
      });
    },
    async updateSubscription(subscription: Subscription): Promise<void> {
      this.viewSubscription(subscription);
      if (!this.$data.subscriptions.selectedSubscription) {
        return;
      }

      try {
        await UpdateSubscriptionForm(
          this.$data.subscriptions.selectedSubscription,
        ).dialog({
          dialog: {
            title: `Update Subscription`,
          },
        });
      } catch (e) {
        this.$data.subscriptions.selectedSubscription.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.execute("kinvault/subscription/update", {
        subscription: this.$data.subscriptions.selectedSubscription,
      });

      await this.refresh();
    },
    async deleteSubscription(subscription: Subscription): Promise<void> {
      this.viewSubscription(subscription);
      if (!this.$data.subscriptions.selectedSubscription) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Subscription",
          message: `Are you sure you want to delete this subscription?`,
        },
      });

      await window.Kernel.ActionBus.execute("kinvault/subscription/delete", {
        subscription: this.$data.subscriptions.selectedSubscription,
      });

      this.subscriptions.selectedSubscription = null;

      await this.refresh();
    },
    async createLog(storageRequest: PhysicalStorageFile): Promise<void> {
      if (!this.$data.subscriptions.selectedSubscription) {
        return;
      }

      const form = CreatePhysicalStorageFileLogForm(storageRequest);

      try {
        const { physicalStorageFileLog } = await form.dialog({
          dialog: {
            title: `${storageRequest.title} Log`,
          },
        });

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/storage-request/log/create",
          {
            physicalStorageFileLog,
            storageRequest,
            kintin: this.kintin as Kintin,
            subscription: this.$data.subscriptions.selectedSubscription,
          },
        );

        await this.refresh();
      } catch (e) {
        form.localData.physicalStorageFileLog.$delete();
        throw e;
      }
    },
    async createStorageRequest(): Promise<void> {
      if (!this.$data.subscriptions.selectedSubscription) {
        return;
      }

      const physicalStorageFile = await UpdatePhysicalStorageFileForm().dialog({
        dialog: {
          title: `Create Storage Request`,
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/storage-request/create",
        {
          storageRequest: physicalStorageFile,
          kintin: this.kintin as Kintin,
          subscription: this.subscriptions.selectedSubscription as Subscription,
        },
      );

      await this.refresh();
    },
    async uploadScan(storageRequest: PhysicalStorageFile): Promise<void> {
      if (!this.$data.subscriptions.selectedSubscription) {
        return;
      }

      const { files } = await UploadKintinStorageRequestForm().dialog({
        dialog: {
          title: `Upload Scan`,
        },
      });

      const file = files.first();

      if (!file) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/storage-request/upload",
        {
          storageRequest,
          file: file as File,
          subscription: this.$data.subscriptions.selectedSubscription,
          kintin: this.kintin as Kintin,
        },
      );

      await this.refresh();
    },
    async downloadScan(storageRequest: PhysicalStorageFile): Promise<void> {
      const file = storageRequest.$data.file;

      if (!file) {
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/file/download", {
        file,
        kintin: this.kintin as Kintin,
      });
    },
    async updateStorageRequest(
      storageRequest: PhysicalStorageFile,
    ): Promise<void> {
      try {
        await UpdatePhysicalStorageFileForm(storageRequest).dialog({
          dialog: {
            title: `Update ${storageRequest.title}`,
          },
        });
      } catch (e) {
        storageRequest.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/storage-request/update",
        {
          storageRequest,
          kintin: this.kintin as Kintin,
          subscription: this.subscriptions.selectedSubscription as Subscription,
        },
      );
      await this.refresh();
      this.viewSubscription(
        this.subscriptions.selectedSubscription as Subscription,
      );
    },
    async deleteStorageRequest(
      storageRequest: PhysicalStorageFile,
    ): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Storage Request",
          message: `Are you sure you want to delete this storage request?`,
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/storage-request/delete",
        {
          storageRequest,
          kintin: this.kintin as Kintin,
          subscription: this.subscriptions.selectedSubscription as Subscription,
        },
      );

      await this.refresh();
    },
  },
});
</script>
