<template>
  <Card v-if="$data.form" title="Branded Kinvault Membership">
    <FormControls
      :form="$data.form"
      :delete-dialog="deleteDialog"
      :mode="brandedKinvaultMembership ? 'update' : 'create'"
      delete-button="Revoke"
      @cancel="visitUserDetails"
      @save="save"
      @remove="remove"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
</template>

<script lang="ts">
import { UserJoinBrandedKinvaultForm } from "@/module/admin.user/form/user-join-branded-kinvault.form";
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { AlertDialogRequest } from "@kinherit/framework/global/dialog";
import { BrandedKinvaultMembership } from "@kinherit/sdk";
import { DeepPartial } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import {
  AdminUserDetailsJoinBrandedKinvaultParams,
  AdminUserDetailsJoinBrandedKinvaultRoute,
} from ".";

export default defineComponent({
  name: AdminUserDetailsJoinBrandedKinvaultRoute,
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: {
        some: ["user:write", "branded-kinvault-membership:write"],
      },
    }),
    UserDetailsMixin,
  ],
  components: {
    FormControls,
    Card,
  },
  data: () => ({
    form: null as null | FormBuilder<
      () => Partial<BrandedKinvaultMembership>,
      string
    >,
    loading: true,
  }),
  computed: {
    $localParams(): AdminUserDetailsJoinBrandedKinvaultParams {
      return this.$route.params as any;
    },
    brandedKinvaultMembership(): BrandedKinvaultMembership | null {
      if (!this.$localParams.brandedKinvaultMembership) {
        return null;
      }

      return BrandedKinvaultMembership.$findOne(
        this.$localParams.brandedKinvaultMembership,
      );
    },
    deleteDialog(): DeepPartial<AlertDialogRequest> {
      return {
        dialog: {
          title: "Revoke Branded Kinvault Membership",
          message: `Are you sure you want to revoke '${this.user?.profile.fullName}' membership to '${this.brandedKinvaultMembership?.brandedKinvault.profile.organisationName}'?`,
        },
        button: {
          ok: {
            text: "Revoke",
          },
        },
      };
    },
  },
  async mounted(): Promise<void> {
    this.form = await UserJoinBrandedKinvaultForm(
      this.brandedKinvaultMembership ?? {},
    );
    this.loading = false;
  },
  methods: {
    async save(data: BrandedKinvaultMembership): Promise<void> {
      if (!this.form || !this.user) {
        return;
      }

      if (this.brandedKinvaultMembership) {
        await window.Kernel.ActionBus.execute(
          "admin/user/branded-kinvault-membership/update",
          {
            brandedKinvaultMembership: this.brandedKinvaultMembership,
          },
        );
      } else {
        await window.Kernel.ActionBus.execute(
          "admin/user/branded-kinvault-membership/create",
          {
            user: this.user,
            brandedKinvault: data.brandedKinvault,
          },
        );
      }

      await window.Kernel.ActionBus.execute("admin/user/record", this.$params);

      this.visitUserDetails();
    },
    async remove(): Promise<void> {
      if (!this.brandedKinvaultMembership) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "admin/user/branded-kinvault-membership/delete",
        {
          brandedKinvaultMembership: this.brandedKinvaultMembership,
        },
      );

      await window.Kernel.ActionBus.execute("admin/user/record", this.$params);

      this.visitUserDetails();
    },
    reset(): void {
      if (!this.form) {
        return;
      }

      if (this.brandedKinvaultMembership) {
        this.form.localData.$restore?.();
      } else {
        this.form.resetData();
      }
    },
  },
});
</script>
