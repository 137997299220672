import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailTemplate } from "@kinherit/sdk";

export interface CreateEmailTemplateMessage {
  emailTemplate: EmailTemplate;
}

interface CreateEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export class CreateEmailTemplateHandler extends ActionBase {
  constructor(private message: CreateEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<CreateEmailTemplateResponse> {
    const response = await Api.resource("portal", "/v2/portal/email-template")
      .method("post")
      .body({
        name: this.message.emailTemplate.$data.name,
        subject: this.message.emailTemplate.$data.subject,
        body: this.message.emailTemplate.$data.body,
        allowAttachments:
          this.message.emailTemplate.$data.allowAttachments || false,
        strictAttachments:
          this.message.emailTemplate.$data.strictAttachments || false,
        notes: this.message.emailTemplate.$data.notes,
        requiredAttachments:
          this.message.emailTemplate.$data.requiredAttachments,
        type: this.message.emailTemplate.$data.type,
        includeTo: this.message.emailTemplate.$data.includeTo,
        preloadedFiles: this.message.emailTemplate.$data.preloadedFiles,
      })

      .result();

    this.message.emailTemplate.$delete();

    return {
      emailTemplates: EmailTemplate.$inflate(
        response.emailTemplate,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
