import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCpd } from "@kinherit/sdk";

interface UpdateIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface UpdateIntroducerCpdResponse {
  introducerCpd: IntroducerCpd;
}

export class UpdateIntroducerCpdHandler extends ActionBase {
  constructor(private message: UpdateIntroducerCpdMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerCpdResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}",
      {
        introducerCpd: this.message.introducerCpd,
      },
    )
      .method("patch")

      .body({
        contact: this.message.introducerCpd.$data.contact,
        session: this.message.introducerCpd.$data.session,
        status: this.message.introducerCpd.$data.status,
        type: this.message.introducerCpd.$data.type,
      })
      .result();

    const introducerCpd = IntroducerCpd.$inflate(
      response.introducerCpd,
    ).first();

    if (!introducerCpd) {
      throw new Error("Failed to update Introducer Cpd");
    }

    return { introducerCpd };
  }
}
