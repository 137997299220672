import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Adviser, Api, Kintin } from "@kinherit/sdk";

interface DeleteAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface DeleteAdviserResponse {
  [key: string]: never;
}

export class DeleteAdviserHandler extends ActionBase {
  constructor(private message: DeleteAdviserMessage) {
    super();
  }

  public async execute(): Promise<DeleteAdviserResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-adviser/{kintin}/person/adviser/{adviser}",
      {
        kintin: this.message.kintin,
        adviser: this.message.adviser.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.adviser.$delete();

    return {};
  }
}
