import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Order, OrderItem } from "@kinherit/sdk";
export interface DeleteOrderItemMessage {
  order: Order;
  orderItem: OrderItem | string;
}

export interface DeleteOrderItemResponse {
  [key: string]: never;
}

export class DeleteOrderItemHandler extends ActionBase {
  constructor(private message: DeleteOrderItemMessage) {
    super();
  }

  public async execute(): Promise<DeleteOrderItemResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/order/{order}/order-items/{orderItem}",
      {
        order: this.message.order.id,
        orderItem:
          "string" === typeof this.message.orderItem
            ? this.message.orderItem
            : this.message.orderItem.id,
      },
    )
      .method("delete")
      .result();

    if ("string" !== typeof this.message.orderItem) {
      this.message.orderItem.$delete();
    }

    return {};
  }
}
