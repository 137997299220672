import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Like } from "@kinherit/orm/index";
import { Api, ITag, Tag } from "@kinherit/sdk";

export interface ReadTagMessage {
  name?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof ITag;
    direction: "asc" | "desc";
  };
}

interface ReadTagResponse {
  tags: Array<Tag>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadTagHandler extends ActionBase {
  constructor(private message: ReadTagMessage) {
    super();
  }

  public async execute(): Promise<ReadTagResponse> {
    const request = Api.resource("portal", "/v2/portal/tag")
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(Tag).where({
      name: Like(this.message.name),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      tags: Tag.$inflate(response.tag, this.message.sort, response.$rootIds),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
