import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContract, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerContractMessage {
  query?: QueryMask<typeof IntroducerContract>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerContractResponse {
  introducerContracts: Array<IntroducerContract>;
}

export class ReadIntroducerContractHandler extends ActionBase {
  constructor(private message: ReadIntroducerContractMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContractResponse> {
    const request = Api.resource("core", "/v2/core/select/introducer-contract")
      .method("get")

      .paginate(this.message.pagination ?? false);
    request.buildQuery(IntroducerContract).where(this.message.query ?? {});

    const response = await request.result();

    return {
      introducerContracts: IntroducerContract.$inflate(
        response.introducerContract,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
