import { CreateEmailLogHandler } from "@/module/core/action/email-log/create-email-log.action";
import { CreateEmailNamedAttachmentHandler } from "@/module/core/action/email-log/create-email-named-attachment.action";
import { DeleteEmailLogAttachmentHandler } from "@/module/core/action/email-log/delete-email-log-attachment.action";
import { DeleteEmailLogHandler } from "@/module/core/action/email-log/delete-email-log.action";
import { DeleteEmailNamedAttachmentHandler } from "@/module/core/action/email-log/delete-email-named-attachment.action";
import { SendEmailLogHandler } from "@/module/core/action/email-log/send-email-log.action";
import { UpdateEmailLogHandler } from "@/module/core/action/email-log/update-email-log.action";
import { UploadEmailLogAttachmentHandler } from "@/module/core/action/email-log/upload-email-log-attachment.action";
import { GetSignAppUrlHandler } from "@/module/core/action/environment/get-sign-app-url.action";
import { RecordAccountReferralHandler } from "@/module/core/action/referral/record-account-referral.action";
import { UpdateAccountReferralHandler } from "@/module/core/action/referral/update-account-referral.action";
import { ReadAccountReferralCodeHandler } from "@/module/core/action/select/read-branded-kinvault-invite-referral-code.action";
import { ReadDefaultBrandedKinvaultReferralCodeHandler } from "@/module/core/action/select/read-default-branded-kinvault-referral-code.action";
import { ReadEmailAddressHandler } from "@/module/core/action/select/read-email-address.action";
import { ReadIntroducerFeePaymentRunHandler } from "@/module/core/action/select/read-introducer-fee-payment-run.action";
import { ReadIntroducerNetworkHandler } from "@/module/core/action/select/read-introducer-network.action";
import { ReadOrderHandler } from "@/module/core/action/select/read-order.action";
import { ReadPropertyHandler } from "@/module/core/action/select/read-property.action";
import { ReadWillRevisionHandler } from "@/module/core/action/select/read-will-revision.action";
import { ReadXeroContactHandler } from "@/module/core/action/select/read-xero-contact.action";
import { CreateCreditNoteHandler } from "./credit-note/create-credit-note.action";
import { DeleteCreditNoteHandler } from "./credit-note/delete-credit-note.action";
import { ReadCreditNoteHandler } from "./credit-note/read-credit-note.action";
import { RecordCreditNoteHandler } from "./credit-note/record-credit-note.action";
import { UpdateCreditNoteHandler } from "./credit-note/update-credit-note.action";
import { ReadEmailLogEmailAddressHandler } from "./email-log/read-email-log-email-address.action";
import { ReadEmailLogHandler } from "./email-log/read-email-log.action";
import { ReadLegacyEmailLogHandler } from "./email-log/read-legacy-email-log.action";
import { CreateNoteHandler } from "./note/create-note.action";
import { DeleteNoteHandler } from "./note/delete-note.action";
import { ReadNoteHandler } from "./note/read-note.action";
import { RecordNoteHandler } from "./note/record-note.action";
import { UpdateNoteHandler } from "./note/update-note.action";
import { ReadOptionHandler } from "./read-option.action";
import { ReadSessionHandler } from "./read-session.action";
import { SearchAddressHandler } from "./search-address.action";
import { ReadAddressHandler } from "./select/read-address.action";
import { ReadAdviserHandler } from "./select/read-adviser.action";
import { ReadBrandedKinvaultMembershipHandler } from "./select/read-branded-kinvault-membership.action";
import { ReadBrandedKinvaultHandler } from "./select/read-branded-kinvault.action";
import { ReadCheckHandler } from "./select/read-check.action";
import { ReadEmailTemplateHandler } from "./select/read-email-template.action";
import { ReadIntroducerCompanyHandler } from "./select/read-introducer-company.action";
import { ReadIntroducerContactHandler } from "./select/read-introducer-contact.action";
import { ReadIntroducerContractHandler } from "./select/read-introducer-contract.action";
import { ReadKintinAddressHandler } from "./select/read-kintin-address.action";
import { ReadKintinHandler } from "./select/read-kintin.action";
import { ReadKnowledgeBaseCategoryHandler } from "./select/read-knowledge-base-category.action";
import { ReadPersonHandler } from "./select/read-person.action";
import { ReadProductHandler } from "./select/read-product.action";
import { ReadRoleHandler } from "./select/read-role.action";
import { ReadTagHandler } from "./select/read-tag.action";
import { ReadUserHandler } from "./select/read-user.action";

export const Actions = {
  "core/address/search": SearchAddressHandler,
  "core/credit-note/create": CreateCreditNoteHandler,
  "core/credit-note/delete": DeleteCreditNoteHandler,
  "core/credit-note/read": ReadCreditNoteHandler,
  "core/credit-note/record": RecordCreditNoteHandler,
  "core/credit-note/update": UpdateCreditNoteHandler,
  "core/option/read": ReadOptionHandler,
  "core/select/address/kintin/read": ReadKintinAddressHandler,
  "core/select/address/read": ReadAddressHandler,
  "core/select/adviser/read": ReadAdviserHandler,
  "core/select/check/read": ReadCheckHandler,
  "core/select/introducer-company/read": ReadIntroducerCompanyHandler,
  "core/select/introducer-contact/read": ReadIntroducerContactHandler,
  "core/select/introducer-contract/read": ReadIntroducerContractHandler,
  "core/select/kintin/read": ReadKintinHandler,
  "core/select/knowledge-base-category/read": ReadKnowledgeBaseCategoryHandler,
  "core/select/branded-kinvault-membership/read":
    ReadBrandedKinvaultMembershipHandler,
  "core/select/branded-kinvault/read": ReadBrandedKinvaultHandler,
  "core/select/person/read": ReadPersonHandler,
  "core/select/will-revision/read": ReadWillRevisionHandler,
  "core/select/product/read": ReadProductHandler,
  "core/select/referral-code/read": ReadAccountReferralCodeHandler,
  "core/select/role/read": ReadRoleHandler,
  "core/select/tag/read": ReadTagHandler,
  "core/select/user/read": ReadUserHandler,
  "core/session/read": ReadSessionHandler,
  "core/legacy-email-log/read": ReadLegacyEmailLogHandler,
  "core/select/email-template/read": ReadEmailTemplateHandler,
  "core/note/create": CreateNoteHandler,
  "core/note/read": ReadNoteHandler,
  "core/note/update": UpdateNoteHandler,
  "core/note/delete": DeleteNoteHandler,
  "core/note/record": RecordNoteHandler,
  "core/select/email-address/read": ReadEmailAddressHandler,
  "core/select/property/read": ReadPropertyHandler,
  "core/select/default-branded-kinvault-referral-codes/read":
    ReadDefaultBrandedKinvaultReferralCodeHandler,
  "core/select/branded-kinvault-invite-referral-code/read":
    ReadAccountReferralCodeHandler,
  "core/select/order/read": ReadOrderHandler,
  "core/select/introducer-network/read": ReadIntroducerNetworkHandler,
  "core/select/xero-contact/read": ReadXeroContactHandler,
  "core/select/introducer-fee-payment-run/read":
    ReadIntroducerFeePaymentRunHandler,
  // Email Log
  "core/email-log/read": ReadEmailLogHandler,
  "core/email-log/email-address/read": ReadEmailLogEmailAddressHandler,
  "core/email-log/update": UpdateEmailLogHandler,
  "core/email-log/delete": DeleteEmailLogHandler,
  "core/email-log/create": CreateEmailLogHandler,
  "core/email-log/attachment/upload": UploadEmailLogAttachmentHandler,
  "core/email-log/send": SendEmailLogHandler,
  "core/email-log/attachment/delete": DeleteEmailLogAttachmentHandler,
  "core/email-log/named-attachment/create": CreateEmailNamedAttachmentHandler,
  "core/email-log/named-attachment/delete": DeleteEmailNamedAttachmentHandler,
  // Referral
  "core/account-referral/record": RecordAccountReferralHandler,
  "core/account-referral/update": UpdateAccountReferralHandler,
  // Environment
  "core/environment/get-sign-app-url": GetSignAppUrlHandler,
};
