import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Like } from "@kinherit/orm/index";
import { Api, FailedLoginAttempt, IFailedLoginAttempt } from "@kinherit/sdk";

export interface ReadFailedLoginAttemptsMessage {
  created?: null | [number, number];
  origin?: null | string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IFailedLoginAttempt;
    direction: "asc" | "desc";
  };
}

interface ReadFailedLoginAttemptResponse {
  failedLoginAttempts: FailedLoginAttempt[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadFailedLoginAttemptsHandler extends ActionBase {
  constructor(private message: ReadFailedLoginAttemptsMessage) {
    super();
  }

  public async execute(): Promise<ReadFailedLoginAttemptResponse> {
    const request = Api.resource("portal", "/v2/portal/failed-login-attempt")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(FailedLoginAttempt).where({
      createdAt: Between(this.message.created),
      origin: Like(this.message.origin),
    });

    const response = await request.result();

    return {
      failedLoginAttempts: FailedLoginAttempt.$inflate(
        response.failedLoginAttempt,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
