import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Guardian, Kintin } from "@kinherit/sdk";

interface DeleteGuardianMessage {
  kintin: string | Kintin;
  guardian: Guardian;
}

interface DeleteGuardianResponse {
  [key: string]: never;
}

export class DeleteGuardianHandler extends ActionBase {
  constructor(private message: DeleteGuardianMessage) {
    super();
  }

  public async execute(): Promise<DeleteGuardianResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-guardian/{kintin}/person/guardian/{guardian}",
      {
        kintin: this.message.kintin,
        guardian: this.message.guardian.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.guardian.$delete();

    return {};
  }
}
