import { OptionService } from "@/module/core/service/option.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Check, Option, Role, Tag, User } from "@kinherit/sdk";
import { nextTick } from "vue";
import { ActiveSession } from "../../model/active-session.model";

interface CompleteSessionMessage {
  activeSession: ActiveSession;
}

interface CompleteSessionResponse {
  [key: string]: never;
}

export class CompleteSessionHandler extends ActionBase {
  constructor(private message: CompleteSessionMessage) {
    super();
  }

  public async execute(): Promise<CompleteSessionResponse> {
    Option.$purge();
    Role.$purge();
    Tag.$purge();
    Check.$purge();
    User.$purge();

    await Promise.all([
      OptionService.loadOptions(),
      window.Kernel.ActionBus.execute("core/select/role/read", {}),
      window.Kernel.ActionBus.execute("core/select/tag/read", {}),
      window.Kernel.ActionBus.execute("core/select/check/read", {}),
      window.Kernel.ActionBus.execute("core/session/read", {}),
    ]);

    nextTick(() => {
      window.Kernel.ComponentOptions.Navbar.callRefresh();
    });

    return {};
  }
}
