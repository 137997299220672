import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Property } from "@kinherit/sdk";

interface DeletePropertyMessage {
  kintin: string;
  property: Property;
}

interface DeletePropertyResponse {
  [key: string]: never;
}

export class DeletePropertyHandler extends ActionBase {
  constructor(private message: DeletePropertyMessage) {
    super();
  }

  public async execute(): Promise<DeletePropertyResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-property/{kintin}/properties/{property}",
      {
        kintin: this.message.kintin,
        property: this.message.property.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.property.$delete();

    return {};
  }
}
