import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeAccountsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  referral: string | null;
  referrerCategory: string | null;
  compareTo: string | null;
}

type TAccountStatusCount = {
  id: string;
  name: string;
  count: number;
  inProgressCount: number;
  notNowCount: number;
  dropoutCount: number;
  overdueCount: number;
};

export interface ComputeAccountsReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<TAccountsReportResult>;
}

export type TAccountsReportResult = {
  year: number;
  week: number;
  month: number;
  quarter: number;
  kintinStages: Array<TAccountStatusCount>;
};
export class ComputeAccountsReportHandler extends ActionBase {
  constructor(private message: ComputeAccountsReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeAccountsReportResponse> {
    const {
      year,
      created,
      granularity,
      assignedUser,
      referral,
      compareTo,
      referrerCategory,
    } = this.message || {};

    const calculatedDates = calculateStartEndDates(year, created, compareTo);

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/accounts",
      {},
    )
      .method("get")
      .params({
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        granularity: granularity,
        assignedUser: assignedUser ?? null,
        referral: referral ?? null,
        referrerCategory: referrerCategory ?? null,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];

    const colours = ["#0896A4", "#006B76"];
    // For each dataset returned, create a new dataset object with the correct format
    // for the chart.js graph
    response.datasets.forEach((dataset: any, index: number) => {
      console.log(dataset);
      datasets.push({
        label: "Accounts",
        stack: "Stack " + index,
        backgroundColor: colours[index],
        data: dataset.datasets.map((i: any) => {
          return 1;
        }),
      });
    });

    return {
      datasets: datasets,
      labels: response.labels,
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
