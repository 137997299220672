import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultMembership } from "@kinherit/sdk";

interface DeleteBrandedKinvaultMembershipMessage {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

interface DeleteBrandedKinvaultMembershipResponse {
  [key: string]: never;
}

export class DeleteBrandedKinvaultMembershipHandler extends ActionBase {
  constructor(private message: DeleteBrandedKinvaultMembershipMessage) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultMembershipResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-membership/{brandedKinvaultMembership}",
      this.message,
    )
      .method("delete")
      .result();

    this.message.brandedKinvaultMembership.$delete();

    return {};
  }
}
