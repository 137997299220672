import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContract } from "@kinherit/sdk";

interface UpdateIntroducerCompanyIntroducerContractMessage {
  introducerContract: IntroducerContract;
}

interface UpdateIntroducerCompanyIntroducerContractResponse {
  introducerContract: IntroducerContract;
}

export class UpdateIntroducerCompanyIntroducerContractHandler extends ActionBase {
  constructor(
    private message: UpdateIntroducerCompanyIntroducerContractMessage,
  ) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerCompanyIntroducerContractResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-contract/{introducerCompany}/contracts/{introducerContract}",
      {
        introducerCompany: this.message.introducerContract.$data.company,
        introducerContract: this.message.introducerContract.$data.id,
      },
    )
      .method("patch")
      .body({
        ai_5a_months: this.message.introducerContract.ai_5a_months,
        ai_5a_percent: this.message.introducerContract.ai_5a_percent,
        ai_5b_months: this.message.introducerContract.ai_5b_months,
        ai_5b_percent: this.message.introducerContract.ai_5b_percent,
        ai_5c_months: this.message.introducerContract.ai_5c_months,
        ai_5c_percent: this.message.introducerContract.ai_5c_percent,
        company: this.message.introducerContract.$data.company,
        datedAt: this.message.introducerContract.$data.datedAt ?? undefined,
        datedTill: this.message.introducerContract.$data.datedTill ?? undefined,
        i_4c_months: this.message.introducerContract.i_4c_months,
        i_4c_percent: this.message.introducerContract.i_4c_percent,
        isSigned: this.message.introducerContract.$data.isSigned,
        notes: this.message.introducerContract.$data.notes,
        parentContract: this.message.introducerContract.$data.parentContract,
        prePaidClients: this.message.introducerContract.prePaidClients,
        sentAt: this.message.introducerContract.$data.sentAt ?? undefined,
        type: this.message.introducerContract.$data.type,
        vat: this.message.introducerContract.$data.vat,
      })

      .result();

    const introducerContract = IntroducerContract.$inflate(
      response.introducerContract,
    ).first();

    if (!introducerContract) {
      throw new Error("IntroducerContract not found");
    }

    return {
      introducerContract,
    };
  }
}
