import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { User } from "@kinherit/sdk";

interface DeleteUserMessage {
  user: User;
}

interface DeleteUserResponse {
  [key: string]: never;
}

export class DeleteUserHandler extends ActionBase {
  constructor(private message: DeleteUserMessage) {
    super();
  }

  public async execute(): Promise<DeleteUserResponse> {
    await Api.resource("portal", "/v2/portal/user/{user}", this.message)
      .method("delete")

      .result();

    this.message.user.$delete();

    return {};
  }
}
