<template>
  <div class="kintin-details-address-book-trustees">
    <PageHeader htag="h2" text="Trustees" v-if="kintin">
      <template #buttons>
        <Button text="Add Trustee" @click="createTrustee" class="add-button" />
        <Button
          :text="sorting ? 'Done' : 'Sort'"
          @click="togglingSorting"
          class="sort-button"
          v-if="!isPeopleTab"
        />
        <Button
          v-if="kintin.type.value === 'joint'"
          text="Copy"
          @click="copy"
          class="copy-button"
        />
        <Button
          v-if="tabConfig[selectedTab].person"
          text="Copy From Executors"
          @click="copyFromExecutors"
          class="copy-from-executors-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        v-if="!sorting"
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4"
      />
      <div v-if="!isPeopleTab">
        <Title size="is-6" is-emphasised>Main Trustees</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.trustees.main"
        />
        <TrusteeList
          v-else
          :kintin="kintin"
          :trustees="$data.filtered.trustees.main"
          @reload="filter"
        />
        <Title size="is-6" is-emphasised>Reserve Trustees</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.trustees.reserve"
        />
        <TrusteeList
          v-else
          :kintin="kintin"
          :trustees="$data.filtered.trustees.reserve"
          @reload="filter"
        />
      </div>
      <div v-else>
        <PersonList
          :key="personRenderKey"
          v-if="isPeopleTab"
          :kintin="kintin"
          :people="$data.filtered.people"
          @reload="filter"
        />
      </div>
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<script lang="ts">
import { CreateTrustee } from "@/config/model.config";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import TrusteeList from "@/module/kinvault.kintin/component/lists/TrusteeList.vue";
import { UpdateTrusteeForm } from "@/module/kinvault.kintin/form/update-trustee.form";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Executor, Kintin, Person, Trustee } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CopyRolesForm } from "../../form/copy-roles.form";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `TrusteesContainer`,
  components: {
    Tabs,
    Json,
    TrusteeList,
    Button,
    PageHeader,
    PersonList,
    Title,
    SortRoles,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      trustees: {
        main: Array<Trustee>(),
        reserve: Array<Trustee>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    personRenderKey: 0,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const trustees = Trustee.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin?.primaryPerson?.id,
                this.kintin?.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      this.filtered.trustees = trustees.groupBy({
        main: (trustee) => !trustee.isReserve,
        reserve: (trustee) => trustee.isReserve,
      });

      this.filtered.trustees.main =
        this.filtered.trustees.main.sortBy("sortOrder");
      this.filtered.trustees.reserve =
        this.filtered.trustees.reserve.sortBy("sortOrder");

      this.filtered.people = trustees
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");

      this.personRenderKey++;

      this.$emit("reload");
    },
    async createTrustee() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const trustee = CreateTrustee({
        forPerson,
      });
      try {
        await UpdateTrusteeForm({
          data: trustee,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create trustee",
          },
        });
      } catch {
        trustee.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/trustee/create", {
        trustee,
        kintin: this.kintin,
      });

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.trustees.main,
          ...this.$data.filtered.trustees.reserve,
        ].forEachAsync((trustee) =>
          window.Kernel.ActionBus.execute("kinvault/kintin/trustee/update", {
            trustee,
            kintin: this.kintin,
          }),
        );
      }

      this.sorting = !this.sorting;
    },
    async copy() {
      const forPerson =
        this.selectedTab === 0
          ? this.kintin.secondaryPerson
          : this.kintin.primaryPerson;

      let name: string = "";

      if (forPerson?.profile.fullName) {
        name = forPerson?.profile.fullName;
      }

      const type =
        this.selectedTab === 0 ? "secondary person" : "primary person";

      name = name === "" ? type : `${name} (${type})`;

      const { rolesToCopy } = await CopyRolesForm(
        [
          ...this.$data.filtered.trustees.main,
          ...this.$data.filtered.trustees.reserve,
        ].filter((a) => a.person.id !== forPerson?.id),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy to ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (trustee) => {
        const newTrustee = CreateTrustee({
          ...trustee.$data,
          forPerson: forPerson?.id,
          id: Uuid.generate(),
        });

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/trustee/create",
          {
            trustee: newTrustee,
            kintin: this.kintin.id,
          },
        );
      });

      this.selectedTab = this.selectedTab === 0 ? 1 : 0;
      this.filter();
    },
    async copyFromExecutors() {
      const person = this.tabConfig[this.selectedTab].person;

      if (!person) {
        return;
      }

      const { rolesToCopy } = await CopyRolesForm(
        Executor.$findBy({
          forPerson: {
            id: person.id,
            kintin: {
              id: this.kintin.id,
            },
          },
        }),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy from executors`,
        },
      });

      await rolesToCopy.forEachAsync(async (executor) => {
        const newExecutor = CreateTrustee({
          ...executor.$data,
          forPerson: person.id,
          id: Uuid.generate(),
        });

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/trustee/create",
          {
            trustee: newExecutor,
            kintin: this.kintin.id,
          },
        );
      });

      this.filter();
    },
  },
});
</script>
