import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Person } from "@kinherit/sdk";

interface UpdateKintinPersonMessage {
  person: Person;
  kintin: Kintin;
}

interface UpdateKintinPersonResponse {
  person: Person;
}

export class UpdateKintinPersonHandler extends ActionBase {
  constructor(private message: UpdateKintinPersonMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinPersonResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-person/{kintin}/person/{person}",
      {
        kintin: this.message.kintin.id,
        person: this.message.person.id,
      },
    )
      .method("patch")

      .body({
        type: this.message.person.$data.type,
        notificationDate: this.message.person.$data.notificationDate,
        notifications: this.message.person.$data.notifications,
        gender: this.message.person.$data.gender,
        maritalStatus: this.message.person.$data.maritalStatus,
        under18ForPlanningPurposes:
          this.message.person.$data.under18ForPlanningPurposes,
        requiresCare: this.message.person.$data.requiresCare,
        isTrusted: this.message.person.$data.isTrusted,
        dateOfBirth: this.message.person.$data.dateOfBirth,
        dateOfDeath: this.message.person.$data.dateOfDeath,
        relationToPrimaryPerson:
          this.message.person.$data.relationToPrimaryPerson,
        relationToSecondaryPerson:
          this.message.person.$data.relationToSecondaryPerson,
      })
      .result();

    const person = Person.$inflate(response.person).first();

    if (!person) {
      throw new Error("Failed to update person");
    }

    return {
      person,
    };
  }
}
