import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, User } from "@kinherit/sdk";

export interface RevokeKintinBrandedKinvaultMembershipMessage {
  kintin: string | Kintin;
}

interface RevokeKintinBrandedKinvaultMembershipResponse {
  users: Array<User>;
}

export class RevokeKintinBrandedKinvaultMembershipHandler extends ActionBase {
  constructor(private message: RevokeKintinBrandedKinvaultMembershipMessage) {
    super();
  }

  public async execute(): Promise<RevokeKintinBrandedKinvaultMembershipResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}/branded-kinvault/revoke-access",
      {
        kintin: this.message.kintin,
      },
    )
      .method("put")
      .result();

    return {
      users: User.$inflate(response.user),
    };
  }
}
