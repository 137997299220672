import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  Api,
  BrandedKinvaultInvitation,
  EmailCampaign,
  QueryMask,
} from "@kinherit/sdk";

export interface CreateEmailCampaignMessage {
  campaignName: string;
  search?: string;
  status?: Array<string>;
  created?: null | [number, number];
  brandedKinvault: string;
  query?: QueryMask<typeof BrandedKinvaultInvitation>;
}

interface CreateEmailCampaignResponse {
  emailCampaign: EmailCampaign;
}

export class CreateEmailCampaignHandler extends ActionBase {
  constructor(private message: CreateEmailCampaignMessage) {
    super();
  }

  public async execute(): Promise<CreateEmailCampaignResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/email-group/{name}",
      {
        name: this.message.campaignName,
      },
    )
      .method("put")

      .paginate(false);

    request.buildQuery(BrandedKinvaultInvitation).where({
      brandedKinvault: Equal(this.message.brandedKinvault),
      profile: {
        fullName: Like(this.message.search),
      },
      createdAt: Between(this.message.created),
      ...(this.message.query ?? {}),
    });

    const response = await request.result();

    const emailCampaign = EmailCampaign.$inflate(
      response.emailCampaign,
    ).first();

    if (!emailCampaign) {
      throw new Error("Email campaign not found");
    }

    return {
      emailCampaign,
    };
  }
}
