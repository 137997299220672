import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { WillRevision } from "@kinherit/sdk";

interface CreateWillRevisionMessage {
  willRevision: WillRevision;
}

interface CreateWillRevisionResponse {
  willRevision: WillRevision;
}

export class CreateWillRevisionHandler extends ActionBase {
  constructor(private message: CreateWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<CreateWillRevisionResponse> {
    const content: Record<string, string> = {};

    for (const [key, value] of Object.entries(
      this.message.willRevision.$data.content ?? {},
    )) {
      if ("string" === typeof value) {
        content[key.toString()] = value;
      }
    }

    const response = await Api.resource("portal", "/v2/portal/will-revision")
      .method("post")

      .body({
        approvedAt: this.message.willRevision.$data.approvedAt,
        approvedBy: this.message.willRevision.$data.approvedBy,
        notes: this.message.willRevision.$data.notes,
        content,
        createdBy: this.message.willRevision.$data.createdBy,
        hasBusinessClause: this.message.willRevision.$data.hasBusinessClause,
        hasBusinessTrust: this.message.willRevision.$data.hasBusinessTrust,
        hasEstateProtectionTrust:
          this.message.willRevision.$data.hasEstateProtectionTrust,
        hasIipTrust: this.message.willRevision.$data.hasIipTrust,
        kintin: this.message.willRevision.$data.kintin,
        ownedBy: this.message.willRevision.$data.ownedBy,
      })
      .result();

    const willRevision = WillRevision.$inflate(response.willRevision).first();

    if (!willRevision) {
      throw new Error("Will revision not found");
    }

    this.message.willRevision.$delete();

    return {
      willRevision,
    };
  }
}
