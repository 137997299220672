import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, Person } from "@kinherit/sdk";

export interface DownloadLetterOfAuthorityMessage {
  kintin: Kintin;
  person: Person;
  relationship: string;
  name: string;
  companyName?: string;
  address?: string;
}

export class DownloadLetterOfAuthorityHandler extends ActionBase {
  constructor(private message: DownloadLetterOfAuthorityMessage) {
    super();
  }

  public async execute() {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-process/letter-of-authority",
    )
      .method("post")
      .body({
        kintin: this.message.kintin.id,
        person: this.message.person.id,
        relationship: this.message.relationship,
        name: this.message.name,
        companyName: this.message.companyName,
        address: this.message.address,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${this.message.kintin.ref} - Letter of Authority`,
      });
  }
}
