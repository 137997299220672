import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface CreateBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

interface CreateBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export class CreateBrandedKinvaultSubscriptionReferralHandler extends ActionBase {
  constructor(
    private message: CreateBrandedKinvaultSubscriptionReferralMessage,
  ) {
    super();
  }

  public async execute(): Promise<CreateBrandedKinvaultSubscriptionReferralResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral",
    )
      .method("post")
      .body({
        subscription:
          this.message.brandedKinvaultSubscriptionReferral.$data.subscription,
        subscriptionIntent:
          this.message.brandedKinvaultSubscriptionReferral.$data
            .subscriptionIntent,
        referralCode:
          this.message.brandedKinvaultSubscriptionReferral.referralCode?.id,
      })
      .result();

    const brandedKinvaultSubscriptionReferral =
      BrandedKinvaultSubscriptionReferral.$inflate(
        response.brandedKinvaultSubscriptionReferral,
      ).first();

    if (!brandedKinvaultSubscriptionReferral) {
      throw new Error("Failed to create subscription");
    }

    this.message.brandedKinvaultSubscriptionReferral.$delete();

    return {
      brandedKinvaultSubscriptionReferral,
    };
  }
}
