import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Appointment } from "@kinherit/sdk";

export interface UpdateAppointmentMessage {
  appointment: Appointment;
}

interface UpdateAppointmentResponse {
  appointment: Appointment;
}

export class UpdateAppointmentHandler extends ActionBase {
  constructor(private message: UpdateAppointmentMessage) {
    super();
  }

  public async execute(): Promise<UpdateAppointmentResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/appointment/{appointment}",
      {
        appointment: this.message.appointment,
      },
    )
      .method("patch")

      .body({
        type: this.message.appointment.$data.type,
        outcome: this.message.appointment.$data.outcome,
        tags: this.message.appointment.$data.tags,
        acuityId: this.message.appointment.$data.acuityId,
        calendar: this.message.appointment.$data.calendar,
        appointmentAt: this.message.appointment.$data.appointmentAt,
        cancelled: this.message.appointment.$data.cancelled,
        notes: this.message.appointment.$data.notes,
      });

    const response = await request.result();

    const appointment = Appointment.$inflate(response.appointment).first();

    if (!appointment) {
      throw new Error("Failed to update appointment");
    }

    return {
      appointment,
    };
  }
}
