import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface CreateKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: File;
}

interface CreateKnowledgeBaseDocumentFileLogResponse {}

export class CreateKnowledgeBaseDocumentFileLogHandler extends ActionBase {
  constructor(private message: CreateKnowledgeBaseDocumentFileLogMessage) {
    super();
  }

  public async execute(): Promise<CreateKnowledgeBaseDocumentFileLogResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document",
    )
      .method("post")
      .body({
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
        fileExtension: this.message.file.name.split(".").pop() as string,
        fileName: this.message.file.name,
      })
      .result();

    const fileLog = FileLog.$inflate(response.fileLog).first();

    if (!fileLog) {
      throw new Error("Failed to create knowledge base document");
    }

    await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}/upload",
      {
        knowledgeBaseDocument: this.message.knowledgeBaseDocument.id,
        fileLog: fileLog.id,
      },
    )
      .method("put")
      .files({
        uploadedFile: this.message.file,
      })
      .result();

    return {};
  }
}
