import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Executor, Kintin } from "@kinherit/sdk";

interface DeleteExecutorMessage {
  kintin: string | Kintin;
  executor: Executor;
}

interface DeleteExecutorResponse {
  [key: string]: never;
}

export class DeleteExecutorHandler extends ActionBase {
  constructor(private message: DeleteExecutorMessage) {
    super();
  }

  public async execute(): Promise<DeleteExecutorResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-executor/{kintin}/person/executor/{executor}",
      {
        kintin: this.message.kintin,
        executor: this.message.executor.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.executor.$delete();

    return {};
  }
}
