import { CreateAddressHandler } from "@/module/kinvault.kintin/action/address/create-address.action";
import { DownloadClientSummaryHandler } from "@/module/kinvault.kintin/action/client-summary/download-client-summary.action";
import { ChangeKintinTypeHandler } from "@/module/kinvault.kintin/action/kintin/change-kintin-type.action";
import { DuplicateKintinHandler } from "@/module/kinvault.kintin/action/kintin/duplicate-kintin.action";
import { RecordKintinEmailLogHandler } from "@/module/kinvault.kintin/action/kintin/record-kintin.action-email-log";
import { SendOrderPaymentEmailHandler } from "@/module/kinvault.kintin/action/order/send-order-payment-email.aciton";
import { CreatePersonFromIntroducerContactHandler } from "@/module/kinvault.kintin/action/person/create-person-from-introducer-contact.action";
import { DeleteKintinPersonHandler } from "@/module/kinvault.kintin/action/person/delete-kintin-person.action";
import { UpdateKintinPersonHandler } from "@/module/kinvault.kintin/action/person/update-kintin-person.action";
import { createRoyalMailOrderHandler } from "@/module/kinvault.kintin/action/process/create-royal-mail-order.action";
import { DownloadLetterOfAuthorityHandler } from "@/module/kinvault.kintin/action/process/download-letter-of-authority.action";
import { DownloadLpaFinancialAction } from "@/module/kinvault.kintin/action/process/download-lpa-financial.action";
import { DownloadLpaHealthAction } from "@/module/kinvault.kintin/action/process/download-lpa-health.action";
import { DownloadPackingSlipHandler } from "@/module/kinvault.kintin/action/process/download-packing-slip.action";
import { DownloadStageEClosingLetterHandler } from "@/module/kinvault.kintin/action/process/download-stage-e-closing-letter.action";
import { DownloadStageFClosingLetterHandler } from "@/module/kinvault.kintin/action/process/download-stage-f-closing-letter.action";
import { MergeSignDocContactHandler } from "@/module/kinvault.kintin/action/sign-doc/merge-sign-doc-contact.action";
import { RecordKintinSignDocsHandler } from "@/module/kinvault.kintin/action/sign-doc/record-kintin-sign-docs.action";
import { DownloadWillReviewHandler } from "@/module/kinvault.kintin/action/will-review/download-will-review.action";
import { DownloadKintinWillRevisionContentHandler } from "@/module/kinvault.kintin/action/will-revision/download-kintin-will-revision.action";
import { ReadKintinWillRevisionChecksHandler } from "@/module/kinvault.kintin/action/will-revision/read-kintin-will-revision-checks.action";
import { RecordKintinWillRevisionDataHandler } from "@/module/kinvault.kintin/action/will-revision/record-kintin-will-revision-data.action";
import { RecordKintinWillRevisionContentHandler } from "@/module/kinvault.kintin/action/will-revision/record-kintin-will-revision.action";
import { CreateAdviserHandler } from "./adviser/create-adviser.action";
import { DeleteAdviserHandler } from "./adviser/delete-adviser.action";
import { UpdateAdviserHandler } from "./adviser/update-adviser.action";
import { CreateAttorneyHandler } from "./attorney/create-attorney.action";
import { DeleteAttorneyHandler } from "./attorney/delete-attorney.action";
import { UpdateAttorneyHandler } from "./attorney/update-attorney.action";
import { CreateBeneficiaryHandler } from "./beneficiary/create-beneficiary.action";
import { DeleteBeneficiaryHandler } from "./beneficiary/delete-beneficiary.action";
import { UpdateBeneficiaryHandler } from "./beneficiary/update-beneficiary.action";
import { RevokeKintinBrandedKinvaultMembershipHandler } from "./branded-kinvault-membership/revoke-kintin-branded-kinvault-membership.action";
import { CreateKintinExclusionHandler } from "./exclusion/create-kintin-exclusion.action";
import { DeleteKintinExclusionHandler } from "./exclusion/delete-kintin-exclusion.action";
import { UpdateKintinExclusionHandler } from "./exclusion/update-kintin-exclusion.action";
import { CreateExecutorHandler } from "./executor/create-executor.action";
import { DeleteExecutorHandler } from "./executor/delete-executor.action";
import { UpdateExecutorHandler } from "./executor/update-executor.action";
import { CreateKintinFileHandler } from "./file/create-kintin-file.action";
import { DeleteKintinFileHandler } from "./file/delete-kintin-file.action";
import { DownloadKintinFileHandler } from "./file/download-kintin-file.action";
import { ReadKintinFileHandler } from "./file/read-kintin-file.action";
import { UpdateKintinFileHandler } from "./file/update-kintin-file.action";
import { CreateKintinGiftHandler } from "./gift/create-kintin-gift.action";
import { DeleteKintinGiftHandler } from "./gift/delete-kintin-gift.action";
import { UpdateKintinGiftHandler } from "./gift/update-kintin-gift.action";
import { CreateGuardianHandler } from "./guardian/create-guardian.action";
import { DeleteGuardianHandler } from "./guardian/delete-guardian.action";
import { UpdateGuardianHandler } from "./guardian/update-guardian.action";
import { CreateKintinCheckFollowUpHandler } from "./kintin-check-follow-up/create-kintin-check-follow-up.action";
import { DeleteKintinCheckFollowUpHandler } from "./kintin-check-follow-up/delete-kintin-check-follow-up.action";
import { UpdateKintinCheckFollowUpHandler } from "./kintin-check-follow-up/update-kintin-check-follow-up.action";
import { CreateKintinCheckHandler } from "./kintin-check/create-kintin-check.action";
import { UpdateKintinCheckHandler } from "./kintin-check/update-kintin-check.action";
import { CreateKintinAccessHandler } from "./kintin-user-access/create-kintin-user-access.action";
import { RevokeKintinAccessHandler } from "./kintin-user-access/revoke-kintin-user-access.action";
import { UpdateKintinAccessHandler } from "./kintin-user-access/update-kintin-user-access.action";
import { CreateKintinHandler } from "./kintin/create-kintin.action";
import { ReadKintinHistoryHandler } from "./kintin/read-kintin-changes.action";
import { ReadKintinHandler } from "./kintin/read-kintin.action";
import { RecordKintinAddressBookHandler } from "./kintin/record-kintin-address-book.action";
import { RecordKintinCallScriptHandler } from "./kintin/record-kintin-call-script.action";
import { RecordKintinHandler } from "./kintin/record-kintin.action";
import { UpdateKintinCallScriptHandler } from "./kintin/update-kintin-call-script.action";
import { UpdateKintinHandler } from "./kintin/update-kintin.action";
import { VoidOustandOrdersHandler } from "./order/void-outstanding-orders.action";
import { CreateKintinPersonHandler } from "./person/create-kintin-person.action";
import { RecordKintinPersonHandler } from "./person/record-kintin-person.action";
import { UpdateKintinAddressHandler } from "./person/update-kintin-address.action";
import { UpdateKintinPersonProfileHandler } from "./person/update-kintin-person-profile.action";
import { DownloadLRSeveranceHandler } from "./process/download-lr-severance-form.action";
import { DownloadNoticeOfSeveranceHandler } from "./process/download-notice-of-severance.action";
import { CreatePropertyHandler } from "./property/create-property.action";
import { DeletePropertyHandler } from "./property/delete-property.action";
import { UpdatePropertyHandler } from "./property/update-property.action";
import { CreateKintinSignDocHandler } from "./sign-doc/create-kintin-sign-docs.action";
import { DeleteKintinSignDocHandler } from "./sign-doc/delete-kintin-sign-docs.action";
import { DownloadKintinSignDocDisclaimerHandler } from "./sign-doc/download-kintin-sign-doc-disclaimer.action";
import { PreviewKintinSignDocDisclaimerHandler } from "./sign-doc/preview-kintin-sign-doc-disclaimer.action";
import { ReadKintinSignDocsHandler } from "./sign-doc/read-kintin-sign-docs.action";
import { UpdateKintinSignDocHandler } from "./sign-doc/update-kintin-sign-docs.action";
import { CreateKintinStorageRequestLogHandler } from "./storage-request-log/create-storage-request-log.action";
import { CreateKintinStorageRequestHandler } from "./storage-request/create-storage-request.action";
import { DeleteKintinStorageRequestHandler } from "./storage-request/delete-kintin-storage-request.action";
import { UpdateKintinStorageRequestHandler } from "./storage-request/update-kintin-storage-request.action";
import { UploadKintinStorageRequestHandler } from "./storage-request/upload-kintin-storage-request.action";
import { ReadKintinSubscriptionsHandler } from "./subscription/read-kintin-subscriptions.action";
import { CreateTrusteeHandler } from "./trustee/create-trustee.action";
import { DeleteTrusteeHandler } from "./trustee/delete-trustee.action";
import { UpdateTrusteeHandler } from "./trustee/update-trustee.action";
import { CreateKintinUploadFormHandler } from "./upload-form/create-kintin-upload-form.action";
import { DeleteKintinUploadFormHandler } from "./upload-form/delete-kintin-upload-form.action";
import { DownloadKintinUploadFormSubmissionHandler } from "./upload-form/download-kintin-upload-form-submission.action";
import { ReadKintinUploadFormsHandler } from "./upload-form/read-kintin-upload-forms.action";
import { UpdateKintinUploadFormHandler } from "./upload-form/update-kintin-upload-form.action";
import { CreateKintinUserHandler } from "./users/create-kintin-user.action";
import { KintinUserJoinDefaultBrandedKinvaultHandler } from "./users/kintin-user-join-default-branded-kinvault.action";
import { SwitchAccountHoldersHandler } from "@/module/kinvault.kintin/action/kintin/switch-account-holders.action";

export const Actions = {
  // address-book
  "kinvault/kintin/address-book/record": RecordKintinAddressBookHandler,
  // address
  "kinvault/kintin/address/create": CreateAddressHandler,
  "kinvault/kintin/address/update": UpdateKintinAddressHandler,
  // adviser
  "kinvault/kintin/adviser/create": CreateAdviserHandler,
  "kinvault/kintin/adviser/delete": DeleteAdviserHandler,
  "kinvault/kintin/adviser/update": UpdateAdviserHandler,
  // attorney
  "kinvault/kintin/attorney/create": CreateAttorneyHandler,
  "kinvault/kintin/attorney/delete": DeleteAttorneyHandler,
  "kinvault/kintin/attorney/update": UpdateAttorneyHandler,
  // beneficiary
  "kinvault/kintin/beneficiary/create": CreateBeneficiaryHandler,
  "kinvault/kintin/beneficiary/delete": DeleteBeneficiaryHandler,
  "kinvault/kintin/beneficiary/update": UpdateBeneficiaryHandler,
  // call-script
  "kinvault/kintin/call-script/record": RecordKintinCallScriptHandler,
  "kinvault/kintin/call-script/update": UpdateKintinCallScriptHandler,
  // client-summary
  "kinvault/kintin/client-summary/download": DownloadClientSummaryHandler,
  // kintin
  "kinvault/kintin/update": UpdateKintinHandler,
  "kinvault/kintin/read": ReadKintinHandler,
  "kinvault/kintin/record": RecordKintinHandler,
  "kinvault/kintin/create": CreateKintinHandler,
  "kinvault/kintin/duplicate": DuplicateKintinHandler,
  "kinvault/kintin/change-type": ChangeKintinTypeHandler,
  "kinvault/kintin/switch-account-holders": SwitchAccountHoldersHandler,
  // email-log
  "kinvault/kintin/email-log/record": RecordKintinEmailLogHandler,
  // exclusion
  "kinvault/kintin/exclusion/create": CreateKintinExclusionHandler,
  "kinvault/kintin/exclusion/delete": DeleteKintinExclusionHandler,
  "kinvault/kintin/exclusion/update": UpdateKintinExclusionHandler,
  // executor
  "kinvault/kintin/executor/create": CreateExecutorHandler,
  "kinvault/kintin/executor/delete": DeleteExecutorHandler,
  "kinvault/kintin/executor/update": UpdateExecutorHandler,
  // file
  "kinvault/kintin/file/create": CreateKintinFileHandler,
  "kinvault/kintin/file/delete": DeleteKintinFileHandler,
  "kinvault/kintin/file/download": DownloadKintinFileHandler,
  "kinvault/kintin/file/read": ReadKintinFileHandler,
  "kinvault/kintin/file/update": UpdateKintinFileHandler,
  // gift
  "kinvault/kintin/gift/create": CreateKintinGiftHandler,
  "kinvault/kintin/gift/delete": DeleteKintinGiftHandler,
  "kinvault/kintin/gift/update": UpdateKintinGiftHandler,
  // guardian
  "kinvault/kintin/guardian/create": CreateGuardianHandler,
  "kinvault/kintin/guardian/delete": DeleteGuardianHandler,
  "kinvault/kintin/guardian/update": UpdateGuardianHandler,
  // history
  "kinvault/kintin/history/read": ReadKintinHistoryHandler,
  // kintin-check-follow-up
  "kinvault/kintin/kintin-check-follow-up/create":
    CreateKintinCheckFollowUpHandler,
  "kinvault/kintin/kintin-check-follow-up/delete":
    DeleteKintinCheckFollowUpHandler,
  "kinvault/kintin/kintin-check-follow-up/update":
    UpdateKintinCheckFollowUpHandler,
  // kintin-check
  "kinvault/kintin/kintin-check/create": CreateKintinCheckHandler,
  "kinvault/kintin/kintin-check/update": UpdateKintinCheckHandler,
  // kintin-user-access
  "kinvault/kintin/kintin-user-access/create": CreateKintinAccessHandler,
  "kinvault/kintin/kintin-user-access/revoke": RevokeKintinAccessHandler,
  "kinvault/kintin/kintin-user-access/update": UpdateKintinAccessHandler,
  // order
  "kinvault/kintin/order/void-outstand": VoidOustandOrdersHandler,
  "kinvault/kintin/order/payment-email/send": SendOrderPaymentEmailHandler,
  // branded-kinvault-membership
  "kinvault/kintin/branded-kinvault-membership/revoke":
    RevokeKintinBrandedKinvaultMembershipHandler,
  // person
  "kinvault/person/profile/update": UpdateKintinPersonProfileHandler,
  "kinvault/person/roles/update": UpdateKintinPersonProfileHandler,
  "kinvault/kintin/person/create": CreateKintinPersonHandler,
  "kinvault/kintin/person/delete": DeleteKintinPersonHandler,
  "kinvault/kintin/person/record": RecordKintinPersonHandler,
  "kinvault/kintin/person/update": UpdateKintinPersonHandler,
  "kinvault/kintin/person/from-introducer-contact/create":
    CreatePersonFromIntroducerContactHandler,
  // process
  "kinvault/kintin/process/download-lpa-financial": DownloadLpaFinancialAction,
  "kinvault/kintin/process/download-lpa-health": DownloadLpaHealthAction,
  "kinvault/kintin/process/download-lr-severance": DownloadLRSeveranceHandler,
  "kinvault/kintin/process/download-notice-of-severance":
    DownloadNoticeOfSeveranceHandler,
  "kinvault/kintin/process/download-packing-slip": DownloadPackingSlipHandler,
  "kinvault/kintin/process/download-stage-e-closing-letter":
    DownloadStageEClosingLetterHandler,
  "kinvault/kintin/process/download-stage-f-closing-letter":
    DownloadStageFClosingLetterHandler,
  "kinvault/kintin/process/create-royal-mail-order":
    createRoyalMailOrderHandler,
  "kinvault/kintin/process/download-letter-of-authority":
    DownloadLetterOfAuthorityHandler,
  // property
  "kinvault/kintin/property/create": CreatePropertyHandler,
  "kinvault/kintin/property/delete": DeletePropertyHandler,
  "kinvault/kintin/property/update": UpdatePropertyHandler,
  // sign-doc
  "kinvault/kintin/sign-doc/merge-contacts": MergeSignDocContactHandler,
  "kinvault/kintin/sign-docs/contacts/record": RecordKintinSignDocsHandler,
  "kinvault/kintin/sign-docs/create": CreateKintinSignDocHandler,
  "kinvault/kintin/sign-docs/delete": DeleteKintinSignDocHandler,
  "kinvault/kintin/sign-docs/disclaimer/download":
    DownloadKintinSignDocDisclaimerHandler,
  "kinvault/kintin/sign-docs/disclaimer/preview":
    PreviewKintinSignDocDisclaimerHandler,
  "kinvault/kintin/sign-docs/read": ReadKintinSignDocsHandler,
  "kinvault/kintin/sign-docs/update": UpdateKintinSignDocHandler,
  // storage-request
  "kinvault/kintin/storage-request/create": CreateKintinStorageRequestHandler,
  "kinvault/kintin/storage-request/delete": DeleteKintinStorageRequestHandler,
  "kinvault/kintin/storage-request/log/create":
    CreateKintinStorageRequestLogHandler,
  "kinvault/kintin/storage-request/update": UpdateKintinStorageRequestHandler,
  "kinvault/kintin/storage-request/upload": UploadKintinStorageRequestHandler,
  // subscription
  "kinvault/kintin/subscriptions/read": ReadKintinSubscriptionsHandler,
  // trustee
  "kinvault/kintin/trustee/create": CreateTrusteeHandler,
  "kinvault/kintin/trustee/delete": DeleteTrusteeHandler,
  "kinvault/kintin/trustee/update": UpdateTrusteeHandler,
  // upload-form
  "kinvault/kintin/upload-forms/create": CreateKintinUploadFormHandler,
  "kinvault/kintin/upload-forms/delete": DeleteKintinUploadFormHandler,
  "kinvault/kintin/upload-forms/read": ReadKintinUploadFormsHandler,
  "kinvault/kintin/upload-forms/submission/download":
    DownloadKintinUploadFormSubmissionHandler,
  "kinvault/kintin/upload-forms/update": UpdateKintinUploadFormHandler,
  // user
  "kinvault/kintin/users/create": CreateKintinUserHandler,
  "kinvault/user/join-default-branded-kinvault":
    KintinUserJoinDefaultBrandedKinvaultHandler,
  // will-review
  "kinvault/kintin/will-review/download": DownloadWillReviewHandler,
  // will-revision
  "kinvault/kintin/will-revision/data/record":
    RecordKintinWillRevisionDataHandler,
  "kinvault/kintin/will-revision/content/record":
    RecordKintinWillRevisionContentHandler,
  "kinvault/kintin/will-revision/download":
    DownloadKintinWillRevisionContentHandler,
  "kinvault/kintin/will-revision/checks/read":
    ReadKintinWillRevisionChecksHandler,
};
