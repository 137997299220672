import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface CreateBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

interface CreateBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

export class CreateBrandedKinvaultSubscriptionReferralCodeHandler extends ActionBase {
  constructor(
    private message: CreateBrandedKinvaultSubscriptionReferralCodeMessage,
  ) {
    super();
  }

  public async execute(): Promise<CreateBrandedKinvaultSubscriptionReferralCodeResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral-code",
    )
      .method("post")

      .body({
        code: this.message.brandedKinvaultSubscriptionReferralCode.$data.code,
        name: this.message.brandedKinvaultSubscriptionReferralCode.$data.name,
        brandedKinvault:
          this.message.brandedKinvaultSubscriptionReferralCode.$data
            .brandedKinvault,
      })
      .result();

    const brandedKinvaultSubscriptionReferralCode =
      BrandedKinvaultSubscriptionReferralCode.$inflate(
        response.brandedKinvaultSubscriptionReferralCode,
        undefined,
        response.$rootIds,
      ).first();

    if (!brandedKinvaultSubscriptionReferralCode) {
      throw new Error("Failed to create account referral code");
    }

    this.message.brandedKinvaultSubscriptionReferralCode.$delete();

    return {
      brandedKinvaultSubscriptionReferralCode,
    };
  }
}
