<template>
  <Card
    title="Cpd Session Attendance"
    subtitle="Details of attendance for CPD sessions"
  >
    <Table
      is-scrollable
      enable-per-page
      v-model:page="pagination.currentPage"
      @update:page="refresh"
      v-model:perPage="pagination.perPage"
      @update:perPage="refresh"
      :total-pages="pagination.lastPage"
      :total-records="pagination.count"
      :columns="columns"
      :rows="rows"
      is-fullwidth
      is-narrow
      is-hoverable
      is-clickable
      v-model:sortBy="sort.by"
      @update:sortBy="refresh"
      v-model:sortDirection="sort.direction"
      @update:sortDirection="refresh"
      :sort-by-options="{
        'session.date': 'Date',
        createdAt: 'Created',
      }"
    />
  </Card>
</template>

<script lang="ts">
import { CpdAttendanceTableColumns } from "@/config/table-columns.config";
import { Table } from "@kinherit/framework/component.display/table";
import { Card } from "@kinherit/framework/component.layout/card";
import { IIntroducerCpd, IntroducerCpd } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerContactDetailsCpdSessionRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";
export default defineComponent({
  name: IntroducerContactDetailsCpdSessionRoute,
  mixins: [IntroducerContactDetailsMixin],
  components: {
    Table,
    Card,
  },
  data: () => ({
    columns: CpdAttendanceTableColumns,
    rows: Array<IntroducerCpd>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "session.date" as any as keyof IIntroducerCpd,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const { introducerCpds, pagination } =
        await window.Kernel.ActionBus.execute("introducer/contact/cpd/read", {
          ...this.$params,
          pagination: this.pagination,
          sort: this.sort,
        });

      this.rows = introducerCpds;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
