import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  AccountReferralCode,
  IntroducerCompany,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadAccountReferralCodeMessage {
  query?: QueryMask<typeof AccountReferralCode>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadAccountReferralCodeResponse {
  accountReferralCode: Array<AccountReferralCode>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
}

export class ReadAccountReferralCodeHandler extends ActionBase {
  constructor(private message: ReadAccountReferralCodeMessage) {
    super();
  }

  public async execute(): Promise<ReadAccountReferralCodeResponse> {
    const request = Api.resource(
      "core",
      "/v2/core/select/account-referral-code",
    )
      .method("get")

      .paginate(this.message.pagination ?? false);

    request.buildQuery(AccountReferralCode).where(this.message.query ?? {});

    const response = await request.result();

    return {
      accountReferralCode: AccountReferralCode.$inflate(
        response.accountReferralCode,

        undefined,
        response.$rootIds,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      profiles: Profile.$inflate(response.profile),
    };
  }
}
