import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk";

type TestContactEndpointMessage = {
  name: string;
  email: string;
  tel: string;
  message: string;
  pref?: string;
  referrer?: string;
  helpwith?: string;
  referralcode?: string;
};
export class TestContactEndpointHandler extends ActionBase {
  constructor(private message: TestContactEndpointMessage) {
    super();
  }
  // @todo fix types
  public async execute(): Promise<void> {
    await Api.resource("external", "/v2/external/contact")
      .method("post")
      .body({
        // @ts-ignore
        name: this.message.name,
        // @ts-ignore
        email: this.message.email,
        // @ts-ignore
        tel: this.message.tel,
        // @ts-ignore
        message: this.message.message,
        // @ts-ignore
        pref: this.message.pref,
        // @ts-ignore
        referrer: this.message.referrer,
        // @ts-ignore
        helpwith: this.message.helpwith,
        // @ts-ignore
        referralcode: this.message.referralcode,
      })
      .result();
  }
}
