import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IWillRevision, QueryMask, WillRevision } from "@kinherit/sdk";

export interface ReadWillRevisionMessage {
  query?: QueryMask<typeof WillRevision>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IWillRevision;
    direction: "asc" | "desc";
  };
}

interface ReadWillRevisionResponse {
  willRevisions: Array<WillRevision>;
}

export class ReadWillRevisionHandler extends ActionBase {
  constructor(private message: ReadWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<ReadWillRevisionResponse> {
    const request = Api.resource("core", "/v2/core/select/will-revision")
      .method("get")

      .paginate(this.message.pagination ?? false)
      .sort(this.message.sort);
    request.buildQuery(WillRevision).where(this.message.query ?? {});

    const response = await request.result();

    return {
      willRevisions: WillRevision.$inflate(
        response.willRevision,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
