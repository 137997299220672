import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk";

export class TestEmailHandler extends ActionBase {
  constructor(
    private message: {
      emailAddress: string;
      template:
        | "document-storage-instructions"
        | "branded-kinvault-invitation"
        | "portal-notification"
        | "external-lead-notification"
        | "multi-factor-login"
        | "multi-factor-setup"
        | "order-payment"
        | "password-reset"
        | "email-campaign"
        | "auto-chase"
        | "annual-account-holder-reminder"
        | "annual-first-lite-trusted-people-reminder"
        | "annual-trusted-people-reminder";
    },
  ) {
    super();
  }

  public async execute(): Promise<void> {
    await Api.resource(
      "portal",
      `/v2/portal/email-test/${this.message.template}`,
    )
      .method("put")
      .params({
        emailAddress: this.message.emailAddress,
      })
      .result();
  }
}
