import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { WillRevision } from "@kinherit/sdk";

interface RecordKintinWillRevisionContentMessage {
  willRevision: string;
}

interface RecordKintinWillRevisionContentResponse {
  willRevision: WillRevision;
  content: Partial<Record<WillBuilderSections, string>>;
}

export class RecordKintinWillRevisionContentHandler extends ActionBase {
  constructor(private message: RecordKintinWillRevisionContentMessage) {
    super();
  }

  public async execute(): Promise<RecordKintinWillRevisionContentResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/kintin-will-revision/{willRevision}",
      {
        willRevision: this.message.willRevision,
      },
    )
      .method("get")

      .result();

    const willRevision = response.willRevision?.first();

    if (!willRevision) {
      throw new Error("No will revision found");
    }

    return {
      willRevision: WillRevision.$inflate([
        willRevision,
      ]).first() as WillRevision,
      content: (willRevision.content ?? {}) as Partial<
        Record<WillBuilderSections, string>
      >,
    };
  }
}
