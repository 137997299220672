import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { User } from "@kinherit/sdk";

interface RequestPasswordChangeMessage {
  user: string | User;
}

interface RequestPasswordChangeResponse {
  [key: string]: never;
}

export class RequestPasswordChangeHandler extends ActionBase {
  constructor(private message: RequestPasswordChangeMessage) {
    super();
  }

  public async execute(): Promise<RequestPasswordChangeResponse> {
    await Api.resource("auth", "/v2/auth/change-password/user/{user}", {
      user: this.message.user,
    })
      .method("put")

      .result();

    return {};
  }
}
