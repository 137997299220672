import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk";

interface CreateIntroducerCompanyPartnerPortalPermissionsMessage {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

interface CreateIntroducerCompanyPartnerPortalPermissionsResponse {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

export class CreateIntroducerCompanyPartnerPortalPermissionsHandler extends ActionBase {
  constructor(
    private message: CreateIntroducerCompanyPartnerPortalPermissionsMessage,
  ) {
    super();
  }

  public async execute(): Promise<CreateIntroducerCompanyPartnerPortalPermissionsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-partner-portal/{introducerCompany}/company-partner-portal-permissions",
      {
        introducerCompany: this.message.companyPartnerPortalPermissions.company,
      },
    )
      .method("post")
      .body({
        company: this.message.companyPartnerPortalPermissions.company.$id,
        allowPartnerPortal:
          this.message.companyPartnerPortalPermissions.allowPartnerPortal ??
          false,
        enableClients:
          this.message.companyPartnerPortalPermissions.enableClients ?? false,
        enableFees:
          this.message.companyPartnerPortalPermissions.enableFees ?? false,
        viewOrderProducts:
          this.message.companyPartnerPortalPermissions.viewOrderProducts ??
          false,
        enableStandaloneKinvault:
          this.message.companyPartnerPortalPermissions
            .enableStandaloneKinvault ?? false,
      })
      .result();

    const partnerPortalPermissions = CompanyPartnerPortalPermissions.$inflate(
      response.companyPartnerPortalPermissions,
    ).first();

    if (!partnerPortalPermissions) {
      throw new Error("Failed to create partner portal permission");
    }

    return {
      companyPartnerPortalPermissions: partnerPortalPermissions,
    };
  }
}
