import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Order } from "@kinherit/sdk";

export interface VoidOustandOrdersMessage {
  kintin: string | Kintin;
}

interface VoidOustandOrdersResponse {
  orders: Array<Order>;
}

export class VoidOustandOrdersHandler extends ActionBase {
  constructor(private message: VoidOustandOrdersMessage) {
    super();
  }

  public async execute(): Promise<VoidOustandOrdersResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}/orders/void-outstanding",
      {
        kintin: this.message.kintin,
      },
    )
      .method("put")
      .result();

    return {
      orders: Order.$inflate(response.order),
    };
  }
}
