import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailTemplate } from "@kinherit/sdk";

export interface DeleteEmailTemplateMessage {
  emailTemplate: EmailTemplate | string;
}

interface DeleteEmailTemplateResponse {
  [key: string]: never;
}

export class DeleteEmailTemplateHandler extends ActionBase {
  constructor(private message: DeleteEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<DeleteEmailTemplateResponse> {
    await Api.resource("portal", "/v2/portal/email-template/{emailTemplate}", {
      emailTemplate: this.message.emailTemplate,
    })
      .method("delete")

      .result();

    if (this.message.emailTemplate instanceof EmailTemplate) {
      this.message.emailTemplate.$delete();
    } else {
      EmailTemplate.$findOne(this.message.emailTemplate)?.$delete();
    }

    return {};
  }
}
