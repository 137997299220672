import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface DeleteKintinSignDocMessage {
  signDoc: SignDoc | string;
  kintin: Kintin | string;
}

export interface DeleteKintinSignDocResponse {
  [key: string]: NodeEventEmitter;
}

export class DeleteKintinSignDocHandler extends ActionBase {
  constructor(private message: DeleteKintinSignDocMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinSignDocResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}",
      {
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
        signDoc:
          "string" === typeof this.message.signDoc
            ? this.message.signDoc
            : this.message.signDoc.id,
      },
    )
      .method("delete")
      .result();

    const signDoc =
      "string" === typeof this.message.signDoc
        ? SignDoc.$findOne(this.message.signDoc)
        : this.message.signDoc;

    signDoc?.$delete();

    return {};
  }
}
