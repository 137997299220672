<template>
  <PageHeader text="IFA Call Script" htag="h2" />

  <Message
    title="Script"
    size="is-medium"
    color="is-primary"
    shade="is-light"
    drop-shadow
  >
    <div class="columns is-mobile">
      <div class="column is-narrow">
        <Icon :icon="$style.icon.speechBubble.icon" size="is-large" />
      </div>
      <div class="column">
        <p class="mt-0 has-text-weight-bold">
          Hello there, is this {{ LeadFirstName }} {{ leadLastName }}? It's
          {{ firstName }} calling from Kinherit. We got your name from
          {{ contactFirstName }} {{ contactLastName }} at {{ company }} who
          referred you for some help with your Will and Estate Planning? Does
          that sound right?
        </p>
        <p>
          <em
            >[Give customer a moment if needed to remember/acknowledge call]</em
          >
        </p>
        <p class="has-text-weight-bold">
          OK, great. So I was hoping to book you in for a call with
          {{ specialist }}, who helps {{ company }}'s clients. It’ll only take 2
          minutes, is that OK?
        </p>
        <p><b>Great…</b><em>[Or if not – improvise!]</em></p>
        <p>
          <b>
            Just before we look at diaries, can I ask - is there something in
            particular that prompted your discussion with
            {{ contactFirstName }}? And are there any current medical or
            well-being issues that we should be aware of &hellip; or do you just
            ‘need a Will…’?
          </b>
          <em> [maybe add ‘just helps to have a little bit of background’] </em>
        </p>
        <p class="has-text-weight-bold">
          We get lots of people
          <em>[in your situation /or repeat what they said]</em>, so I’m sure we
          can help.
        </p>
        <p class="has-text-weight-bold">
          OK great. So, just to make sure we allocate the right amount of time
          for your chat, can I just check a few things:
        </p>
        <p class="has-text-weight-bold">
          This is [a single Will? / Will for both you and your partner?]
        </p>
        <p class="has-text-weight-bold">Are you married?</p>
        <p class="has-text-weight-bold">And do you have children under 18?</p>
        <p class="has-text-weight-bold">Are you a homeowner?</p>
        <p class="has-text-weight-bold">And finally, do you own a business?</p>
        <p class="has-text-weight-bold">
          Wonderful – OK, so as I said, I’m arranging a call with
          {{ specialist }} – [he/she’s] really good - very experienced - so
          hopefully you’ll have a really good conversation. Just to confirm this
          initial chat and our recommendations are all complimentary - you only
          pay when you’re ready to proceed, so hopefully that’s all good.
        </p>
        <p>
          <b
            >So, looking at {{ specialist }}’s diary, [he/she] has some
            availability</b
          >
          [pick day 1-2 days ahead]. <b>Could [pick some options] work?</b>
        </p>
        <p><em>[Find / book time]</em></p>
        <p class="has-text-weight-bold">
          Can I just check, is this your best number? And I’ve got your email
          address as {{ email }}? Is that you?
        </p>
        <p class="has-text-weight-bold">
          Great that’s all sorted. I’ll send you an email in a moment confirming
          everything.
        </p>
        <p class="has-text-weight-bold">
          One last thing – I think you were sent a link to a short video on what
          we call our Kinvault – I don’t suppose you have had a chance to watch
          it at all? I’m sure you’re busy…
        </p>
        <p>
          <em>[IF YES]</em>&nbsp;
          <b
            >OK, that’s great. So you understand a bit about our digital
            planning platform then.</b
          >
        </p>
        <p>
          <em>[IF NO]</em>&nbsp;
          <b
            >Don’t worry – I’ll resend the link after our call…. It’s worth a
            quick watch as it’s only a minute long, but it shows you what you
            get as part of your Will package.</b
          >
        </p>
        <p class="has-text-weight-bold">
          Right then, that’s all done. As I mentioned, my name’s X and I’ll send
          you an email after this call.
        </p>
        <p class="has-text-weight-bold">Do you have any questions?</p>
        <p class="has-text-weight-bold">
          OK, then, great. It was very nice to talk to you.
        </p>
        <p class="has-text-weight-bold">You’re very welcome… OK, bye then</p>
        <em>[IF DURING THE CONVERSATION THEY ASK FOR A PRICE]</em>
        <p class="has-text-weight-bold">
          So, [as I said] there’s no charge for an information call, or to get
          an outline of your options.
        </p>
        <p class="has-text-weight-bold">
          If you decide you do want us to help you create your Will, you get the
          Will, your own digital planning platform and all the handover services
          on death (these extra things you don’t get with other Wills), so for
          all of this we charge just £345 (for a single Will) / £495 (for a
          couple with mirror Wills).
        </p>
        <p class="has-text-weight-bold">
          If it helps, our pricing is on our website, but I would stress it’s
          worth having the conversation, to work out what suits your situation,
          knowing that it’s always your decision whether you choose to move
          forward or not.
        </p>
      </div>
    </div>
  </Message>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Icon from "@kinherit/framework/component.display/icon";
import Message from "@kinherit/framework/component.display/message";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { LeadDetailsIfaCallScriptRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsIfaCallScriptRoute,
  mixins: [LeadDetailsMixin, StyleService.mixin, AuthService.mixin()],
  components: { Message, Icon, PageHeader },
  computed: {
    firstName(): string {
      return this.$auth.loggedInUser?.profile.firstName || "[FIRST NAME]";
    },
    LeadFirstName(): string {
      return this.lead?.profile.firstName || "[FIRST NAME]";
    },
    leadLastName(): string {
      return this.lead?.profile.lastName || "[LAST NAME]";
    },
    company(): string {
      const profile = this.lead?.referral?.referralCode?.company?.profile;

      if (!profile) {
        return "[COMPANY]";
      }

      return profile.organisationName ?? profile.fullName ?? "[COMPANY]";
    },
    contactFirstName(): string {
      return this.lead?.referral?.contact?.profile.firstName || "[FIRST NAME]";
    },
    contactLastName(): string {
      return this.lead?.referral?.contact?.profile.lastName || "[LAST NAME]";
    },
    email(): string {
      return this.lead?.profile.emails.first()?.email || "[EMAIL]";
    },
    specialist(): string {
      return (
        this.lead?.ownedBy?.profile.firstName ??
        this.lead?.assignedTo?.profile.firstName ??
        "[SPECIALIST]"
      );
    },
  },
});
</script>
