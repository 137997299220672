import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Exclusion } from "@kinherit/sdk";

interface UpdateKintinExclusionMessage {
  exclusion: Exclusion;
}

interface UpdateKintinExclusionResponse {
  exclusion: Exclusion;
}

export class UpdateKintinExclusionHandler extends ActionBase {
  constructor(private message: UpdateKintinExclusionMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinExclusionResponse> {
    const { exclusion: exclusionData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-exclusion/{kintin}/exclusion/{exclusion}",
      {
        kintin: this.message.exclusion.kintin.id,
        exclusion: this.message.exclusion.id,
      },
    )
      .method("patch")

      .body({
        kintin: this.message.exclusion.$data.kintin,
        excludedBy: this.message.exclusion.$data.excludedBy,
        relationship: this.message.exclusion.$data.relationship,
        who: this.message.exclusion.$data.who,
        why: this.message.exclusion.$data.why,
      })
      .result();

    const exclusion = Exclusion.$inflate(exclusionData).first();

    if (!exclusion) {
      throw new Error("Failed to update exclusion");
    }

    return { exclusion };
  }
}
