import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  In,
  LessThanOrEqual,
  Like,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import { Api, CallLog, ICallLog, Kintin, Person, Profile } from "@kinherit/sdk";

export interface ReadCallLogMessage {
  username?: string;
  direction?: Array<string>;
  minDuration?: number | null;
  maxDuration?: number | null;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof ICallLog;
    direction: "asc" | "desc";
  };
}

interface ReadCallLogResponse {
  callLogs: Array<CallLog>;
  people: Array<Person>;
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadCallLogHandler extends ActionBase {
  constructor(private message: ReadCallLogMessage) {
    super();
  }

  public async execute(): Promise<ReadCallLogResponse> {
    const request = Api.resource("portal", "/v2/portal/call-log")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(CallLog).where({
      username: Like(this.message.username),
      duration:
        this.message.minDuration && this.message.maxDuration
          ? Between([this.message.minDuration, this.message.maxDuration])
          : this.message.minDuration
            ? LessThanOrEqual(this.message.minDuration)
            : this.message.maxDuration
              ? MoreThanOrEqual(this.message.maxDuration)
              : undefined,
      direction: {
        id: In(this.message.direction),
      },
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      callLogs: CallLog.$inflate(
        response.callLog,
        this.message.sort,
        response.$rootIds,
      ),
      people: Person.$inflate(response.person),
      kintins: Kintin.$inflate(response.kintin),
      profiles: Profile.$inflate(response.profile),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
