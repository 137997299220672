import { ActiveSession } from "@/module/auth/model/active-session.model";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Store } from "@kinherit/orm";
import { nextTick } from "vue";
import { AuthLoginRoute } from "../../page";

interface LogoutSessionMessage {
  [key: string]: never;
}

interface LogoutSessionResponse {
  [key: string]: never;
}

export class LogoutSessionHandler extends ActionBase {
  constructor(private message: LogoutSessionMessage) {
    super();
  }

  public async execute(): Promise<LogoutSessionResponse> {
    Store.purge();
    ActiveSession.$getInstance()?.$delete();
    nextTick(() => {
      window.Kernel.ComponentOptions.Navbar.callRefresh();
      window.Kernel.visitRoute({
        name: AuthLoginRoute,
      });
    });

    return {};
  }
}
