import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk/api";

export class GetSignAppUrlHandler extends ActionBase {
  constructor(private message: {}) {
    super();
  }

  public async execute(): Promise<{
    url: string;
  }> {
    const url = await Api.resource("core", "/v2/core/environment/sign-url")
      .method("get")
      .result();

    return {
      url,
    };
  }
}
