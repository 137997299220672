import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCpd } from "@kinherit/sdk";

interface DeleteIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface DeleteIntroducerCpdResponse {
  [key: string]: never;
}

export class DeleteIntroducerCpdHandler extends ActionBase {
  constructor(private message: DeleteIntroducerCpdMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerCpdResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}",
      {
        introducerCpd: this.message.introducerCpd,
      },
    )
      .method("delete")

      .result();

    this.message.introducerCpd.$delete();

    return {};
  }
}
