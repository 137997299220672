<template>
  <Card title="Appointment Breakdown">
    <p>{{ computedTimeframeText }}</p>

    <div v-if="results">
      <Card
        v-for="result in results"
        :key="result.groupName"
        :title="result.groupName"
      >
        <p>
          {{ result.total }} appointments, {{ result.cancelled }} cancelled ({{
            result.cancelled
              ? ((result.cancelled / result.total) * 100).toFixed(2)
              : 0
          }}%), {{ result.total - result.cancelled }} held
        </p>
        <Table
          :is-narrow="true"
          :is-hoverable="true"
          :rows="result.appointmentTypes"
          :columns="computedColumns"
          click-to-expand
        >
          <template #details="{ row }">
            <div class="columns">
              <AppointmentBreakdownDetail
                title="Positive"
                class="mt-3"
                :outcomes="row.positiveOutcomes"
              />
              <AppointmentBreakdownDetail
                title="Negative"
                :outcomes="row.negativeOutcomes"
              />
              <AppointmentBreakdownDetail
                title="Neutral"
                :outcomes="row.neutralOutcomes"
              />
            </div>
            <div class="columns">
              <AppointmentBreakdownDetail
                title="Positive (Cancelled)"
                class="mt-3"
                :outcomes="row.cancelledPositiveOutcomes"
              />
              <AppointmentBreakdownDetail
                title="Negative (Cancelled)"
                :outcomes="row.cancelledNegativeOutcomes"
              />
              <AppointmentBreakdownDetail
                title="Neutral (Cancelled)"
                :outcomes="row.cancelledNeutralOutcomes"
              />
            </div>
          </template>
        </Table>
      </Card>
    </div>
  </Card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  AppointmentsBreakdownReportParams,
  AppointmentsBreakdownReportRoute,
} from ".";

import { getPeriodStartAndEndDates } from "../data/helper-functions";

import { ComputeAppointmentsBreakdownReportResponse } from "@/module/report/action/compute-appointments-breakdown-report.action";

import Card from "@kinherit/framework/component.layout/card";
import Table from "@kinherit/framework/component.display/table";
import { Option } from "@kinherit/sdk";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import AppointmentBreakdownDetail from "../component/AppointmentBreakdownDetail.vue";
import { quickPercentage } from "../data/helper-functions";
type AppointmentGroup = {
  groupName: string;
  total: number;
  cancelled: number;
  appointmentTypes: AppointmentTypeResult[];
};
type AppointmentGroupParsed = AppointmentGroup & {
  appointmentTypes: AppointmentTypeResultParsed[];
};

type AppointmentTypeResult = {
  id: string;
  total: number;
  cancelled: number;
  outcomes: AppointmentOutcomeResult[];
};
type AppointmentOutcomeResult = {
  id: string;
  count: number;
};

type AppointmentTypeResultParsed = AppointmentTypeResult & {
  name: string;
  positiveOutcomes: AppointmentOutcomeResultParsed[];
  negativeOutcomes: AppointmentOutcomeResultParsed[];
  neutralOutcomes: AppointmentOutcomeResultParsed[];
  cancelledPositiveOutcomes: AppointmentOutcomeResultParsed[];
  cancelledNegativeOutcomes: AppointmentOutcomeResultParsed[];
  cancelledNeutralOutcomes: AppointmentOutcomeResultParsed[];
  positiveOutcomesCount: number;
  negativeOutcomesCount: number;
  neutralOutcomesCount: number;
};

export type AppointmentOutcomeResultParsed = AppointmentOutcomeResult & {
  name: string;
  class: string;
  percentage: string | null;
};

export default defineComponent({
  name: AppointmentsBreakdownReportRoute,
  props: {},
  components: {
    Card,
    Table,
    AppointmentBreakdownDetail,
  },
  data: () => ({
    results: [] as AppointmentGroup[],
    appointmentTypes: Option.$findBy({
      group: "appointmentType",
    }),
    appointmentOutcomes: Option.$findBy({
      group: "appointmentOutcome",
    }),
  }),
  computed: {
    $params(): AppointmentsBreakdownReportParams {
      return this.$route.params as AppointmentsBreakdownReportParams;
    },
    computedTimeframeText() {
      return `Breakdown for ${this.$params.year} -> ${this.$params.granularity} -> ${this.$params.period}`;
    },
    computedOutcomeGroupNegative() {
      return this.appointmentOutcomes.filter((outcome) => {
        return outcome.class === "danger";
      });
    },
    computedOutcomeGroupPositive() {
      return this.appointmentOutcomes.filter((outcome) => {
        return outcome.class === "success";
      });
    },
    computedNeutralOutcomeGroup() {
      return this.appointmentOutcomes.filter((outcome) => {
        return !["success", "danger"].includes(outcome.class ?? "");
      });
    },
    computedColumns() {
      const baseColumns = [
        {
          title: "Type",
          map: (results: AppointmentTypeResultParsed) => results.name,
        },
        {
          title: "Total Booked",
          map: (results: AppointmentTypeResultParsed) => results.total,
        },
        {
          title: "Cancelled",
          class: "has-text-danger has-text-weight-bold",
          mapHtml: (results: AppointmentTypeResultParsed) => {
            return quickPercentage(results.cancelled, results.total);
          },
        },
        {
          title: "Held",
          map: (results: AppointmentTypeResultParsed) =>
            results.total - results.cancelled,
        },
        {
          title: "Positive",
          class: "has-text-success has-text-weight-bold",
          mapHtml: (results: AppointmentTypeResultParsed) => {
            return quickPercentage(
              results.positiveOutcomesCount,
              results.total,
            );
          },
        },
        {
          title: "Negative",
          class: "has-text-danger has-text-weight-bold",
          mapHtml: (results: AppointmentTypeResultParsed) => {
            return quickPercentage(
              results.negativeOutcomesCount,
              results.total,
            );
          },
        },
        {
          title: "Neutral",
          class: "has-text-info has-text-weight-bold",
          mapHtml: (results: AppointmentTypeResultParsed) => {
            return quickPercentage(results.neutralOutcomesCount, results.total);
          },
        },
      ] as TableColumn[];

      return [...baseColumns];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const created = getPeriodStartAndEndDates(
        this.$params.granularity as "month" | "week" | "quarter" | "year",
        this.$params.year,
        this.$params.period,
      );
      const result = await window.Kernel.ActionBus.execute(
        "report/appointments/breakdown",
        {
          created,
        },
      );
      this.results = this.parseResults(result);
    },
    parseResults(
      result: ComputeAppointmentsBreakdownReportResponse,
    ): AppointmentGroupParsed[] {
      return result.data.map((groupResult) => {
        return {
          ...groupResult,
          groupName:
            groupResult.groupName.charAt(0).toUpperCase() +
            groupResult.groupName.slice(1),
          appointmentTypes: groupResult.appointmentTypes.map(
            (appointmentType) => {
              const parsedOutcomes = appointmentType.outcomes.map((outcome) => {
                const outcomeOption = this.appointmentOutcomes.find(
                  (option) => option.id === outcome.id,
                );
                return {
                  ...outcome,
                  name: outcomeOption?.text ?? outcome.id,
                  class: outcomeOption?.class ?? "",
                  percentage:
                    outcome.count > 0
                      ? ((outcome.count / appointmentType.total) * 100).toFixed(
                          2,
                        )
                      : null,
                };
              });

              const parsedCancelledOutcomes =
                appointmentType.cancelledOutcomes.map((outcome) => {
                  const outcomeOption = this.appointmentOutcomes.find(
                    (option) => option.id === outcome.id,
                  );
                  return {
                    ...outcome,
                    name: outcomeOption?.text ?? outcome.id,
                    class: outcomeOption?.class ?? "",
                    percentage:
                      outcome.count > 0
                        ? (
                            (outcome.count / appointmentType.cancelled) *
                            100
                          ).toFixed(2)
                        : null,
                  };
                });

              // Split outcomes into positive, negative, and neutral based on class
              const positiveOutcomes = parsedOutcomes.filter(
                (outcome) => outcome.class === "success",
              );
              const negativeOutcomes = parsedOutcomes.filter(
                (outcome) => outcome.class === "danger",
              );
              const neutralOutcomes = parsedOutcomes.filter(
                (outcome) => !["success", "danger"].includes(outcome.class),
              );
              const positiveOutcomesCount = positiveOutcomes.reduce(
                (acc, outcome) => acc + outcome.count,
                0,
              );
              const negativeOutcomesCount = negativeOutcomes.reduce(
                (acc, outcome) => acc + outcome.count,
                0,
              );
              const neutralOutcomesCount = neutralOutcomes.reduce(
                (acc, outcome) => acc + outcome.count,
                0,
              );
              // Cancelled
              const cancelledPositiveOutcomes = parsedCancelledOutcomes.filter(
                (outcome) => outcome.class === "success",
              );
              const cancelledNegativeOutcomes = parsedCancelledOutcomes.filter(
                (outcome) => outcome.class === "danger",
              );
              const cancelledNeutralOutcomes = parsedCancelledOutcomes.filter(
                (outcome) => !["success", "danger"].includes(outcome.class),
              );

              return {
                ...appointmentType,
                name:
                  this.appointmentTypes.find(
                    (option) => option.id === appointmentType.id,
                  )?.text ?? appointmentType.id,
                positiveOutcomes,
                negativeOutcomes,
                neutralOutcomes,
                cancelledPositiveOutcomes,
                cancelledNegativeOutcomes,
                cancelledNeutralOutcomes,
                positiveOutcomesCount,
                negativeOutcomesCount,
                neutralOutcomesCount,
              };
            },
          ),
        };
      });
    },
  },
});
</script>
