import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCompany } from "@kinherit/sdk";

interface DownloadIntroducerCompanyStatsMessage {
  introducerCompany: IntroducerCompany;
}

export class DownloadIntroducerCompanyStatsHandler extends ActionBase {
  constructor(private message: DownloadIntroducerCompanyStatsMessage) {
    super();
  }

  public async execute(): Promise<{}> {
    const { introducerCompany } = this.message;
    await Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}/stats/download",
      {
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
      },
    )
      .method("get")
      .download({
        encoding: "application/pdf",
        fileName: `Referral Report - ${introducerCompany.profile?.organisationNumber}.pdf`,
      });

    return {};
  }
}
