import { CreateXeroRepeatingInvoiceHandler } from "@/module/kinvault.subscription/action/create-xero-repeating-invoice.action";
import { CreateSubscriptionHandler } from "./create-subscription.action";
import { DeleteSubscriptionHandler } from "./delete-subscription.action";
import { ReadSubscriptionHandler } from "./read-subscription.action";
import { UpdateSubscriptionHandler } from "./update-subscription.action";

export const Actions = {
  "kinvault/subscription/read": ReadSubscriptionHandler,
  "kinvault/subscription/update": UpdateSubscriptionHandler,
  "kinvault/subscription/delete": DeleteSubscriptionHandler,
  "kinvault/subscription/create": CreateSubscriptionHandler,
  "kinvault/subscription/create-xero-repeating-invoice":
    CreateXeroRepeatingInvoiceHandler,
};
