import { ActionBase } from "@kinherit/framework/action-bus/base";
import { In, IsNotNull, Like, IsNull, Between } from "@kinherit/orm/index";
import {
  Api,
  IPhysicalStorageFileLog,
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Subscription,
} from "@kinherit/sdk";

export interface ReadStorageRequestMessage {
  name?: string;
  complete?: boolean | null;
  logAction: string[];
  created?: [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IPhysicalStorageFileLog;
    direction: "asc" | "desc";
  };
}

interface ReadStorageRequestResponse {
  physicalStorageFiles: Array<PhysicalStorageFile>;
  subscriptions: Array<Subscription>;
  kintins: Array<Kintin>;
  physicalStorageFileLogs: Array<PhysicalStorageFileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadStorageRequestHandler extends ActionBase {
  constructor(private message: ReadStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<ReadStorageRequestResponse> {
    const request = Api.resource("portal", "/v2/portal/storage-request")
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(PhysicalStorageFileLog).where({
      logAction: {
        id: In(this.message.logAction),
      },
      file: {
        subscription: {
          kintin: {
            id: IsNotNull(),
            friendlyName: Like(this.message.name),
          },
        },
      },
      completedAt:
        null === this.message.complete || undefined === this.message.complete
          ? undefined
          : true === this.message.complete
            ? IsNotNull()
            : IsNull(),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      physicalStorageFiles: PhysicalStorageFile.$inflate(
        response.physicalStorageFile,
      ),
      subscriptions: Subscription.$inflate(response.subscription),
      kintins: Kintin.$inflate(response.kintin),
      physicalStorageFileLogs: PhysicalStorageFileLog.$inflate(
        response.physicalStorageFileLog,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
