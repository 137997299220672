import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Like, In, Between } from "@kinherit/orm/index";
import {
  Api,
  EmailAddress,
  IIntroducerContact,
  IntroducerCompany,
  IntroducerContact,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerContactMessage {
  search?: string;
  tags?: Array<string>;
  status?: Array<string>;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IIntroducerContact;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerContactResponse {
  introducerContacts: Array<IntroducerContact>;
  introducerCompanies: Array<IntroducerCompany>;
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerContactHandler extends ActionBase {
  constructor(private message: ReadIntroducerContactMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContactResponse> {
    const request = Api.resource("portal", "/v2/portal/introducer-contact")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(IntroducerContact).where([
      {
        profile: {
          fullName: Like(this.message.search),
        },
        tags: {
          id: In(this.message.tags),
        },
        status: {
          id: In(this.message.status),
        },
        createdAt: Between(this.message.created),
      },
      {
        profile: {
          emails: {
            email: Like(this.message.search),
          },
        },
        tags: {
          id: In(this.message.tags),
        },
        status: {
          id: In(this.message.status),
        },
        createdAt: Between(this.message.created),
      },
    ]);

    const response = await request.result();

    return {
      introducerContacts: IntroducerContact.$inflate(
        response.introducerContact,
        this.message.sort,
        response.$rootIds,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      profiles: Profile.$inflate(response.profile),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
