import { ActionBase } from "@kinherit/framework/action-bus/base";
import { OpenDangerDialog } from "@kinherit/framework/global/dialog";
import { Api } from "@kinherit/sdk/api";

export class UniqueUsernameAction extends ActionBase {
  constructor(public username: string) {
    super();
  }

  public async execute(): Promise<void> {
    try {
      await Api.resource("auth", "/v2/auth/unique-username")
        .method("get")
        .params({ username: this.username })
        .result();
    } catch (error) {
      OpenDangerDialog({
        dialog: {
          title: "Username is not unique",
          message: `The username "${this.username}" is already in use. This usually indicates that an account already exists: check with TK!`,
        },
      });
      throw error;
    }
  }
}
