import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  BrandedKinvault,
  Kintin,
  KintinAccess,
  Profile,
  User,
} from "@kinherit/sdk";

interface CreateKintinMessage {
  kintin: Kintin;
  users: {
    primary: User;
    secondary: User | null;
  };
  access: {
    primary: KintinAccess;
    secondary: KintinAccess | null;
  };
  brandedKinvault: BrandedKinvault | null;
}

interface CreateKintinResponse {
  kintin: Kintin;
  profiles: Array<Profile>;
}

export class CreateKintinHandler extends ActionBase {
  constructor(private message: CreateKintinMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinResponse> {
    const primaryProfile = this.message.kintin.primaryPerson?.profile;
    const secondaryProfile = this.message.kintin.secondaryPerson?.profile;

    const response = await Api.resource("portal", "/v2/portal/kintin", {
      kintin: this.message.kintin,
    })
      .method("post")

      .body({
        process: this.message.kintin.$data.process,
        type: this.message.kintin.$data.type,
        status: this.message.kintin.$data.status,
        stage: this.message.kintin.$data.stage,
        callScript: {},
        referral: {},
        permissions: {},
        primaryPerson: {
          profile: {
            firstName: primaryProfile.$data.firstName,
            lastName: primaryProfile.$data.lastName,
            phoneNumbers: primaryProfile.phoneNumbers.map((phoneNumber) => ({
              tel: phoneNumber.tel,
            })),
            emails: primaryProfile.emails.map((email) => ({
              email: email.email,
            })),
          },
          type: "person",
          notifyOnDeath: false,
        },
        secondaryPerson: secondaryProfile
          ? {
              profile: {
                firstName: secondaryProfile.$data.firstName,
                lastName: secondaryProfile.$data.lastName,
                phoneNumbers: secondaryProfile.phoneNumbers.map(
                  (phoneNumber) => ({
                    tel: phoneNumber.tel,
                  }),
                ),
                emails: secondaryProfile.emails.map((email) => ({
                  email: email.email,
                })),
              },
              type: "person",
              notifyOnDeath: false,
            }
          : undefined,
      })
      .result();

    const kintin = Kintin.$inflate(response.kintin).first();

    if (!kintin) {
      throw new Error("Failed to create kintin");
    }

    await ["primary" as const, "secondary" as const].forEachAsync(
      async (key) => {
        const user = this.message.users[key];
        const access = this.message.access[key];

        if (!user || !access) {
          return;
        }

        const { user: newUser } = await window.Kernel.ActionBus.execute(
          "kinvault/kintin/users/create",
          {
            kintin,
            user,
            accessType: "readonly",
          },
        );

        access.user = newUser;
        access.kintin = kintin;

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/kintin-user-access/create",
          {
            kintin,
            kintinAccess: access,
          },
        );

        if (this.message.brandedKinvault) {
          await window.Kernel.ActionBus.execute(
            "admin/user/branded-kinvault-membership/create",
            {
              brandedKinvault: this.message.brandedKinvault,
              user: newUser,
            },
          );
        }
      },
    );

    this.message.kintin.$delete();
    this.message.access.primary.$delete();
    this.message.access.secondary?.$delete();
    this.message.users.primary.$delete();
    this.message.users.secondary?.$delete();

    return {
      kintin,
      profiles: Profile.$inflate(response.profile),
    };
  }
}
