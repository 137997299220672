import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Api,
  IntroducerBillingContact,
  IntroducerCompany,
} from "@kinherit/sdk";
export interface DeleteIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface DeleteIntroducerBillingContactResponse {
  [key: string]: never;
}

export class DeleteIntroducerBillingContactHandler extends ActionBase {
  constructor(private message: DeleteIntroducerBillingContactMessage) {
    super();
  }

  public async execute(): Promise<DeleteIntroducerBillingContactResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts/{introducerBillingContact}",
      {
        introducerCompany: this.message.introducerCompany,
        introducerBillingContact: this.message.introducerBillingContact,
      },
    )
      .method("delete")
      .result();

    this.message.introducerBillingContact.$delete();

    return {};
  }
}
