import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Property } from "@kinherit/sdk";

interface UpdatePropertyMessage {
  kintin: string;
  property: Property;
}

interface UpdatePropertyResponse {
  property: Property;
}

export class UpdatePropertyHandler extends ActionBase {
  constructor(private message: UpdatePropertyMessage) {
    super();
  }

  public async execute(): Promise<UpdatePropertyResponse> {
    const { property: propertyData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-property/{kintin}/properties/{property}",
      {
        kintin: this.message.kintin,
        property: this.message.property.$data.id,
      },
    )
      .method("patch")

      .body({
        titleDeed: this.message.property.$data.titleDeed,
        price: this.message.property.$data.price,
        value: this.message.property.$data.value,
        lessMortgage: this.message.property.$data.lessMortgage,
        notes: this.message.property.$data.notes,
        address: this.message.property.$data.address,
        howOwned: this.message.property.$data.howOwned,
        ownership: this.message.property.$data.ownership,
        type: this.message.property.$data.type,
      })
      .result();

    const property = Property.$inflate(propertyData).first();

    if (!property) {
      throw new Error("Failed to update property");
    }

    return { property };
  }
}
