import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Record<string, File>;
};

type Response = {};

export class CreateEmailNamedAttachmentHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}/named-attachment",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}/named-attachment",
      [Kintin.$name]:
        "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}/named-attachment",
      [Lead.$name]:
        "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}/named-attachment",
      [Person.$name]:
        "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}/named-attachment",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}/named-attachment",
    } as const;

    const route = routes[this.message.owner.$name];

    for (const name in this.message.files) {
      const file = this.message.files[name];

      await Api.resource("portal", route, {
        owner: this.message.owner,
        emailLog: this.message.emailLog,
      })
        .method("put")

        .files({
          uploadedFile: file,
          attachmentName: name,
        })
        .result();
    }

    return {} as R;
  }
}
