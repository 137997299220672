import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferralCode, BrandedKinvault } from "@kinherit/sdk";

interface Message {
  brandedKinvault: BrandedKinvault;
  file: File;
  defaultReferralCode: AccountReferralCode;
  brandOverride: BrandedKinvault | null;
  sendInvitations: boolean;
}

interface Response {
  [key: string]: never;
}

export class UploadBrandedKinvaultInvitationCsvHandler extends ActionBase {
  constructor(private message: Message) {
    super();
  }

  public async execute(): Promise<Response> {
    const { file } = this.message;

    const resource = this.message.sendInvitations
      ? "/v2/portal/branded-kinvault/invitation/{brandedKinvault}/csv/send"
      : "/v2/portal/branded-kinvault/invitation/{brandedKinvault}/csv/upload";

    return (await Api.resource("portal", resource, {
      brandedKinvault: this.message.brandedKinvault,
    })
      .method("put")

      .files({
        uploadedFile: file,
        defaultReferralCode: this.message.defaultReferralCode.id,
        brandOverride:
          this.message.brandOverride && this.message.brandOverride.id,
      })
      .result()) as never;
  }
}
