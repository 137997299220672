import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface UpdateIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment;
}

export interface UpdateIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
}

export class UpdateIntroducerFeePaymentHandler extends ActionBase {
  constructor(private message: UpdateIntroducerFeePaymentMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerFeePaymentResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
      this.message,
    )
      .method("patch")
      .body({
        completedAt: this.message.introducerFeePayment.$data.completedAt,
        status: this.message.introducerFeePayment.$data.status,
        xeroContact: this.message.introducerFeePayment.$data.xeroContact,
        introducerFeePaymentRun:
          this.message.introducerFeePayment.$data.introducerFeePaymentRun,
      })
      .result();

    const introducerFeePayment = IntroducerFeePayment.$inflate(
      repsonse.introducerFeePayment,
    ).first();

    if (!introducerFeePayment) {
      throw new Error("Failed to Update introducerfeepayment");
    }

    return {
      introducerFeePayment: introducerFeePayment,
    };
  }
}
