import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk";

export class ClearCacheHandler extends ActionBase {
  constructor(private _message: {}) {
    super();
  }

  public async execute(): Promise<void> {
    await Api.resource("core", "/v2/core/cache").method("delete").result();
  }
}
