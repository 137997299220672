import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface DeleteEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
}

export interface DeleteEmailCampaignLogResponse {
  [key: string]: never;
}

export class DeleteEmailCampaignLogHandler extends ActionBase {
  constructor(private message: DeleteEmailCampaignLogMessage) {
    super();
  }

  public async execute(): Promise<DeleteEmailCampaignLogResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign-log/{emailCampaignLog}",
      this.message,
    )
      .method("delete")

      .result();

    this.message.emailCampaignLog.$delete();

    return {};
  }
}
