<template>
  <PageHeader htag="h1" text="Orders" />
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :row-classes="rowClasses"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      paidAt: 'Paid',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { OrderTableColumns } from "@/config/table-columns.config";
import { ReadOrderForm } from "@/module/order/form/read-order.form";
import { IOrder, Order } from "@kinherit/sdk";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { OrderMasterListRoute } from ".";
import { OrderDetailsParams, OrderDetailsRoute } from "./details";

export default defineComponent({
  name: OrderMasterListRoute,
  components: { MasterListPage, PageHeader },
  data: () => ({
    filters: ReadOrderForm(),
    columns: OrderTableColumns({
      name: true,
    }),
    rows: Array<Order>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IOrder,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light":
          scope.row.status.value === "void" ||
          scope.row.status.value === "refund",
        "has-background-success-light": scope.row.status.value === "paid",
      };
    },
    handleClick(order: Order, event: MouseEvent): void {
      const params: OrderDetailsParams = { order: order.id };

      window.Kernel.visitRoute(
        {
          name: OrderDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadOrderForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute("order/order/read", {
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.orders;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
