import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Beneficiary, Kintin } from "@kinherit/sdk";

interface DeleteBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface DeleteBeneficiaryResponse {
  [key: string]: never;
}

export class DeleteBeneficiaryHandler extends ActionBase {
  constructor(private message: DeleteBeneficiaryMessage) {
    super();
  }

  public async execute(): Promise<DeleteBeneficiaryResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary/{beneficiary}",
      {
        kintin: this.message.kintin,
        beneficiary: this.message.beneficiary.$data.id,
      },
    )
      .method("delete")
      .result();

    this.message.beneficiary.$delete();

    return {};
  }
}
