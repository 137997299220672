import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ComputeCashflowReportMessage {
  group: "paidAt" | "createdAt";
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
  referral: string | null;
  referrerCategory: string | null;
}

interface ComputeCashflowReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    advisedValue: number;
    advisedCount: number;
    hostedValue: number;
    hostedCount: number;
    lpasOnlyValue: number;
    lpasOnlyCount: number;
    trustRegValue: number;
    trustRegCount: number;
    sumValue: number;
  }>;
}
export type TCashflowReportResult = {
  year: number;
  month: number;
  week: number;
  quarter: number;
  unpaidValue: number;
  unpaidCount: number;
  advisedValue: number;
  advisedCount: number;
  hostedValue: number;
  hostedCount: number;
  lpasOnlyValue: number;
  lpasOnlyCount: number;
  trustRegValue: number;
  trustRegCount: number;
  subscriptionValue: number;
  subscriptionCount: number;
  sumValue: number;
};
export class ComputeCashflowReportHandler extends ActionBase {
  constructor(private message: ComputeCashflowReportMessage) {
    super();
  }

  public async execute(): Promise<ComputeCashflowReportResponse> {
    const {
      year,
      created,
      granularity,
      assignedUser,
      referral,
      referrerCategory,
      compareTo,
      group,
    } = this.message || {};

    const calculatedDates = calculateStartEndDates(year, created, compareTo);

    const response = await Api.resource(
      "portal",
      "/v2/portal/report/cashflow",
      {},
    )
      .method("get")
      .params({
        group: group,
        startDate: calculatedDates.startDate,
        endDate: calculatedDates.endDate,
        granularity: granularity,
        assignedUser: assignedUser ?? null,
        referral: referral ?? null,
        referrerCategory: referrerCategory ?? null,
        compareToStartDate: calculatedDates.compareToStartDate,
        compareToEndDate: calculatedDates.compareToEndDate,
      })

      .result();

    const datasets: {
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }[] = [];

    const colours = ["#689F38", "#33691E"];
    // For each dataset returned, create a new dataset object with the correct format
    // for the chart.js graph
    response.datasets.forEach((dataset, index: number) => {
      datasets.push({
        label: "Cashflow",
        stack: "Stack " + index,
        backgroundColor: colours[index],
        data: dataset.datasets.map((i: any) => {
          return i.sumValue;
        }),
      });
    });

    return {
      datasets: datasets,
      labels: response.labels.map((label) => label.toString()),
      results:
        response.datasets.length > 1
          ? mergeArrays(response.datasets.pluck("datasets"))
          : response.datasets[0].datasets,
    };
  }
}
