import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Api,
  IntroducerCompany,
  IntroducerContract,
  Order,
} from "@kinherit/sdk";

export interface CreateIntroducerInvoiceMessage {
  introducerCompany: IntroducerCompany | string;
  brand: "kinherit";
  introducerCompanyType: "agent" | "introducer";
  introducerContract: IntroducerContract | string;
  orders: Array<Order | string>;
  feesType: "introducer" | "agent" | "parentAgent";
}

interface CreateIntroducerInvoiceResponse {
  [key: string]: never;
}

export class CreateIntroducerInvoiceHandler extends ActionBase {
  constructor(private message: CreateIntroducerInvoiceMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerInvoiceResponse> {
    const response = await Api.resource(
      "external",
      `/v2/external/xero/create-introducer-fee-bill`,
    )
      .method("post")
      .body({
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
        //introducerCompanyType: this.message.introducerCompanyType,
        brand: this.message.brand,
        introducerContract:
          "string" === typeof this.message.introducerContract
            ? this.message.introducerContract
            : this.message.introducerContract.id,
        orders: this.message.orders.map((order) =>
          "string" === typeof order ? order : order.id,
        ),
        feesType: this.message.feesType,
      })
      .result();

    console.log(response);

    return {};
  }
}
