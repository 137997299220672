<template>
  <PageHeader htag="h2" text="Registration" />
  <MasterListPage
    @row-clicked="handleClick"
    is-nested
    :count="registrations.length"
    :columns="columns"
    :rows="registrations"
    :enable-per-page="false"
  >
    <template #email="{ row }: { row: CpdSessionRegistration }">
      <span v-for="email in row.profile.emails" :key="email.id">
        {{ email.email }}
      </span>
    </template>
    <template #matched="{ row }: { row: CpdSessionRegistration }">
      <span
        :class="{
          'badge is-success': row.isMatched,
          'badge is-danger': !row.isMatched,
        }"
      >
        {{ row.isMatched ? "Yes" : "No" }}
      </span>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateCpdSessionRegistrationMatchesForm } from "@/module/cpd/form/update-cpd-session-registration-matches.form";
import { CpdDetailsMixin } from "@/module/cpd/mixin/cpd-details.mixin";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { CpdSessionRegistration, IntroducerCpd, Option } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { CpdDetailsRegistrationsRoute } from ".";
export default defineComponent({
  name: CpdDetailsRegistrationsRoute,
  components: {
    MasterListPage,
    PageHeader,
  },
  mixins: [CpdDetailsMixin],
  data: () => ({
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: CpdSessionRegistration) => v.profile.fullName,
      },
      {
        title: "Email",
        sort: true,
        slot: "email",
      },
      {
        title: "Matched",
        sort: true,
        slot: "matched",
      },
    ] as Array<TableColumn>,
    registrations: Array<CpdSessionRegistration>(),
  }),
  mounted(): void {
    this.registrations = CpdSessionRegistration.$findBy({
      session: {
        id: this.cpdSession?.id,
      },
    });
  },
  methods: {
    async handleClick(registration: CpdSessionRegistration): Promise<void> {
      // Don't allow duplicate matches
      if (registration.isMatched) return;

      const cpdSession = this.cpdSession;

      if (!cpdSession) {
        return;
      }

      console.log(registration);

      const form = await UpdateCpdSessionRegistrationMatchesForm(registration);

      const { match: introducerContact } = await form.dialog({
        dialog: {
          title: "Registration Matches",
        },
        button: {
          left: {
            text: "Delete",
            color: "is-danger",
            show: this.hasWriteAccess,
            click: async (_, controls) => {
              await DangerDialog({
                dialog: {
                  title: "Delete Registration",
                  message: "Are you sure you want to delete this registration?",
                },
              });

              await window.Kernel.ActionBus.execute(
                "cpd/session/registration/delete",
                {
                  cpdSessionRegistration: registration,
                },
              );

              await this.refresh();
              this.registrations = CpdSessionRegistration.$findBy({
                session: {
                  id: cpdSession.id,
                },
              });

              controls.dismissDialog(false);
            },
          },
        },
      });

      if (!introducerContact) {
        return;
      }

      await window.Kernel.ActionBus.execute(
        "cpd/session/introducer-cpd/create",
        {
          introducerCpd: new IntroducerCpd({
            contact: introducerContact.id,
            createdAt: new DateTime().formatMachine,
            id: Uuid.generate(),
            session: cpdSession.id,
            status: Option.$findOneByOrThrow({
              group: "introducerCpdStatus",
              value: "invited",
            }).id,
            type: cpdSession.type.id,
          }),
        },
      );

      registration.isMatched = true;

      await window.Kernel.ActionBus.execute("cpd/session/registration/update", {
        cpdSessionRegistration: registration,
      });

      this.refresh();

      this.registrations = CpdSessionRegistration.$findBy({
        session: {
          id: cpdSession.id,
        },
      });
    },
  },
});
</script>
