import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral:
    | BrandedKinvaultSubscriptionReferral
    | string;
}

interface DeleteBrandedKinvaultSubscriptionReferralResponse {
  [key: string]: never;
}

export class DeleteBrandedKinvaultSubscriptionReferralHandler extends ActionBase {
  constructor(
    private message: DeleteBrandedKinvaultSubscriptionReferralMessage,
  ) {
    super();
  }

  public async execute(): Promise<DeleteBrandedKinvaultSubscriptionReferralResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
      {
        brandedKinvaultSubscriptionReferral:
          typeof this.message.brandedKinvaultSubscriptionReferral,
      },
    ).method("delete");

    await request.result();

    return {};
  }
}
