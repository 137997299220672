import {
  UpdateOfficerProfileMessage,
  UpdateOfficerProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateOfficerProfileHandler = UpdateProfileHandler as new (
  message: UpdateOfficerProfileMessage,
) => UpdateProfileHandler<
  UpdateOfficerProfileMessage,
  UpdateOfficerProfileResponse
>;
