import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface UpdateEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}

export interface UpdateEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
}

export class UpdateEmailCampaignLogHandler extends ActionBase {
  constructor(private message: UpdateEmailCampaignLogMessage) {
    super();
  }

  public async execute(): Promise<UpdateEmailCampaignLogResponse> {
    const localAttachments =
      this.message.emailCampaignLog.attachments.pluck("id") ?? [];

    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign-log/{emailCampaignLog}",
      this.message,
    )
      .method("patch")

      .body({
        campaign: this.message.emailCampaignLog.$data.campaign,
        scheduledToSendAt:
          this.message.emailCampaignLog.$data.scheduledToSendAt,
        body: this.message.emailCampaignLog.$data.body,
        title: this.message.emailCampaignLog.$data.title,
        subject: this.message.emailCampaignLog.$data.subject,
        template: this.message.emailCampaignLog.$data.template,
        data: this.message.emailCampaignLog.$data.data,
        from: this.message.emailCampaignLog.$data.from,
        status: this.message.emailCampaignLog.$data.status,
      });

    const response = await request.result();

    const emailCampaignLog = EmailCampaignLog.$inflate(
      response.emailCampaignLog,
    ).first();

    if (!emailCampaignLog) {
      throw new Error("Failed to update email log");
    }

    // Unnamed attachments
    const remoteAttachments = emailCampaignLog.attachments.pluck("id");
    const deletedAttachments = remoteAttachments.remove(...localAttachments);

    await window.Kernel.ActionBus.execute(
      "admin/email-campaign/campaign-log/attachment/upload",
      {
        emailCampaignLog,
        files: this.message.files,
      },
    );

    for (const fileLog of deletedAttachments) {
      await window.Kernel.ActionBus.execute(
        "admin/email-campaign/campaign-log/attachment/delete",
        {
          emailCampaignLog,
          fileLog,
        },
      );
    }

    this.message.emailCampaignLog.$persist();

    return {
      emailCampaignLog: EmailCampaignLog.$inflate(
        response.emailCampaignLog,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
