import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const ReportRoute = "Report";
const ReportURI = "/reports";
export type ReportParams = RouteParamObject<typeof ReportURI>;

export const AccountsReportRoute = "AccountsReportRoute";
const AccountsReportUri = "/reports/accounts";

export const AgreementsReportRoute = "AgreementsReportRoute";
const AgreementsReportUri = "/reports/agreements";

export const AppointmentsReportRoute = "AppointmentsReportRoute";
const AppointmentsReportUri = "/reports/appointments";

export const AppointmentsBreakdownReportRoute =
  "AppointmentsBreakdownReportRoute";
const AppointmentsBreakdownReportUri =
  "/reports/appointments/breakdown/:year/:granularity/:period/";
export type AppointmentsBreakdownReportParams = RouteParamObject<
  typeof AppointmentsBreakdownReportUri
>;

export const CashflowReportRoute = "CashflowReportRoute";
const CashflowReportUri = "/reports/cashflow";

export const CashflowByEpReportRoute = "CashflowByEpReportRoute";
const CashflowByEpReportUri = "/reports/cashflow/ep";

export const CompaniesReportRoute = "CompaniesReportRoute";
const CompaniesReportUri = "/reports/companies";

export const DownloadReportRoute = "DownloadReportRoute";
const DownloadReportUri = "/reports/download";

export const LeadsReportRoute = "LeadsReportRoute";
const LeadsReportUri = "/reports/leads";

export const LeadsByEpReportRoute = "LeadsByEpReportRoute";
const LeadsByEpReportUri = "/reports/leads/ep";

export const ProductsReportRoute = "ProductsReportRoute";
const ProductsReportUri = "/reports/products";

export const SignupsReportRoute = "SignupsReportRoute";
const SignupsReportUri = "/reports/signups";

export const DocstorageReportRoute = "DocstorageReportRoute";
const DocstorageReportUri = "/reports/docstorage";

export const Routes: Array<RouteInterface> = [
  {
    path: ReportURI,
    name: ReportRoute,
    component: () =>
      import(/* webpackChunkName: "report-details" */ "./Reports.vue"),
    meta: {
      breadcrumbs: (params) => [
        {
          text: "Reports",
          route: { name: ReportRoute, params },
        },
      ],
    },
    redirect: { name: LeadsReportRoute },
    children: [
      {
        name: AccountsReportRoute,
        path: AccountsReportUri,
        component: () => import("./Reports.Accounts.vue"),
      },
      {
        name: AgreementsReportRoute,
        path: AgreementsReportUri,
        component: () => import("./Reports.Agreements.vue"),
      },
      {
        name: AppointmentsReportRoute,
        path: AppointmentsReportUri,
        component: () => import("./Reports.Appointments.vue"),
      },
      {
        name: AppointmentsBreakdownReportRoute,
        path: AppointmentsBreakdownReportUri,
        component: () => import("./Reports.Appointments.Breakdown.vue"),
      },
      {
        name: CashflowReportRoute,
        path: CashflowReportUri,
        component: () => import("./Reports.Cashflow.vue"),
      },
      {
        name: CashflowByEpReportRoute,
        path: CashflowByEpReportUri,
        component: () => import("./Reports.Cashflow.ByEp.vue"),
      },
      {
        name: CompaniesReportRoute,
        path: CompaniesReportUri,
        component: () => import("./Reports.Companies.vue"),
      },
      {
        name: DownloadReportRoute,
        path: DownloadReportUri,
        component: () => import("./Reports.Download.vue"),
      },
      {
        name: LeadsReportRoute,
        path: LeadsReportUri,
        component: () => import("./Reports.Leads.vue"),
      },
      {
        name: LeadsByEpReportRoute,
        path: LeadsByEpReportUri,
        component: () => import("./Reports.Leads.ByEp.vue"),
      },
      {
        name: ProductsReportRoute,
        path: ProductsReportUri,
        component: () => import("./Reports.Products.vue"),
      },
      {
        name: SignupsReportRoute,
        path: SignupsReportUri,
        component: () => import("./Reports.Signups.vue"),
      },
      {
        name: DocstorageReportRoute,
        path: DocstorageReportUri,
        component: () => import("./Reports.Docstorage.vue"),
      },
    ],
  },
];
