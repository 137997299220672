import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Property } from "@kinherit/sdk";

interface CreatePropertyMessage {
  kintin: string;
  property: Property;
}

interface CreatePropertyResponse {
  property: Property;
}

export class CreatePropertyHandler extends ActionBase {
  constructor(private message: CreatePropertyMessage) {
    super();
  }

  public async execute(): Promise<CreatePropertyResponse> {
    const { property: propertyData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-property/{kintin}/properties",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        kintin: this.message.kintin,
        titleDeed: this.message.property.$data.titleDeed,
        price: this.message.property.$data.price,
        value: this.message.property.$data.value,
        lessMortgage: this.message.property.$data.lessMortgage,
        notes: this.message.property.$data.notes,
        address: this.message.property.$data.address,
        howOwned: this.message.property.$data.howOwned,
        ownership: this.message.property.$data.ownership,
        type: this.message.property.$data.type,
      })
      .result();

    const property = Property.$inflate(propertyData).first();

    if (!property) {
      throw new Error("Failed to create property");
    }

    this.message.property.$delete();

    return { property };
  }
}
