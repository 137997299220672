import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface DeleteKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface DeleteKintinStorageRequestResponse {
  [key: string]: never;
}

export class DeleteKintinStorageRequestHandler extends ActionBase {
  constructor(private message: DeleteKintinStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinStorageRequestResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}",
      {
        kintin: this.message.kintin,
        subscription: this.message.subscription,
        physicalStorageFile: this.message.storageRequest,
      },
    )
      .method("delete")

      .result();

    this.message.storageRequest.$delete();

    return {};
  }
}
