import { AcuityReconcileAction } from "@/module/settings/action/acuity-reconcile.action";
import { ClearCacheHandler } from "@/module/settings/action/clear-cache.action";
import { SetXeroTokenHandler } from "@/module/settings/action/set-xero-token.action";
import { SyncXeroContactsHandler } from "@/module/settings/action/sync-xero-contacts.action";
import { TestContactEndpointHandler } from "@/module/settings/action/test-contact-endpoint.action";
import { TestEmailHandler } from "@/module/settings/action/test-email.action";

export const Actions = {
  "settings/set-xero-token": SetXeroTokenHandler,
  "settings/clear-cache": ClearCacheHandler,
  "settings/acuity-reconcile": AcuityReconcileAction,
  "settings/test-email": TestEmailHandler,
  "settings/test-contact-endpoint": TestContactEndpointHandler,
  "settings/sync-xero-contacts": SyncXeroContactsHandler,
};
