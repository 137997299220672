import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Adviser,
  Attorney,
  Beneficiary,
  EmailAddress,
  Executor,
  Guardian,
  Person,
  Profile,
  Trustee,
} from "@kinherit/sdk";

interface RecordKintinEmailLogMessage {
  kintin: string;
}

interface RecordKintinEmailLogResponse {
  profiles: Array<Profile>;
  people: Array<Person>;
  emailAddresses: Array<EmailAddress>;
  trustees: Array<Trustee>;
  executors: Array<Executor>;
  guardians: Array<Guardian>;
  beneficiaries: Array<Beneficiary>;
  attorneys: Array<Attorney>;
  advisers: Array<Adviser>;
}

export class RecordKintinEmailLogHandler extends ActionBase {
  constructor(private message: RecordKintinEmailLogMessage) {
    super();
  }

  public async execute(): Promise<RecordKintinEmailLogResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin/{kintin}/email-log",
      {
        kintin: this.message.kintin,
      },
    )
      .method("get")

      .result();

    return {
      profiles: Profile.$inflate(response.profile),
      people: Person.$inflate(response.person),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      trustees: Trustee.$inflate(response.trustee),
      executors: Executor.$inflate(response.executor),
      guardians: Guardian.$inflate(response.guardian),
      beneficiaries: Beneficiary.$inflate(response.beneficiary),
      attorneys: Attorney.$inflate(response.attorney),
      advisers: Adviser.$inflate(response.adviser),
    };
  }
}
