import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Tag } from "@kinherit/sdk";

export class UpdateTagAction extends ActionBase {
  constructor(private message: Tag) {
    super();
  }

  async execute(): Promise<Tag> {
    const data = await Api.resource("portal", "/v2/portal/tag/{tag}", {
      tag: this.message.id,
    })
      .method("patch")

      .body({
        name: this.message.name,
        description: this.message.description,
      })
      .result();

    const tag = Tag.$inflate(data.tag).first();

    if (!tag) {
      throw new Error("Failed to update tag");
    }

    return tag;
  }
}
