import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePayment } from "@kinherit/sdk";
import { IntroducerFeePaymentItem, XeroContact } from "@kinherit/sdk/index";

export interface RecordIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment | string;
}

export interface RecordIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
  xeroContact: XeroContact;
  introducerFeePaymentItems: IntroducerFeePaymentItem[];
}

export class RecordIntroducerFeePaymentHandler extends ActionBase {
  constructor(private message: RecordIntroducerFeePaymentMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerFeePaymentResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
      this.message,
    ).method("get");

    const response = await request.result();

    const introducerFeePayment = IntroducerFeePayment.$inflate(
      response.introducerFeePayment,
    ).first();

    if (!introducerFeePayment) {
      throw new Error("Failed to find introducerFeePayment");
    }

    const xeroContact = XeroContact.$inflate(response.xeroContact).first();

    if (!xeroContact) {
      throw new Error("Failed to find xeroContact");
    }

    return {
      introducerFeePayment,
      xeroContact,
      introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
        response.introducerFeePaymentItem,
      ),
    };
  }
}
