import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CreditNote } from "@kinherit/sdk";

export interface DeleteCreditNoteMessage {
  creditNote: CreditNote;
}

export interface DeleteCreditNoteResponse {
  [key: string]: never;
}

export class DeleteCreditNoteHandler extends ActionBase {
  constructor(public readonly message: DeleteCreditNoteMessage) {
    super();
  }

  public async execute(): Promise<DeleteCreditNoteResponse> {
    await Api.resource("portal", "/v2/portal/credit-note/{creditNote}", {
      creditNote: this.message.creditNote,
    })
      .method("delete")
      .body({})

      .result();

    this.message.creditNote.$delete();

    return {};
  }
}
