import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  IsNull,
  Like,
} from "@kinherit/orm/index";
import { Api, Credential, IUser, Profile, User } from "@kinherit/sdk";

export interface ReadUserMessage {
  search?: null | string;
  role?: Array<string>;
  created?: null | [number, number];
  displayUserInPortal?: boolean;
  acuityUser?: boolean;
  brandedKinvault?: Array<string>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IUser;
    direction: "asc" | "desc";
  };
}

interface ReadUserResponse {
  users: Array<User>;
  profiles: Array<Profile>;
  credentials: Array<Credential>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadUserHandler extends ActionBase {
  constructor(private message: ReadUserMessage) {
    super();
  }

  public async execute(): Promise<ReadUserResponse> {
    const request = Api.resource("portal", "/v2/portal/user")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "createdAt",
        sortDirection: this.message.sort?.direction ?? "desc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(User).where({
      profile: {
        fullName: Like(this.message.search),
      },
      roles: {
        id: In(this.message.role),
      },
      displayUserInPortal: Equal(
        undefined === this.message.displayUserInPortal
          ? undefined
          : this.message.displayUserInPortal
            ? 1
            : 0,
      ),
      createdAt: Between(this.message.created),
      acuityUserId:
        undefined !== this.message.acuityUser
          ? this.message.acuityUser
            ? IsNotNull()
            : IsNull()
          : undefined,
      brandedKinvaultMemberships: {
        brandedKinvault: {
          id: In(this.message.brandedKinvault),
        },
      },
    });

    const response = await request.result();

    return {
      users: User.$inflate(response.user, this.message.sort, response.$rootIds),
      profiles: Profile.$inflate(response.profile),
      credentials: Credential.$inflate(response.credential),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
