import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Attorney, Kintin } from "@kinherit/sdk";

interface UpdateAttorneyMessage {
  kintin: string | Kintin;
  attorney: Attorney;
}

interface UpdateAttorneyResponse {
  attorney: Attorney;
}

export class UpdateAttorneyHandler extends ActionBase {
  constructor(private message: UpdateAttorneyMessage) {
    super();
  }

  public async execute(): Promise<UpdateAttorneyResponse> {
    const { attorney: attorneyData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-attorney/{kintin}/person/attorney/{attorney}",
      {
        kintin: this.message.kintin,
        attorney: this.message.attorney.$data.id,
      },
    )
      .method("patch")

      .body({
        isReserve: this.message.attorney.$data.isReserve,
        notes: this.message.attorney.$data.notes,
        isCertProv: this.message.attorney.$data.isCertProv,
        isHealthWealth: this.message.attorney.$data.isHealthWealth,
        isPropFinance: this.message.attorney.$data.isPropFinance,
        person: this.message.attorney.$data.person,
        forPerson: this.message.attorney.$data.forPerson,
        sortOrder: this.message.attorney.$data.sortOrder,
      })
      .result();

    const attorney = Attorney.$inflate(attorneyData).first();

    if (!attorney) {
      throw new Error("Failed to update attorney");
    }

    return { attorney };
  }
}
