import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  FileLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
};

type Response = {
  files: FileLog[];
};

export class UploadEmailLogAttachmentHandler<
  M extends Message,
  R extends Response,
> extends ActionBase {
  constructor(private message: M) {
    super();
  }

  public async execute(): Promise<R> {
    const routes = {
      [IntroducerCompany.$name]:
        "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}/attachment",
      [IntroducerContact.$name]:
        "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}/attachment",
      [Kintin.$name]:
        "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}/attachment",
      [Lead.$name]:
        "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}/attachment",
      [Person.$name]:
        "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}/attachment",
      [BrandedKinvault.$name]:
        "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}/attachment",
    } as const;

    const route = routes[this.message.owner.$name];

    const files: FileLog[] = [];

    for (const file of this.message.files) {
      const response = await Api.resource("portal", route, {
        owner: this.message.owner,
        emailLog: this.message.emailLog,
      })
        .method("put")

        .files({
          uploadedFile: file,
        })
        .result();

      files.push(...FileLog.$inflate(response.fileLog));
    }

    return {
      files,
    } as R;
  }
}
