<template>
  <ProfileSummary
    :key="key"
    v-if="lead && !loading"
    class="lead-details"
    :profile-id="lead.profile.id"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar-above>
      <RouterChildView name="sidebar">
        <LeadDetailsSidebar
          @update-settings="updateSettings"
          @manage-referral="manageReferral"
        />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <LeadDetailsNotes />
      </RouterChildView>
    </template>
  </ProfileSummary>
  <PageNotFound v-else-if="!loading" />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { UpdateReferralForm } from "@/shared/form/referral/update-referral.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { LeadDetailsParams, LeadDetailsRoute } from ".";
import { LeadMasterListRoute } from "..";
import { ConvertLeadToKintinForm } from "../../form/convert-lead-to-kintin.form";
import { UpdateLeadSettingsForm } from "../../form/update-lead-settings.form";
import LeadDetailsSidebar from "./LeadDetails.Sidebar.vue";
import { LeadDetailsCallScriptRoute } from "./call-script";
import { LeadDetailsChangeLogRoute } from "./change-log";
import { LeadDetailsEmailLogRoute } from "./email-log";
import { LeadDetailsIfaCallScriptRoute } from "./ifa-call-script";
import { LeadDetailsNotesRoute } from "./notes";
import LeadDetailsNotes from "./notes/LeadDetails.Notes.vue";
import { LeadDetailsUpdateProfileRoute } from "./update-profile";

export default defineComponent({
  name: LeadDetailsRoute,
  mixins: [LeadDetailsMixin],
  components: {
    PageNotFound,
    RouterChildView,
    LeadDetailsSidebar,
    ProfileSummary,
    LeadDetailsNotes,
  },
  computed: {
    $params(): LeadDetailsParams {
      return this.$route.params as LeadDetailsParams;
    },
    showContextMenu(): boolean {
      return AuthService.hasPermission("lead:write");
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Notes",
        route: {
          name: LeadDetailsNotesRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.notes.icon,
      },
      {
        label: "Emails",
        route: {
          name: LeadDetailsEmailLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.email.icon,
      },
      {
        label: "IFA Call Script",
        route: {
          name: LeadDetailsIfaCallScriptRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.phone.icon,
      },
      {
        label: "Call Script",
        route: {
          name: LeadDetailsCallScriptRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.phone.icon,
      },

      {
        label: "Changes",
        route: {
          name: LeadDetailsChangeLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.history.icon,
      },
    ],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.execute(
      "lead/record",
      to.params as LeadDetailsParams,
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const convertOptions = ["converted"].includes(
        this.lead.status?.value as string,
      )
        ? []
        : [
            {
              title: "Convert to Kinvault",
              line1: `Convert ${this.lead.profile.fullName} into a Kinvault`,
              icon: StyleService.icon.kintin.icon,
              permissions: "lead:write",
              action: this.convertToKintin,
            },
          ];

      const deleteOptions = ["converted"].includes(
        this.lead.status?.value as string,
      )
        ? []
        : [
            {
              title: "Delete Lead",
              line1: `Delete ${this.lead.profile.fullName}`,
              icon: StyleService.icon.delete.icon,
              permissions: "lead:delete",
              action: this.deleteLead,
            },
          ];

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Create Note",
              icon: StyleService.icon.notes.icon,
              line1: "Create a new note",
              action: this.createNote,
              premissions: ["lead:write"],
            },
            ...convertOptions,
            {
              title: "Edit Profile",
              line1: `Edit ${this.lead.profile.fullName}'s profile`,
              icon: StyleService.icon.profile.icon,
              permissions: "lead:write",
              action: this.editProfile,
            },
            {
              title: "Manage Referral",
              line1: `Manage ${this.lead.profile.fullName}'s referral`,
              icon: StyleService.icon.referral.icon,
              permissions: "lead:write",
              action: this.manageReferral,
            },
            {
              title: "Update Settings",
              line1: `Update ${this.lead.profile.fullName}'s settings`,
              icon: StyleService.icon.settings.icon,
              permissions: "lead:write",
              action: this.updateSettings,
            },
            ...deleteOptions,
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: LeadDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    async createNote(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const note = await UpdateNoteForm({
        data: "create-note",
        lead: this.lead,
      }).dialog({
        dialog: {
          title: "Create note",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      await window.Kernel.ActionBus.execute("core/note/create", {
        note: note,
        lead: this.lead,
      });

      this.key++;
    },
    async convertToKintin(): Promise<void> {
      if (!this.lead || !this.lead.profile) {
        return;
      }

      const emailToCheck = this.lead.profile?.primaryEmailAddress?.email;

      if (!emailToCheck) {
        await OpenAlertDialog({
          dialog: {
            title: "Convert to Kintin",
            message: `This lead does not have a primary email address.`,
          },
          button: {
            ok: {
              text: "Return to Lead",
              color: "is-primary",
            },
          },
        });

        return;
      }

      await window.Kernel.ActionBus.execute(
        "auth/unique-username",
        emailToCheck,
      );

      const data = await ConvertLeadToKintinForm(this.lead).dialog({
        dialog: {
          title: "Convert to Kintin",
        },
        button: {
          ok: {
            text: "Convert",
          },
        },
      });

      const { kintin } = await window.Kernel.ActionBus.execute(
        "lead/convert/kintin",
        {
          lead: this.lead,
          ...data,
        },
      );

      await OpenAlertDialog({
        dialog: {
          title: "Convert to Kintin",
          message: `Successfully converted ${this.lead.profile.fullName} into a Kintin`,
        },
        button: {
          ok: {
            text: "View Kintin",
            color: "is-success",
          },
          cancel: {
            text: "Return to Lead",
            color: "is-primary",
          },
        },
      });

      window.Kernel.Router.push({
        name: KinvaultKintinDetailsRoute,
        params: { kintin: kintin.id } as KinvaultKintinDetailsParams,
      });
    },
    async updateSettings(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const settings = await UpdateLeadSettingsForm(this.lead).dialog({
        dialog: {
          title: "Update Settings",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      await window.Kernel.ActionBus.execute("lead/settings/update", {
        lead: this.lead,
        settings: settings,
      });

      this.key++;
    },
    async deleteLead(): Promise<void> {
      if (!this.lead) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Lead",
          message: `Are you sure you want to delete ${this.lead.profile.fullName}?`,
        },
      });

      await window.Kernel.ActionBus.execute("lead/delete", {
        lead: this.lead,
      });

      window.Kernel.Router.push({
        name: LeadMasterListRoute,
      });
    },
    async manageReferral(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const referral = this.lead.referral;

      try {
        await UpdateReferralForm({ data: referral }).dialog({
          dialog: {
            title: `Manage ${this.lead.profile.fullName} referral`,
          },
        });
      } catch (e) {
        referral.$restore();
        throw e;
      }

      referral.$persist();

      await window.Kernel.ActionBus.execute("core/account-referral/update", {
        referral,
      });

      this.lead.$restore();
      this.key++;
    },
  },
});
</script>
