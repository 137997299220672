import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { WillRevision } from "@kinherit/sdk";

export interface RecordWillRevisionMessage {
  willRevision: string | WillRevision;
}

interface RecordWillRevisionResponse {
  willRevisions: Array<WillRevision>;
}

export class RecordWillRevisionHandler extends ActionBase {
  constructor(private message: RecordWillRevisionMessage) {
    super();
  }

  public async execute(): Promise<RecordWillRevisionResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/will-revision/{willRevision}",
      {
        willRevision: this.message.willRevision,
      },
    ).method("get");
    const response = await request.result();

    return {
      willRevisions: WillRevision.$inflate(response.willRevision),
    };
  }
}
