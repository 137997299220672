import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface RecordIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
}

export interface RecordIntroducerFeePaymentRunResponse {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export class RecordIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: RecordIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerFeePaymentRunResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
      this.message,
    ).method("get");

    const response = await request.result();

    const introducerFeePaymentRun = IntroducerFeePaymentRun.$inflate(
      response.introducerFeePaymentRun,
    ).first();

    if (!introducerFeePaymentRun) {
      throw new Error("Failed to find introducerFeePaymentRun");
    }

    return {
      introducerFeePaymentRun,
    };
  }
}
