import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IOrder, Order, QueryMask } from "@kinherit/sdk";

export interface ReadOrderMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
  query?: QueryMask<typeof Order>;
}

interface ReadOrderResponse {
  orders: Array<Order>;
}

export class ReadOrderHandler extends ActionBase {
  constructor(private message: ReadOrderMessage) {
    super();
  }

  public async execute(): Promise<ReadOrderResponse> {
    const request = Api.resource("core", "/v2/core/select/order")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "createdAt",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false);

    request.buildQuery(Order).where(this.message.query ?? {});

    const response = await request.result();

    return {
      orders: Order.$inflate(
        response.order,
        this.message.sort,
        response.$rootIds,
      ),
    };
  }
}
