import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog } from "@kinherit/sdk";

interface DeleteKintinFileMessage {
  fileLog: FileLog;
}

interface DeleteKintinFileResponse {
  [key: string]: never;
}

export class DeleteKintinFileHandler extends ActionBase {
  constructor(private message: DeleteKintinFileMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinFileResponse> {
    const kintin = this.message.fileLog.$data.kintin;

    if (!kintin) {
      throw new Error("Kintin is required");
    }

    await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files/{fileLog}",
      {
        kintin,
        fileLog: this.message.fileLog,
      },
    )
      .method("delete")

      .result();

    this.message.fileLog.$delete();

    return {};
  }
}
