import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api } from "@kinherit/sdk";

export class SetXeroTokenHandler extends ActionBase {
  constructor(private _message: {}) {
    super();
  }

  public async execute(): Promise<void> {
    const { url } = await Api.resource(
      "external",
      "/v2/external/xero/request-consent",
    )
      .method("get")
      .result();

    window.open(url, "_blank");
  }
}
