import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { ITag, Tag } from "@kinherit/sdk";

export interface ReadTagMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof ITag;
    direction: "asc" | "desc";
  };
}

interface ReadTagResponse {
  tags: Array<Tag>;
}

export class ReadTagHandler extends ActionBase {
  constructor(private message: ReadTagMessage) {
    super();
  }

  public async execute(): Promise<ReadTagResponse> {
    const response = await Api.resource("core", "/v2/core/select/tag")
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "name",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false)
      .result();

    return {
      tags: Tag.$inflate(response.tag, this.message.sort, response.$rootIds),
    };
  }
}
