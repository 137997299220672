import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { EmailTemplate } from "@kinherit/sdk";

export interface UpdateEmailTemplateMessage {
  emailTemplate: EmailTemplate;
}

interface UpdateEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export class UpdateEmailTemplateHandler extends ActionBase {
  constructor(private message: UpdateEmailTemplateMessage) {
    super();
  }

  public async execute(): Promise<UpdateEmailTemplateResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/email-template/{emailTemplate}",
      {
        emailTemplate: this.message.emailTemplate,
      },
    )
      .method("patch")
      .body({
        name: this.message.emailTemplate.$data.name,
        subject: this.message.emailTemplate.$data.subject,
        body: this.message.emailTemplate.$data.body,
        allowAttachments: this.message.emailTemplate.$data.allowAttachments,
        strictAttachments: this.message.emailTemplate.$data.strictAttachments,
        notes: this.message.emailTemplate.$data.notes,
        requiredAttachments:
          this.message.emailTemplate.$data.requiredAttachments,
        type: this.message.emailTemplate.$data.type,
        includeTo: this.message.emailTemplate.$data.includeTo,
        preloadedFiles: this.message.emailTemplate.$data.preloadedFiles,
      })

      .result();

    return {
      emailTemplates: EmailTemplate.$inflate(
        response.emailTemplate,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
