import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import {
  Api,
  CompanyPartnerPortalPermissions,
  IIntroducerCompany,
  IntroducerCompany,
  IntroducerNetwork,
  Profile,
  User,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyMessage {
  search?: string | null;
  tags?: Array<string>;
  status?: Array<string>;
  type?: Array<string>;
  stage?: Array<string>;
  seniorAdviser?: Array<string>;
  assignedUser?: Array<string>;
  network?: Array<string>;
  introducedBy?: Array<string>;

  created?: null | [number, number];
  contracts?: {
    type?: {
      value?: "agentintroducer";
    };
  };
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerCompany;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerCompanyResponse {
  introducerCompanies: Array<IntroducerCompany>;
  introducerNetworks: Array<IntroducerNetwork>;
  companyPartnerPortalPermissions: Array<CompanyPartnerPortalPermissions>;
  profiles: Array<Profile>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerCompanyHandler extends ActionBase {
  constructor(private message: ReadIntroducerCompanyMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerCompanyResponse> {
    const request = Api.resource("portal", "/v2/portal/introducer-company")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(IntroducerCompany).where([
      {
        contracts: {
          type: {
            value: Equal(this.message.contracts?.type?.value),
          },
        },
        profile: {
          fullName: Like(this.message.search),
        },
        tags: {
          id: In(this.message.tags),
        },
        status: {
          id: In(this.message.status),
        },
        type: {
          id: In(this.message.type),
        },
        stage: {
          id: In(this.message.stage),
        },
        assignedTo: {
          id: In(this.message.assignedUser),
        },
        seniorAdviser: {
          id: In(this.message.seniorAdviser),
        },
        network: In(this.message.network),
        introducedBy: {
          id: In(this.message.introducedBy),
        },
        createdAt: Between(this.message.created),
      },
      {
        contracts: {
          type: {
            value: Equal(this.message.contracts?.type?.value),
          },
        },
        profile: {
          organisationName: Like(this.message.search),
        },
        tags: {
          id: In(this.message.tags),
        },
        status: {
          id: In(this.message.status),
        },
        type: {
          id: In(this.message.type),
        },
        stage: {
          id: In(this.message.stage),
        },
        assignedTo: {
          id: In(this.message.assignedUser),
        },
        seniorAdviser: {
          id: In(this.message.seniorAdviser),
        },
        network: In(this.message.network),
        introducedBy: {
          id: In(this.message.introducedBy),
        },
        createdAt: Between(this.message.created),
      },
    ]);

    const response = await request.result();

    return {
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
        this.message.sort,
        response.$rootIds,
      ),
      introducerNetworks: IntroducerNetwork.$inflate(
        response.introducerNetwork,
      ),
      companyPartnerPortalPermissions: CompanyPartnerPortalPermissions.$inflate(
        response.companyPartnerPortalPermissions,
      ),
      profiles: Profile.$inflate(response.profile),
      users: User.$inflate(response.user),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
