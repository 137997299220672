import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, UploadForm } from "@kinherit/sdk";

interface DeleteKintinUploadFormMessage {
  uploadForm: UploadForm;
  kintin: Kintin;
}

interface DeleteKintinUploadFormResponse {
  [key: string]: never;
}

export class DeleteKintinUploadFormHandler extends ActionBase {
  constructor(private message: DeleteKintinUploadFormMessage) {
    super();
  }

  public async execute(): Promise<DeleteKintinUploadFormResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-upload-form/{kintin}/upload-forms/{uploadForm}",
      {
        kintin: this.message.kintin.id,
        uploadForm: this.message.uploadForm.id,
      },
    )
      .method("delete")
      .result();

    this.message.uploadForm.$delete();

    return {};
  }
}
