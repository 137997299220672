import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Like, Between } from "@kinherit/orm/index";
import {
  Api,
  IKnowledgeBaseCategory,
  KnowledgeBaseCategory,
} from "@kinherit/sdk";

export interface ReadKnowledgeBaseCategoryMessage {
  name?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKnowledgeBaseCategory;
    direction: "asc" | "desc";
  };
}

interface ReadKnowledgeBaseCategoryResponse {
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadKnowledgeBaseCategoryHandler extends ActionBase {
  constructor(private message: ReadKnowledgeBaseCategoryMessage) {
    super();
  }

  public async execute(): Promise<ReadKnowledgeBaseCategoryResponse> {
    const request = Api.resource("portal", "/v2/portal/knowledge-base-category")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination ?? false);

    request.buildQuery(KnowledgeBaseCategory).where({
      title: Like(this.message.name),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
        response.knowledgeBaseCategory,
        this.message.sort,
        response.$rootIds,
      ),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
