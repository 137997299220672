import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Address,
  EmailAddress,
  IntroducerBillingContact,
  IntroducerCompany,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

export interface RecordIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany;
  introducerBillingContact: IntroducerBillingContact;
}

export interface RecordIntroducerBillingContactResponse {
  introducerBillingContact: IntroducerBillingContact;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
}

export class RecordIntroducerBillingContactHandler extends ActionBase {
  constructor(private message: RecordIntroducerBillingContactMessage) {
    super();
  }

  public async execute(): Promise<RecordIntroducerBillingContactResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts/{introducerBillingContact}",
      {
        introducerCompany: this.message.introducerCompany,
        introducerBillingContact: this.message.introducerBillingContact,
      },
    ).method("get");
    const response = await request.result();

    const introducerBillingContact = IntroducerBillingContact.$inflate(
      response.introducerBillingContact,
    ).first();

    if (!introducerBillingContact) {
      throw new Error("Failed to find introducer billing contact");
    }

    return {
      introducerBillingContact,
      profiles: Profile.$inflate(response.profile),
      addresses: Address.$inflate(response.address),
      phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
      emailAddresses: EmailAddress.$inflate(response.emailAddress),
      websites: Website.$inflate(response.website),
    };
  }
}
