import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Api,
  IntroducerContact,
  Kintin,
  Person,
  Profile,
} from "@kinherit/sdk/index";

export class CreatePersonFromIntroducerContactHandler extends ActionBase {
  constructor(
    private message: {
      introducerContact: string | IntroducerContact;
      kintin: string | Kintin;
    },
  ) {
    super();
  }

  async execute(): Promise<{
    person: Person;
    profile: Profile;
  }> {
    const result = await Api.resource(
      "portal",
      "/v2/portal/kintin-person/create-person-from-introducer-contact",
    )
      .method("put")
      .body({
        kintin:
          "string" === typeof this.message.kintin
            ? this.message.kintin
            : this.message.kintin.id,
      })
      .result();

    const people = Person.$inflate(result.person);

    const person = people.first();

    if (!person) {
      throw new Error("Failed to create person from introducer contact");
    }

    const profiles = Profile.$inflate(result.profile);

    const profile = profiles.first();

    if (!profile) {
      throw new Error("Failed to create profile from introducer contact");
    }

    return {
      person,
      profile,
    };
  }
}
