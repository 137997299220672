import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, In, Like } from "@kinherit/orm/index";
import { Api, BrandedKinvault, IBrandedKinvault, Profile } from "@kinherit/sdk";

export interface ReadBrandedKinvaultMessage {
  name?: string;
  created?: null | [number, number];
  type: Array<string>;
  poolType: Array<string>;
  mode: Array<string>;
  frontendUrl: string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IBrandedKinvault;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultResponse {
  brandedKinvaults: Array<BrandedKinvault>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadBrandedKinvaultHandler extends ActionBase {
  constructor(private message: ReadBrandedKinvaultMessage) {
    super();
  }

  public async execute(): Promise<ReadBrandedKinvaultResponse> {
    const request = Api.resource("portal", "/v2/portal/branded-kinvault")
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(BrandedKinvault).where({
      profile: {
        fullName: Like(this.message.name),
      },
      type: { id: In(this.message.type) },
      clientPoolType: { id: In(this.message.poolType) },
      siteMode: { id: In(this.message.mode) },
      createdAt: Between(this.message.created),
      frontendUrl: Like(this.message.frontendUrl),
    });

    const response = await request.result();

    return {
      brandedKinvaults: BrandedKinvault.$inflate(
        response.brandedKinvault,
        this.message.sort,
        response.$rootIds,
      ),
      profiles: Profile.$inflate(response.profile),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
