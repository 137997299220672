import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerNetwork } from "@kinherit/sdk";

export interface UpdateIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork;
}

interface UpdateIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
}

export class UpdateIntroducerNetworkHandler extends ActionBase {
  constructor(private message: UpdateIntroducerNetworkMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerNetworkResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-network/{introducerNetwork}",
      {
        introducerNetwork: this.message.introducerNetwork,
      },
    )
      .method("patch")
      .body({
        name: this.message.introducerNetwork.$data.name,
      })

      .result();

    return {
      introducerNetworks: IntroducerNetwork.$inflate(
        response.introducerNetwork,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
