import { CreateEmailCampaignLogHandler } from "@/module/admin.email-campaign/action/campaign-log/create-email-campaign-log.action";
import { DeleteEmailCampaignLogAttachmentHandler } from "@/module/admin.email-campaign/action/campaign-log/delete-email-campaign-log-attachment.action";
import { DeleteEmailCampaignLogHandler } from "@/module/admin.email-campaign/action/campaign-log/delete-email-campaign-log.action";
import { ReadEmailCampaignLogHandler } from "@/module/admin.email-campaign/action/campaign-log/read-email-campaign-log.action";
import { RecordEmailCampaignLogHandler } from "@/module/admin.email-campaign/action/campaign-log/record-email-campaign-log.action";
import { UpdateEmailCampaignLogHandler } from "@/module/admin.email-campaign/action/campaign-log/update-email-campaign-log.action";
import { UploadEmailCampaignLogAttachmentHandler } from "@/module/admin.email-campaign/action/campaign-log/upload-email-campaign-log-attachment.action";
import { AddEmailCampaignProfileHandler } from "@/module/admin.email-campaign/action/campaign/add-email-campaign-profile.action";
import { AddEmailCampaignProfilesHandler } from "@/module/admin.email-campaign/action/campaign/add-email-campaign-profiles.action";
import { AddProfilesFromFilterHandler } from "@/module/admin.email-campaign/action/campaign/add-profiles-from-filter.action";
import { CreateEmailCampaignHandler } from "@/module/admin.email-campaign/action/campaign/create-email-campaign.action";
import { DeleteEmailCampaignHandler } from "@/module/admin.email-campaign/action/campaign/delete-email-campaign.action";
import { ReadEmailCampaignHandler } from "@/module/admin.email-campaign/action/campaign/read-email-campaign.action";
import { RecordEmailCampaignHandler } from "@/module/admin.email-campaign/action/campaign/record-email-campaign.action";
import { RemoveEmailCampaignProfileHandler } from "@/module/admin.email-campaign/action/campaign/remove-email-campaign-profile.action";
import { UpdateEmailCampaignHandler } from "@/module/admin.email-campaign/action/campaign/update-email-campaign.action";
import { UploadEmailCampaignMembersCsvHandler } from "@/module/admin.email-campaign/action/campaign/upload-email-campaign-members-csv.action";
import { ValidateEmailCampaignMembersCsvHandler } from "@/module/admin.email-campaign/action/campaign/validate-email-campaign-members-csv.action";
import { ReadProfileHandler } from "@/module/admin.email-campaign/action/profile/read-profile.action";

export const Actions = {
  // Email campaign
  "admin/email-campaign/campaign/read": ReadEmailCampaignHandler,
  "admin/email-campaign/campaign/create": CreateEmailCampaignHandler,
  "admin/email-campaign/campaign/update": UpdateEmailCampaignHandler,
  "admin/email-campaign/campaign/record": RecordEmailCampaignHandler,
  "admin/email-campaign/campaign/delete": DeleteEmailCampaignHandler,
  // Email campaign log
  "admin/email-campaign/campaign-log/read": ReadEmailCampaignLogHandler,
  "admin/email-campaign/campaign-log/create": CreateEmailCampaignLogHandler,
  "admin/email-campaign/campaign-log/update": UpdateEmailCampaignLogHandler,
  "admin/email-campaign/campaign-log/record": RecordEmailCampaignLogHandler,
  "admin/email-campaign/campaign-log/delete": DeleteEmailCampaignLogHandler,
  // Email campaign log attachment
  "admin/email-campaign/campaign-log/attachment/upload":
    UploadEmailCampaignLogAttachmentHandler,
  "admin/email-campaign/campaign-log/attachment/delete":
    DeleteEmailCampaignLogAttachmentHandler,
  // Profile
  "admin/email-campaign/profile/add-from-filters": AddProfilesFromFilterHandler,
  "admin/email-campaign/profile/read": ReadProfileHandler,
  "admin/email-campaign/profile/add": AddEmailCampaignProfileHandler,
  "admin/email-campaign/profiles/add": AddEmailCampaignProfilesHandler,
  "admin/email-campaign/profile/remove": RemoveEmailCampaignProfileHandler,
  "admin/email-campaign/members/csv/validate":
    ValidateEmailCampaignMembersCsvHandler,
  "admin/email-campaign/members/csv/upload":
    UploadEmailCampaignMembersCsvHandler,
};
