import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Session } from "@/module/auth/model/session.model";
import { SnackBarService } from "@/service/snack-bar.service";
import { FormPasswordField } from "@kinherit/framework/component.input/password-field";
import { FormPinField } from "@kinherit/framework/component.input/pin-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { OpenFormDialog } from "@kinherit/framework/global/dialog";
import { DateTime } from "@kinherit/ts-common";
import { reactive } from "vue";

export class LoginDialogService {
  #activeSession?: ActiveSession;
  #session?: Session;
  #password?: string;
  #multiFactorMethodId?: string;
  #multiFactorSessionId?: string;

  get #multiFactorMethod(): {
    id: string;
    type: "totp" | "sms" | "email";
    alias: string | null;
  } | null {
    if (!this.#session) {
      return null;
    }

    return (
      this.#session.mfMethods.find(
        (method) => method.id === this.#multiFactorMethodId,
      ) ?? null
    );
  }

  async login(): Promise<void> {
    let password: string | undefined = undefined;

    if (undefined === this.#password) {
      password = await this.requestPassword();
    }

    if (
      undefined === this.#password ||
      undefined === this.#activeSession ||
      undefined === this.#session
    ) {
      const currentActiveSession = ActiveSession.$getInstance();

      if (null === currentActiveSession) {
        throw new Error("No active session found");
      }

      try {
        const { activeSession, session } =
          await window.Kernel.ActionBus.execute("auth/session/request", {
            username: currentActiveSession.username,
            password: this.#password ?? (password as string),
          });
        this.#password = password;
        this.#activeSession = activeSession;
        this.#session = session;
      } catch (e: any) {
        return this.login();
      }
    }

    if (!this.#activeSession.mfaRequired) {
      return;
    }

    if (undefined === this.#multiFactorMethodId) {
      const methodId = await this.pickMultiFactorMethod();
      const multifactorRequest = await window.Kernel.ActionBus.execute(
        "auth/multi-factor/request",
        {
          methodId,
          session: this.#session,
        },
      );
      this.#multiFactorMethodId = methodId;
      this.#multiFactorSessionId = multifactorRequest.multiFactorSessionId;

      try {
        const code = await this.confirmMultiFactorCode(multifactorRequest.code);

        await window.Kernel.ActionBus.execute("auth/multi-factor/complete", {
          multiFactorSessionId: this.#multiFactorSessionId,
          session: this.#session,
          code,
        });
      } catch (e: any) {
        if (this.#session.mfMethods.length > 1) {
          this.#multiFactorMethodId = undefined;
          this.#multiFactorSessionId = undefined;
          return this.login();
        }

        throw e;
      }
    }
  }

  async requestPassword(): Promise<string> {
    const data = await OpenFormDialog({
      form: defineForm({
        name: "password-form",
        data: () => ({
          password: "",
        }),
        formAreas: (data) => [
          defineFormArea({
            name: "password",
            data,
            components: () => ({
              default: [
                FormPasswordField({
                  props: {
                    label: "Password",
                    validators: ["required"],
                  },
                  models: {
                    value: "password",
                  },
                }),
              ],
            }),
          }),
        ],
      }),
      dialog: {
        type: "alert",
        title: "Welcome back, " + ActiveSession.$getInstance()?.username,
        isAboveLoading: true,
      },
      button: {
        ok: {
          text: "Login",
        },
        cancel: {
          text: "Logout",
        },
      },
    });

    return (data as any).password;
  }

  async pickMultiFactorMethod(): Promise<string> {
    if (!this.#session) {
      throw new Error("No session found");
    }

    if (this.#session.mfMethods.length === 1) {
      return this.#session.mfMethods[0].id;
    }

    const { method } = await defineForm({
      name: "mfa-form",
      data: () => ({
        method: null as null | string,
      }),
      formAreas: (data) => [
        defineFormArea({
          name: "method",
          data,
          components: () => ({
            default: [
              FormRadioField({
                props: {
                  label: "Method",
                  options: Object.fromEntries(
                    this.#session?.mfMethods.map((method) => [
                      method.id,
                      method.alias ?? "Authenticator",
                    ]) ?? [],
                  ),
                  validators: ["required"],
                  direction: "is-vertical",
                },
                models: {
                  value: "method",
                },
              }),
            ],
          }),
        }),
      ],
    }).dialog({
      dialog: {
        type: "alert",
        title: "Select Multi-Factor Method",
        message: "Please select the method you would like to use.",
        isAboveLoading: true,
      },
      button: {
        ok: {
          text: "Select",
        },
        cancel: {
          text: "Logout",
        },
      },
    });

    return method as string;
  }

  async confirmMultiFactorCode(code?: number): Promise<number> {
    let message = "Enter your multi-factor code";

    switch (this.#multiFactorMethod?.type) {
      case "totp":
        message = `Please enter the six digit code from your authenticator app to continue.`;
        break;
      case "sms":
        message = `Please enter the six digit code sent to your phone to continue.`;
        break;
      case "email":
        message = `Please check your email for the six digit code to continue.`;
        break;
    }

    const formData = reactive({
      code: code?.toString() ?? "",
    });

    const form = defineForm({
      name: "mfa-form",
      data: () => formData,
      formAreas: (data) => [
        defineFormArea({
          name: "code",
          data,
          components: () => ({
            default: [
              FormPinField({
                props: {
                  label: "Code",
                  validators: ["required"],
                },
                models: {
                  value: "code",
                },
              }),
            ],
          }),
        }),
      ],
    });

    let lastSent = new DateTime();

    const data = await OpenFormDialog({
      form,
      dialog: {
        title: "Multi-Factor Authentication",
        type: "alert",
        message,
        isAboveLoading: true,
      },
      button: {
        ok: {
          text: "Confirm",
        },
        cancel: {
          show: true,
          text:
            (this.#session?.mfMethods.length ?? 0) > 1
              ? "Change Method"
              : "Logout",
        },
        left: {
          show: this.#multiFactorMethod?.type !== "totp",
          text: "Resend Code",
          click: async () => {
            const now = new DateTime();
            if (lastSent.clone().add(20, "seconds").isAfter(now)) {
              const difference = (now.timestamp - lastSent.timestamp) / 1000;
              const remaining = Math.round(20 - difference);

              SnackBarService.errors.generic(
                `Please wait ${remaining} seconds before resending a code`,
              );
              return;
            }

            const methodId = this.#multiFactorMethodId;

            if (!methodId) {
              throw new Error("No multi-factor method found");
            }

            const session = this.#session;

            if (!session) {
              throw new Error("No session found");
            }

            const multifactorRequest = await window.Kernel.ActionBus.execute(
              "auth/multi-factor/request",
              {
                methodId,
                session,
              },
            );
            this.#multiFactorMethodId = methodId;
            this.#multiFactorSessionId =
              multifactorRequest.multiFactorSessionId;
            formData.code = multifactorRequest.code?.toString() ?? "";

            form.controls.incrementFormRenderKey();

            lastSent = new DateTime();
          },
        },
      },
    });

    return Number((data as any).code);
  }
}
