import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { User } from "@kinherit/sdk";

export interface UpdateUserMessage {
  user: User;
}

interface UpdateUserResponse {
  user: User;
}

export class UpdateUserHandler extends ActionBase {
  constructor(private message: UpdateUserMessage) {
    super();
  }

  public async execute(): Promise<UpdateUserResponse> {
    const response = await Api.resource("portal", "/v2/portal/user/{user}", {
      user: this.message.user,
    })
      .method("patch")

      .body({
        acuityUserId: this.message.user.$data.acuityUserId,
        roles: this.message.user.$data.roles,
        credential: {
          username: this.message.user.credential.$data.username,
        },
        displayUserInPortal: this.message.user.$data.displayUserInPortal,
        isEstatePlanner: this.message.user.$data.isEstatePlanner,
        isLegalAssistant: this.message.user.$data.isLegalAssistant,
        status: this.message.user.$data.status,
        sendEmailNotifications: this.message.user.$data.sendEmailNotifications,
        sendSmsNotifications: this.message.user.$data.sendSmsNotifications,
      })
      .result();

    const user = User.$inflate(response.user).first();

    if (!user) {
      throw new Error("Failed to update user");
    }

    return {
      user,
    };
  }
}
