<template>
  <Card :title="name" :title-size="'is-4'" class="Property-summary">
    <span v-html="data" />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="edit"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="remove"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Kintin, Property } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { UpdatePropertyForm } from "../../form/update-property.form";

export default defineComponent({
  name: "PropertySummary",
  props: {
    role: {
      type: Property as PropType<Property>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  emits: ["reload"],
  components: { Card, Button },
  computed: {
    name(): string {
      return "";
    },
    data(): string {
      const details: string[] = [];
      const property = this.role;

      const notSet = "<span class='has-text-danger'>not set</span>";

      if (property.type && property.address?.summary) {
        details.push(
          `${property.type.text} Property at ${property.address.summary}`,
        );
      } else if (property.type) {
        details.push(`${property.type.text} Property`);
      } else if (property.address) {
        details.push(`${property.address.summary ?? notSet}`);
      }

      if (property.howOwned && property.ownership) {
        details.push(`${property.howOwned.text} as ${property.ownership.text}`);
      }

      const value = Array<string>();

      if (property.price) {
        value.push(`Purchased for ${property.price.format}`);
      }

      if (property.value) {
        value.push(`with a current market value at ${property.value.format}`);
      }

      if ((property.lessMortgage?.amount ?? 0) > 0) {
        value.push(
          `less an outstanding mortgage of ${property.lessMortgage?.format}`,
        );
      } else {
        value.push(`with no mortgage`);
      }

      if (value.length > 0) {
        value[0] = value[0].ucFirst();
        details.push(value.join(", "));
      }

      if (property.notes) {
        details.push(`Notes: ${property.notes}`);
      }

      if (property.value) {
        details.push(
          `Net Value: ${
            property.value.amount - (property.lessMortgage?.amount ?? 0)
          }`,
        );
      }

      return details.join("<br />");
    },
  },
  methods: {
    async edit() {
      if (!this.kintin) {
        return;
      }

      const property = this.role;

      try {
        await UpdatePropertyForm({
          property,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit property",
          },
        });
      } catch {
        property.$restore();
        return;
      }

      await window.Kernel.ActionBus.execute("kinvault/kintin/property/update", {
        property,
        kintin: this.kintin.id,
      });

      this.$emit("reload");
    },
    async remove() {
      if (!this.kintin) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete property",
          message: `Are you sure you want to delete this property?`,
        },
      });

      await window.Kernel.ActionBus.execute("kinvault/kintin/property/delete", {
        property: this.role,
        kintin: this.kintin.id,
      });

      this.$emit("reload");
    },
  },
});
</script>
