import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin } from "@kinherit/sdk";

interface DownloadClientSummaryMessage {
  content: string;
  kintin: Kintin;
  password: boolean;
}

interface DownloadClientSummaryResponse {
  [key: string]: never;
}

export class DownloadClientSummaryHandler extends ActionBase {
  constructor(private message: DownloadClientSummaryMessage) {
    super();
  }

  public async execute(): Promise<DownloadClientSummaryResponse> {
    const { kintin, content } = this.message;

    const ref = kintin.ref;
    const name = kintin.friendlyName;

    await Api.resource("portal", "/v2/portal/kintin-call-script/client-summary")
      .method("post")
      .body({
        content,
        kintin: kintin.id,
        password: this.message.password,
      })

      .download({
        encoding: "application/pdf",
        fileName: `${ref} Client Summary - ${name}`,
      });

    return {};
  }
}
