import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm";
import {
  IIntroducerContract,
  IntroducerCompany,
  IntroducerContract,
  Profile,
} from "@kinherit/sdk";
import { IntroducerContractStatus } from "../data/introducer-contract-status.data";

export interface ReadIntroducerContractMessage {
  name?: string;
  contractType?: Array<string>;
  isSigned?: boolean | null;
  contractStatus?: null | keyof typeof IntroducerContractStatus;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerContract;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerContractResponse {
  introducerContracts: Array<IntroducerContract>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadIntroducerContractHandler extends ActionBase {
  constructor(private message: ReadIntroducerContractMessage) {
    super();
  }

  public async execute(): Promise<ReadIntroducerContractResponse> {
    const deletedAt =
      this.message.contractStatus === "void" ? IsNotNull() : undefined;
    const sentAt =
      this.message.contractStatus === "not-sent"
        ? undefined
        : this.message.contractStatus === "sent-not-signed" ||
            this.message.contractStatus === "sent-signed"
          ? IsNotNull()
          : undefined;
    const isSigned =
      this.message.contractStatus === "sent-not-signed"
        ? Equal(false)
        : this.message.contractStatus === "sent-signed"
          ? Equal(true)
          : Equal(this.message.isSigned);

    const request = Api.resource("portal", "/v2/portal/introducer-contract")
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(IntroducerContract).where([
      {
        company: {
          profile: {
            fullName: Like(this.message.name),
          },
        },
        type: {
          id: In(this.message.contractType),
        },
        isSigned: isSigned,
        sentAt: sentAt,
        deletedAt: deletedAt,
        createdAt: Between(this.message.created),
      },
      {
        company: {
          profile: {
            organisationName: Like(this.message.name),
          },
        },
        type: {
          id: In(this.message.contractType),
        },
        isSigned: isSigned,
        sentAt: sentAt,
        deletedAt: deletedAt,
        createdAt: Between(this.message.created),
      },
    ]);

    const response = await request.result();

    return {
      introducerContracts: IntroducerContract.$inflate(
        response.introducerContract,
        this.message.sort,
        response.$rootIds,
      ),
      introducerCompanies: IntroducerCompany.$inflate(
        response.introducerCompany,
      ),
      profiles: Profile.$inflate(response.profile),
      pagination: {
        currentPage: Number.parseInt(
          (response.$pagination?.currentPage as any) ?? "1",
        ),
        lastPage: Number.parseInt(
          (response.$pagination?.lastPage as any) ?? "0",
        ),
        count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
      },
    };
  }
}
