<template>
  <PageHeader htag="h2" text="Upload Forms">
    <template #buttons>
      <Button
        v-if="hasWritePermission"
        text="Add Form"
        @click="$emit('create-upload-form')"
      />
    </template>
  </PageHeader>
  <MasterListPage
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    is-nested
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      name: 'Name',
    }"
    :is-expanded="true"
    :empty-message="`No upload forms found.`"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #details="{ row }">
      <div class="columns">
        <div class="column">
          <ul v-if="row.submissions?.length > 0" class="my-2">
            <li
              v-for="submission in row.submissions"
              :key="submission.id"
              class="my-2"
            >
              <span
                class="has-text-link is-clickable"
                @click="viewSubmission(submission)"
                target="_blank"
                v-tool-tip="`Open file`"
              >
                <b>
                  {{ submission.fileName }}
                </b>
              </span>
              ({{ submission.fileExtension }}) - uploaded
              {{ submission.createdAt.formatDateTime }}
            </li>
          </ul>
          <div v-else class="has-text-grey">No files uploaded</div>
        </div>
        <div class="column is-narrow">
          <Button
            text="Get Link"
            @click="generateLink(row)"
            color="is-success"
            is-outlined
          />
        </div>
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateUploadForm } from "@/module/kinvault.kintin/form/update-upload-form.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
import { FileLog, IUploadForm, UploadForm } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsUploadFormRoute } from ".";

export default defineComponent({
  name: KintinDetailsUploadFormRoute,
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  components: {
    MasterListPage,
    Button,
    PageHeader,
  },
  emits: ["create-upload-form"],
  data: () => ({
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Files",
        map: (v: UploadForm) => v.submissionCount,
      },
      {
        title: "Created",
        map: (v: UploadForm) => v.createdAt.formatDate,
      },
      {
        title: "Expires",
        map: (v: UploadForm) => v.validTill.formatDate,
      },
    ] as Array<TableColumn>,
    rows: Array<UploadForm>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "name" as keyof IUploadForm,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission() {
      return this.$auth.hasPermission("kintin:write");
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const { uploadForms, pagination } = await window.Kernel.ActionBus.execute(
        "kinvault/kintin/upload-forms/read",
        {
          ...this.$params,
          pagination: this.pagination,
          sort: this.sort,
        },
      );

      this.rows = uploadForms;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async handleClick(uploadform: UploadForm): Promise<void> {
      const item = await OpenContextMenu({
        items: [
          {
            title: "Edit",
            line1: "Edit this form",
            action: this.edit,
            icon: StyleService.icon.edit.icon,
          },
          {
            title: "Delete",
            line1: "Delete this form",
            action: this.delete,
            icon: StyleService.icon.delete.icon,
          },
        ],
        titleField: "title",
        trackingField: "tracking",
        hideField: "hide",
        iconLeftField: "icon",
        line1Field: "line1",
      });

      await item.action(uploadform);
    },
    async edit(uploadForm: UploadForm): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      try {
        await UpdateUploadForm(uploadForm).dialog({
          dialog: {
            title: "Update upload form",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        uploadForm.$restore();
        throw e;
      }

      uploadForm.kintin = kintin;
      uploadForm.$persist();

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/upload-forms/update",
        {
          uploadForm,
          kintin,
        },
      );

      this.refresh();
    },
    async delete(uploadForm: UploadForm): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete upload form",
          message: "Are you sure you want to delete this upload form?",
        },
      });

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/upload-forms/delete",
        {
          uploadForm,
          kintin,
        },
      );

      this.refresh();
    },
    viewSubmission(submission: FileLog): void {
      window.Kernel.ActionBus.execute(
        "kinvault/kintin/upload-forms/submission/download",
        {
          submission,
          ...this.$params,
        },
      );
    },
    async generateLink(uploadForm: UploadForm): Promise<void> {
      const { url } = await window.Kernel.ActionBus.execute(
        "core/environment/get-sign-app-url",
        {},
      );
      const link = `${url}/upload/${uploadForm.id}`;

      await OpenAlertDialog({
        dialog: {
          title: "Upload form link",
          message: link,
        },
        button: {
          ok: {
            text: "Copy",
          },
        },
      });

      await ClipBoardService.copyTextToClipBoard(link);
    },
  },
});
</script>
