<template>
  <PageHeader htag="h1" text="Networks">
    <template #buttons>
      <Button class="create" @click="createIntroducerNetwork">
        Create Introducer Network
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #actions="{ row }">
      <div class="buttons is-right">
        <Button @click="updateNetwork(row)" class="edit-button"> Edit </Button>
        <Button @click="deleteNetwork(row)" class="delete-button">
          Delete
        </Button>
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadIntroducerNetworkForm } from "@/module/introducer.network/form/read-introducer-network.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerNetwork, IntroducerNetwork } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerNetworkMasterListRoute } from ".";
import { CreateIntroducerNetworkForm } from "../form/create-introducer-network.form";

export default defineComponent({
  name: IntroducerNetworkMasterListRoute,
  mixins: [AuthService.mixin()],
  components: { MasterListPage, PageHeader, Button },
  data: () => ({
    filters: ReadIntroducerNetworkForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v) => v.name,
      },
      {
        title: "Companies",
        sort: true,
        map: (v) => v.companyCount,
      },
      {
        title: "Created",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<IntroducerNetwork>>,
    rows: Array<IntroducerNetwork>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerNetwork,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-network:write");
    },
  },
  methods: {
    async updateNetwork(introducerNetwork: IntroducerNetwork): Promise<void> {
      const form = CreateIntroducerNetworkForm(introducerNetwork);

      try {
        await form.dialog({
          dialog: {
            title: "Edit Introducer Network",
          },
        });

        await window.Kernel.ActionBus.execute("introducer/network/update", {
          introducerNetwork: form.localData,
        });

        this.refresh(this.filters.localData);
      } catch {
        introducerNetwork.$restore();
      }
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerNetworkForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.execute(
        "introducer/network/read",
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.introducerNetworks;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createIntroducerNetwork(): Promise<void> {
      const form = CreateIntroducerNetworkForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Introducer Network",
          },
        });

        await window.Kernel.ActionBus.execute("introducer/network/create", {
          introducerNetwork: form.localData,
        });

        this.refresh(this.filters.localData);
      } catch {
        form.localData.$delete();
      }
    },
    async deleteNetwork(network: IntroducerNetwork): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Introducer Network",
          message: `Are you sure you want to delete ${network.name}?`,
        },
      });

      await window.Kernel.ActionBus.execute("introducer/network/delete", {
        introducerNetwork: network,
      });

      this.refresh(this.filters.localData);
    },
  },
});
</script>
