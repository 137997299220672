<template>
  <SidebarPage
    :key="key"
    v-if="referralCode && !loading"
    class="admin-referral-details"
    :has-context-menu="hasContextMenu"
    :name="`Referral Code - ${referralCode.code}`"
    @context-menu="contextMenu"
    :tabs="tabs"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <AccountReferralCodeDetailsSummarySidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <AccountReferralCodeDetailsSummary />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AccountReferralCodeDetailsMixin } from "@/module/admin.account-referral-code/mixin/account-referral-code-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  AccountReferralCodeDetailsParams,
  AccountReferralCodeDetailsRoute,
} from ".";
import { ReferralCodeMasterListRoute } from "..";
import { UpdateAccountReferralCodeForm } from "../../form/update-account-referral-code.form";
import AccountReferralCodeDetailsSummarySidebar from "./AccountReferralCodeDetails.Sidebar.vue";
import { AccountReferralCodeDetailsSummaryRoute } from "./summary";
import AccountReferralCodeDetailsSummary from "./summary/AccountReferralCode.Summary.vue";

export default defineComponent({
  name: AccountReferralCodeDetailsRoute,
  mixins: [AccountReferralCodeDetailsMixin],
  components: {
    PageNotFound,
    SidebarPage,
    RouterChildView,
    AccountReferralCodeDetailsSummary,
    AccountReferralCodeDetailsSummarySidebar,
  },
  computed: {
    hasContextMenu(): boolean {
      return AuthService.hasPermission("referral-code:write");
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: AccountReferralCodeDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
    ],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.execute(
      "admin/account-referral-code/record",
      to.params as AccountReferralCodeDetailsParams,
    );
  },
  mounted(): void {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.referralCode) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit",
              line1: "Edit this referral code",
              icon: StyleService.icon.edit.icon,
              permission: "referral-code:write",
              action: this.edit,
            },
            {
              title: "Delete",
              line1: "Delete this referral code",
              icon: StyleService.icon.delete.icon,
              permission: "referral-code:write",
              action: this.delete,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action?.(),
      })();
    },
    async delete(): Promise<void> {
      if (!this.referralCode) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Referral Code",
          message: "Are you sure you want to delete this referral code?",
        },
      });

      await window.Kernel.ActionBus.execute(
        "admin/account-referral-code/delete",
        {
          accountReferralCode: this.referralCode,
        },
      );

      window.Kernel.visitRoute({
        name: ReferralCodeMasterListRoute,
      });
    },
    async edit(): Promise<void> {
      if (!this.referralCode) {
        return;
      }

      try {
        await UpdateAccountReferralCodeForm(this.referralCode).dialog({
          dialog: {
            title: "Edit Referral Code",
          },
        });
      } catch (e) {
        this.referralCode.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.execute(
        "admin/account-referral-code/update",
        {
          accountReferralCode: this.referralCode,
        },
      );

      this.key++;
    },
  },
});
</script>
