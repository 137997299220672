import {
  UpdateIntroducerCompanyProfileMessage,
  UpdateIntroducerCompanyProfileResponse,
  UpdateProfileHandler,
} from "@/shared/action/profile/update-profile.action";

export const UpdateIntroducerCompanyProfileHandler =
  UpdateProfileHandler as new (
    message: UpdateIntroducerCompanyProfileMessage,
  ) => UpdateProfileHandler<
    UpdateIntroducerCompanyProfileMessage,
    UpdateIntroducerCompanyProfileResponse
  >;
