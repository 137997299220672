import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Trustee } from "@kinherit/sdk";

interface UpdateTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface UpdateTrusteeResponse {
  trustee: Trustee;
}

export class UpdateTrusteeHandler extends ActionBase {
  constructor(private message: UpdateTrusteeMessage) {
    super();
  }

  public async execute(): Promise<UpdateTrusteeResponse> {
    const { trustee: trusteeData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-trustee/{kintin}/person/trustee/{trustee}",
      {
        kintin: this.message.kintin,
        trustee: this.message.trustee.$data.id,
      },
    )
      .method("patch")

      .body({
        forPerson: this.message.trustee.$data.forPerson,
        person: this.message.trustee.$data.person,
        isReserve: this.message.trustee.$data.isReserve,
        notes: this.message.trustee.$data.notes,
        sortOrder: this.message.trustee.$data.sortOrder,
        attainmentAge: this.message.trustee.$data.attainmentAge,
      })
      .result();

    const trustee = Trustee.$inflate(trusteeData).first();

    if (!trustee) {
      throw new Error("Failed to update trustee");
    }

    return { trustee };
  }
}
