import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";

interface ChangeTokenPasswordMessage {
  username: string;
  newPassword: string;
  token: string;
}

interface ChangeTokenPasswordResponse {
  [key: string]: never;
}

export class ChangeTokenPasswordHandler extends ActionBase {
  constructor(private message: ChangeTokenPasswordMessage) {
    super();
  }

  public async execute(): Promise<ChangeTokenPasswordResponse> {
    await Api.resource("auth", "/v2/auth/change-password/token/{token}", {
      token: this.message.token,
    })
      .method("put")
      .body({
        newPassword: this.message.newPassword,
      })

      .result();

    return {};
  }
}
