import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, Person } from "@kinherit/sdk";

type sendOfficerLetterMessage = {
  kintin: string | Kintin;
  officer: string | Person;
};

interface sendOfficerLetterResponse {
  [key: string]: never;
}

export class sendOfficerLetterHandler extends ActionBase {
  constructor(private message: sendOfficerLetterMessage) {
    super();
  }

  public async execute(): Promise<sendOfficerLetterResponse> {
    const kintin =
      "string" === typeof this.message.kintin
        ? this.message.kintin
        : this.message.kintin.id;
    const officer =
      "string" === typeof this.message.officer
        ? this.message.officer
        : this.message.officer.id;

    await Api.resource("portal", "/v2/portal/officer-letter/send")
      .method("get")
      .params({
        kintin,
        officer,
      })
      .result();

    return {};
  }
}
