import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Tag } from "@kinherit/sdk";

interface RecordTagMessage {
  tag: string;
}

interface RecordTagResponse {
  tag: Tag;
}

export class RecordTagHandler extends ActionBase {
  constructor(private message: RecordTagMessage) {
    super();
  }

  public async execute(): Promise<RecordTagResponse> {
    const response = await Api.resource("portal", "/v2/portal/tag/{tag}", {
      tag: this.message.tag,
    })
      .method("get")

      .result();

    const tag = Tag.$inflate(response.tag).first();

    if (!tag) {
      throw new Error("Tag not found");
    }

    return {
      tag,
    };
  }
}
