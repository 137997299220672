import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Between } from "@kinherit/orm";
import { EmailCampaignLog, IEmailCampaign } from "@kinherit/sdk";

export interface ReadEmailCampaignLogMessage {
  scheduledToSendAt?: null | [number, number];
  created?: null | [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IEmailCampaign;
    direction: "asc" | "desc";
  };
}

export interface ReadEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadEmailCampaignLogHandler extends ActionBase {
  constructor(private message: ReadEmailCampaignLogMessage) {
    super();
  }

  public async execute(): Promise<ReadEmailCampaignLogResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/email-campaign-log",
      this.message,
    )
      .method("get")

      .paginate(this.message.pagination)
      .sort(this.message.sort);

    request.buildQuery(EmailCampaignLog).where({
      scheduledToSendAt: Between(this.message.scheduledToSendAt),
      createdAt: Between(this.message.created),
    });

    const response = await request.result();

    return {
      emailCampaignLog: EmailCampaignLog.$inflate(
        response.emailCampaignLog,
        undefined,
        response.$rootIds,
      ),
      pagination: this.getPagination(response),
    };
  }
}
