import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  BrandedKinvaultMembership,
  IBrandedKinvaultMembership,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultMembershipMessage {
  query?: QueryMask<typeof BrandedKinvaultMembership>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IBrandedKinvaultMembership;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultMembershipResponse {
  brandedKinvaultMemberships: Array<BrandedKinvaultMembership>;
  users: Array<User>;
  profiles: Array<Profile>;
}

export class ReadBrandedKinvaultMembershipHandler extends ActionBase {
  constructor(private message: ReadBrandedKinvaultMembershipMessage) {
    super();
  }

  public async execute(): Promise<ReadBrandedKinvaultMembershipResponse> {
    const request = Api.resource(
      "core",
      "/v2/core/select/branded-kinvault-membership",
    )
      .method("get")

      .sort({
        sortBy: this.message.sort?.by ?? "user.profile.fullName",
        sortDirection: this.message.sort?.direction ?? "asc",
      })
      .paginate(this.message.pagination ?? false);

    request
      .buildQuery(BrandedKinvaultMembership)
      .where(this.message.query ?? {});

    const response = await request.result();

    return {
      brandedKinvaultMemberships: BrandedKinvaultMembership.$inflate(
        response.brandedKinvaultMembership,
        this.message.sort,
        response.$rootIds,
      ),
      users: User.$inflate(response.user),
      profiles: Profile.$inflate(response.profile),
    };
  }
}
