import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  BrandedKinvault,
  ThemeCssVar,
  ThemeKinvault,
  ThemeKinvaultFooter,
  ThemeKinvaultHeader,
} from "@kinherit/sdk";

export interface ReplaceThemeKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
  themeKinvault: ThemeKinvault;
}

interface ReplaceThemeKinvaultResponse {
  themeKinvault: ThemeKinvault;
  themeKinvaultFooter: ThemeKinvaultFooter;
  themeKinvaultHeader: ThemeKinvaultHeader;
}

export class ReplaceThemeKinvaultHandler extends ActionBase {
  constructor(private message: ReplaceThemeKinvaultMessage) {
    super();
  }

  public async execute(): Promise<ReplaceThemeKinvaultResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault-theme/{brandedKinvault}/replace",
      {
        brandedKinvault: this.message.brandedKinvault,
      },
    )
      .method("put")
      .body({
        brandedKinvault:
          "string" === typeof this.message.brandedKinvault
            ? this.message.brandedKinvault
            : this.message.brandedKinvault.$id,
        brand: this.message.themeKinvault.$data.brand,
        favIcon: this.message.themeKinvault.$data.favIcon,
        login: {
          screenImg: this.message.themeKinvault.login.$data.screenImg,
          customScreenImage:
            this.message.themeKinvault.login.$data.customScreenImage,
        },
        header: {
          type: this.message.themeKinvault.header.$data.type,
          defaultLogo: this.message.themeKinvault.header.$data.defaultLogo,
          defaultLogoClass:
            this.message.themeKinvault.header.$data.defaultLogoClass,
          loginLogo: this.message.themeKinvault.header.$data.loginLogo,
          loginLogoClass:
            this.message.themeKinvault.header.$data.loginLogoClass,
          strap: this.message.themeKinvault.header.$data.strap,
        },
        footer: {
          class: this.message.themeKinvault.footer.$data.class,
          leftLogoClass: this.message.themeKinvault.footer.$data.leftLogoClass,
          leftLogo: this.message.themeKinvault.footer.$data.leftLogo,
          rightLogoClass:
            this.message.themeKinvault.footer.$data.rightLogoClass,
          rightLogo: this.message.themeKinvault.footer.$data.rightLogo,
          textLine1: this.message.themeKinvault.footer.$data.textLine1,
          textLine2: this.message.themeKinvault.footer.$data.textLine2,
          twitter: this.message.themeKinvault.footer.$data.twitter,
          facebook: this.message.themeKinvault.footer.$data.facebook,
          linkedIn: this.message.themeKinvault.footer.$data.linkedIn,
          youtube: this.message.themeKinvault.footer.$data.youtube,
        },
        cssVars: this.message.themeKinvault.cssVars.map((cssVar) => ({
          key: cssVar.$data.key,
          value: cssVar.$data.value,
        })),
      })
      .result();

    this.message.themeKinvault.$restore({
      footer: true,
      header: true,
      login: true,
      cssVars: true,
    });

    ThemeCssVar.$delete(this.message.themeKinvault.cssVars.pluck("$id"));

    return await window.Kernel.ActionBus.execute(
      "admin/branded-kinvault/kinvault-theme/record",
      {
        brandedKinvault: this.message.brandedKinvault,
        themeKinvault: this.message.themeKinvault,
      },
    );
  }
}
