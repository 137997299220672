import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface DeleteKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface DeleteKnowledgeBaseCategoryResponse {
  [key: string]: never;
}

export class DeleteKnowledgeBaseCategoryHandler extends ActionBase {
  constructor(private message: DeleteKnowledgeBaseCategoryMessage) {
    super();
  }

  public async execute(): Promise<DeleteKnowledgeBaseCategoryResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}",
      {
        knowledgeBaseCategory: this.message.knowledgeBaseCategory.id,
      },
    )
      .method("delete")
      .result();

    return {};
  }
}
