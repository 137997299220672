<template>
  <div class="kintin-details-address-book-beneficiaries">
    <PageHeader htag="h2" text="Beneficiaries" v-if="kintin">
      <template #buttons>
        <Button
          text="Add Beneficiary"
          @click="createBeneficiary"
          class="add-button"
        />
        <Button
          :text="sorting ? 'Done' : 'Sort'"
          @click="togglingSorting"
          class="sort-button"
          v-if="!isPeopleTab"
        />
        <Button
          v-if="kintin.type.value === 'joint'"
          text="Copy"
          @click="copy"
          class="copy-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        v-if="!sorting"
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4"
      />
      <div v-if="!isPeopleTab">
        <Title size="is-6" is-emphasised>First line</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.beneficiaries.firstLine"
        />
        <BeneficiaryList
          v-else
          :kintin="kintin"
          :beneficiaries="$data.filtered.beneficiaries.firstLine"
          @reload="filter"
        />

        <Title size="is-6" is-emphasised>Second line</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.beneficiaries.secondLine"
        />
        <BeneficiaryList
          v-else
          :kintin="kintin"
          :beneficiaries="$data.filtered.beneficiaries.secondLine"
          @reload="filter"
        />

        <Title size="is-6" is-emphasised>Reserve</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.beneficiaries.reserve"
        />
        <BeneficiaryList
          v-else
          :kintin="kintin"
          :beneficiaries="$data.filtered.beneficiaries.reserve"
          @reload="filter"
        />
      </div>
      <PersonList
        :key="renderKey"
        v-if="isPeopleTab"
        :kintin="kintin"
        :people="$data.filtered.people"
        @reload="filter"
      />
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<script lang="ts">
import { CreateBeneficiary } from "@/config/model.config";
import BeneficiaryList from "@/module/kinvault.kintin/component/lists/BeneficiaryList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdateBeneficiaryForm } from "@/module/kinvault.kintin/form/update-beneficiary.form";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Beneficiary, Kintin, Person } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CopyRolesForm } from "../../form/copy-roles.form";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `BeneficiariesContainer`,
  components: {
    Tabs,
    Json,
    Title,
    BeneficiaryList,
    Button,
    PageHeader,
    PersonList,
    SortRoles,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      beneficiaries: {
        firstLine: Array<Beneficiary>(),
        secondLine: Array<Beneficiary>(),
        reserve: Array<Beneficiary>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    renderKey: 0,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const beneficiaries = Beneficiary.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin?.primaryPerson?.id,
                this.kintin?.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      // NB position doesn't start at 0.
      this.filtered.beneficiaries = beneficiaries.groupBy({
        firstLine: (b) => b.position === 0,
        secondLine: (b) => b.position === 1,
        reserve: (b) => b.position === 2,
      });

      this.filtered.beneficiaries.firstLine =
        this.filtered.beneficiaries.firstLine.sortBy("sortOrder");
      this.filtered.beneficiaries.secondLine =
        this.filtered.beneficiaries.secondLine.sortBy("sortOrder");
      this.filtered.beneficiaries.reserve =
        this.filtered.beneficiaries.reserve.sortBy("sortOrder");

      this.filtered.people = beneficiaries
        .pluck("person")
        .unique("id")
        .cast<Person>()
        .filter(Boolean)
        .sortBy("profile.fullName");

      this.renderKey++;

      this.$emit("reload");
    },
    async createBeneficiary() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const beneficiary = CreateBeneficiary({
        forPerson,
      });

      beneficiary.position = 0;

      try {
        await UpdateBeneficiaryForm({
          data: beneficiary,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create beneficiary",
          },
        });
      } catch {
        beneficiary.$delete();
        return;
      }

      await window.Kernel.ActionBus.execute(
        "kinvault/kintin/beneficiary/create",
        {
          beneficiary,
          kintin: this.kintin,
        },
      );

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.beneficiaries.firstLine,
          ...this.$data.filtered.beneficiaries.secondLine,
          ...this.$data.filtered.beneficiaries.reserve,
        ].forEachAsync((beneficiary) =>
          window.Kernel.ActionBus.execute(
            "kinvault/kintin/beneficiary/update",
            {
              beneficiary,
              kintin: this.kintin,
            },
          ),
        );
      }

      this.sorting = !this.sorting;
    },
    async copy() {
      const forPerson =
        this.selectedTab === 0
          ? this.kintin.secondaryPerson
          : this.kintin.primaryPerson;

      let name: string = "";

      if (forPerson?.profile.fullName) {
        name = forPerson?.profile.fullName;
      }

      const type =
        this.selectedTab === 0 ? "secondary person" : "primary person";

      name = name === "" ? type : `${name} (${type})`;

      const { rolesToCopy } = await CopyRolesForm(
        [
          ...this.$data.filtered.beneficiaries.firstLine,
          ...this.$data.filtered.beneficiaries.secondLine,
          ...this.$data.filtered.beneficiaries.reserve,
        ].filter((a) => a.person?.id !== forPerson?.id),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy to ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (beneficiary) => {
        const newAdviser = CreateBeneficiary({
          ...beneficiary.$data,
          forPerson: forPerson?.id,
          id: Uuid.generate(),
        });

        await window.Kernel.ActionBus.execute(
          "kinvault/kintin/beneficiary/create",
          {
            beneficiary: newAdviser,
            kintin: this.kintin,
          },
        );
      });

      this.selectedTab = this.selectedTab === 0 ? 1 : 0;
      this.filter();
    },
  },
});
</script>
