import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferralCode, IAccountReferralCode } from "@kinherit/sdk";
import { Between, In, Like } from "@kinherit/orm";

interface ReadAccountReferralCodeMessage {
  code?: string;
  category?: Array<string>;
  created?: null | [number, number];
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort: {
    by: keyof IAccountReferralCode;
    direction: "asc" | "desc";
  };
}

interface ReadAccountReferralCodeResponse {
  accountReferralCodes: AccountReferralCode[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export class ReadAccountReferralCodeHandler extends ActionBase {
  constructor(private message: ReadAccountReferralCodeMessage) {
    super();
  }

  public async execute(): Promise<ReadAccountReferralCodeResponse> {
    const request = Api.resource(
      "portal",
      "/v2/portal/referral/account-referral-code",
    )
      .method("get")

      .sort(this.message.sort)
      .paginate(this.message.pagination);

    request.buildQuery(AccountReferralCode).where([
      {
        code: Like(this.message.code),
        category: {
          id: In(this.message.category),
        },
        createdAt: Between(this.message.created),
      },
      {
        name: Like(this.message.code),
        category: {
          id: In(this.message.category),
        },
        createdAt: Between(this.message.created),
      },
    ]);

    const response = await request.result();

    const accountReferralCodes = AccountReferralCode.$inflate(
      response.accountReferralCode,
      undefined,
      response.$rootIds,
    );

    return {
      accountReferralCodes,
      pagination: this.getPagination(response),
    };
  }
}
