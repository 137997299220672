import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, UploadForm } from "@kinherit/sdk";

interface UpdateKintinUploadFormMessage {
  uploadForm: UploadForm;
  kintin: Kintin;
}

interface UpdateKintinUploadFormResponse {
  uploadForm: UploadForm;
}

export class UpdateKintinUploadFormHandler extends ActionBase {
  constructor(private message: UpdateKintinUploadFormMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinUploadFormResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-upload-form/{kintin}/upload-forms/{uploadForm}",
      {
        kintin: this.message.kintin.id,
        uploadForm: this.message.uploadForm.id,
      },
    )
      .method("patch")
      .body({
        name: this.message.uploadForm.$data.name,
        kintin: this.message.kintin.id,
        validTill: this.message.uploadForm.$data.validTill,
      })

      .result();

    const uploadForm = UploadForm.$inflate(response.uploadForm).first();

    if (!uploadForm) {
      throw new Error("Failed to Update upload form");
    }

    return {
      uploadForm,
    };
  }
}
