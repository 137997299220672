import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Executor, Kintin } from "@kinherit/sdk";

interface UpdateExecutorMessage {
  kintin: string | Kintin;
  executor: Executor;
}

interface UpdateExecutorResponse {
  executor: Executor;
}

export class UpdateExecutorHandler extends ActionBase {
  constructor(private message: UpdateExecutorMessage) {
    super();
  }

  public async execute(): Promise<UpdateExecutorResponse> {
    const { executor: executorData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-executor/{kintin}/person/executor/{executor}",
      {
        kintin: this.message.kintin,
        executor: this.message.executor.$data.id,
      },
    )
      .method("patch")

      .body({
        forPerson: this.message.executor.$data.forPerson,
        person: this.message.executor.$data.person,
        isReserve: this.message.executor.$data.isReserve,
        notes: this.message.executor.$data.notes,
        professionalSupport: this.message.executor.$data.professionalSupport,
        sortOrder: this.message.executor.$data.sortOrder,
      })
      .result();

    const executor = Executor.$inflate(executorData).first();

    if (!executor) {
      throw new Error("Failed to update executor");
    }

    return { executor };
  }
}
