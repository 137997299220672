<template>
  <div
    class="status-component is-clickable"
    :class="{
      'is-vertical': direction === 'is-vertical',
    }"
    @click.stop="handleClick"
    :key="renderKey"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <div
      v-if="status.class === 'success'"
      class="status-component__box has-border has-border-success has-background-success"
    >
      <span>{{ status.text }}</span>
    </div>
    <div
      v-else-if="status.class === 'danger'"
      :class="[
        'status-component__box has-border has-border-danger',
        { 'has-background-danger': hover },
      ]"
    >
      <span>
        {{ status.text }}
      </span>
    </div>
    <div
      v-else
      :class="[
        `status-component__box has-border has-border-${status.class}`,
        `has-background-${status.class + (hover ? '' : '-light')}`,
      ]"
    >
      <span> {{ status.text }} </span>
    </div>
    <div
      v-if="status.class !== 'success' && status.class !== 'danger'"
      :class="[
        `status-component__box has-border has-border-${nextAction.class}`,
        `has-background-${nextAction.class + (hover ? '' : '-light')}`,
      ]"
    >
      <span>{{ nextAction.text }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { UpdateLeadStatusForm } from "@/shared/form/lead/update-lead-status.form";
import { Lead } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LeadStatusComponent",
  props: {
    lead: {
      type: Object as () => Lead,
      required: true,
    },
    direction: {
      type: String,
      default: "is-horizontal",
    },
  },
  data: () => ({
    status: {
      text: "Not Set",
      class: "grey",
    },
    nextAction: {
      text: "Not Set",
      class: "grey",
    },
    renderKey: 0,
    hover: false as boolean,
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const lead = Lead.$findOneOrThrow(this.lead.id);

      this.status = {
        text: lead.status?.text ?? "Not Set",
        class: lead.status?.class ?? "grey",
      };

      const nextActionAt = lead.referral?.nextActionAt;
      const isToday = nextActionAt?.sameDayAs(new DateTime()) ?? false;
      const isTomorrow =
        nextActionAt?.sameDayAs(new DateTime().add(1, "day")) ?? false;
      const isOverdue = nextActionAt?.isBefore(new DateTime()) ?? false;

      switch (true) {
        case isToday:
          this.nextAction = {
            text: "Today",
            class: "teal",
          };
          break;
        case isTomorrow:
          this.nextAction = {
            text: "Tomorrow",
            class: "purple",
          };
          break;
        case isOverdue:
          this.nextAction = {
            text:
              "Overdue by " +
                nextActionAt?.since({
                  format: "day",
                  short: true,
                }) ?? "Unknown",
            class: "danger",
          };
          break;
        default:
          this.nextAction = {
            text: nextActionAt?.formatDate ?? "Not Set",
            class: "grey",
          };
          break;
      }

      this.renderKey++;
      this.$forceUpdate();
    },
    async handleClick() {
      if (this.lead.status?.value === "converted") {
        return;
      }

      const lead = Lead.$findOneOrThrow(this.lead.id);

      try {
        await UpdateLeadStatusForm(lead).dialog({
          dialog: {
            title: `${this.lead.profile.fullName}`,
            type: "fullscreen",
          },
          button: {
            ok: {
              text: "Update",
            },
            cancel: {
              text: "Cancel",
            },
          },
        });

        await window.Kernel.ActionBus.execute("lead/settings/update", {
          lead,
        });

        const referral = lead.referral;

        await window.Kernel.ActionBus.execute("core/account-referral/update", {
          referral,
        });

        this.load();
      } catch (e) {
        lead.$restore();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.is-vertical {
  @media (min-width: 768px) {
    flex-wrap: wrap; // This will allow it to wrap under 768px
    justify-content: center;
  }
}

.status-component {
  display: flex;
  gap: 0.5em;

  @media (max-width: 1024px) {
    flex-direction: column;

    &__box span {
      position: static !important;
      transform: none !important;
    }
  }

  &__box {
    padding: 0.75em 0.75em;
    font-size: $size-small;
    line-height: 1;
    @include font-weight(700);
    flex: 1;
    min-width: 12em;
    transition: all 0.25s;
    border-width: 0 0 0 0.5em;

    @media (min-width: 1024px) {
      &:first-of-type {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
      }

      &:last-of-type {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
      }
    }
  }
}
</style>
