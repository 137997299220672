import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { MfAuthMethod } from "@kinherit/sdk";

interface DeleteUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface DeleteUserMfAuthMethodResponse {
  [key: string]: never;
}

export class DeleteUserMfAuthMethodHandler extends ActionBase {
  constructor(private message: DeleteUserMfAuthMethodMessage) {
    super();
  }

  public async execute(): Promise<DeleteUserMfAuthMethodResponse> {
    const credential = this.message.mfAuthMethod.credential;

    await Api.resource(
      "portal",
      "/v2/portal/user/{credential}/mf-auth-methods/{mfAuthMethod}",
      {
        credential,
        mfAuthMethod: this.message.mfAuthMethod.id,
      },
    )
      .method("delete")
      .result();

    this.message.mfAuthMethod.$delete();

    return {};
  }
}
