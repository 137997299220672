import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, UploadForm } from "@kinherit/sdk";

interface CreateKintinUploadFormMessage {
  uploadForm: UploadForm;
  kintin: Kintin;
}

interface CreateKintinUploadFormResponse {
  uploadForm: UploadForm;
}

export class CreateKintinUploadFormHandler extends ActionBase {
  constructor(private message: CreateKintinUploadFormMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinUploadFormResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-upload-form/{kintin}/upload-forms",
      {
        kintin: this.message.kintin.id,
      },
    )
      .method("post")
      .body({
        name: this.message.uploadForm.$data.name,
        kintin: this.message.kintin.id,
        validTill: this.message.uploadForm.$data.validTill,
      })

      .result();

    const uploadForm = UploadForm.$inflate(response.uploadForm).first();

    if (!uploadForm) {
      throw new Error("Failed to create upload form");
    }

    this.message.uploadForm.$delete();

    return {
      uploadForm,
    };
  }
}
