import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Order } from "@kinherit/sdk";

type DownloadOrderInvoiceMessage = {
  order: Order;
  isBusinessInvoice: boolean;
};

interface DownloadOrderInvoiceResponse {
  [key: string]: never;
}

export class DownloadOrderInvoiceHandler extends ActionBase {
  constructor(private message: DownloadOrderInvoiceMessage) {
    super();
  }

  public async execute(): Promise<DownloadOrderInvoiceResponse> {
    const { order, isBusinessInvoice } = this.message;
    const { kintin } = order;

    const primaryPerson = kintin?.primaryPerson;
    const secondaryPerson = kintin?.secondaryPerson;

    const surname = [
      primaryPerson?.profile.lastName,
      secondaryPerson?.profile.lastName,
    ]
      .filter(Boolean)
      .unique()
      .join(" & ");

    const ref = kintin?.ref;

    await Api.resource("portal", "/v2/portal/order/{order}/invoice", {
      order,
    })
      .method("get")
      .params({
        isBusinessInvoice,
      })
      .download({
        encoding: "application/pdf",
        fileName: `${ref} Order Invoice - ${surname}`,
      });

    return {};
  }
}
