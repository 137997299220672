import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Order, Person } from "@kinherit/sdk";

export interface SendOrderPaymentEmailMessage {
  order: string | Order;
  person: string | Person;
}

interface SendOrderPaymentEmailResponse {
  [key: string]: never;
}

export class SendOrderPaymentEmailHandler extends ActionBase {
  constructor(private message: SendOrderPaymentEmailMessage) {
    super();
  }

  public async execute(): Promise<SendOrderPaymentEmailResponse> {
    await Api.resource(
      "portal",
      "/v2/portal/order/{order}/send-order-payment-email/{person}",
      {
        order: this.message.order,
        person: this.message.person,
      },
    )
      .method("put")
      .result();

    return {};
  }
}
