import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { FileLog } from "@kinherit/sdk";

interface UpdateKintinFileMessage {
  fileLog: FileLog;
  uploadedFile: File | null;
}

interface UpdateKintinFileResponse {
  fileLog: FileLog;
}

export class UpdateKintinFileHandler extends ActionBase {
  constructor(private message: UpdateKintinFileMessage) {
    super();
  }

  public async execute(): Promise<UpdateKintinFileResponse> {
    const kintin = this.message.fileLog.$data.kintin;

    if (!kintin) {
      throw new Error("Kintin is required");
    }

    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files/{fileLog}",
      {
        kintin,
        fileLog: this.message.fileLog,
      },
    )
      .method("patch")

      .body({
        kintin: this.message.fileLog.$data.kintin,
        type: this.message.fileLog.$data.type,
        subType: this.message.fileLog.$data.subType,
        tab: this.message.fileLog.$data.tab,
        fileName: this.message.fileLog.$data.fileName,
        fileExtension: this.message.fileLog.$data.fileExtension,
        fileSize: this.message.fileLog.$data.fileSize,
        notes: this.message.fileLog.$data.notes,
      })
      .result();

    const fileLog = FileLog.$inflate(response.fileLog).first();

    if (!fileLog) {
      throw new Error("Failed to update file");
    }

    if (this.message.uploadedFile) {
      await Api.resource(
        "portal",
        "/v2/portal/kintin-file/{kintin}/files/{file}/upload",
        {
          kintin,
          file: fileLog.$data.id,
        },
      )
        .method("put")

        .files({
          uploadedFile: this.message.uploadedFile,
        })
        .result();
    }

    return { fileLog };
  }
}
