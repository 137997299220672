import { CreateAccountReferralCodeHandler } from "@/module/admin.account-referral-code/action/create-account-referral-code.action";
import { DeleteAccountReferralCodeHandler } from "@/module/admin.account-referral-code/action/delete-account-referral-code.action";
import { ReadAccountReferralCodeHandler } from "@/module/admin.account-referral-code/action/read-account-referral-code.action";
import { RecordAccountReferralCodeHandler } from "@/module/admin.account-referral-code/action/record-account-referral-code.action";
import { UpdateAccountReferralCodeHandler } from "@/module/admin.account-referral-code/action/update-account-referral-code.action";

export const Actions = {
  "admin/account-referral-code/create": CreateAccountReferralCodeHandler,
  "admin/account-referral-code/delete": DeleteAccountReferralCodeHandler,
  "admin/account-referral-code/read": ReadAccountReferralCodeHandler,
  "admin/account-referral-code/update": UpdateAccountReferralCodeHandler,
  "admin/account-referral-code/record": RecordAccountReferralCodeHandler,
};
