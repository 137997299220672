import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { CpdSessionRegistration } from "@kinherit/sdk";

interface UpdateCpdSessionRegistrationMessage {
  cpdSessionRegistration: CpdSessionRegistration;
}

interface UpdateCpdSessionRegistrationResponse {
  cpdSessionRegistration: CpdSessionRegistration;
}

export class UpdateCpdSessionRegistrationHandler extends ActionBase {
  constructor(private message: UpdateCpdSessionRegistrationMessage) {
    super();
  }

  public async execute(): Promise<UpdateCpdSessionRegistrationResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/cpd-session/cpd-session-registration/{cpdSessionRegistration}",
      {
        cpdSessionRegistration: this.message.cpdSessionRegistration,
      },
    )
      .method("patch")

      .body({
        isMatched: this.message.cpdSessionRegistration.$data.isMatched,
        session: this.message.cpdSessionRegistration.$data.session,
      })
      .result();

    const cpdSessionRegistration = CpdSessionRegistration.$inflate(
      response.cpdSessionRegistration,
    ).first();

    if (!cpdSessionRegistration) {
      throw new Error("Failed to update cpd session registration");
    }

    return { cpdSessionRegistration };
  }
}
