import { RecordKnowledgeBaseDocumentHandler } from "@/module/knowledge-base/action/document/record-knowledge-base-document.action";
import { CreateKnowledgeBaseDocumentFileLogHandler } from "@/module/knowledge-base/action/file-log/create-knowledge-base-document-file-log.action";
import { DeleteKnowledgeBaseDocumentFileLogHandler } from "@/module/knowledge-base/action/file-log/delete-knowledge-base-document-file-log.action";
import { UpdateKnowledgeBaseDocumentFileLogHandler } from "@/module/knowledge-base/action/file-log/update-knowledge-base-document-file-log.action";
import { CreateKnowledgeBaseCategoryHandler } from "./category/create-knowledge-base-category.action";
import { DeleteKnowledgeBaseCategoryHandler } from "./category/delete-knowledge-base-category.action";
import { ReadKnowledgeBaseCategoryHandler } from "./category/read-knowledge-base-category.action";
import { UpdateKnowledgeBaseCategoryHandler } from "./category/update-knowledge-base-category.action";
import { AddKnowledgeBaseCategoryDocumentHandler } from "./document/add-knowledge-base-category-document.action";
import { CreateKnowledgeBaseDocumentHandler } from "./document/create-knowledge-base-document.action";
import { DeleteKnowledgeBaseDocumentHandler } from "./document/delete-knowledge-base-document.action";
import { ReadKnowledgeBaseDocumentHandler } from "./document/read-knowledge-base-document.action";
import { RemoveKnowledgeBaseCategoryDocumentHandler } from "./document/remove-knowledge-base-category-document.action";
import { UpdateKnowledgeBaseDocumentHandler } from "./document/update-knowledge-base-document.action";
import { DownloadKnowledgeBaseDocumentFileLogHandler } from "@/module/knowledge-base/action/file-log/download-knowledge-base-document-file-log.action";

export const Actions = {
  "knowledge-base/document/read": ReadKnowledgeBaseDocumentHandler,
  "knowledge-base/document/record": RecordKnowledgeBaseDocumentHandler,
  "knowledge-base/category/read": ReadKnowledgeBaseCategoryHandler,
  "knowledge-base/category/create": CreateKnowledgeBaseCategoryHandler,
  "knowledge-base/document/create": CreateKnowledgeBaseDocumentHandler,
  "knowledge-base/category/update": UpdateKnowledgeBaseCategoryHandler,
  "knowledge-base/document/update": UpdateKnowledgeBaseDocumentHandler,
  "knowledge-base/category/delete": DeleteKnowledgeBaseCategoryHandler,
  "knowledge-base/document/delete": DeleteKnowledgeBaseDocumentHandler,
  "knowledge-base/category/document/add":
    AddKnowledgeBaseCategoryDocumentHandler,
  "knowledge-base/category/document/remove":
    RemoveKnowledgeBaseCategoryDocumentHandler,
  "knowledge-base/document/file-log/create":
    CreateKnowledgeBaseDocumentFileLogHandler,
  "knowledge-base/document/file-log/delete":
    DeleteKnowledgeBaseDocumentFileLogHandler,
  "knowledge-base/document/file-log/update":
    UpdateKnowledgeBaseDocumentFileLogHandler,
  "knowledge-base/document/file-log/download":
    DownloadKnowledgeBaseDocumentFileLogHandler,
};
