import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerContact } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

interface ComputeIntroducerContactStatsMessage {
  introducerContact: IntroducerContact | string;
}

interface ComputeIntroducerContactStatsResponse {
  lastLead?: DateTime | undefined;
  lastCpdAttendance?: DateTime | undefined;
  lifeTimeValue: number;
  totalLeads: number;
}

export class ComputeIntroducerContactStatsHandler extends ActionBase {
  constructor(private message: ComputeIntroducerContactStatsMessage) {
    super();
  }

  public async execute(): Promise<ComputeIntroducerContactStatsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-contact/{introducerContact}/stats",
      {
        introducerContact:
          "string" === typeof this.message.introducerContact
            ? this.message.introducerContact
            : this.message.introducerContact.id,
      },
    )
      .method("get")
      .result();

    return {
      ...response,
      lastLead: response.lastLead
        ? DateTime.fromString(response.lastLead)
        : undefined,
      lastCpdAttendance: response.lastCpdAttendance
        ? DateTime.fromString(response.lastCpdAttendance)
        : undefined,
    };
  }
}
