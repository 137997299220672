import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerOutsource } from "@kinherit/sdk";

export interface UpdateIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource;
}

interface UpdateIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
}

export class UpdateIntroducerOutsourceHandler extends ActionBase {
  constructor(private message: UpdateIntroducerOutsourceMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerOutsourceResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-outsource/{introducerOutsource}",
      {
        introducerOutsource: this.message.introducerOutsource,
      },
    )
      .method("patch")
      .body({
        status: this.message.introducerOutsource.$data.status,
        type: this.message.introducerOutsource.$data.type,
        company: this.message.introducerOutsource.$data.company,
        expectedValue: this.message.introducerOutsource.$data.expectedValue,
        paidValue: this.message.introducerOutsource.$data.paidValue,
        paidAt: this.message.introducerOutsource.$data.paidAt,
        nextActionAt: this.message.introducerOutsource.$data.nextActionAt,
        assignedTo: this.message.introducerOutsource.$data.assignedTo,
        datedAt: this.message.introducerOutsource.$data.datedAt,
      })

      .result();

    return {
      introducerOutsources: IntroducerOutsource.$inflate(
        response.introducerOutsource,
        undefined,
        response.$rootIds,
      ),
    };
  }
}
