<template>
  <div class="introducer-company-details-accounting">
    <PageHeader htag="h2" text="Xero Integration" class="mt-4" />
    <Card>
      <Form
        v-if="!xeroId"
        :config="$data.form"
        @submit="submit"
        is-borderless
      />
      <div v-if="xeroId">
        <p class="level">
          <span>
            Linked to Xero with ID:
            <code>{{ xeroId }}</code>
          </span>

          <Button
            @click="removeXeroId"
            icon-left="Trash"
            size="is-small"
            color="is-plain"
            class="delete-item"
            aria-label="Remove Xero ID"
            text="Remove Xero ID"
          />
        </p>
        <Table
          is-striped
          is-narrow
          :columns="table.columns"
          :rows="table.rows"
          @loaded="fetch"
        >
          <template #name="{ row }">
            {{ row.contact.name }}
          </template>
          <template #invoiceNumber="{ row }">
            {{ row.invoiceNumber }}
            <small v-if="row.reference">({{ row.reference }})</small>
          </template>
          <template #date="{ row }">
            {{ formatDate(row.date) }}
          </template>
          <template #amount="{ row }">
            {{ new Currency({ amount: row.amountPaid }).format }}
          </template>
        </Table>
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadInvoicesForm } from "@/module/external/form/read-invoices.form";
import { IntroducerCompanyAccountForm } from "@/module/introducer.company/form/introducer-company-accounting.form";
import { StyleService } from "@/service/style.service";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Form } from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import TextField from "@kinherit/framework/component.input/text-field";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsAccountingRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsAccountingRoute,
  mixins: [IntroducerCompanyDetailsMixin, StyleService.mixin],
  components: {
    Card,
    TextField,
    Button,
    Table,
    Form,
    PageHeader,
  },
  data: () => ({
    Currency,
    // tabs: {
    //   active: 0,
    //   config: [
    //     {
    //       label: "Kinherit",
    //       icon: StyleService.icon.kintin.icon,
    //       key: "kintinXeroContactId" as const,
    //       type: "KINHERIT" as const,
    //     },
    //   ],
    // },
    table: {
      columns: [
        {
          title: "Name",
          slot: "name",
        },
        {
          title: "Date",
          slot: "date",
        },
        {
          title: "Type",
          field: "type",
        },
        {
          title: "Invoice Number",
          slot: "invoiceNumber",
        },
        {
          title: "Amount",
          slot: "amount",
        },
        {
          title: "Status",
          field: "status",
        },
      ] as TableColumn[],
      rows: Array<any>(),
    },
    form: IntroducerCompanyAccountForm(),
    filters: ReadInvoicesForm(),
  }),
  computed: {
    xeroId(): string | null {
      if (!this.introducerCompany) {
        return null;
      }

      return this.introducerCompany.xeroContact?.xeroContactId ?? null;
    },
  },
  methods: {
    formatDate(dateString: string): string {
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", options);
    },
    async fetch(): Promise<void> {
      if (!this.xeroId) {
        this.table.rows = [];
        return;
      }

      const { invoices } = await window.Kernel.ActionBus.execute(
        "external/xero/invoices/read",
        {
          ...this.filters,
          contactIds: [this.xeroId],
          type: "KINHERIT",
        },
      );

      this.table.rows = invoices;
    },
    async removeXeroId(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }
      await DangerDialog({
        dialog: {
          title: `Reset Xero ID`,
          message: `Are you sure you want to remove the link to Xero?`,
        },
      });

      this.introducerCompany.xeroContact = null;

      await window.Kernel.ActionBus.execute("introducer/company/update", {
        introducerCompany: this.introducerCompany,
      });
    },
    async submit(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const selectedXeroContact = this.$data.form.localData.contact;

      if (!selectedXeroContact) {
        return;
      }

      this.introducerCompany.xeroContact = selectedXeroContact;

      await window.Kernel.ActionBus.execute("introducer/company/update", {
        introducerCompany: this.introducerCompany,
      });
    },
  },
});
</script>
