import { CreateTagAction } from "./create-tag.action";
import { DeleteTagAction } from "./delete-tag.action";
import { ReadTagHandler } from "./read-tag.action";
import { RecordTagHandler } from "./record-tag.action";
import { UpdateTagAction } from "./update-tag.action";

export const Actions = {
  "admin/tag/read": ReadTagHandler,
  "admin/tag/record": RecordTagHandler,
  "admin/tag/update": UpdateTagAction,
  "admin/tag/create": CreateTagAction,
  "admin/tag/delete": DeleteTagAction,
};
