import { Module } from "@/config/module.config";
import { Actions } from "./action";
import { Routes } from "./page";

Module.register({
  name: "kinvault.will-revision",
  routes: Routes,
  actions: Actions,
  session: true,
  permissions: [],
});

export default {};
