import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import {
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Subscription,
} from "@kinherit/sdk";

interface CreateKintinStorageRequestLogMessage {
  kintin: string | Kintin;
  subscription: string | Subscription;
  storageRequest: string | PhysicalStorageFile;
  physicalStorageFileLog: PhysicalStorageFileLog;
}

interface CreateKintinStorageRequestLogResponse {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

export class CreateKintinStorageRequestLogHandler extends ActionBase {
  constructor(private message: CreateKintinStorageRequestLogMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinStorageRequestLogResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}/log",
      {
        kintin: this.message.kintin,
        subscription: this.message.subscription,
        physicalStorageFile: this.message.storageRequest,
      },
    )
      .method("post")

      .body({
        completedAt: this.message.physicalStorageFileLog.$data.completedAt,
        requestedAt: this.message.physicalStorageFileLog.$data.requestedAt,
        confirmedBy: this.message.physicalStorageFileLog.$data.confirmedBy,
        createdBy: this.message.physicalStorageFileLog.$data.createdBy,
        file: this.message.physicalStorageFileLog.$data.file,
        logAction: this.message.physicalStorageFileLog.$data.logAction,
        notes: this.message.physicalStorageFileLog.$data.notes,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const physicalStorageFileLog = PhysicalStorageFileLog.$inflate(
      response.physicalStorageFileLog,
    ).first();

    if (!physicalStorageFileLog) {
      throw new Error("Failed to create property");
    }

    this.message.physicalStorageFileLog.$delete();

    return { physicalStorageFileLog };
  }
}
