import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerCompany } from "@kinherit/sdk";

interface ComputeIntroducerCompanyStatsMessage {
  introducerCompany: IntroducerCompany;
}

interface ComputeIntroducerCompanyStatsResponse {
  lastLead?: string | undefined;
  lifeTimeValue: number;
  lifeTimeAvgValue: number;
  totalContacts: number;
  totalContracts: number;
  totalLeads: number;
  quarters: {
    name: "Q1" | "Q2" | "Q3" | "Q4" | "QTD" | "Last 12 Months";
    year: string;
    totalLeads: number;
    convertedToCall1: number;
    totalSales: number;
    averagePriceOfSale: number;
  }[];
}

export class ComputeIntroducerCompanyStatsHandler extends ActionBase {
  constructor(private message: ComputeIntroducerCompanyStatsMessage) {
    super();
  }

  public async execute(): Promise<ComputeIntroducerCompanyStatsResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/introducer-company/{introducerCompany}/stats",
      {
        introducerCompany:
          "string" === typeof this.message.introducerCompany
            ? this.message.introducerCompany
            : this.message.introducerCompany.id,
      },
    )
      .method("get")
      .result();

    return response;
  }
}
