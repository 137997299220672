import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface SendBrandedKinvaultInvitationEmailMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface SendBrandedKinvaultInvitationEmailResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export class SendBrandedKinvaultInvitationEmailHandler extends ActionBase {
  constructor(private message: SendBrandedKinvaultInvitationEmailMessage) {
    super();
  }

  public async execute(): Promise<SendBrandedKinvaultInvitationEmailResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}",
      {
        brandedKinvaultInvitation: this.message.brandedKinvaultInvitation,
        invitationType: "initial",
      },
    )
      .method("put")
      .result();

    const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
    ).first();

    if (!brandedKinvaultInvitation) {
      throw new Error("Failed to send branded kinvault invitation email");
    }

    return {
      brandedKinvaultInvitation,
    };
  }
}
