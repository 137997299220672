import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Beneficiary, Kintin } from "@kinherit/sdk";

interface CreateBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface CreateBeneficiaryResponse {
  beneficiary: Beneficiary;
}

export class CreateBeneficiaryHandler extends ActionBase {
  constructor(private message: CreateBeneficiaryMessage) {
    super();
  }

  public async execute(): Promise<CreateBeneficiaryResponse> {
    const { beneficiary: beneficiaryData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")

      .body({
        classGiftType: this.message.beneficiary.$data.classGiftType,
        forPerson: this.message.beneficiary.$data.forPerson,
        ifPredeceased: this.message.beneficiary.$data.ifPredeceased,
        notes: this.message.beneficiary.$data.notes,
        person: this.message.beneficiary.$data.person,
        position: this.message.beneficiary.$data.position,
        reservePerson: this.message.beneficiary.$data.reservePerson,
        reserveclassGiftType:
          this.message.beneficiary.$data.reserveclassGiftType,
        shareFraction: this.message.beneficiary.$data.shareFraction,
        sharePercentage: this.message.beneficiary.$data.sharePercentage,
        kintin: this.message.kintin.toString(),
      })
      .result();

    const beneficiary = Beneficiary.$inflate(beneficiaryData).first();

    if (!beneficiary) {
      throw new Error("Failed to create beneficiary");
    }

    this.message.beneficiary.$delete();

    return { beneficiary };
  }
}
