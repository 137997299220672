import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KintinCheckFollowUp, Note } from "@kinherit/sdk";

interface CreateKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface CreateKintinCheckFollowUpResponse {
  kintinCheckFollowUp: KintinCheckFollowUp;
}

export class CreateKintinCheckFollowUpHandler extends ActionBase {
  constructor(private message: CreateKintinCheckFollowUpMessage) {
    super();
  }

  public async execute(): Promise<CreateKintinCheckFollowUpResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups",
      {
        kintin: this.message.kintin,
      },
    )
      .method("post")
      .body({
        step: this.message.kintinCheckFollowUp.$data.step,
        kintin: this.message.kintin,
        note: {
          completedAt: this.message.kintinCheckFollowUp.note.$data.completedAt,
          kintin: this.message.kintin,
          name: this.message.kintinCheckFollowUp.note.$data.name,
          notes: this.message.kintinCheckFollowUp.note.$data.notes,
          dueAt: this.message.kintinCheckFollowUp.note.$data.dueAt,
          pinned: this.message.kintinCheckFollowUp.note.$data.pinned,
          type: "kintinCheckFollowUp",
        },
      })

      .result();

    const kintinCheckFollowUp = KintinCheckFollowUp.$inflate(
      response.kintinCheckFollowUp,
    ).first();

    this.message.kintinCheckFollowUp.$delete();

    if (!kintinCheckFollowUp) {
      throw new Error("Failed to create kintin check");
    }

    Note.$inflate(response.note);

    return {
      kintinCheckFollowUp,
    };
  }
}
