import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Beneficiary, Kintin } from "@kinherit/sdk";

interface UpdateBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface UpdateBeneficiaryResponse {
  beneficiary: Beneficiary;
}

export class UpdateBeneficiaryHandler extends ActionBase {
  constructor(private message: UpdateBeneficiaryMessage) {
    super();
  }

  public async execute(): Promise<UpdateBeneficiaryResponse> {
    const { beneficiary: beneficiaryData } = await Api.resource(
      "portal",
      "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary/{beneficiary}",
      {
        kintin: this.message.kintin,
        beneficiary: this.message.beneficiary.$data.id,
      },
    )
      .method("patch")

      .body({
        classGiftType: this.message.beneficiary.$data.classGiftType,
        forPerson: this.message.beneficiary.$data.forPerson,
        ifPredeceased: this.message.beneficiary.$data.ifPredeceased,
        notes: this.message.beneficiary.$data.notes,
        person: this.message.beneficiary.$data.person,
        position: this.message.beneficiary.$data.position,
        reservePerson: this.message.beneficiary.$data.reservePerson,
        reserveclassGiftType:
          this.message.beneficiary.$data.reserveclassGiftType,
        shareFraction: this.message.beneficiary.$data.shareFraction,
        sharePercentage: this.message.beneficiary.$data.sharePercentage,
        sortOrder: this.message.beneficiary.$data.sortOrder,
      })
      .result();

    const beneficiary = Beneficiary.$inflate(beneficiaryData).first();

    if (!beneficiary) {
      throw new Error("Failed to update beneficiary");
    }

    return { beneficiary };
  }
}
