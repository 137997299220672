import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface UpdateBrandedKinvaultInvitationMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface UpdateBrandedKinvaultInvitationResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export class UpdateBrandedKinvaultInvitationHandler extends ActionBase {
  constructor(private message: UpdateBrandedKinvaultInvitationMessage) {
    super();
  }

  public async execute(): Promise<UpdateBrandedKinvaultInvitationResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}",
      {
        brandedKinvaultInvitation: this.message.brandedKinvaultInvitation.id,
      },
    )
      .method("patch")

      .body({
        brandedKinvault:
          this.message.brandedKinvaultInvitation.$data.brandedKinvault,
        createdBy: this.message.brandedKinvaultInvitation.$data.createdBy,
        profile: this.message.brandedKinvaultInvitation.$data.profile,
        signUpToken: this.message.brandedKinvaultInvitation.$data.signUpToken,
        externalRef: this.message.brandedKinvaultInvitation.$data.externalRef,
        status: this.message.brandedKinvaultInvitation.$data.status,
        sentAt: this.message.brandedKinvaultInvitation.$data.sentAt,
        completedAt: this.message.brandedKinvaultInvitation.$data.completedAt,
        declinedAt: this.message.brandedKinvaultInvitation.$data.declinedAt,
        introducerContact:
          this.message.brandedKinvaultInvitation.$data.introducerContact,
        isBillable: this.message.brandedKinvaultInvitation.$data.isBillable,
      })
      .result();

    const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
    ).first();

    if (!brandedKinvaultInvitation) {
      throw new Error("BrandedKinvaultInvitation not found");
    }

    return {
      brandedKinvaultInvitation,
    };
  }
}
