import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface UploadKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
  file: File;
}

interface UploadKintinStorageRequestResponse {
  subscription: Subscription;
  physicalStorageFiles: Array<PhysicalStorageFile>;
}

export class UploadKintinStorageRequestHandler extends ActionBase {
  constructor(private message: UploadKintinStorageRequestMessage) {
    super();
  }

  public async execute(): Promise<UploadKintinStorageRequestResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{storageRequest}",
      {
        kintin: this.message.kintin,
        subscription: this.message.subscription,
        storageRequest: this.message.storageRequest,
      },
    )
      .method("put")
      .files({
        uploadedFile: this.message.file,
      })

      .result();

    const subscription = Subscription.$inflate(response.subscription).first();

    if (!subscription) {
      throw new Error("Failed to create storage request");
    }

    return {
      subscription,
      physicalStorageFiles: PhysicalStorageFile.$inflate(
        response.physicalStorageFile,
      ),
    };
  }
}
