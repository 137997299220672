import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { AccountReferral, Lead, Profile } from "@kinherit/sdk";

interface CreateLeadMessage {
  lead: Lead;
}

interface CreateLeadResponse {
  lead: Lead;
  referrals: Array<AccountReferral>;
  profiles: Array<Profile>;
}

export class CreateLeadHandler extends ActionBase {
  constructor(private message: CreateLeadMessage) {
    super();
  }

  public async execute(): Promise<CreateLeadResponse> {
    const response = await Api.resource("portal", "/v2/portal/lead")
      .method("post")

      .body({
        assignedTo: this.message.lead.$data.assignedTo,
        description: this.message.lead.$data.description,
        ownedBy: this.message.lead.$data.ownedBy,
        status: this.message.lead.$data.status,
        profile: {
          firstName: this.message.lead.profile.$data.firstName,
          lastName: this.message.lead.profile.$data.lastName,
          phoneNumbers: this.message.lead.profile.phoneNumbers.map(
            (phoneNumber) => ({
              tel: phoneNumber.tel,
            }),
          ),
          emails: this.message.lead.profile.emails.map((email) => ({
            email: email.email,
            primary: email.primary,
          })),
        },
        referral: {
          contact: this.message.lead.referral?.contact?.$data.id,
          referralCode: this.message.lead.referral?.referralCode?.$data.id,
        },
        externalLeadId: this.message.lead.$data.externalLeadId,
      })
      .result();

    const lead = Lead.$inflate(response.lead).first();

    if (!lead) {
      throw new Error("Failed to create lead");
    }

    this.message.lead.$delete({
      profile: {
        emails: true,
        phoneNumbers: true,
      },
      referral: true,
    });

    return {
      lead,
      referrals: AccountReferral.$inflate(response.accountReferral),
      profiles: Profile.$inflate(response.profile),
    };
  }
}
