import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface UpdateIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export interface UpdateIntroducerFeePaymentRunResponse {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export class UpdateIntroducerFeePaymentRunHandler extends ActionBase {
  constructor(private message: UpdateIntroducerFeePaymentRunMessage) {
    super();
  }

  public async execute(): Promise<UpdateIntroducerFeePaymentRunResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
      this.message,
    )
      .method("patch")
      .body({
        completedAt: this.message.introducerFeePaymentRun.$data.completedAt,
        name: this.message.introducerFeePaymentRun.$data.name,
        status: this.message.introducerFeePaymentRun.$data.status,
      })
      .result();

    const introducerFeePaymentRun = IntroducerFeePaymentRun.$inflate(
      repsonse.introducerFeePaymentRun,
    ).first();

    if (!introducerFeePaymentRun) {
      throw new Error("Failed to Update introducerfeepaymentrun");
    }

    return {
      introducerFeePaymentRun: introducerFeePaymentRun,
    };
  }
}
