import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Person } from "@kinherit/sdk";

interface UpdateOfficerCallStatusMessage {
  person: Person;
}

interface UpdateOfficerCallStatusResponse {
  person: Person;
}

export class UpdateOfficerCallStatusHandler extends ActionBase {
  constructor(private message: UpdateOfficerCallStatusMessage) {
    super();
  }

  public async execute(): Promise<UpdateOfficerCallStatusResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/kintin-person/{kintin}/person/{person}",
      {
        kintin: this.message.person.$data.kintin as string,
        person: this.message.person.id,
      },
    )
      .method("patch")

      .body({
        officerCallStatus: this.message.person.$data.officerCallStatus,
      })
      .result();

    const person = Person.$inflate(response.person).first();

    if (!person) {
      throw new Error(`Failed to create person`);
    }

    return { person };
  }
}
