import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface CreateIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment;
}

export interface CreateIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
}

export class CreateIntroducerFeePaymentHandler extends ActionBase {
  constructor(private message: CreateIntroducerFeePaymentMessage) {
    super();
  }

  public async execute(): Promise<CreateIntroducerFeePaymentResponse> {
    const repsonse = await Api.resource(
      "portal",
      "/v2/portal/introducer-fee-payment",
    )
      .method("post")
      .body({
        completedAt: this.message.introducerFeePayment.$data.completedAt,
        status: this.message.introducerFeePayment.$data.status,
        xeroContact: this.message.introducerFeePayment.$data.xeroContact,
        introducerFeePaymentRun:
          this.message.introducerFeePayment.$data.introducerFeePaymentRun,
      })
      .result();

    const introducerFeePayment = IntroducerFeePayment.$inflate(
      repsonse.introducerFeePayment,
    ).first();

    if (!introducerFeePayment) {
      throw new Error("Failed to create introducerfeepayment");
    }

    this.message.introducerFeePayment.$delete();

    return {
      introducerFeePayment: introducerFeePayment,
    };
  }
}
