import { Api } from "@/service/api.service";
import { ActionBase } from "@kinherit/framework/action-bus/base";
import { KnowledgeBaseDocument } from "@kinherit/sdk";

interface CreateKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface CreateKnowledgeBaseDocumentResponse {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

export class CreateKnowledgeBaseDocumentHandler extends ActionBase {
  constructor(private message: CreateKnowledgeBaseDocumentMessage) {
    super();
  }

  public async execute(): Promise<CreateKnowledgeBaseDocumentResponse> {
    const response = await Api.resource(
      "portal",
      "/v2/portal/knowledge-base-document",
    )
      .method("post")

      .body({
        title: this.message.knowledgeBaseDocument.title,
        content: this.message.knowledgeBaseDocument.content,
      })
      .result();

    const newDocumentId = response.knowledgeBaseDocument?.first()?.id;

    if (!newDocumentId) {
      throw new Error("Could not create knowledge base document");
    }

    await this.message.knowledgeBaseDocument.categories.forEachAsync(
      async (category) => {
        await Api.resource(
          "portal",
          `/v2/portal/knowledge-base-category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/add`,
          {
            knowledgeBaseCategory: category.id,
            knowledgeBaseDocument: newDocumentId,
          },
        )
          .method("post")

          .result();
      },
    );

    const knowledgeBaseDocument = KnowledgeBaseDocument.$inflate(
      response.knowledgeBaseDocument,
    ).first();

    if (!knowledgeBaseDocument) {
      throw new Error("Failed to create knowledge base document");
    }

    this.message.knowledgeBaseDocument.$delete();

    return {
      knowledgeBaseDocument,
    };
  }
}
