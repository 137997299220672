import { ActionBase } from "@kinherit/framework/action-bus/base";
import { Api, Kintin } from "@kinherit/sdk/index";

export class DuplicateKintinHandler extends ActionBase {
  constructor(private message: { kintin: Kintin }) {
    super();
  }

  public async execute(): Promise<{
    kintin: string;
  }> {
    return await Api.resource("portal", "/v2/portal/kintin/duplicate")
      .method("put")
      .body({
        kintin: this.message.kintin.id,
      })
      .result();
  }
}
